import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Result, Radio } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
};

const UserForm = ({ data, updateHandler, createHandler, result, setResult, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleLegalType = (e) => {
    setLegalType(e.target.value);
    form.setFieldsValue({ legalType: e.target.value });
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NOVI KORISNIK' : 'IZMENA KORISNIKA'}`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={user}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item name='legalType' label='Tip subjekta'>
                  <Radio.Group onChange={handleLegalType}>
                    <Radio value='PRIVATE'>Fizičko lice</Radio>
                    <Radio value='COMPANY'>Pravno lice</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite email!',
                    },
                  ]}
                  className='userField edit-product'
                >
                  <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                  label='Ime'
                  name='firstName'
                  rules={[
                    {
                      required: true,
                      //    whitespace: true,
                      message: 'Molimo Vas unesite ime!',
                    },
                  ]}
                  className='userField edit-product'
                >
                  <Input placeholder='Ime' />
                </Form.Item>

                <Form.Item
                  label='Prezime'
                  name='lastName'
                  rules={[
                    {
                      required: true,
                      //   whitespace: true,
                      message: 'Molimo Vas unesite prezime!',
                    },
                  ]}
                  className='userField edit-product'
                >
                  <Input placeholder='Prezime' />
                </Form.Item>

                <Form.Item label='Telefon' name='phone'
                  className='userField edit-product'>
                  <Input placeholder='Telefon' />
                </Form.Item>

                <Form.Item label='Adresa' name='address'
                  className='userField edit-product'>
                  <Input placeholder='Adresa' />
                </Form.Item>

                <Form.Item
                  label='Grad'
                  name='city'
                  className='userField edit-product'
                >
                  <Input placeholder='Grad' />
                </Form.Item>

                <Form.Item label='Poštanski broj' name='zip' style={{ marginLeft: '15px' }}
                  className='userField edit-product'>
                  <Input placeholder='Poštanski broj' />
                </Form.Item>

                <Form.Item
                  label='Država'
                  name='country'
                  className='userField edit-product'
                >
                  <Input placeholder='Država' />
                </Form.Item>

                {legalType === 'COMPANY' && (
                  <>
                    <Form.Item
                      className='userField edit-product'
                      style={{ marginLeft: '15px' }}
                      label='Naziv kompanije'
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          // whitespace: true,
                          message: 'Molimo Vas unesite naziv kompanije!',
                        },
                      ]}
                    >
                      <Input placeholder='Naziv kompanije' />
                    </Form.Item>

                    <Form.Item
                      label='PDV broj'
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          // whitespace: true,
                          message: 'Molimo Vas unesite PDV broj!',
                        },
                      ]}
                      className='userField edit-product'
                    >
                      <Input placeholder='PDV broj' />
                    </Form.Item>
                  </>
                )}

                {isNew && (
                  <Form.Item
                    label='Lozinka'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas unesite lozinku!',
                      },
                    ]}
                    className='userField edit-product'
                  >
                    <Input.Password placeholder='Lozinka' />
                  </Form.Item>
                )}

                <Form.Item
                  label='Uloga'
                  name='role'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas odaberite ulogu!',
                    },
                  ]}
                  className='userField edit-product'
                >
                  <Select placeholder='Uloga'>
                    <Select.Option value='superAdmin'>superAdmin</Select.Option>
                    <Select.Option value='admin'>Admin</Select.Option>
                    <Select.Option value='sales'>Komercijalista</Select.Option>
                    <Select.Option value='operator'>Operater</Select.Option>
                    <Select.Option value='operatorI'>Operater 1</Select.Option>
                    <Select.Option value='storekeeper'>Magacioner</Select.Option>
                    <Select.Option value='preparer'>Pripremaš</Select.Option>
                  </Select>
                </Form.Item>

                {!isNew && (
                  <Form.Item
                    label='Status'
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: 'Molimo Vas odaberite status!',
                      },
                    ]}
                    className='userField edit-product'
                  >
                    <Select>
                      <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                      <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                      <Select.Option value='DELETED'>DELETED</Select.Option>
                      <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item label='Avatar' className='upload-wrapper right userField edit-product' name='avatar' >
                  <UploadBox editHandler={avatarHandler} deleteHandler={deleteAvatarHandler} image={image} index={0} name='avatar' />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    {`${isNew ? 'Dodaj' : 'Izmeni'} korisnika`}
                  </Button>
                </Form.Item>
              </Form>
            )}
            {result && (
              <Result
                title='Korisnik je kreiran i poslat je verifikacioni mail'
                extra={
                  <Button type='primary' key='console' onClick={() => setResult(false)}>
                    Ok
                  </Button>
                }
              />
            )}
          </Card>

          {modal.visible && (
            <GalleryModal
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
