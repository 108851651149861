import React from 'react';
import { Input, Button, Form, Row, Card, Select } from 'antd';
import { Option } from 'antd/lib/mentions';

const { TextArea } = Input;

const initValues = {
  productCode: '',
  productName: '',
  quantity: '',
  stack: '',
  totalValue: '',
  averagePrice: '',
  purchasePrice: '',
  family: '',
  group: '',
  type: '',
  species: '',
  note: '',
  barCode: '',
  description: '',
  tax: '',
  supplier: '',
  englishName: '',
  foreignName: '',
  manufacturer: '',
  catalogueNumber: '',
  max: '',
  min: '',
  weight: '',
  customTax: '',
  act: '',
  unitOneIn00: '',
  unitTwo: '',
  unitOneIn000: '',
  unitThreeIn00: '',
  unitTreeunitTwo: '',
  unitThreeTotal: '',
  unitFourIn00: '',
  unitFourIn000: '',
  unitFourTotal: '',
};

const ProductForm = ({ isNew, data, onSubmit, productPdf, tools, handleChangePDF }) => {
  const [form] = Form.useForm();

  let initialValues = data ? { ...data } : initValues;

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={`${isNew ? 'NOVI PROIZVOD' : 'IZMENA PROIZVODA'}`} bordered={false}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='horizontal'
            form={form}
          >
            <Form.Item
              label='Šifra proizvoda'
              name='productCode'
              rules={[{ required: true, message: 'Molimo Vas unesite šifru proizvoda!' }]}
              style={{ marginLeft: '30px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Naziv proizvoda'
              name='productName'
              rules={[{ required: true, message: 'Molimo Vas unesite naziv proizvoda!' }]}
              style={{ marginLeft: '25px' }}
            >
              <Input />
            </Form.Item>
            <Form.Item label='jm1' name='unitOneIn00' className='productField'>
              <Input />
            </Form.Item>
            <Form.Item style={{ marginLeft: '35px' }} label='PDF dokument' name='file'>
              <Select onChange={(e) => handleChangePDF(e)} allowClear>
                {productPdf.data.items &&
                  productPdf.data.items.length > 0 &&
                  productPdf.data.items.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.originalname}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ marginLeft: '55px' }} label='Alat' name='tool'>
              <Select
                allowClear
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {tools.data.items &&
                  tools.data.items.length > 0 &&
                  tools.data.items.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.dimension}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label='Količina' name='quantity' className='productField'>
              <Input />
            </Form.Item>
            <Form.Item label='jm2' name='unitTwo' className='productField'>
              <Input />
            </Form.Item>
            <Form.Item label='jm3' name='unitThreeIn00' className='productField'>
              <Input />
            </Form.Item>

            <div style={{ marginTop: '10px' }} className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} proizvod
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ProductForm;
