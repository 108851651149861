import React, { useEffect, useState } from 'react';
import { Tabs, Card, Input, Button, Form, InputNumber, Select, DatePicker } from 'antd';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import moment from 'moment';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};
const SERVER_URL_CALCULATOR =
  process.env.NODE_ENV === 'production'
    ? 'https://birografikaprodaja-api.concordsoft.solutions'
    : 'http://localhost:3333';

const { Option } = Select;

const OrderConfirmationForm = ({ isNew, data, onSubmit, token }) => {
  const [form] = Form.useForm();
  const [ordersToProduction, fetchOrdersToProduction] = useAxios('', null, token, 'get');

  let initialValues = data
    ? {
        ...data,
        date: moment(data.date),
        expectedDeliveryDate: moment(data.expectedDeliveryDate),
      }
    : {};

  useEffect(() => {
    fetchOrdersToProduction(`${SERVER_URL}/orders-to-production`, []);
  }, []);

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  const handleOrderToProductionChange = async (e) => {
    // console.log(e);

    ordersToProduction.data.forEach(async (element) => {
      // console.log(e, element._id);
      if (e.includes(element._id)) {
        console.log(element.companyID);
        // console.log(element);
        if (element.calculationId) {
          // console.log(element);
          const res = await Axios.get(`${SERVER_URL_CALCULATOR}/calculations-client/${element.calculationId}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });

          // console.log(res.data.client);

          form.setFieldsValue({
            commercialist: res.data.client.salesRepresentative,
            deliveryAddress: res.data.client.deliveryAddress
              ? res.data.client.deliveryAddress
              : res.data.client.address,
            contactPerson: res.data.client.contactPerson,
            contactDeliveryPerson: res.data.client.contactDeliveryPerson,
            email: res.data.client.email,
            client: res.data.client._id,
            currency: res.data.client.currency,
            clientname: res.data.client.companyName,
            clientcode: res.data.client.code,
          });
        } else {
          const res = await Axios.get(`${SERVER_URL}/company-code/${element.companyID}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` },
          });

          console.log(res.data);

          form.setFieldsValue({
            commercialist: res.data.salesRepresentative,
            deliveryAddress: res.data.deliveryAddress ? res.data.deliveryAddress : res.data.address,
            contactPerson: res.data.contactPerson,
            contactDeliveryPerson: res.data.contactDeliveryPerson,
            email: res.data.email,
            client: res.data._id,
            currency: res.data.currency,
            clientname: res.data.companyName,
            clientcode: res.data.code,
          });
        }
      }
    });

    if (e.length < 1) {
      form.setFieldsValue({
        commercialist: '',
        deliveryAddress: '',
        contactPerson: '',
        contactDeliveryPerson: '',
        email: '',
        client: '',
        currency: '',
        clientname: '',
        clientcode: '',
      });
    }
  };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card title={`${isNew ? 'NOVA POTVRDA PORUDŽBINE' : 'IZMENA POTVRDE PORUDŽBINE'}`}>
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            form={form}
            layout='horizontal'
          >
            <Form.Item name='ordernumber' label='Broj potvrde porudžbine:'>
              <Input disabled />
            </Form.Item>

            {isNew ? (
              <Form.Item required name='orders' label='Nalozi proizvodnji da proizvede:'>
                <Select mode='multiple' onChange={(e) => handleOrderToProductionChange(e)}>
                  {ordersToProduction &&
                    ordersToProduction.data &&
                    ordersToProduction.data &&
                    ordersToProduction.data.length > 0 &&
                    ordersToProduction.data.map((item, index) => {
                      return <Option value={item._id}>{item.code}</Option>;
                    })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item required label='Nalozi proizvodnji da proizvede:'>
                <Input
                  value={`${
                    data && data.orders && data.orders.length > 0 && data.orders.map((item, index) => ` ${item.code}`)
                  }`}
                  disabled
                ></Input>
              </Form.Item>
            )}

            <Form.Item label='Datum potvrde:' name='date'>
              <DatePicker format={'DD. MM. YYYY.'} />
            </Form.Item>

            <Form.Item name='commercialist' label='Komercijalista:'>
              <Input />
            </Form.Item>

            <Form.Item label='Očekivani datum isporuke(najkasnije do):' name='expectedDeliveryDate'>
              <DatePicker format={'DD. MM. YYYY.'} />
            </Form.Item>

            <Form.Item name='deliveryWay' label='Način isporuke:'>
              <Input />
            </Form.Item>

            <Form.Item name='deliveryAddress' label='Adresa isporuke:'>
              <Input />
            </Form.Item>

            <Form.Item name='contactDeliveryPerson' label='Kontakt osoba za isporuku:'>
              <Input />
            </Form.Item>

            <Form.Item name='contactPerson' label='Kontakt osoba za račun:'>
              <Input />
            </Form.Item>

            <Form.Item name='email' label='Mejl adresa za slanje e-fakture:'>
              <Input />
            </Form.Item>

            <Form.Item name='POnumber' label='PO broj(obavezno navesti na fakturi):'>
              <Input />
            </Form.Item>

            <Form.Item name='currency' label='Valuta:'>
              <Input />
            </Form.Item>

            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} potvrdu porudžbine
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default OrderConfirmationForm;
