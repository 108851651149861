import React, { useEffect, useContext, useState } from 'react';
import { Button, Card, Row, Col } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { forEach } from 'lodash';
// import { ConsoleSqlOutlined } from '@ant-design/icons';

const ViewMaterial = (props) => {
  const { materialId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [rolls, fetchRolls] = useAxios('', [], currentuser.data.token, 'get');
  const [materialConsumption, fetchMaterialConsumption] = useAxios('', [], currentuser.data.token, 'get');
  const [rollDataState, setRollDataState] = useState();
  const filter = { material: materialId };
  const filter2 = { MaterialID: materialId };
  useEffect(() => {
    if (materialId) {
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`);
      fetchRolls(`${SERVER_URL}/rolls?filter=${JSON.stringify(filter)}`);
      fetchMaterialConsumption(`${SERVER_URL}/material-consumption?filter=${JSON.stringify(filter2)}`);
      // fetchProductionProcess(`${SERVER_URL}/production-process`, []);
    }
  }, [materialId, fetchRollsReserved, fetchRolls, fetchMaterialConsumption]);

  let rollData = [];
  let reservedLength;
  let reservedWeight;
  let spentByWO;
  let totalSpent;
  let toReduce;
  let consumptionArray = [];

  if (
    rolls &&
    rolls.data &&
    rolls.data.items &&
    materialConsumption &&
    materialConsumption.data &&
    materialConsumption.data.items &&
    rollsReserved &&
    rollsReserved.data &&
    rollsReserved.data.items
  ) {
    let status;
    for (const roll of rolls.data.items) {
      // console.log('roll', roll);
      reservedWeight = 0;
      // supply = 0;
      reservedLength = 0;
      spentByWO = 0;
      toReduce = 0;
      totalSpent = 0;

      let workOrderCodes = [];
      let reservedCodes = [];
      consumptionArray = [];
      let resLength = 0;
      let resWeight = 0;
      status = roll.status;
      if (roll && roll.rollsReserved) {
        for (const rollReservedInRoll of roll.rollsReserved) {
          if (rollReservedInRoll && rollReservedInRoll.status === 'active') {
            resLength += rollReservedInRoll.materialQuantity;
            consumptionArray.push({
              workOrder: rollReservedInRoll?.workOrder?.code,
              reservedKG:
                roll.material.calculationMethod === 't*1000/p/s*1000'
                  ? (resLength * roll.material.specificWeight * roll.material.width) / 1000000
                  : ((resLength * roll.material.width) / 1000) * (roll.initialWeight / roll.initialState),
            });

            resWeight +=
              roll.material.calculationMethod === 't*1000/p/s*1000'
                ? (resLength * roll.material.specificWeight * roll.material.width) / 1000000
                : ((resLength * roll.material.width) / 1000) * (roll.initialWeight / roll.initialState);
          }
          for (const rollReserved of rollsReserved.data.items) {
            spentByWO = 0;
            if (rollReserved?._id === rollReservedInRoll?._id) {
              for (const matCons of materialConsumption.data.items) {
                if (matCons?.RollReservedID === rollReserved?._id) {
                  if (matCons?.workOrder === rollReserved?.workOrder?._id) {
                    spentByWO += matCons?.spentQuantity;
                    totalSpent += matCons?.spentQuantity;
                  } else {
                    workOrderCodes.push(`${rollReserved?.workOrder?.code} (${spentByWO.toFixed(2)}kg) `);
                    consumptionArray.push({ workOrder: rollReserved?.workOrder?.code, spentKG: spentByWO.toFixed(2) });
                  }
                }
              }
              if (spentByWO > 0) {
                workOrderCodes.push(`${rollReserved?.workOrder?.code} (${spentByWO.toFixed(2)}kg) `);
                consumptionArray.push({ workOrder: rollReserved?.workOrder?.code, spentKG: spentByWO.toFixed(2) });
              }
              if (rollReserved?.status === 'active') {
                reservedLength = rollReserved.materialQuantity;
                reservedWeight =
                  rollReserved.material.calculationMethod === 't*1000/p/s*1000'
                    ? (reservedLength * rollReserved.material.specificWeight * rollReserved.material.width) / 1000000
                    : ((reservedLength * rollReserved.material.width) / 1000) *
                      (roll.initialWeight / roll.initialState);
                reservedCodes.push(
                  ` ${rollReserved?.workOrder?.code} (${reservedLength}m / ${reservedWeight.toFixed(2)}kg)`,
                );
              }
            }
          }
        }
      }

      //helper funkcija za racunanje potrosnje i rezervisanog stanja
      if (consumptionArray && consumptionArray.length > 0) {
        const uniqueWorkOrders = consumptionArray
          .map((item) => item.workOrder)
          .filter((value, index, self) => self.indexOf(value) === index);
        consumptionArray = uniqueWorkOrders.map((workOrder) => {
          return consumptionArray
            .filter((item) => item.workOrder === workOrder)
            .reduce((acc, item) => {
              if (!acc.workOrder) {
                acc.workOrder = workOrder;
                acc.reservedKG = 0;
                acc.spentKG = 0;
              }

              if (item.reservedKG) {
                acc.reservedKG += parseFloat(item.reservedKG);
              }
              if (item.spentKG) {
                acc.spentKG += parseFloat(item.spentKG);
              }

              return acc;
            }, {});
        });

        for (let i = 0; i < consumptionArray.length; i++) {
          if (consumptionArray[i].spentKG > consumptionArray[i].reservedKG) {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].spentKG,
            };
          } else if (consumptionArray[i].spentKG < consumptionArray[i].reservedKG) {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].reservedKG,
            };
          } else {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].reservedKG,
            };
          }
        }

        for (let i = 0; i < consumptionArray.length; i++) {
          toReduce += consumptionArray[i].toReduce;
        }
      }

      if (!currentuser.data.role.includes('storekeeper')) {
        rollData.push({
          calculationMethod: roll.material.calculationMethod,
          materialName: roll.material.name,
          reservedWeight: resWeight?.toFixed(2),
          code: roll.material.code,
          type: roll.material.type,
          weight: roll.weight,
          width: roll.material.width,
          consumptionArray,
          specificWeight: roll.material.specificWeight,
          totalSpent,
          toReduce,
          rollId: roll.rollID,
          storagePosition: roll.storagePosition,
          workOrderCodes: workOrderCodes,
          reservedCodes: reservedCodes,
          QRCode: roll.QRCode,
          length: roll?.length?.toFixed(0),
          reservedLength: resLength?.toFixed(0),
          // reservedWeight: resWeight?.toFixed(2),
          initialLength: roll.initialLength?.toFixed(0),
          initialState: roll.initialState,
          initialWeight: roll.initialWeight?.toFixed(2),
          status: status,
        });
      } else {
        if (status !== 'Potrošena') {
          rollData.push({
            calculationMethod: roll.material.calculationMethod,
            materialName: roll.material.name,
            type: roll.material.type,
            code: roll.material.code,
            weight: roll.weight,
            totalSpent,
            toReduce,
            width: roll.material.width,
            specificWeight: roll.material.specificWeight,
            consumptionArray,
            rollId: roll.rollID,
            storagePosition: roll.storagePosition,
            workOrderCodes: workOrderCodes,
            reservedCodes: reservedCodes,
            QRCode: roll.QRCode,
            length: roll?.length?.toFixed(0),
            reservedLength: resLength?.toFixed(0),
            // reservedWeight: resWeight?.toFixed(2),
            initialLength: roll.initialLength?.toFixed(0),
            initialState: roll.initialState,
            initialWeight: roll.initialWeight?.toFixed(2),
            status: status,
          });
        }
      }
    }
  }

  useEffect(() => {
    sortTableData();
  }, [rollData.length < 1]);

  const sortTableData = () => {
    if (rollData) {
      let storageArr = [];
      let prodArr = [];
      let spentArr = [];
      let verifyFromProdArr = [];
      let verifyFromStorArr = [];

      if (rollData.length > 0) {
        for (let i = 0; i < rollData.length; i++) {
          if (rollData[i].status === 'U magacinu') {
            storageArr.push(rollData[i]);
          } else if (rollData[i].status === 'U proizvodnji') {
            prodArr.push(rollData[i]);
          } else if (rollData[i].status === 'Čeka verifikaciju(iz proizvodnje)') {
            verifyFromProdArr.push(rollData[i]);
          } else if (rollData[i].status === 'Potrošena') {
            if (!currentuser.data.role.includes('storekeeper')) {
              spentArr.push(rollData[i]);
            }
          } else if (rollData[i].status === 'Čeka verifikaciju(iz magacina)') {
            verifyFromStorArr.push(rollData[i]);
          }
        }

        rollData = [];

        rollData.push(...verifyFromProdArr);
        rollData.push(...verifyFromStorArr);
        rollData.push(...storageArr);
        rollData.push(...prodArr);
        rollData.push(...spentArr);

        setRollDataState(rollData);
      }
    }
  };
  // console.log('rollData', rollData);
  return (
    <div className='dashboard'>
      <div className='card-wrapper-material'>
        <Card title='PREGLED MATERIJALA' bordered={false}>
          <Row gutter={16} style={{ backgroundColor: '#216c97', margin: '10px 0', fontSize: '1.1rem', color: 'white' }}>
            <Col span={12}>Rolna</Col>
            <Col span={12} className='text-right'>
              QR
            </Col>
          </Row>
          {rollData &&
            rollData.length > 0 &&
            rollData.map((item, index) => (
              <Row key={index} gutter={16} className='rollReserved'>
                <Col span={16}>
                  <p>
                    Naziv: {item?.materialName} | Šifra: {item?.code} | ID rolne: {item?.rollId} | Stanje na ulazu:{' '}
                    {item?.initialLength}m ({item?.initialWeight}kg) | Fizičko stanje: {item?.length}m (
                    {item?.weight?.toFixed(2)}kg)
                  </p>

                  <p>{item?.storagePosition ? `Pozicija u magacinu: ${item.storagePosition}` : ''}</p>

                  <p>Rezervisano: {item?.reservedCodes?.length > 0 ? `${item?.reservedCodes}` : '0m (0kg)'}</p>
                  <p>
                    Dostupno stanje:
                    {item?.type === 'kg' &&
                      `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                    {item?.type === 'm2' &&
                      `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                  </p>
                  <p>
                    Upotreba po radnim nalozima:{' '}
                    {item?.workOrderCodes?.length > 0 ? item?.workOrderCodes?.join(', ') : 'Rolna još nije korištena'}
                  </p>
                  <p>Status rolne: {item?.status === 'spent' ? 'Potrošena' : item?.status}</p>
                </Col>
                <Col span={8} className='text-right'>
                  <img src={item?.QRCode ? item?.QRCode : ''} />
                </Col>
              </Row>
            ))}
          <div className='text-center'>
            <Button type='primary' onClick={() => (window.location.href = '/admin/materials')}>
              Zatvori
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ViewMaterial;
