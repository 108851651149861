import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { WorkTimeForm } from '../../components/forms';
import moment from "moment";

const EditWorkTime = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [workTime, fetchWorkTime] = useAxios('', {}, currentuser.data.token, 'get');
  const [machines, fetchMachines] = useAxios('', [], currentuser.data.token, 'get');
  const [machine, setMachine] = useState('');
  const [workTimeExists, setWorkTimeExists] = useState(false);

  const { workTimeId } = props.match.params;

  useEffect(() => {
    if (workTimeId) {
      fetchWorkTime(`${SERVER_URL}/work-time/${workTimeId}`, {});
    }
    fetchMachines(`${SERVER_URL}/machines`, []);
  }, [fetchWorkTime, workTimeId, fetchMachines]);

  const onSubmit = async (formData, isNew) => {
    if (!workTimeExists) {
      const method = isNew ? 'post' : 'put';
      const route = isNew ? `${SERVER_URL}/work-time` : `${SERVER_URL}/work-time/${workTimeId}`;
      try {
        await Axios[method](route, formData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        notification.success({
          message: `Radno vreme je ${isNew ? 'kreirano.' : 'izmenjeno.'}`,
          placement: 'bottomRight',
        });
        history.push('/admin/work-time');
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message ? error.message : error;
        console.log(msg);
        console.log(error);
        notification.error({
          message: msg,
          placement: 'bottomRight',
        });
        notification.error({
          message: 'Radno vreme vec postoji.',
          placement: 'bottomRight'
        });
      }
    } else {
      notification.error({
          message: 'Radno vreme vec postoji.',
          placement: 'bottomRight'
        });
    }
  };

  const addWorkTimeWholeYear = async (startTimeState,endTimeState) => {

    const pocetnoVreme = moment().set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 })
    const krajnjeVreme = moment().set({ hours: 22, minutes: 0, seconds: 0, milliseconds: 0 })

    if(startTimeState.isSame(pocetnoVreme) && endTimeState.isSame(krajnjeVreme)){
      notification.error({
          message: 'Promenite početno ili vreme završetka!',
          placement: 'bottomRight',
        });
    }else{
      if (!workTimeExists) {
      try {
        await Axios.post(`${SERVER_URL}/work-time-whole-year?machine=${machine}`, {startTimeState, endTimeState}, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        notification.success({
          message: `Radno vreme za celu godinu je kreirano.`,
          placement: 'bottomRight',
        });
        history.push('/admin/work-time');
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message ? error.message : error;
        console.log(msg);
        console.log(error);
        notification.error({
          message: msg,
          placement: 'bottomRight',
        });
      }
    }
    else {
      notification.error({
        message: 'Radno vreme već postoji.',
        placement: 'bottomRight'
      }

      );
    }
  };
    }
    
    

  const selectMachine = async (value, form) => {
    const formValues = form.getFieldsValue();
    if (value) {
      setMachine(value);
    }
    if (value || machine) {
      try {
        const response = await Axios.get(`${SERVER_URL}/last-work-time?machine=${value ? value : machine}&startTime=${formValues.startTime}&endTime=${formValues.endTime}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        let workTime = response.data;
        if (workTime.length > 0) {
          setWorkTimeExists(true);
        } else {
          setWorkTimeExists(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isDataFetched = !workTime.isLoading && workTime.data && currentuser.language && !machines.isLoading && machines.data;
  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!workTimeId && isDataFetched && (
          <WorkTimeForm
            isNew={true}
            machines={machines.data}
            language={currentuser.language}
            addWorkTimeWholeYear={addWorkTimeWholeYear}
            machineHandler={selectMachine}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {workTimeId && !workTime.isError && workTime.data && isDataFetched && (
          <WorkTimeForm
            isNew={false}
            data={workTime.data}
            machines={machines.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            machineHandler={selectMachine}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditWorkTime;
