export default  function calculateTotal(itemPrice, orderItems, extraCharges) {
	const totalItemPrice = itemPrice.reduce((acc, item, index) => {
	  return acc + (item * (orderItems[index]?.quantity || 0));
	}, 0);
  
	const totalExtraCharges = extraCharges.reduce((acc, item) => {
	  return acc + (item.printFormsPrice * item.printFormsAmount || 0) + (item.toolsIsChargingPrice * item.toolsAmount || 0);
	}, 0);
  
	return totalItemPrice + totalExtraCharges;
  }