import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import EmailVerification from '../../pages/EmailVerification';

const Routes = () => {
  const user = useContext(UserContext);
  ROUTES.map((item) => {
    if (item.label === 'Plan proizvodnje') {
      item.path = item.children[0]?.path;
      item.component = item.children[0]?.component;
      for (const child of item.children) {
        child.showInMenu = false;
      }
    }
    return item;
  });
  return (
    <Switch>
      <ProtectedRoute
        user={user.data}
        exact
        path='/admin'
        component={Home}
        allowed={['operator', 'operatorI', 'admin', 'sales', 'storekeeper', 'preparer', 'superAdmin']}
      />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) =>
        route.children.map((item, i) => (
          <ProtectedRoute
            exact
            value={item?.value}
            user={user.data}
            key={`R_${i}`}
            path={item.path}
            component={item.component}
            allowed={item.allowed}
            showInMenu={
              (item.showInMenu =
                user.data &&
                !item.label.includes('Izmen') &&
                !item.label.includes('Pregled') &&
                !item.label.includes('Plan proizvodnje') &&
                !item.label.includes('Verifikuj rolnu') &&
                item.allowed.some((role) => user.data.role.includes(role))
                  ? true
                  : false)
            }
          />
        )),
      )}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/admin' /> : <Login />)} />
      <ProtectedRoute user={user.data} exact path='/email-verification/:emailToken' component={EmailVerification} />
      <Route render={() => <Redirect to='/admin/work-orders' />} />
    </Switch>
  );
};

export default Routes;
