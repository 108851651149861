import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ component: Component, allowed, user, value, ...rest }) => {
  const userRoles = user && user.role.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1));
  const allowedRoles =
    allowed && allowed.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1)).join('-');

  return (
    <Route
      {...rest}
      render={(props) =>
        (user && allowed && userRoles.some((r) => allowed.includes(r))) ||
        (!user && rest.location.pathname.includes('email-verification')) ? ( // if signed in and user's role is allowed
          <Component {...props} value={value} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;
