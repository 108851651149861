import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import RollForm from '../../components/forms/RollForm';

const EditRoll = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [roll, fetchRoll] = useAxios('', [], currentuser.data.token, 'get');
  const { rollId } = props.match.params;
  useEffect(() => {
    if (rollId) {
      fetchRoll(`${SERVER_URL}/rolls/${rollId}`);
    }
  }, [rollId, fetchRoll]);
  const updateRoll = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/rolls/${rollId}`,
        { ...data, createdAt: roll.data.createdAt },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Rolna je izmenjena.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/materials');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  const isDataFetched = !roll.isLoading && roll.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {rollId && !roll.isError && roll.data && isDataFetched && (
          <RollForm
            data={roll.data}
            updateHandler={updateRoll}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditRoll;
