import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import HilzneViewForm from '../../components/forms/HilzneViewForm';

const ViewHilzne = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { hilznaId } = props.match.params;

  useEffect(() => {
    if (hilznaId) fetchData(`${SERVER_URL}/hilzna/${hilznaId}`, {});
  }, [fetchData, hilznaId]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/hilzne'>
          <Button type='primary'>Sve hilzne</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!hilznaId && isDataFetched && (
          <HilzneViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {hilznaId && !data.isError && data.data && isDataFetched && (
          <HilzneViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewHilzne;
