import ROUTES from './routeConfig';

const SERVER_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
const SERVER_URL_CALCULATOR =
  process.env.NODE_ENV === 'production'
    ? 'https://birografikaprodaja-api.concordsoft.solutions'
    : 'http://localhost:3333';
const APP_URL = process.env.NODE_ENV === 'production' ? process.env.APP_PROD_URL : process.env.APP_DEV_URL;

export { ROUTES, SERVER_URL, APP_URL, SERVER_URL_CALCULATOR };
