import React, { useState, useEffect, useContext } from 'react';
import {
  Divider,
  Input,
  Select,
  Button,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Card,
  InputNumber,
  Popover,
  notification,
  Modal,
} from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { UserContext } from '../../App';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { SERVER_URL_CALCULATOR } from '../../config';

const { Option } = Select;
const { TextArea } = Input;

const initValues = {
  id: undefined,
  code: undefined,
  companyID: undefined,
  companyName: undefined,
  machine: undefined,
  creationDate: moment(),
  productID: undefined,
  productName: undefined,
  internalCode: undefined,
  productCode: undefined,
  orderCode: undefined,
  orderDate: moment(),
  deliveryDate: moment(),
  printing: undefined,
  printingQuantity: undefined,
  deliveryAddress: undefined,
  invoiceAddress: undefined,
  format: undefined,
  plates: undefined,
  graphics: undefined,
  graphicEdits: undefined,
  films: undefined,
  numberOfColors: undefined,
  platesOrder: undefined,
  lacquering: undefined,
  print: undefined,
  lacquerTypes: undefined,
  promerHilzne: undefined,
  rollDiameter: undefined,
  unrollDirection: undefined,
  note: undefined,
  rollLabeling: undefined,
  boxLabeling: undefined,
  rollStacking: undefined,
  paletteLabeling: undefined,
  productSetout: undefined,
  workOrderStatus: 'kreiran',
  materialName: undefined,
  materialDimension: undefined,
  materialQuantity: undefined,
  firstColor: undefined,
  secondColor: undefined,
  thirdColor: undefined,
  fourthColor: undefined,
  fifthColor: undefined,
  sixthColor: undefined,
  seventhColor: undefined,
  lacquier: undefined,
  cilinderVolume: undefined,
  pieces: undefined,
  rowsToCut: undefined,
  rollPiecesNum: undefined,
  rollNum: undefined,
  materialNameSecond: undefined,
  materialDimensionSecond: undefined,
  materialQuantitySecond: undefined,
  materialNameThird: undefined,
  materialDimensionThird: undefined,
  materialQuantityThird: undefined,
  printForms: undefined,
  productCodeSecond: undefined,
  productNameSecond: undefined,
  productionTime: undefined,
  tools: undefined,
  toolsBox: undefined,
  toolsID: undefined,
  unrollDirectionBack: undefined,
  closedBy: undefined,
  processFinished: undefined,
  materialRollQuantity: [],
  materialRoll: [],
  material: [],
  materialReserved: [],
  operatorI: undefined,
  operatorII: undefined,
  operatorIII: undefined,
  createdBy: undefined,
  rollID: [],
  productsAddedAdditionaly: [],
  firstProductQuantity: undefined,
  secondProductQuantity: undefined,
};

const WorkOrderForm = ({
  isNew,
  data,
  onSubmit,
  machines,
  companies,
  materials,
  rolls,
  selectMaterial,
  rollHandler,
  roll,
  material,
  rollsReserved,
  editRollReserved,
  removeRollReserved,
  removeProductsInWo,
  products,
  rollsReservedWorkOrder,
  productsInWorkOrder,
  workOrders,
  // allRollsReserved,
  // allRolls
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  // const [inputVisible, setInputVisible] = useState(false);
  const [itemFile, setItemFile] = useState();
  const [itemFileTwo, setItemFileTwo] = useState();
  const [itemFileAdded, setItemFileAdded] = useState([]);
  // const [rollNumber, setRollNumber] = useState([]);
  const [validationState, setValidationState] = useState(false);
  const [materialArr, setMaterialArr] = useState([{}]);
  const [productsArr, setProductArr] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [hideSendAuthenticationMail, setHideSendAuthenticationMail] = useState(!data?.sendAuthentication);
  const [isDisabledTwo, setIsDisabledTwo] = useState(true);
  const [isNPPModalVisible, setIsNPPModalVisible] = useState(false);
  const [isDisabledAdded, setIsDisabledAdded] = useState(true);
  const [rerender, setReRender] = useState(true);
  const [checkLakiranje, setcheckLakiranje] = useState(data?.lacquering);
  const [folioTypes, setFolioTypes] = useState([]);
  const [machinesForPlasticization, setMachinesForPlasticization] = useState();
  const [selectedFolioType, setSelectedFolioType] = useState();
  const [selectedTypeOfLabel, setSelectedTypeOfLabel] = useState(null);
  const [selectedFolioMachine, setSelectedFolioMachine] = useState(null);
  const [hideFolioTypeWarmFT, setHideFolioTypeWarmFT] = useState(true);
  const [historyOfChanges, setHistoryOfChanges] = useState({});
  const [productsListSecond, setProductsListSecond] = useState(
    data && products && products.items ? products.items.filter((item) => item.productCode !== data.productCode) : [],
  );
  const [code, setCode] = useState();
  const currentuser = useContext(UserContext);
  const [preparerDisabledButtons, setPreparerDisabledButtons] = useState(
    currentuser &&
      currentuser.data &&
      currentuser.data.role &&
      currentuser.data.role.includes('preparer') &&
      !currentuser.data.role.includes('superAdmin'),
  );
  const [showGraphicEdits, setShowGraphicEdits] = useState(
    data && data.graphics === 'Postojeća sa izmenom' ? true : false,
  );
  let result = [];

  let compareArr = [];

  let initialValues = data
    ? {
        ...data,
        creationDate: moment(data.creationDate),
        orderDate: moment(data.orderDate),
        deliveryDate: moment(data.deliveryDate),
        materialReserved: result.length > 0 ? result : [],
        goodMetersReserved: rollsReservedWorkOrder.items?.map((item) => item.goodMeters),
        calcWasteReserved: rollsReservedWorkOrder.items?.map((item) => item.calcWaste),
        materialRollQuantityReserved: rollsReservedWorkOrder.items?.map((item) => item.materialQuantity),
        rollID: rollsReservedWorkOrder.items?.map((item) => item.roll?.rollID),
      }
    : initValues;

  const [additionalCalculatedWastePaper, setAdditionalCalculatedWastePaper] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalCalculatedWastePaper
      ? data?.additionalWorkOrderData?.[0].additionalCalculatedWastePaper
      : [],
  );

  const [additionalFormat, setAdditionalFormat] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalFormat ? data?.additionalWorkOrderData?.[0].additionalFormat : [],
  );

  const [additionalCilinderVolume, setAdditionalCilinderVolume] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalCilinderVolume
      ? data?.additionalWorkOrderData?.[0].additionalCilinderVolume
      : [],
  );

  const [additionalLacquerTypes, setAdditionalLacquerTypes] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalLacquerTypes
      ? data?.additionalWorkOrderData?.[0].additionalLacquerTypes
      : [],
  );

  const [additionalLacquering, setAdditionalLacquering] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalLacquering
      ? data?.additionalWorkOrderData?.[0].additionalLacquering
      : [],
  );

  const [additionalMaterialInfo, setAdditionalMaterialInfo] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalMaterialInfo
      ? data?.additionalWorkOrderData?.[0].additionalMaterialInfo
      : [],
  );

  const [additionalNumberOfColors, setadditionalNumberOfColors] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalNumberOfColors
      ? data?.additionalWorkOrderData?.[0].additionalNumberOfColors
      : [],
  );

  const [additionalPieces, setadditionalPieces] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPieces ? data?.additionalWorkOrderData?.[0].additionalPieces : [],
  );

  const [additionalPiecesByMaterialWidth, setadditionalPiecesByMaterialWidth] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPiecesByMaterialWidth
      ? data?.additionalWorkOrderData?.[0].additionalPiecesByMaterialWidth
      : [],
  );

  const [additionalPlates, setadditionalPlates] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPlates ? data?.additionalWorkOrderData?.[0].additionalPlates : [],
  );

  const [additionalPlatesOrder, setadditionalPlatesOrder] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPlatesOrder
      ? data?.additionalWorkOrderData?.[0].additionalPlatesOrder
      : [],
  );

  const [additionalProductionTime, setadditionalProductionTime] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalProductionTime
      ? data?.additionalWorkOrderData?.[0].additionalProductionTime
      : [],
  );

  const [additionalPromerHilzne, setadditionalPromerHilzne] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPromerHilzne
      ? data?.additionalWorkOrderData?.[0].additionalPromerHilzne
      : [],
  );

  const [additionalRollDiameter, setadditionalRollDiameter] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollDiameter
      ? data?.additionalWorkOrderData?.[0].additionalRollDiameter
      : [],
  );

  const [additionalRollNum, setadditionalRollNum] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollNum ? data?.additionalWorkOrderData?.[0].additionalRollNum : [],
  );

  const [additionalRollPiecesNum, setadditionalRollPiecesNum] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollPiecesNum
      ? data?.additionalWorkOrderData?.[0].additionalRollPiecesNum
      : [],
  );

  const [additionalRowsToCut, setadditionalRowsToCut] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRowsToCut
      ? data?.additionalWorkOrderData?.[0].additionalRowsToCut
      : [],
  );

  const [additionalTools, setadditionalTools] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalTools ? data?.additionalWorkOrderData?.[0].additionalTools : [],
  );

  const [additionalToolsBox, setadditionalToolsBox] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsBox ? data?.additionalWorkOrderData?.[0].additionalToolsBox : [],
  );

  const [additionalToolsID, setadditionalToolsID] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsID ? data?.additionalWorkOrderData?.[0].additionalToolsID : [],
  );

  const [additionalToolsIsCharging, setadditionalToolsIsCharging] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsIsCharging
      ? data?.additionalWorkOrderData?.[0].additionalToolsIsCharging
      : [],
  );

  const [additionalVarnishCoverage, setadditionalVarnishCoverage] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalVarnishCoverage
      ? data?.additionalWorkOrderData?.[0].additionalVarnishCoverage
      : [],
  );

  const [additionalVarnishWay, setadditionalVarnishWay] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalVarnishWay
      ? data?.additionalWorkOrderData?.[0].additionalVarnishWay
      : [],
  );

  //////////////////////////////////////////////////////////////////////////
  const [additionalCalculatedWastePaperSaved, setAdditionalCalculatedWastePaperSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalCalculatedWastePaper
      ? data?.additionalWorkOrderData?.[0].additionalCalculatedWastePaper
      : [],
  );

  const [additionalFormatSaved, setAdditionalFormatSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalFormat ? data?.additionalWorkOrderData?.[0].additionalFormat : [],
  );

  const [additionalCilinderVolumeSaved, setAdditionalCilinderVolumeSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalCilinderVolume
      ? data?.additionalWorkOrderData?.[0].additionalCilinderVolume
      : [],
  );

  const [additionalLacquerTypesSaved, setAdditionalLacquerTypesSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalLacquerTypes
      ? data?.additionalWorkOrderData?.[0].additionalLacquerTypes
      : [],
  );

  const [additionalLacqueringSaved, setAdditionalLacqueringSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalLacquering
      ? data?.additionalWorkOrderData?.[0].additionalLacquering
      : [],
  );

  const [additionalMaterialInfoSaved, setAdditionalMaterialInfoSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalMaterialInfo
      ? data?.additionalWorkOrderData?.[0].additionalMaterialInfo
      : [],
  );

  const [additionalNumberOfColorsSaved, setadditionalNumberOfColorsSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalNumberOfColors
      ? data?.additionalWorkOrderData?.[0].additionalNumberOfColors
      : [],
  );

  const [additionalPiecesSaved, setadditionalPiecesSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPieces ? data?.additionalWorkOrderData?.[0].additionalPieces : [],
  );

  const [additionalPiecesByMaterialWidthSaved, setadditionalPiecesByMaterialWidthSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPiecesByMaterialWidth
      ? data?.additionalWorkOrderData?.[0].additionalPiecesByMaterialWidth
      : [],
  );

  const [additionalPlatesSaved, setadditionalPlatesSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPlates ? data?.additionalWorkOrderData?.[0].additionalPlates : [],
  );

  const [additionalPlatesOrderSaved, setadditionalPlatesOrderSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPlatesOrder
      ? data?.additionalWorkOrderData?.[0].additionalPlatesOrder
      : [],
  );

  const [additionalProductionTimeSaved, setadditionalProductionTimeSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalProductionTime
      ? data?.additionalWorkOrderData?.[0].additionalProductionTime
      : [],
  );

  const [additionalPromerHilzneSaved, setadditionalPromerHilzneSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalPromerHilzne
      ? data?.additionalWorkOrderData?.[0].additionalPromerHilzne
      : [],
  );

  const [additionalRollDiameterSaved, setadditionalRollDiameterSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollDiameter
      ? data?.additionalWorkOrderData?.[0].additionalRollDiameter
      : [],
  );

  const [additionalRollNumSaved, setadditionalRollNumSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollNum ? data?.additionalWorkOrderData?.[0].additionalRollNum : [],
  );

  const [additionalRollPiecesNumSaved, setadditionalRollPiecesNumSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRollPiecesNum
      ? data?.additionalWorkOrderData?.[0].additionalRollPiecesNum
      : [],
  );

  const [additionalRowsToCutSaved, setadditionalRowsToCutSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalRowsToCut
      ? data?.additionalWorkOrderData?.[0].additionalRowsToCut
      : [],
  );

  const [additionalToolsSaved, setadditionalToolsSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalTools ? data?.additionalWorkOrderData?.[0].additionalTools : [],
  );

  const [additionalToolsBoxSaved, setadditionalToolsBoxSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsBox ? data?.additionalWorkOrderData?.[0].additionalToolsBox : [],
  );

  const [additionalToolsIDSaved, setadditionalToolsIDSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsID ? data?.additionalWorkOrderData?.[0].additionalToolsID : [],
  );

  const [additionalToolsIsChargingSaved, setadditionalToolsIsChargingSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalToolsIsCharging
      ? data?.additionalWorkOrderData?.[0].additionalToolsIsCharging
      : [],
  );

  const [additionalVarnishCoverageSaved, setadditionalVarnishCoverageSaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalVarnishCoverage
      ? data?.additionalWorkOrderData?.[0].additionalVarnishCoverage
      : [],
  );

  const [additionalVarnishWaySaved, setadditionalVarnishWaySaved] = useState(
    data?.additionalWorkOrderData?.[0]?.additionalVarnishWay
      ? data?.additionalWorkOrderData?.[0].additionalVarnishWay
      : [],
  );
  ///////////////////////////////////////////////////////////

  const [disabledFields, setDisabledFields] = useState({});

  useEffect(() => {
    if (initialValues && initialValues?.folioWay) {
      fetchFolioTypes(initialValues?.folioWay);
    }

    fetchSelectedFolioMachine();
    fetchFinishingWorkPlasticization();
  }, []);

  useEffect(() => {
    fetchLastEditLog();
    if (form.getFieldValue('folioWay') === 'hladniFT') {
      setHideFolioTypeWarmFT(true);
    }
    if (form.getFieldValue('folioWay') === 'topliFT') {
      setHideFolioTypeWarmFT(false);
    }

    if (data && data.additionalWorkOrderData && additionalMaterialInfo) {
      form.setFieldsValue({
        multipleCalculationsSelected: true,
      });
    }

    setDisabledFields({
      disableLacquiringFields2: additionalLacquering[0] === 'DA' ? false : true,
      disableLacquiringFields3: additionalLacquering[1] === 'DA' ? false : true,
    });
  }, []);

  useEffect(() => {
    if (data && data.productCode) {
      findProduct(data.productCode);
    }
    if (data && data.productCodeSecond) {
      findProductSecond(data.productCodeSecond);
    }

    if (data && data.toolsID) {
      form.setFieldsValue({ toolsID: data.toolsID });
    }
    if (data && data.toolsBox) {
      form.setFieldsValue({ toolsBox: data.toolsBox });
    }
  }, []);

  useEffect(() => {
    if (data && data.productUnits) {
      handleChangeUnits(data.productUnits, true);
      handleInitialAdditionalData();
    }
  }, [data]);

  const handleInitialAdditionalData = () => {
    // console.log(data.additionalWorkOrderData[0].additionalCalculatedWastePaper);
    setAdditionalCalculatedWastePaper(data?.additionalWorkOrderData[0]?.additionalCalculatedWastePaper);
    setAdditionalFormat(data?.additionalWorkOrderData[0]?.additionalFormat);
    setAdditionalCilinderVolume(data?.additionalWorkOrderData[0]?.additionalCilinderVolume);
    setAdditionalLacquerTypes(data?.additionalWorkOrderData[0]?.additionalLacquerTypes);
    setAdditionalLacquering(data?.additionalWorkOrderData[0]?.additionalLacquering);
    setAdditionalMaterialInfo(data?.additionalWorkOrderData[0]?.additionalMaterialInfo);
    setadditionalNumberOfColors(data?.additionalWorkOrderData[0]?.additionalNumberOfColors);
    setadditionalPieces(data?.additionalWorkOrderData[0]?.additionalPieces);
    setadditionalPiecesByMaterialWidth(data?.additionalWorkOrderData[0]?.additionalPiecesByMaterialWidth);
    setadditionalPlatesOrder(data?.additionalWorkOrderData[0]?.additionalPlatesOrder);
    setadditionalPlates(data?.additionalWorkOrderData[0]?.additionalPlates);
    setadditionalProductionTime(data?.additionalWorkOrderData[0]?.additionalProductionTime);
    setadditionalPromerHilzne(data?.additionalWorkOrderData[0]?.additionalPromerHilzne);
    setadditionalRollDiameter(data?.additionalWorkOrderData[0]?.additionalRollDiameter);
    setadditionalRollNum(data?.additionalWorkOrderData[0]?.additionalRollNum);
    setadditionalRollPiecesNum(data?.additionalWorkOrderData[0]?.additionalRollPiecesNum);
    setadditionalRowsToCut(data?.additionalWorkOrderData[0]?.additionalRowsToCut);
    setadditionalTools(data?.additionalWorkOrderData[0]?.additionalTools);
    setadditionalToolsBox(data?.additionalWorkOrderData[0]?.additionalToolsBox);
    setadditionalToolsID(data?.additionalWorkOrderData[0]?.additionalToolsID);
    setadditionalToolsIsCharging(data?.additionalWorkOrderData[0]?.additionalToolsIsCharging);
    setadditionalVarnishCoverage(data?.additionalWorkOrderData[0]?.additionalVarnishCoverage);
    setadditionalVarnishWay(data?.additionalWorkOrderData[0]?.additionalVarnishWay);
    ///cuvanje u pozadini ako se promeni jedinica mere da ne bi obrisao
    setAdditionalCalculatedWastePaperSaved(data.additionalWorkOrderData[0]?.additionalCalculatedWastePaper);
    setAdditionalFormatSaved(data.additionalWorkOrderData[0]?.additionalFormat);
    setAdditionalCilinderVolumeSaved(data.additionalWorkOrderData[0]?.additionalCilinderVolume);
    setAdditionalLacquerTypesSaved(data.additionalWorkOrderData[0]?.additionalLacquerTypes);
    setAdditionalLacqueringSaved(data.additionalWorkOrderData[0]?.additionalLacquering);
    setAdditionalMaterialInfoSaved(data.additionalWorkOrderData[0]?.additionalMaterialInfo);
    setadditionalNumberOfColorsSaved(data.additionalWorkOrderData[0]?.additionalNumberOfColors);
    setadditionalPiecesSaved(data.additionalWorkOrderData[0]?.additionalPieces);
    setadditionalPiecesByMaterialWidthSaved(data.additionalWorkOrderData[0]?.additionalPiecesByMaterialWidth);
    setadditionalPlatesOrderSaved(data.additionalWorkOrderData[0]?.additionalPlatesOrder);
    setadditionalPlatesSaved(data.additionalWorkOrderData[0]?.additionalPlates);
    setadditionalProductionTimeSaved(data.additionalWorkOrderData[0]?.additionalProductionTime);
    setadditionalPromerHilzneSaved(data.additionalWorkOrderData[0]?.additionalPromerHilzne);
    setadditionalRollDiameterSaved(data.additionalWorkOrderData[0]?.additionalRollDiameter);
    setadditionalRollNumSaved(data.additionalWorkOrderData[0]?.additionalRollNum);
    setadditionalRollPiecesNumSaved(data.additionalWorkOrderData[0]?.additionalRollPiecesNum);
    setadditionalRowsToCutSaved(data.additionalWorkOrderData[0]?.additionalRowsToCut);
    setadditionalToolsSaved(data.additionalWorkOrderData[0]?.additionalTools);
    setadditionalToolsBoxSaved(data.additionalWorkOrderData[0]?.additionalToolsBox);
    setadditionalToolsIDSaved(data.additionalWorkOrderData[0]?.additionalToolsID);
    setadditionalToolsIsChargingSaved(data.additionalWorkOrderData[0]?.additionalToolsIsCharging);
    setadditionalVarnishCoverageSaved(data.additionalWorkOrderData[0]?.additionalVarnishCoverage);
    setadditionalVarnishWaySaved(data.additionalWorkOrderData[0]?.additionalVarnishWay);

    setReRender(!rerender);
  };

  // console.log(data);

  for (let i = rollsReservedWorkOrder?.items?.length - 1; i >= 0; i--) {
    let sum = 0;

    for (let j = rollsReserved?.items?.length - 1; j >= 0; j--) {
      if (rollsReservedWorkOrder.items[i].roll?._id === rollsReserved.items[j].roll?._id) {
        sum = sum + rollsReserved.items[j].materialQuantity;
        const state =
          rollsReserved.items[j].material?.calculationMethod === 't*1000/p/s*1000'
            ? rollsReserved.items[j].material?.width *
              rollsReserved.items[j].material?.specificWeight *
              (rollsReserved.items[j].roll?.length - sum)
            : // rollsReserved.items[j].material?.calculationMethod === 't*1000/p/s'
              //   ? rollsReserved.items[j].material?.width * 1000 *
              //   rollsReserved.items[j].material?.specificWeight *
              //   (rollsReserved.items[j].roll?.length - sum)
              rollsReserved.items[j].material?.width * (rollsReserved.items[j].roll?.length - sum);

        result[i] =
          rollsReserved.items[j].material?.code +
          ' ' +
          rollsReserved.items[j].material?.name +
          ' - ' +
          rollsReserved.items[j].roll?.length?.toFixed(0) +
          'm' +
          '(' +
          sum?.toFixed(0) +
          'm' +
          ')';
        compareArr[i] = rollsReserved.items[j].roll?.length - sum;
      }
    }
  }

  initialValues.createdBy = isNew ? `${currentuser.data.firstName} ${currentuser.data.lastName}` : data.createdBy;
  form.setFieldsValue({ code: isNew ? code : data.code });

  if (initialValues.machine && initialValues.machine.name) {
    initialValues.machine = initialValues.machine._id;
  }

  const onFinish = async (values, isNew) => {
    setValidationState(true);

    if (values.sendAuthentication === true) {
      values.workOrderStatus = 'kreiran-priprema';
    }

    for (const hour of hours) {
      if (hour.stringValue === values.productionTime) {
        values.productionTime = hour.numValue;
      }
    }

    values.additionalWorkOrderData = [
      {
        additionalCalculatedWastePaper,
        additionalFormat,
        additionalCilinderVolume,
        additionalLacquerTypes,
        additionalLacquering,
        additionalMaterialInfo,
        additionalNumberOfColors,
        additionalPieces,
        additionalPiecesByMaterialWidth,
        additionalPlates,
        additionalPlatesOrder,
        additionalProductionTime,
        additionalPromerHilzne,
        additionalRollDiameter,
        additionalRollNum,
        additionalRollPiecesNum,
        additionalRowsToCut,
        additionalTools,
        additionalToolsBox,
        additionalToolsID,
        additionalToolsIsCharging,
        additionalVarnishCoverage,
        additionalVarnishWay,
      },
    ];

    values.printingQuantity = totalQuantity;
    onSubmit(values, isNew, productsAddedAdditionaly);
  };

  const fetchFinishingWorkPlasticization = async () => {
    const res = await Axios.get(`${SERVER_URL_CALCULATOR}/finishing-work-name/Plastifikacija`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setMachinesForPlasticization(res.data[0].machines);
  };

  const fetchSelectedFolioMachine = async () => {
    if (data && data.folioPrintingMachine && machinesForPlasticization) {
      for (const iterator of machinesForPlasticization) {
        if (iterator._id === data.folioPrintingMachine) {
          setSelectedFolioMachine(iterator);
        }
      }
    }
  };

  const printingFormsPriceHandler = () => {
    let printingFormsType = form.getFieldValue('printingFormsType');

    if (printingFormsType === 'nove') {
      let numberOfSpecialColors = form.getFieldValue('numberOfSpecialColors');
      let numberOfColors = form.getFieldValue('numberOfColors');
      let totalNumberOfColors = parseFloat(numberOfSpecialColors)
        ? parseFloat(numberOfColors) + parseFloat(numberOfSpecialColors)
        : parseFloat(numberOfColors);
      if (form.getFieldValue('folioWay') === 'hladniFT' && form.getFieldValue('folioPrinting')) {
        totalNumberOfColors++;
      }
      if (form.getFieldValue('varnishing')) {
        totalNumberOfColors++;
      }
      const priceOfPrintingForm = selectedTypeOfLabel.priceOfPrintingForm;
      const printingFormsPrice = totalNumberOfColors * priceOfPrintingForm;

      form.setFieldsValue({ printingFormsPrice });
    } else if (printingFormsType === 'postojece') {
      form.setFieldsValue({ printingFormsPrice: 0 });
    }
  };

  const onChangeFolioPrintingMachine = (e) => {
    if (machinesForPlasticization) {
      for (const iterator of machinesForPlasticization) {
        if (iterator._id === e) {
          setSelectedFolioMachine(iterator);
        }
      }
    }
  };

  const fetchFolioTypes = async (folioWay) => {
    const res = await Axios.get(`${SERVER_URL}/types-of-folio-byWay/${folioWay}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setFolioTypes(res.data);
  };

  const fetchLastEditLog = async () => {
    if (data && data._id) {
      const log = await Axios.get(`${SERVER_URL}/log-workorder/${data?._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setHistoryOfChanges(log?.data[0]?.workorder);
    }
  };

  const findCompany = (id) => {
    companies.items.find((item) => {
      if (item._id === id) {
        form.setFieldsValue({
          companyName: item.name,
          companyID: item.sku,
          deliveryAddress: item.deliveryAddress,
          invoiceAddress: item.invoiceAddress,
          contactPerson: item.contactPerson,
          phone: item.phone ? item.phone : item.mobile,
          email: item.email,
        });
      }
    });
  };

  ///promena alata na osnovu izbora prozivoda
  const handleToolAndUnitsOnProductChange = (item) => {
    let productUnits = '';
    if (item.unitOneIn00) {
      productUnits += ' jm1: ' + item.unitOneIn00;
    }
    if (item.unitTwo) {
      productUnits += ' jm2: ' + item.unitTwo;
    }
    if (item.unitThreeIn00) {
      productUnits += ' jm3: ' + item.unitThreeIn00;
    }
    form.setFieldsValue({ toolsID: item?.tool?.toolID, toolsBox: item?.tool?.box, productUnits });
  };

  const findProduct = (productCode, index) => {
    products?.items?.find((item) => {
      if (item.productCode === productCode) {
        form.setFieldsValue({ productName: item.productName });
      }
      if (item.productCode === productCode) {
        if (item.file) {
          setItemFile(item);

          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }

        ///promena alata na osnovu proizvoda
        handleToolAndUnitsOnProductChange(item);
      }
    });
    const filteredList = products?.items?.filter((item) => item.productCode !== productCode);
    setProductsListSecond(filteredList);
  };

  const findProductSecond = (productCode) => {
    if (productCode !== '') {
      products.items.find((item) => {
        if (item.productCode === productCode) {
          form.setFieldsValue({ productNameSecond: item.productName });
        }

        if (item.productCode === productCode) {
          if (item.file) {
            setItemFileTwo(item);

            setIsDisabledTwo(false);
          } else {
            setIsDisabledTwo(true);
          }
        }
      });
    } else {
      form.setFieldValue('productCodeSecond', undefined);
      form.setFieldValue('productNameSecond', undefined);
    }

    const filteredList = products.items.filter((item) => item.productCode !== productCode);
    setProductsListSecond(filteredList);
  };

  const findProductAdded = (productCode, index) => {
    products.items.find((item) => {
      if (item.productCode === productCode) {
        let formValue = form.getFieldsValue();
        formValue.productNameAdded[index] = item.productName;
        form.setFieldsValue(formValue);
      }

      if (item.productCode === productCode) {
        if (item.file) {
          setItemFileAdded((itemFileAdded) => [...itemFileAdded, item]);
          setIsDisabledAdded(false);
        } else {
          setIsDisabledAdded(true);
        }
      }
    });
    const filteredList = products.items.filter((item) => item.productCode !== productCode);
    setProductsListSecond(filteredList);
  };

  const graphic = ['Postojeća', 'Postojeća sa izmenom', 'Nova'];
  const promerHilzneArray = [
    '28',
    '40',
    '43',
    '45',
    '50',
    '55',
    '76x03',
    '76x05',
    '76x10',
    '125x12',
    '150',
    '152x07',
    '152x10',
    '152x12',
    '152x15',
  ];
  const unrollDirectionArray = [
    'U smeru čitanja teksta - etiketa motana spolja',
    'U smeru čitanja teksta - etiketa motana iznutra',
    'Suprotno od smera čitanja teksta - etiketa motana spolja',
    'Suprotno od smera čitanja teksta - etiketa motana iznutra',
    'Na noge - etiketa motana spolja',
    'Na noge - etiketa motana iznutra',
    'Na glavu - etiketa motana spolja',
    'Na glavu - etiketa motana iznutra',
  ];
  let hours = [];
  for (let i = 0; i <= 50; i++) {
    if (i !== 0 && i <= 50) {
      hours.push({ numValue: parseFloat(i.toFixed(1)), stringValue: i < 10 ? `0${i}:00 h` : `${i}:00 h` });
    }
    if (i < 50) {
      hours.push({ numValue: i + 0.5, stringValue: i < 10 ? `0${i}:30 h` : `${i}:30 h` });
    }
  }

  for (const hour of hours) {
    if (data && hour.numValue === parseFloat(data.productionTime)) {
      data.productionTime = hour.stringValue;
    }
  }

  if (JSON.stringify(materialArr) === JSON.stringify({})) {
    setMaterialArr([]);
  }

  const createPdf = async () => {
    if (itemFile?.file) {
      const fileName = itemFile.file.originalname;
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf-tool?fileName=${fileName}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const pathArr = fileName.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleAddRoll = async () => {
    //dodavanje novog reda
    setMaterialArr([...materialArr, {}]);

    ///stavljanje makulature na 50min default
    let forma = form.getFieldsValue();
    let calcWaste = forma.calcWaste;
    calcWaste[forma.calcWaste.length] = 50;

    form.setFieldsValue({ calcWaste });
  };

  const createPdfSecond = async () => {
    const fileName = itemFileTwo.file.originalname;
    const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf-tool?fileName=${fileName}`, {
      responseType: 'arraybuffer',
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    const pathArr = fileName.split('\\');
    const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
    let a = document.createElement('a');
    let url = URL.createObjectURL(blob);
    a.href = url;
    a.download = pathArr[pathArr.length - 1];
    a.click();
    URL.revokeObjectURL(url);
  };

  const onChangeFolioWay = (e) => {
    if (e === 'hladniFT') {
      setHideFolioTypeWarmFT(true);
    } else {
      setHideFolioTypeWarmFT(false);
    }
    fetchFolioTypes(e);

    form.setFieldsValue({
      folioType: null,
      folioPrice: null,
    });
  };

  const onChangeFolioType = (folioTypeId) => {
    for (const iterator of folioTypes) {
      if (iterator._id === folioTypeId) {
        form.setFieldsValue({ folioPrice: iterator.pricePerSquareMeter });
        setSelectedFolioType(iterator);
      }
    }
  };

  const saveAsOrderToProduction = async () => {
    let formData = form.getFieldsValue();
    // formData.tools = 'Postojeći';
    // formData.toolsIsCharging = 'NE';
    formData.plates = 'Postojeće';
    formData.printForms = 0;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.editedBy;
    formData.createdBy = `${currentuser.data.firstName} ${currentuser.data.lastName}`;
    formData.creationDate = moment();
    delete formData.updatedAt;

    let productionTime = formData.productionTime;

    if (formData.productionTime.includes('h')) {
      let hours = parseFloat(formData.productionTime.slice(0, 2));
      let mins = parseFloat(formData.productionTime.slice(3, 5) / 6);

      hours = hours.toString();
      mins = mins.toString();

      productionTime = `${hours}.${mins}`;

      //productionTime --- ako nije ceo broj treba zaokruziti na pola sata
      if (productionTime.includes('.')) {
        if (productionTime.split('.')[1] !== '0') {
          // productionTime = JSON.stringify(productionTime);

          let afterDot = productionTime.split('.')[1];

          afterDot = parseFloat(afterDot);

          if (parseInt(afterDot) > 5) {
            let hourPart = parseInt(productionTime.split('.')[0]);
            hourPart++;

            productionTime = JSON.stringify(hourPart) + '.0';
          } else {
            let hourPart = productionTime.split('.')[0];

            productionTime = hourPart + '.5';
          }
        }
        productionTime = parseFloat(productionTime);
      }
    }

    formData.productionTime = productionTime;
    if (!formData.machine) {
      notification.error({
        message: 'Unesite mašinu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.productionTime) {
      notification.error({
        message: 'Unesite vreme za izradu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.productCode) {
      notification.error({
        message: 'Unesite šifru prozivoda!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.orderDate) {
      notification.error({
        message: 'Unesite datum narudžbe!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.deliveryDate) {
      notification.error({
        message: 'Unesite rok isporuke!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.companyName) {
      notification.error({
        message: 'Unesite kupca!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    // if (!formData.printingQuantity) {
    //   notification.error({
    //     message: 'Unesite tiraž za štampu!',
    //     duration: 2,
    //     placement: 'bottomRight',
    //   });
    //   return;
    // }
    if (!formData.cilinderVolume) {
      notification.error({
        message: 'Unesite obim cilindra!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.numberOfColors) {
      notification.error({
        message: 'Unesite broj boja!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.material) {
      notification.error({
        message: 'Unesite materijal!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.materialRoll) {
      notification.error({
        message: 'Izaberite rolnu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.materialRollQuantity) {
      notification.error({
        message: 'Izaberite količinu materijala!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    let newCirculation;

    if (
      !isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('1');
      newCirculation =
        parseFloat(modalForm.getFieldValue('firstProductQuantity')) +
        parseFloat(modalForm.getFieldValue('secondProductQuantity'));
    } else if (
      !isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('2');
      newCirculation = parseFloat(modalForm.getFieldValue('firstProductQuantity'));
    } else if (
      isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('3');

      newCirculation = parseFloat(modalForm.getFieldValue('secondProductQuantity'));
    } else {
      // console.log('4', formData);

      newCirculation = parseFloat(formData.printing);
    }

    let oldCirculation = parseFloat(formData.printing);
    // let materialQuantityOld = parseFloat(formData.materialQuantity);
    // let materialQuantitySecondOld = parseFloat(formData.materialQuantitySecond);
    // let materialQuantityThirdOld = parseFloat(formData.materialQuantityThird);
    // let quantityFirstForOnePiece = 0;
    // let quantitySecondForOnePiece = 0;
    // let quantityThirdForOnePiece = 0;

    try {
      ///sacuvaj ovaj rn
      const response = await Axios.put(
        `${SERVER_URL}/work-orders/${data._id}`,
        { ...formData, printingQuantity: oldCirculation },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      let dataforop = formData;
      dataforop.productCodeAdded = [];
      dataforop.productNameAdded = [];
      dataforop.orderToProductionStatus = 'kreiran';
      dataforop.workOrder = dataforop.code;
      dataforop.printingQuantity = newCirculation;
      dataforop.graphics = 'Postojeća';
      let modalValues = modalForm.getFieldsValue();
      if (modalValues.boxLabeling) {
        dataforop.boxLabeling = modalValues.boxLabeling;
      }
      if (modalValues.deliveryDate) {
        dataforop.deliveryDate = modalValues.deliveryDate;
      }
      if (modalValues.graphics) {
        dataforop.graphics = modalValues.graphics;
      }
      if (modalValues.newProductName) {
        formData.productName = modalValues.newProductName;
        formData.productCode = '';
        formData.newProduct = true;
        formData.workOrder = '';
      }
      // if (modalValues.newCirculation) {
      //   dataforop.newCirculation = modalValues.newCirculation;
      // }
      if (modalValues.firstProductQuantity) {
        dataforop.firstProductQuantity = modalValues.firstProductQuantity;
      }
      if (modalValues.secondProductQuantity) {
        dataforop.secondProductQuantity = modalValues.secondProductQuantity;
      }
      if (modalValues.numberOfColors) {
        dataforop.numberOfColors = modalValues.numberOfColors;
      }
      if (modalValues.orderCode) {
        dataforop.orderCode = modalValues.orderCode;
      }
      if (modalValues.orderDate) {
        dataforop.orderDate = modalValues.orderDate;
      }
      if (modalValues.paletteLabeling) {
        dataforop.paletteLabeling = modalValues.paletteLabeling;
      }
      if (modalValues.plates) {
        dataforop.plates = modalValues.plates;
      }
      if (modalValues.printForms) {
        dataforop.printForms = modalValues.printForms;
      }
      if (modalValues.printing) {
        dataforop.printing = modalValues.printing;
      }
      if (modalValues.productSetout) {
        dataforop.productSetout = modalValues.productSetout;
      }
      if (modalValues.rollDiameter) {
        dataforop.rollDiameter = modalValues.rollDiameter;
      }
      if (modalValues.rollLabeling) {
        dataforop.rollLabeling = modalValues.rollLabeling;
      }
      if (modalValues.rollPiecesNum) {
        dataforop.rollPiecesNum = modalValues.rollPiecesNum;
      }
      if (modalValues.rollStacking) {
        dataforop.rollStacking = modalValues.rollStacking;
      }
      if (modalValues.unrollDirection) {
        dataforop.unrollDirection = modalValues.unrollDirection;
      }
      if (modalValues.unrollDirectionBack) {
        dataforop.unrollDirectionBack = modalValues.unrollDirectionBack;
      }
      if (modalValues.sendAuthentication) {
        dataforop.sendAuthentication = modalValues.sendAuthentication;
      } else {
        dataforop.sendAuthentication = false;
      }
      if (modalValues.sendAuthenticationMail) {
        dataforop.sendAuthenticationMail = modalValues.sendAuthenticationMail;
      } else {
        dataforop.sendAuthenticationMail = '';
      }

      delete dataforop.updatedAt;
      delete dataforop.editedBy;
      // dataforop.code = null;
      productionTime = formData.productionTime;

      if (formData.goodMetersFirst) {
        dataforop.goodMetersFirst = Math.ceil(parseFloat(newCirculation / (formData.pieces * formData.rowsToCut)));
        dataforop.materialQuantity = parseFloat(formData.goodMetersFirst + formData.calculatedWastePaper);
      }

      if (
        formData.goodMetersSecond &&
        formData.additionalWorkOrderData &&
        formData.additionalWorkOrderData.length > 0 &&
        formData.additionalWorkOrderData[0].additionalPieces &&
        formData.additionalWorkOrderData[0].additionalRowsToCut &&
        formData.additionalWorkOrderData[0].additionalPieces.length > 0 &&
        formData.additionalWorkOrderData[0].additionalRowsToCut.length > 0
      ) {
        formData.goodMetersSecond = Math.ceil(
          parseFloat(
            newCirculation /
              (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[0]) *
                parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[0])),
          ),
        );
        formData.materialQuantitySecond = parseFloat(formData.goodMetersSecond + formData.calculatedWastePaper);
      }

      if (
        formData.goodMetersThird &&
        formData.additionalWorkOrderData &&
        formData.additionalWorkOrderData.length > 0 &&
        formData.additionalWorkOrderData[0].additionalPieces &&
        formData.additionalWorkOrderData[0].additionalRowsToCut &&
        formData.additionalWorkOrderData[0].additionalPieces.length > 1 &&
        formData.additionalWorkOrderData[0].additionalRowsToCut.length > 1
      ) {
        formData.goodMetersThird = Math.ceil(
          parseFloat(
            newCirculation /
              (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[1]) *
                parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[1])),
          ),
        );
        formData.materialQuantityThird = parseFloat(formData.goodMetersThird + formData.calculatedWastePaper);
      }

      let timeForOnePiece = formData.productionTime / oldCirculation;

      let pt = timeForOnePiece * newCirculation;

      pt = pt.toString();
      dataforop.productionTime = pt;

      ///sacuvaj ga kao npp
      const res = await Axios.post(`${SERVER_URL}/orders-to-production`, dataforop, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      ///proveri da li je imao dodatne proizvode
      const resp = await Axios.get(`${SERVER_URL}/products-in-workorder?workOrderId=${data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let dataArr = [];
      if (resp && resp.data && resp.data.items) {
        for (const iterator of resp.data.items) {
          if (iterator.product && iterator.product.length > 0) {
            let data = { orderToProduction: res.data.ordertoproductionId, product: iterator?.product[0]?.productCode };
            dataArr.push(data);
          }
        }
      }
      if (dataArr) {
        //ako jeste dodaj ih u npp
        await Axios.post(`${SERVER_URL}/products-in-ordertoproduction`, dataArr, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      ///proveri da li ima rollsRes ako ima sacuvaj u NPP u polje za info
      const rr = await Axios.get(`${SERVER_URL}/rolls-reserved-byWorkOrder/${data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let rollsResArr = [];
      if (rr && rr.data) {
        for (const iterator of rr.data) {
          iterator.orderToProduction = res.data.ordertoproductionId;
          iterator.workOrder = undefined;
          iterator._id = undefined;
          rollsResArr.push(iterator);
        }
      }

      let materialInfo = '';

      if (rollsResArr.length > 0) {
        for (const iterator of rollsResArr) {
          materialInfo =
            materialInfo +
            ` Materijal: ${iterator?.material?.name}` +
            ` Rolna: ${iterator?.roll?.rollID}` +
            ` Dobrih metara: ${iterator.goodMeters}` +
            ` Kalkulisana makulatura: ${iterator.calcWaste}, `;
        }
      }

      dataforop.materialInfoFromWO = materialInfo;
      dataforop.code = res.data.item.code;
      dataforop.creationDate = new Date();
      dataforop.dontAddEditedBy = true;

      await Axios.put(`${SERVER_URL}/order-to-production-data/${res.data.item._id}`, dataforop, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Nalog proizvodnji je kreiran.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.href = '/admin/orders-to-production';
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const handleChangeUnits = (e, initial) => {
    let check;
    if (!initial) {
      e.persist();
      check = e.target.value;
    } else {
      check = e;
    }

    if (check.includes('par')) {
      setAdditionalCalculatedWastePaper([
        additionalCalculatedWastePaperSaved && additionalCalculatedWastePaperSaved[0]
          ? additionalCalculatedWastePaperSaved[0]
          : '',
      ]);
      setAdditionalFormat([additionalFormatSaved && additionalFormatSaved[0] ? additionalFormatSaved[0] : '']);
      setAdditionalCilinderVolume([
        additionalCilinderVolumeSaved && additionalCilinderVolumeSaved[0] ? additionalCilinderVolumeSaved[0] : '',
      ]);
      setAdditionalLacquerTypes([
        additionalLacquerTypesSaved && additionalLacquerTypesSaved[0] ? additionalLacquerTypesSaved[0] : '',
      ]);
      setAdditionalLacquering([
        additionalLacqueringSaved && additionalLacqueringSaved[0] ? additionalLacqueringSaved[0] : '',
      ]);
      setAdditionalMaterialInfo([
        additionalMaterialInfoSaved && additionalMaterialInfoSaved[0] ? additionalMaterialInfoSaved[0] : '',
      ]);
      setadditionalNumberOfColors([
        additionalNumberOfColorsSaved && additionalNumberOfColorsSaved[0] ? additionalNumberOfColorsSaved[0] : '',
      ]);
      setadditionalPieces([additionalPiecesSaved && additionalPiecesSaved[0] ? additionalPiecesSaved[0] : '']);
      setadditionalPiecesByMaterialWidth([
        additionalPiecesByMaterialWidthSaved && additionalPiecesByMaterialWidthSaved[0]
          ? additionalPiecesByMaterialWidthSaved[0]
          : '',
      ]);
      setadditionalPlatesOrder([
        additionalPlatesOrderSaved && additionalPlatesOrderSaved[0] ? additionalPlatesOrderSaved[0] : '',
      ]);
      setadditionalPlates([additionalPlatesSaved && additionalPlatesSaved[0] ? additionalPlatesSaved[0] : '']);
      setadditionalProductionTime([
        additionalProductionTimeSaved && additionalProductionTimeSaved[0] ? additionalProductionTimeSaved[0] : '',
      ]);
      setadditionalPromerHilzne([
        additionalPromerHilzneSaved && additionalPromerHilzneSaved[0] ? additionalPromerHilzneSaved[0] : '',
      ]);
      setadditionalRollDiameter([
        additionalRollDiameterSaved && additionalRollDiameterSaved[0] ? additionalRollDiameterSaved[0] : '',
      ]);
      setadditionalRollNum([additionalRollNumSaved && additionalRollNumSaved[0] ? additionalRollNumSaved[0] : '']);
      setadditionalRollPiecesNum([
        additionalRollPiecesNumSaved && additionalRollPiecesNumSaved[0] ? additionalRollPiecesNumSaved[0] : '',
      ]);
      setadditionalRowsToCut([
        additionalRowsToCutSaved && additionalRowsToCutSaved[0] ? additionalRowsToCutSaved[0] : '',
      ]);
      setadditionalTools([additionalToolsSaved && additionalToolsSaved[0] ? additionalToolsSaved[0] : '']);
      setadditionalToolsBox([additionalToolsBoxSaved && additionalToolsBoxSaved[0] ? additionalToolsBoxSaved[0] : '']);
      setadditionalToolsID([additionalToolsIDSaved && additionalToolsIDSaved[0] ? additionalToolsIDSaved[0] : '']);
      setadditionalToolsIsCharging([
        additionalToolsIsChargingSaved && additionalToolsIsChargingSaved[0] ? additionalToolsIsChargingSaved[0] : '',
      ]);
      setadditionalVarnishCoverage([
        additionalVarnishCoverageSaved && additionalVarnishCoverageSaved[0] ? additionalVarnishCoverageSaved[0] : '',
      ]);
      setadditionalVarnishWay([
        additionalVarnishWaySaved && additionalVarnishWaySaved[0] ? additionalVarnishWaySaved[0] : '',
      ]);
    } else if (check.includes('komplet')) {
      setAdditionalCalculatedWastePaper(
        additionalCalculatedWastePaperSaved && additionalCalculatedWastePaperSaved.length === 0
          ? ['', '']
          : additionalCalculatedWastePaperSaved && additionalCalculatedWastePaperSaved.length === 1
          ? [additionalCalculatedWastePaperSaved[0], '']
          : additionalCalculatedWastePaperSaved && additionalCalculatedWastePaperSaved.length === 2
          ? additionalCalculatedWastePaperSaved
          : ['', ''],
      );
      setAdditionalFormat(
        additionalFormatSaved && additionalFormatSaved.length === 0
          ? ['', '']
          : additionalFormatSaved && additionalFormatSaved.length === 1
          ? [additionalFormatSaved[0], '']
          : additionalFormatSaved && additionalFormatSaved.length === 2
          ? additionalFormatSaved
          : ['', ''],
      );
      setAdditionalCilinderVolume(
        additionalCilinderVolumeSaved && additionalCilinderVolumeSaved.length === 0
          ? ['', '']
          : additionalCilinderVolumeSaved && additionalCilinderVolumeSaved.length === 1
          ? [additionalCilinderVolumeSaved[0], '']
          : additionalCilinderVolumeSaved && additionalCilinderVolumeSaved.length === 2
          ? additionalCilinderVolumeSaved
          : ['', ''],
      );
      setAdditionalLacquerTypes(
        additionalLacquerTypesSaved && additionalLacquerTypesSaved.length === 0
          ? ['', '']
          : additionalLacquerTypesSaved && additionalLacquerTypesSaved.length === 1
          ? [additionalLacquerTypesSaved[0], '']
          : additionalLacquerTypesSaved && additionalLacquerTypesSaved.length === 2
          ? additionalLacquerTypesSaved
          : ['', ''],
      );
      setAdditionalLacquering(
        additionalLacqueringSaved && additionalLacqueringSaved.length === 0
          ? ['', '']
          : additionalLacqueringSaved && additionalLacqueringSaved.length === 1
          ? [additionalLacqueringSaved[0], '']
          : additionalLacqueringSaved && additionalLacqueringSaved.length === 2
          ? additionalLacqueringSaved
          : ['', ''],
      );
      setAdditionalMaterialInfo(
        additionalMaterialInfoSaved && additionalMaterialInfoSaved.length === 0
          ? ['', '']
          : additionalMaterialInfoSaved && additionalMaterialInfoSaved.length === 1
          ? [additionalMaterialInfoSaved[0], '']
          : additionalMaterialInfoSaved && additionalMaterialInfoSaved.length === 2
          ? additionalMaterialInfoSaved
          : ['', ''],
      );
      setadditionalNumberOfColors(
        additionalNumberOfColorsSaved && additionalNumberOfColorsSaved.length === 0
          ? ['', '']
          : additionalNumberOfColorsSaved && additionalNumberOfColorsSaved.length === 1
          ? [additionalNumberOfColorsSaved[0], '']
          : additionalNumberOfColorsSaved && additionalNumberOfColorsSaved.length === 2
          ? additionalNumberOfColorsSaved
          : ['', ''],
      );
      setadditionalPieces(
        additionalPiecesSaved && additionalPiecesSaved.length === 0
          ? ['', '']
          : additionalPiecesSaved && additionalPiecesSaved.length === 1
          ? [additionalPiecesSaved[0], '']
          : additionalPiecesSaved && additionalPiecesSaved.length === 2
          ? additionalPiecesSaved
          : ['', ''],
      );
      setadditionalPiecesByMaterialWidth(
        additionalPiecesByMaterialWidthSaved && additionalPiecesByMaterialWidthSaved.length === 0
          ? ['', '']
          : additionalPiecesByMaterialWidthSaved && additionalPiecesByMaterialWidthSaved.length === 1
          ? [additionalPiecesByMaterialWidthSaved[0], '']
          : additionalPiecesByMaterialWidthSaved && additionalPiecesByMaterialWidthSaved.length === 2
          ? additionalPiecesByMaterialWidthSaved
          : ['', ''],
      );
      setadditionalPlatesOrder(
        additionalPlatesOrderSaved && additionalPlatesOrderSaved.length === 0
          ? ['', '']
          : additionalPlatesOrderSaved && additionalPlatesOrderSaved.length === 1
          ? [additionalPlatesOrderSaved[0], '']
          : additionalPlatesOrderSaved && additionalPlatesOrderSaved.length === 2
          ? additionalPlatesOrderSaved
          : ['', ''],
      );
      setadditionalPlates(
        additionalPlatesSaved && additionalPlatesSaved.length === 0
          ? ['', '']
          : additionalPlatesSaved && additionalPlatesSaved.length === 1
          ? [additionalPlatesSaved[0], '']
          : additionalPlatesSaved && additionalPlatesSaved.length === 2
          ? additionalPlatesSaved
          : ['', ''],
      );
      setadditionalProductionTime(
        additionalProductionTimeSaved && additionalProductionTimeSaved.length === 0
          ? ['', '']
          : additionalProductionTimeSaved && additionalProductionTimeSaved.length === 1
          ? [additionalProductionTimeSaved[0], '']
          : additionalProductionTimeSaved && additionalProductionTimeSaved.length === 2
          ? additionalProductionTimeSaved
          : ['', ''],
      );
      setadditionalPromerHilzne(
        additionalPromerHilzneSaved && additionalPromerHilzneSaved.length === 0
          ? ['', '']
          : additionalPromerHilzneSaved && additionalPromerHilzneSaved.length === 1
          ? [additionalPromerHilzneSaved[0], '']
          : additionalPromerHilzneSaved && additionalPromerHilzneSaved.length === 2
          ? additionalPromerHilzneSaved
          : ['', ''],
      );
      setadditionalRollDiameter(
        additionalRollDiameterSaved && additionalRollDiameterSaved.length === 0
          ? ['', '']
          : additionalRollDiameterSaved && additionalRollDiameterSaved.length === 1
          ? [additionalRollDiameterSaved[0], '']
          : additionalRollDiameterSaved && additionalRollDiameterSaved.length === 2
          ? additionalRollDiameterSaved
          : ['', ''],
      );
      setadditionalRollNum(
        additionalRollNumSaved && additionalRollNumSaved.length === 0
          ? ['', '']
          : additionalRollNumSaved && additionalRollNumSaved.length === 1
          ? [additionalRollNumSaved[0], '']
          : additionalRollNumSaved && additionalRollNumSaved.length === 2
          ? additionalRollNumSaved
          : ['', ''],
      );
      setadditionalRollPiecesNum(
        additionalRollPiecesNumSaved && additionalRollPiecesNumSaved.length === 0
          ? ['', '']
          : additionalRollPiecesNumSaved && additionalRollPiecesNumSaved.length === 1
          ? [additionalRollPiecesNumSaved[0], '']
          : additionalRollPiecesNumSaved && additionalRollPiecesNumSaved.length === 2
          ? additionalRollPiecesNumSaved
          : ['', ''],
      );
      setadditionalRowsToCut(
        additionalRowsToCutSaved && additionalRowsToCutSaved.length === 0
          ? ['', '']
          : additionalRowsToCutSaved && additionalRowsToCutSaved.length === 1
          ? [additionalRowsToCutSaved[0], '']
          : additionalRowsToCutSaved && additionalRowsToCutSaved.length === 2
          ? additionalRowsToCutSaved
          : ['', ''],
      );
      setadditionalTools(
        additionalToolsSaved && additionalToolsSaved.length === 0
          ? ['', '']
          : additionalToolsSaved && additionalToolsSaved.length === 1
          ? [additionalToolsSaved[0], '']
          : additionalToolsSaved && additionalToolsSaved.length === 2
          ? additionalToolsSaved
          : ['', ''],
      );
      setadditionalToolsBox(
        additionalToolsBoxSaved && additionalToolsBoxSaved.length === 0
          ? ['', '']
          : additionalToolsBoxSaved && additionalToolsBoxSaved.length === 1
          ? [additionalToolsBoxSaved[0], '']
          : additionalToolsBoxSaved && additionalToolsBoxSaved.length === 2
          ? additionalToolsBoxSaved
          : ['', ''],
      );
      setadditionalToolsID(
        additionalToolsIDSaved && additionalToolsIDSaved.length === 0
          ? ['', '']
          : additionalToolsIDSaved && additionalToolsIDSaved.length === 1
          ? [additionalToolsIDSaved[0], '']
          : additionalToolsIDSaved && additionalToolsIDSaved.length === 2
          ? additionalToolsIDSaved
          : ['', ''],
      );
      setadditionalToolsIsCharging(
        additionalToolsIsChargingSaved && additionalToolsIsChargingSaved.length === 0
          ? ['', '']
          : additionalToolsIsChargingSaved && additionalToolsIsChargingSaved.length === 1
          ? [additionalToolsIsChargingSaved[0], '']
          : additionalToolsIsChargingSaved && additionalToolsIsChargingSaved.length === 2
          ? additionalToolsIsChargingSaved
          : ['', ''],
      );
      setadditionalVarnishCoverage(
        additionalVarnishCoverageSaved && additionalVarnishCoverageSaved.length === 0
          ? ['', '']
          : additionalVarnishCoverageSaved && additionalVarnishCoverageSaved.length === 1
          ? [additionalVarnishCoverageSaved[0], '']
          : additionalVarnishCoverageSaved && additionalVarnishCoverageSaved.length === 2
          ? additionalVarnishCoverageSaved
          : ['', ''],
      );
      setadditionalVarnishWay(
        additionalVarnishWaySaved && additionalVarnishWaySaved.length === 0
          ? ['', '']
          : additionalVarnishWaySaved && additionalVarnishWaySaved.length === 1
          ? [additionalVarnishWaySaved[0], '']
          : additionalVarnishWaySaved && additionalVarnishWaySaved.length === 2
          ? additionalVarnishWaySaved
          : ['', ''],
      );
    } else {
      setAdditionalCalculatedWastePaper([]);
      setAdditionalFormat([]);
      setAdditionalCilinderVolume([]);
      setAdditionalLacquerTypes([]);
      setAdditionalLacquering([]);
      setAdditionalMaterialInfo([]);
      setadditionalNumberOfColors([]);
      setadditionalPieces([]);
      setadditionalPiecesByMaterialWidth([]);
      setadditionalPlatesOrder([]);
      setadditionalPlates([]);
      setadditionalProductionTime([]);
      setadditionalPromerHilzne([]);
      setadditionalRollDiameter([]);
      setadditionalRollNum([]);
      setadditionalRollPiecesNum([]);
      setadditionalRowsToCut([]);
      setadditionalTools([]);
      setadditionalToolsBox([]);
      setadditionalToolsID([]);
      setadditionalToolsIsCharging([]);
      setadditionalVarnishCoverage([]);
      setadditionalVarnishWay([]);
    }

    setReRender(!rerender);
  };

  const materialRollQuantityHandler = async (e, index) => {
    // e.persist();

    let goodMeters = form.getFieldValue('goodMeters');
    let calcWaste = form.getFieldValue('calcWaste');

    let materialRollQuantity = [];
    for (let i = 0; i < goodMeters.length; i++) {
      materialRollQuantity[i] = parseFloat(calcWaste[i]) + parseFloat(goodMeters[i]);
    }

    for (const iterator of materialRollQuantity) {
      if (isNaN(iterator)) {
        form.setFieldsValue({ materialRollQuantity: 0 });
      } else {
        form.setFieldsValue({ materialRollQuantity: materialRollQuantity });
      }
    }

    setReRender((prev) => !prev);
  };

  const onChangeNumOfRows = (numOfRows) => {
    const defaultTotalLength = parseFloat(initialValues?.rowsToCut) * parseFloat(initialValues?.materialQuantity);
    const defaultGoodMeters = parseFloat(initialValues?.rowsToCut) * parseFloat(initialValues?.goodMetersFirst);
    const defaultCalcWaste = parseFloat(initialValues?.rowsToCut) * parseFloat(initialValues?.calculatedWastePaper);

    const materialQuantity = defaultTotalLength / numOfRows;
    const goodMetersFirst = defaultGoodMeters / numOfRows;
    const calculatedWastePaper = defaultCalcWaste / numOfRows;

    let materialDimension = initialValues?.materialDimension?.toString() + ', došlo je do izmene br. red. za odsecanje';

    form.setFieldsValue({ materialQuantity, materialDimension, goodMetersFirst, calculatedWastePaper });
  };

  const btnShowNPPModal = async () => {
    setIsNPPModalVisible(true);
  };

  const handleNPPCancel = () => {
    setIsNPPModalVisible(false);
  };

  const handleAuthenticationStatus = async () => {
    let formdata = form.getFieldsValue();

    if (!formdata.platesOrder) {
      notification.error({
        message: 'Ne možete overiti, niste odabrali redosled ploča.',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    if (formdata.productCode && !itemFile) {
      notification.error({
        message: 'Ne možete overiti, niste dodali PDF za prvi proizvod.',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    if (formdata.productCodeSecond !== undefined && !itemFileTwo) {
      notification.error({
        message: 'Ne možete overiti, niste dodali PDF za drugi proizvod.',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    if (formdata.productCodeAdded[0]) {
      if (formdata.productCodeAdded.length !== itemFileAdded.length) {
        notification.error({
          message: 'Ne možete overiti, niste dodali PDF za neki od dodatnih proizvoda.',
          duration: 2,
          placement: 'bottomRight',
        });
        return;
      }
    }

    formdata.workOrderAuthenticationStatus = 'Overena';

    const response = await Axios.put(`${SERVER_URL}/work-order-authstatus/${data._id}`, formdata, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    notification.success({
      message: 'Uspešno overen radni nalog!',
      duration: 2,
      placement: 'bottomRight',
    });

    setTimeout(() => {
      window.location.href = '/admin/work-orders';
    }, 700);
  };

  const handleSendAuthenticationStatus = async () => {
    let formdata = form.getFieldsValue();

    formdata.workOrderAuthenticationStatus = 'Poslata na overu';

    const response = await Axios.put(`${SERVER_URL}/work-order-authstatus/${data._id}`, formdata, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    notification.success({
      message: 'Radni nalog uspešno poslat na overu!',
      duration: 2,
      placement: 'bottomRight',
    });

    setTimeout(() => {
      window.location.href = '/admin/work-orders';
    }, 700);
  };

  const handlePiecesByMaterialWidth = (multipleCalculations, index) => {
    if (multipleCalculations) {
      let arr = additionalPiecesByMaterialWidth;

      arr[index] = parseFloat(additionalPieces[index]) * parseFloat(additionalRowsToCut[index]);

      // console.log(additionalPieces, additionalRowsToCut);
      setadditionalPiecesByMaterialWidth(arr);
      setadditionalPiecesByMaterialWidthSaved(arr);
      return;
    }
    let piecesByMaterialWidth = form.getFieldValue('pieces') * form.getFieldValue('rowsToCut');

    form.setFieldsValue({ piecesByMaterialWidth });
  };

  const [productsAddedAdditionaly, setProductsAddedAdditionaly] = useState(
    initialValues && initialValues?.productsAddedAdditionaly && initialValues?.productsAddedAdditionaly?.length > 0
      ? initialValues?.productsAddedAdditionaly
      : [],
  );

  const [additionalProductsArray, setAdditionalProductsArray] = useState(
    initialValues && initialValues?.productsAddedAdditionaly && initialValues?.productsAddedAdditionaly?.length > 0
      ? initialValues?.productsAddedAdditionaly
      : [],
  );

  const handleAddAdditionalProduct = (index) => {
    const productCodeAdded = form.getFieldValue(`productCodeAdded${index}`);
    const productQuantity = form.getFieldValue(`productQuantityAdded${index}`);
    // const productNameAdded = form.getFieldValue(`productNameAdded`)[0];
    // if any of those three are empty return and throw notification
    if (!productCodeAdded || !productQuantity) {
      notification.error({
        message: 'Morate uneti šifru, količinu i naziv proizvoda!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    const productNameAdded = form.getFieldValue(`productNameAdded`)[0];
    setProductsAddedAdditionaly([...productsAddedAdditionaly, { productNameAdded, productCodeAdded, productQuantity }]);
    const button = document.getElementById(`handleAddAdditionalProductButton${index}`);
    const prodQuantity = document.getElementById(`productQuantityAdd${index}`);
    button.setAttribute('disabled', 'disabled');
    prodQuantity.setAttribute('disabled', 'disabled');
  };

  const [recalculate, setRecalculate] = useState(false);

  const removeAdditionalProduct = (productCode, index) => {
    let filteredArray = additionalProductsArray.filter((item) => item.productCodeAdded !== productCode);
    setAdditionalProductsArray(filteredArray);
    setProductsAddedAdditionaly(productsAddedAdditionaly.filter((item) => item.productCodeAdded !== productCode));
    setRecalculate(!recalculate);
  };

  const [firstProductInitQuantity, setFirstProductInitQuantity] = useState(initialValues.firstProductQuantity || 0);
  const [secondProductInitQuantity, setSecondProductInitQuantity] = useState(initialValues.secondProductQuantity || 0);

  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    let totalQuantity = 0;
    if (productsAddedAdditionaly.length > 0) {
      productsAddedAdditionaly.forEach((item) => {
        // console.log('item', item);
        totalQuantity += Number(item.productQuantity);
      });
      if (firstProductInitQuantity && !isNaN(firstProductInitQuantity)) {
        totalQuantity += firstProductInitQuantity;
      }
      if (secondProductInitQuantity && !isNaN(secondProductInitQuantity)) {
        totalQuantity += secondProductInitQuantity;
      }
      setTotalQuantity(totalQuantity);
    } else {
      if (firstProductInitQuantity && !isNaN(firstProductInitQuantity)) {
        totalQuantity += firstProductInitQuantity;
      }
      if (secondProductInitQuantity && !isNaN(secondProductInitQuantity)) {
        totalQuantity += secondProductInitQuantity;
      }
      setTotalQuantity(totalQuantity);
    }
  }, [firstProductInitQuantity, secondProductInitQuantity, productsAddedAdditionaly, recalculate]);

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card className='workOrderForm' title={`${isNew ? 'NOVI RADNI NALOG' : 'IZMENA RADNOG NALOGA'}`}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='horizontal'
            form={form}
          >
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={12}>
                <Form.Item label='Broj radnog naloga' name='code'>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label='Broj NPP-a' name='orderToProduction'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={6} xs={10}>
                <Form.Item label='Mašina' name='machine' required>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges?.machine &&
                      historyOfChanges.machine?._id &&
                      historyOfChanges.machine._id !== form.getFieldValue('machine') && (
                        <Popover
                          placement='top'
                          title='Mašina'
                          content={<p>{historyOfChanges?.machine?.name}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {machines.items &&
                      machines.items.length > 0 &&
                      machines.items.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name='prevWorkOrderCode' label='Broj prethodnog radnog naloga'>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              {historyOfChanges &&
                historyOfChanges.creationDate &&
                moment(historyOfChanges.creationDate).format('DD-MM-YYYY') !==
                  moment(form.getFieldValue('creationDate')).format('DD-MM-YYYY') && (
                  <Popover
                    placement='top'
                    title='Datum'
                    content={<p>{moment(historyOfChanges.creationDate).format('DD-MM-YYYY')}</p>}
                    trigger='hover'
                  >
                    <HistoryOutlined style={{ color: 'black' }} />
                  </Popover>
                )}
              <Col md={6} xs={12}>
                <Form.Item label='Datum' name='creationDate'>
                  <DatePicker disabled={preparerDisabledButtons} format={'DD-MM-YYYY'} />
                </Form.Item>
              </Col>
              <Col md={5} xs={9}>
                <Form.Item label='Vreme izrade' name='productionTime' required>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.productionTime &&
                      historyOfChanges.productionTime !== form.getFieldValue('productionTime') && (
                        <Popover
                          placement='top'
                          title='Vreme izrade'
                          content={<p>{historyOfChanges.productionTime}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    {hours.map((item, index) => (
                      <Option key={index} value={item.numValue}>
                        {item.stringValue}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {additionalProductionTime &&
                  additionalProductionTime.map((item, index) => {
                    return (
                      <Form.Item label={`Vreme izrade ${index + 2}`} required key={index}>
                        <Select
                          onChange={(e) => {
                            let priv = additionalProductionTime;
                            priv[index] = e;
                            setadditionalProductionTime(priv);
                            setadditionalProductionTimeSaved(priv);
                            setReRender(!rerender);
                          }}
                          value={additionalProductionTime[index]}
                          suffixIcon={
                            historyOfChanges &&
                            historyOfChanges.productionTime &&
                            historyOfChanges.productionTime !== form.getFieldValue(`productionTime${index + 2}`) && (
                              <Popover
                                placement='top'
                                title='Vreme izrade'
                                content={<p>{historyOfChanges.additionalProductionTime}</p>}
                                trigger='hover'
                              >
                                <HistoryOutlined style={{ color: 'black' }} />
                              </Popover>
                            )
                          }
                        >
                          {hours.map((item, index) => (
                            <Option key={index} value={item.numValue}>
                              {item.stringValue}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  })}
              </Col>
            </Row>
            <div style={{ width: '100%' }} className='subtitle'>
              OSNOVNI PODACI O KUPCU I PROIZVODU
            </div>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Naziv i opis proizvoda'
                  name='productName'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.productName &&
                      historyOfChanges.productName !== form.getFieldValue('productName') && (
                        <Popover
                          placement='top'
                          title='Naziv i opis proizvoda'
                          content={<p>{historyOfChanges.productName}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Šifra proizvoda'
                  name='productCode'
                  labelCol={{ span: 24 }}
                  required
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => findProduct(value)}
                  >
                    {products.items &&
                      products.items.length > 0 &&
                      products.items.map((item, index) => (
                        <Option
                          key={index}
                          value={item.productCode}
                        >{`${item.productCode} - ${item.productName}`}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label='Tiraž'
                  name='firstProductQuantity'
                  labelCol={{ span: 24 }}
                  required
                  wrapperCol={{ span: 24 }}
                >
                  <Input type='number' onChange={(e) => setFirstProductInitQuantity(Number(e.target.value))} />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Button style={{ marginTop: '41px', height: '30px' }} disabled={isDisabled} onClick={() => createPdf()}>
                  OČITAJ PDF VEZAN ZA PROIZVOD
                </Button>
              </Col>
            </Row>

            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Naziv i opis proizvoda 2'
                  name='productNameSecond'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.productNameSecond &&
                      historyOfChanges.productNameSecond !== form.getFieldValue('productNameSecond') && (
                        <Popover
                          placement='top'
                          title='Naziv i opis proizvoda 2'
                          content={<p>{historyOfChanges.productNameSecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={12}>
                <Form.Item
                  label='Šifra proizvoda 2'
                  name='productCodeSecond'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => findProductSecond(value)}
                  >
                    <Option value=''></Option>
                    {products.items &&
                      products.items.length > 0 &&
                      products.items.map((item, index) => (
                        <Option
                          key={index}
                          value={item.productCode}
                        >{`${item.productCode} - ${item.productName}`}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item label='Tiraž' name='secondProductQuantity' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input type='number' onChange={(e) => setSecondProductInitQuantity(Number(e.target.value))} />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Button
                  style={{ marginTop: '41px', height: '30px' }}
                  disabled={isDisabledTwo}
                  onClick={() => createPdfSecond()}
                >
                  OČITAJ PDF VEZAN ZA PROIZVOD
                </Button>
              </Col>
            </Row>
            <Row>
              {historyOfChanges &&
                historyOfChanges.orderDate &&
                moment(historyOfChanges.orderDate).format('DD-MM-YYYY') !==
                  moment(form.getFieldValue('orderDate')).format('DD-MM-YYYY') && (
                  <Popover
                    placement='top'
                    title='Datum narudžbe'
                    content={<p>{moment(historyOfChanges.orderDate).format('DD-MM-YYYY')}</p>}
                    trigger='hover'
                  >
                    <HistoryOutlined style={{ color: 'black' }} />
                  </Popover>
                )}
              <Col>
                <Form.Item
                  label='Datum narudžbe'
                  name='orderDate'
                  labelCol={{ span: 24 }}
                  required
                  wrapperCol={{ span: 24 }}
                >
                  <DatePicker disabled={preparerDisabledButtons} format={'DD-MM-YYYY'} />
                </Form.Item>
              </Col>
              {historyOfChanges &&
                historyOfChanges.deliveryDate &&
                moment(historyOfChanges.deliveryDate).format('DD-MM-YYYY') !==
                  moment(form.getFieldValue('deliveryDate')).format('DD-MM-YYYY') && (
                  <Popover
                    placement='top'
                    title='Rok isporuke'
                    content={<p>{moment(historyOfChanges.deliveryDate).format('DD-MM-YYYY')}</p>}
                    trigger='hover'
                  >
                    <HistoryOutlined style={{ color: 'black' }} />
                  </Popover>
                )}
              <Col md={4} xs={14}>
                <Form.Item
                  label='Rok isporuke'
                  name='deliveryDate'
                  labelCol={{ span: 24 }}
                  required
                  wrapperCol={{ span: 24 }}
                >
                  <DatePicker disabled={preparerDisabledButtons} format={'DD-MM-YYYY'} />
                </Form.Item>
              </Col>
              <div style={{ marginTop: '10px', marginLeft: 'auto', marginBottom: '10px' }}>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => setProductArr([...productsArr, {}])}
                  type='primary'
                  htmlType='button'
                >
                  Dodaj
                </Button>
                <Button
                  onClick={() => {
                    if (productsArr.length > 0) {
                      setProductArr(productsArr.slice(0, -1));
                    }
                  }}
                  type='primary'
                  htmlType='button'
                >
                  Ukloni
                </Button>
              </div>
            </Row>

            {productsArr.map((item, index) => (
              <Row className='mobileVersion' gutter={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Col md={8} xs={24}>
                  <Form.Item
                    name={['productNameAdded', index]}
                    // name={`productNameAdded${index}`}
                    label='Naziv dodatnog proizvoda'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    // name={['productCodeAdded', index]}
                    name={`productCodeAdded${index}`}
                    label='Šifra'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      // disabled={preparerDisabledButtons}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value) => findProductAdded(value, index)}
                      // id={`productCodeAddSelect${index}`}
                    >
                      {products.items &&
                        products.items.length > 0 &&
                        products.items.map((item, index) => (
                          <Option
                            key={index}
                            value={item.productCode}
                            // id={`productCodeAddSelect${index}`}
                          >{`${item.productCode} - ${item.productName}`}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={2} xs={12}>
                  <Form.Item
                    label='Tiraž'
                    name={`productQuantityAdded${index}`}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input type='number' id={`productQuantityAdd${index}`} />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Button
                    onClick={() => handleAddAdditionalProduct(index)}
                    style={{ marginLeft: '20px', marginTop: '10px' }}
                    type='primary'
                    htmlType='button'
                    id={`handleAddAdditionalProductButton${index}`}
                    // disabled={data?.workOrderStatus !== 'kreiran'}
                  >
                    Dodaj
                  </Button>
                </Col>
              </Row>
            ))}
            {additionalProductsArray &&
              additionalProductsArray.length > 0 &&
              additionalProductsArray.map((item, index) => {
                return (
                  <div>
                    <Row className='mobileVersion' gutter={8}>
                      <Col md={8} xs={24}>
                        <Form.Item label='Naziv dodatnog proizvoda' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Input value={`${item.productNameAdded}`} disabled></Input>
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={24}>
                        <Form.Item label='Šifra' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Input value={`${item.productCodeAdded}`} disabled></Input>
                        </Form.Item>
                      </Col>
                      <Col md={2} xs={24}>
                        <Form.Item label='Tiraž' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Input value={item.productQuantity} disabled></Input>
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          onClick={() => removeAdditionalProduct(item.productCodeAdded, index)}
                          style={{ marginLeft: '20px', marginTop: '10px' }}
                          type='primary'
                          htmlType='button'
                        >
                          Ukloni
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            {(!additionalProductsArray || additionalProductsArray.length === 0) &&
              productsInWorkOrder?.items?.map(
                (item, index) =>
                  item.product[0] && (
                    <div>
                      <Row className='mobileVersion' gutter={8}>
                        <Col md={8} xs={24}>
                          <Form.Item label='Naziv dodatnog proizvoda' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input value={item.product[0].productName} disabled />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label='Šifra dodatnog proizvoda' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input value={item.product[0].productCode} disabled></Input>
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            onClick={() => removeProductsInWo(item._id)}
                            style={{ marginLeft: '20px', marginTop: '10px' }}
                            type='primary'
                            htmlType='button'
                            disabled={data?.workOrderStatus !== 'kreiran'}
                          >
                            Ukloni
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ),
              )}
            <Row className='mobileVersion' gutter={8}>
              <Col md={8} xs={24}>
                <Form.Item label='Kupac' required name='clientId' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.clientId &&
                      historyOfChanges.clientId._id &&
                      historyOfChanges.clientId._id !== form.getFieldValue('clientId') && (
                        <Popover
                          placement='top'
                          title='Kupac'
                          content={
                            <p>
                              {historyOfChanges.clientId.sku} - {historyOfChanges.clientId.name}
                            </p>
                          }
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(value) => findCompany(value)}
                  >
                    {companies.items &&
                      companies.items.length > 0 &&
                      companies.items.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {`${item.sku}-${item.name}`}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Porudžbenica' name='orderCode' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.orderCode &&
                      historyOfChanges.orderCode &&
                      historyOfChanges.orderCode !== form.getFieldValue('orderCode') && (
                        <Popover
                          placement='top'
                          title='Porudžbenica'
                          content={<p>{historyOfChanges.orderCode}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={12}>
                <Form.Item label='Naziv kupca' name='companyName' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col md={8} xs={12}>
                <Form.Item label='Šifra kupca' name='companyID' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={24}>
                {totalQuantity && totalQuantity > 0 ? (
                  <Form.Item
                    label='Tiraž za štampu'
                    required
                    // name='printingQuantity'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input type='number' disabled value={totalQuantity || 0} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Tiraž za štampu'
                    required
                    name='printingQuantity'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      disabled={preparerDisabledButtons}
                      type='number'
                      suffix={
                        historyOfChanges &&
                        historyOfChanges.printingQuantity &&
                        historyOfChanges.printingQuantity !== form.getFieldValue('printingQuantity') && (
                          <Popover
                            placement='top'
                            title='Tiraž za štampu'
                            content={<p>{historyOfChanges.printingQuantity}</p>}
                            trigger='hover'
                          >
                            <HistoryOutlined style={{ color: 'black' }} />
                          </Popover>
                        )
                      }
                      style={{ width: 150 }}
                      min='0'
                    />
                  </Form.Item>
                )}
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Jedinice mere' name='productUnits' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    onChange={(e) => {
                      handleChangeUnits(e, false);
                    }}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.productUnits &&
                      historyOfChanges.productUnits !== form.getFieldValue('productUnits') && (
                        <Popover
                          placement='top'
                          title='Jedinice mere'
                          content={<p>{historyOfChanges.productUnits}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    style={{ width: 250 }}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Tiraž za isporuku' name='printing' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    style={{ width: 150 }}
                    type='text'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.printing &&
                      historyOfChanges.printing &&
                      historyOfChanges.printing !== form.getFieldValue('printing') && (
                        <Popover
                          placement='top'
                          title='Tiraž za isporuku'
                          content={<p>{historyOfChanges.printing}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Adresa za isporuku'
                  name='deliveryAddress'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.deliveryAddress &&
                      historyOfChanges.deliveryAddress &&
                      historyOfChanges.deliveryAddress !== form.getFieldValue('deliveryAddress') && (
                        <Popover
                          placement='top'
                          title='Adresa za isporuku'
                          content={<p>{historyOfChanges.deliveryAddress}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Adresa za fakturisanje'
                  name='invoiceAddress'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.invoiceAddress &&
                      historyOfChanges.invoiceAddress &&
                      historyOfChanges.invoiceAddress !== form.getFieldValue('invoiceAddress') && (
                        <Popover
                          placement='top'
                          title='Adresa za fakturisanje'
                          content={<p>{historyOfChanges.invoiceAddress}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Kontakt osoba' name='contactPerson' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.contactPerson &&
                      historyOfChanges.contactPerson &&
                      historyOfChanges.contactPerson !== form.getFieldValue('contactPerson') && (
                        <Popover
                          placement='top'
                          title='Kontakt osoba'
                          content={<p>{historyOfChanges.contactPerson}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Telefon' name='phone' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.phone &&
                      historyOfChanges.phone &&
                      historyOfChanges.phone !== form.getFieldValue('phone') && (
                        <Popover
                          placement='top'
                          title='Telefon'
                          content={<p>{historyOfChanges.phone}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Email' name='email' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={true}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.email &&
                      historyOfChanges.email &&
                      historyOfChanges.email !== form.getFieldValue('email') && (
                        <Popover
                          placement='top'
                          title='Email'
                          content={<p>{historyOfChanges.email}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ width: '100%' }} className='subtitle'>
              TEHNIČKI PODACI O PROIZVODU
            </div>
            <Row className='formatField' gutter={8} justify='center'>
              <Col md={12} xs={24}>
                <Form.Item label='Format' name='format' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.format &&
                      historyOfChanges.format &&
                      historyOfChanges.format !== form.getFieldValue('format') && (
                        <Popover
                          placement='top'
                          title='Format'
                          content={<p>{historyOfChanges.format}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            {additionalFormat &&
              additionalFormat.map((item, index) => (
                <Row className='formatField' gutter={8} justify='center' key={index}>
                  <Col md={12} xs={24}>
                    <Form.Item label={`Format ${index + 2}`} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalFormat;
                          priv[index] = e.target.value;
                          setAdditionalFormat(priv);
                          setAdditionalFormatSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalFormat[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.format &&
                          historyOfChanges.format !== form.getFieldValue(`format${index}`) && (
                            <Popover
                              placement='top'
                              title={`Format ${index}`}
                              content={<p>{historyOfChanges.format}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            <Row className='mobileVersion' gutter={8}>
              <Col md={8} xs={12}>
                <Form.Item
                  label='Obim cilindra/Z'
                  required
                  name='cilinderVolume'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.cilinderVolume &&
                      historyOfChanges.cilinderVolume &&
                      historyOfChanges.cilinderVolume !== form.getFieldValue('cilinderVolume') && (
                        <Popover
                          placement='top'
                          title='Obim cilindra'
                          content={<p>{historyOfChanges.cilinderVolume}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalCilinderVolume &&
                  additionalCilinderVolume.map((item, index) => (
                    <Form.Item
                      label={`Obim cilindra/Z ${index + 2}`}
                      required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalCilinderVolume;
                          priv[index] = e.target.value;
                          setAdditionalCilinderVolume(priv);
                          setAdditionalCilinderVolumeSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalCilinderVolume[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.cilinderVolume &&
                          historyOfChanges.cilinderVolume !== form.getFieldValue(`cilinderVolume${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Obim cilindra ${index + 2}`}
                              content={<p>{historyOfChanges.cilinderVolume}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={8} xs={12}>
                <Form.Item label='Broj kom u TM' name='pieces' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    onChange={() => handlePiecesByMaterialWidth(false)}
                    disabled={preparerDisabledButtons}
                    style={{ width: '300px' }}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.pieces &&
                      historyOfChanges.pieces &&
                      historyOfChanges.pieces !== form.getFieldValue('pieces') && (
                        <Popover
                          placement='top'
                          title='Broj kom u TM'
                          content={<p>{historyOfChanges.pieces}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalPieces &&
                  additionalPieces.map((item, index) => (
                    <Form.Item
                      label={`Broj kom u TM ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalPieces;
                          priv[index] = e.target.value;
                          setadditionalPieces(priv);
                          setadditionalPiecesSaved(priv);
                          setReRender(!rerender);
                          handlePiecesByMaterialWidth(true, index);
                        }}
                        value={additionalPieces[index]}
                        style={{ width: '300px' }}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.pieces &&
                          historyOfChanges.pieces !== form.getFieldValue(`pieces${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Broj kom u TM ${index + 2}`}
                              content={<p>{historyOfChanges.pieces}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  label='Broj redova za odsecanje'
                  name='rowsToCut'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    type='number'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rowsToCut &&
                      historyOfChanges.rowsToCut &&
                      historyOfChanges.rowsToCut !== form.getFieldValue('rowsToCut') && (
                        <Popover
                          placement='top'
                          title='Broj redova za odsecanje'
                          content={<p>{historyOfChanges.rowsToCut}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    style={{ width: '150px' }}
                    min='0'
                    onChange={(e) => {
                      onChangeNumOfRows(e);
                      handlePiecesByMaterialWidth(false);
                    }}
                  />
                </Form.Item>
                {additionalRowsToCut &&
                  additionalRowsToCut.map((item, index) => (
                    <Form.Item
                      label={`Broj redova za odsecanje ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalRowsToCut;
                          priv[index] = e.target.value;
                          setadditionalRowsToCut(priv);
                          setadditionalRowsToCutSaved(priv);
                          setReRender(!rerender);
                          handlePiecesByMaterialWidth(true, index);
                        }}
                        value={additionalRowsToCut[index]}
                        type='number'
                        min='0'
                        style={{ width: '150px' }}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.rowsToCut &&
                          historyOfChanges.rowsToCut !== form.getFieldValue(`rowsToCut${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Broj redova za odsecanje ${index + 2}`}
                              content={<p>{historyOfChanges.rowsToCut}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  label='Broj kom u TM po SM'
                  name='piecesByMaterialWidth'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    type='number'
                    min='0'
                    disabled
                    style={{ width: '150px' }}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.piecesByMaterialWidth &&
                      historyOfChanges.piecesByMaterialWidth &&
                      historyOfChanges.piecesByMaterialWidth !== form.getFieldValue('piecesByMaterialWidth') && (
                        <Popover
                          placement='top'
                          title='Broj kom u TM po SM'
                          content={<p>{historyOfChanges.piecesByMaterialWidth}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalPiecesByMaterialWidth &&
                  additionalPiecesByMaterialWidth.map((item, index) => (
                    <Form.Item
                      label={`Broj kom u TM po SM ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalPiecesByMaterialWidth;
                          priv[index] = e.target.value;
                          setadditionalPiecesByMaterialWidth(priv);
                          setadditionalPiecesByMaterialWidthSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalPiecesByMaterialWidth[index]}
                        type='number'
                        min='0'
                        disabled
                        style={{ width: '150px' }}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.piecesByMaterialWidth &&
                          historyOfChanges.piecesByMaterialWidth !==
                            form.getFieldValue(`piecesByMaterialWidth${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Broj kom u TM po SM ${index + 2}`}
                              content={<p>{historyOfChanges.piecesByMaterialWidth}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
            </Row>
            <div style={{ width: '100%' }} className='subtitle'>
              PRIPREMA
            </div>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={24}>
                <Form.Item label='Grafička priprema' name='graphics' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.graphics &&
                      historyOfChanges.graphics &&
                      historyOfChanges.graphics !== form.getFieldValue('graphics') && (
                        <Popover
                          placement='top'
                          title='Grafička priprema'
                          content={<p>{historyOfChanges.graphics}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    onChange={(value) =>
                      value === 'Postojeća sa izmenom' ? setShowGraphicEdits(true) : setShowGraphicEdits(false)
                    }
                  >
                    <Option></Option>
                    {graphic.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label='Ploče' name='plates' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.paltes &&
                      historyOfChanges.paltes &&
                      historyOfChanges.paltes !== form.getFieldValue('paltes') && (
                        <Popover
                          placement='top'
                          title='Ploče'
                          content={<p>{historyOfChanges.paltes}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalPlates &&
                  additionalPlates.map((item, index) => (
                    <Form.Item
                      label={`Ploče ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalPlates;
                          priv[index] = e.target.value;
                          setadditionalPlates(priv);
                          setadditionalPlatesSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalPlates[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.plates &&
                          historyOfChanges.plates !== form.getFieldValue(`plates${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Ploče ${index + 2}`}
                              content={<p>{historyOfChanges.plates}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Broj boja'
                  required
                  name='numberOfColors'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.numberOfColors &&
                      historyOfChanges.numberOfColors &&
                      historyOfChanges.numberOfColors !== form.getFieldValue('numberOfColors') && (
                        <Popover
                          placement='top'
                          title='Broj boja'
                          content={<p>{historyOfChanges.numberOfColors}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value='0'>0</Option>
                    <Option value='1'>1</Option>
                    <Option value='2'>2</Option>
                    <Option value='3'>3</Option>
                    <Option value='4'>4</Option>
                    <Option value='5'>5</Option>
                    <Option value='6'>6</Option>
                    <Option value='7'>7</Option>
                    <Option value='8'>8</Option>
                  </Select>
                </Form.Item>
                {additionalNumberOfColors &&
                  additionalNumberOfColors.map((item, index) => (
                    <Form.Item
                      label={`Broj boja ${index + 2}`}
                      required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        onChange={(e) => {
                          let priv = additionalNumberOfColors;
                          priv[index] = e;
                          setadditionalNumberOfColors(priv);
                          setadditionalNumberOfColorsSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalNumberOfColors[index]}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.numberOfColors &&
                          historyOfChanges.numberOfColors !== form.getFieldValue(`numberOfColors${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Broj boja ${index + 2}`}
                              content={<p>{historyOfChanges.numberOfColors}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value='0'>0</Option>
                        <Option value='1'>1</Option>
                        <Option value='2'>2</Option>
                        <Option value='3'>3</Option>
                        <Option value='4'>4</Option>
                        <Option value='5'>5</Option>
                        <Option value='6'>6</Option>
                        <Option value='7'>7</Option>
                        <Option value='8'>8</Option>
                      </Select>
                    </Form.Item>
                  ))}
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label='Štamparske forme: Naplaćuje se?'
                  name='printForms'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.printForms &&
                      historyOfChanges.printForms &&
                      historyOfChanges.printForms !== form.getFieldValue('printForms') && (
                        <Popover
                          placement='top'
                          title='Štamparske forme: Naplaćuje se?'
                          content={<p>{historyOfChanges.printForms}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            {showGraphicEdits && (
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Form.Item
                    label='Izmene za grafičku pripremu'
                    name='graphicEdits'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      suffix={
                        historyOfChanges &&
                        historyOfChanges.graphicEdits &&
                        historyOfChanges.graphicEdits &&
                        historyOfChanges.graphicEdits !== form.getFieldValue('graphicEdits') && (
                          <Popover
                            placement='top'
                            title='Izmene za grafičku pripremu'
                            content={<p>{historyOfChanges.graphicEdits}</p>}
                            trigger='hover'
                          >
                            <HistoryOutlined style={{ color: 'black' }} />
                          </Popover>
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row className='mobileVersion' gutter={8}>
              <Col flex={2} xs={24}>
                <Form.Item label='Redosled ploča' name='platesOrder' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.platesOrder &&
                      historyOfChanges.platesOrder &&
                      historyOfChanges.platesOrder !== form.getFieldValue('platesOrder') && (
                        <Popover
                          placement='top'
                          title='Redosled ploča'
                          content={<p>{historyOfChanges.platesOrder}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              {additionalPlatesOrder &&
                additionalPlatesOrder.map((item, index) => (
                  <Col flex={2} xs={24} key={index}>
                    <Form.Item
                      label={`Redosled ploča ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalPlatesOrder;
                          priv[index] = e.target.value;
                          setadditionalPlatesOrder(priv);
                          setadditionalPlatesOrderSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalPlatesOrder[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.platesOrder &&
                          historyOfChanges.platesOrder !== form.getFieldValue(`platesOrder${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Redosled ploča ${index + 2}`}
                              content={<p>{historyOfChanges.platesOrder}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                ))}
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={4} xs={12}>
                <Form.Item label='Alat' name='tools' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.tools &&
                      historyOfChanges.tools &&
                      historyOfChanges.tools !== form.getFieldValue('tools') && (
                        <Popover
                          placement='top'
                          title='Obim cilindra'
                          content={<p>{historyOfChanges.tools}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalTools &&
                  additionalTools.map((item, index) => (
                    <Form.Item
                      label={`Alat ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalTools;
                          priv[index] = e.target.value;
                          setadditionalTools(priv);
                          setadditionalToolsSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalTools[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.tools &&
                          historyOfChanges.tools !== form.getFieldValue(`tools${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Alat ${index + 2}`}
                              content={<p>{historyOfChanges.tools}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={4} xs={12}>
                <Form.Item label='Oznaka alata' name='toolsID' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.toolsID &&
                      historyOfChanges.toolsID !== form.getFieldValue('toolsID') && (
                        <Popover
                          placement='top'
                          title='Oznaka alata'
                          content={<p>{historyOfChanges.toolsID}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalToolsID &&
                  additionalToolsID.map((item, index) => (
                    <Form.Item
                      label={`Oznaka alata ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalToolsID;
                          priv[index] = e.target.value;
                          setadditionalToolsID(priv);
                          setadditionalToolsIDSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalToolsID[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.toolsID &&
                          historyOfChanges.toolsID !== form.getFieldValue(`toolsID${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Oznaka alata ${index + 2}`}
                              content={<p>{historyOfChanges.toolsID}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  label='Oznaka kutije alata'
                  name='toolsBox'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.toolsBox &&
                      historyOfChanges.toolsBox !== form.getFieldValue('toolsBox') && (
                        <Popover
                          placement='top'
                          title='Oznaka kutije alata'
                          content={<p>{historyOfChanges.toolsBox}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalToolsBox &&
                  additionalToolsBox.map((item, index) => (
                    <Form.Item
                      label={`Oznaka kutije alata ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalToolsBox;
                          priv[index] = e.target.value;
                          setadditionalToolsBox(priv);
                          setadditionalToolsBoxSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalToolsBox[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.toolsBox &&
                          historyOfChanges.toolsBox !== form.getFieldValue(`toolsBox${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Oznaka kutije alata ${index + 2}`}
                              content={<p>{historyOfChanges.toolsBox}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  label='Naplaćuje se?'
                  name='toolsIsCharging'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.toolsIsCharging &&
                      historyOfChanges.toolsIsCharging !== form.getFieldValue('toolsIsCharging') && (
                        <Popover
                          placement='top'
                          title='Naplaćuje se?'
                          content={<p>{historyOfChanges.toolsIsCharging}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    style={{ width: '150px' }}
                  />
                </Form.Item>
                {additionalToolsIsCharging &&
                  additionalToolsIsCharging.map((item, index) => (
                    <Form.Item
                      label={`Naplaćuje se? ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalToolsIsCharging;
                          priv[index] = e.target.value;
                          setadditionalToolsIsCharging(priv);
                          setadditionalToolsIsChargingSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalToolsIsCharging[index]}
                        min='0'
                        style={{ width: '150px' }}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.toolsIsCharging &&
                          historyOfChanges.toolsIsCharging !== form.getFieldValue(`toolsIsCharging${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Naplaćuje se? ${index + 2}`}
                              content={<p>{historyOfChanges.toolsIsCharging}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={4} xs={12}>
                <Form.Item label='Lakiranje' name='lacquering' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.lacquering &&
                      historyOfChanges.lacquering !== form.getFieldValue('lacquering') && (
                        <Popover
                          placement='top'
                          title='Lakiranje'
                          content={<p>{historyOfChanges.lacquering}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    onChange={(e) => {
                      setcheckLakiranje(e);
                      if (e?.taget?.value !== 'DA') {
                        form.setFieldsValue({ lacquerTypes: '' });
                        form.setFieldsValue({ varnishCoverage: '' });
                        form.setFieldsValue({ varnishWay: '' });
                      }
                    }}
                  >
                    <Option value=''></Option>
                    <Option value='DA'>DA</Option>
                    <Option value='NE'>NE</Option>
                  </Select>
                </Form.Item>
                {additionalLacquering &&
                  additionalLacquering.map((item, index) => (
                    <Form.Item
                      label={`Lakiranje ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        value={additionalLacquering[index]}
                        onChange={(value) => {
                          let priv = additionalLacquering;
                          priv[index] = value;
                          setAdditionalLacquering(priv);
                          setAdditionalLacqueringSaved(priv);
                          const shouldDisable = value !== 'DA';
                          const fieldIndex = index + 2;
                          setReRender(!rerender);
                          setDisabledFields((prev) => ({
                            ...prev,
                            [`disableLacquiringFields${fieldIndex}`]: shouldDisable,
                          }));
                        }}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.lacquering &&
                          historyOfChanges.lacquering !== form.getFieldValue(`lacquering${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Lakiranje ${index + 2}`}
                              content={<p>{historyOfChanges.lacquering}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value=''></Option>
                        <Option value='DA'>DA</Option>
                        <Option value='NE'>NE</Option>
                      </Select>
                    </Form.Item>
                  ))}
              </Col>

              <Col md={4} xs={12}>
                <Form.Item label='Tip laka' name='lacquerTypes' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={checkLakiranje === 'NE' || checkLakiranje === ''}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.lacquerTypes &&
                      historyOfChanges.lacquerTypes &&
                      historyOfChanges.lacquerTypes !== form.getFieldValue('lacquerTypes') && (
                        <Popover
                          placement='top'
                          title='Tip laka'
                          content={<p>{historyOfChanges.lacquerTypes}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    <Option value='SJAJNI'>SJAJNI</Option>
                    <Option value='MAT'>MAT</Option>
                  </Select>
                </Form.Item>
                {additionalLacquerTypes &&
                  additionalLacquerTypes.map((item, index) => (
                    <Form.Item
                      label={`Tip laka ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        onChange={(e) => {
                          let priv = additionalLacquerTypes;
                          priv[index] = e;
                          setAdditionalLacquerTypes(priv);
                          setAdditionalLacquerTypesSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalLacquerTypes[index]}
                        disabled={!!disabledFields[`disableLacquiringFields${index + 2}`]}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.lacquerTypes &&
                          historyOfChanges.lacquerTypes !== form.getFieldValue(`lacquerTypes${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Tip laka ${index + 2}`}
                              content={<p>{historyOfChanges.lacquerTypes}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value=''></Option>
                        <Option value='SJAJNI'>SJAJNI</Option>
                        <Option value='MAT'>MAT</Option>
                      </Select>
                    </Form.Item>
                  ))}
              </Col>

              <Col>
                <Form.Item label='Vrsta laka' name='varnishWay' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={checkLakiranje === 'NE' || checkLakiranje === ''}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.varnishWay &&
                      historyOfChanges.varnishWay &&
                      historyOfChanges.varnishWay !== form.getFieldValue('varnishWay') && (
                        <Popover
                          placement='top'
                          title='Vrsta laka'
                          content={<p>{historyOfChanges.varnishWay}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    <Option value='VODENI'>VODENI</Option>
                    <Option value='UV'>UV</Option>
                  </Select>
                </Form.Item>
                {additionalVarnishWay &&
                  additionalVarnishWay.map((item, index) => (
                    <Form.Item
                      label={`Vrsta laka ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        onChange={(e) => {
                          let priv = additionalVarnishWay;
                          priv[index] = e;
                          setadditionalVarnishWay(priv);
                          setadditionalVarnishWaySaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalVarnishWay[index]}
                        disabled={!!disabledFields[`disableLacquiringFields${index + 2}`]}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.varnishWay &&
                          historyOfChanges.varnishWay !== form.getFieldValue(`varnishWay${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Vrsta laka ${index + 2}`}
                              content={<p>{historyOfChanges.varnishWay}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value=''></Option>
                        <Option value='VODENI'>VODENI</Option>
                        <Option value='UV'>UV</Option>
                      </Select>
                    </Form.Item>
                  ))}
              </Col>

              <Col>
                <Form.Item
                  label='Pokrivenost laka'
                  name='varnishCoverage'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={checkLakiranje === 'NE' || checkLakiranje === ''}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.varnishCoverage &&
                      historyOfChanges.varnishCoverage &&
                      historyOfChanges.varnishCoverage !== form.getFieldValue('varnishCoverage') && (
                        <Popover
                          placement='top'
                          title='Pokrivenost laka'
                          content={<p>{historyOfChanges.varnishCoverage}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    <Option value='potpuno'>POTPUNO</Option>
                    <Option value='parcijalno'>PARCIJALNO</Option>
                  </Select>
                </Form.Item>
                {additionalVarnishCoverage &&
                  additionalVarnishCoverage.map((item, index) => (
                    <Form.Item
                      label={`Pokrivenost laka ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        onChange={(e) => {
                          let priv = additionalVarnishCoverage;
                          priv[index] = e;
                          setadditionalVarnishCoverage(priv);
                          setadditionalVarnishCoverageSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalVarnishCoverage[index]}
                        disabled={!!disabledFields[`disableLacquiringFields${index + 2}`]}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.varnishCoverage &&
                          historyOfChanges.varnishCoverage !== form.getFieldValue(`varnishCoverage${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Pokrivenost laka ${index + 2}`}
                              content={<p>{historyOfChanges.varnishCoverage}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value=''></Option>
                        <Option value='potpuno'>POTPUNO</Option>
                        <Option value='parcijalno'>PARCIJALNO</Option>
                      </Select>
                    </Form.Item>
                  ))}
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={4} xs={12}>
                <Form.Item label='Štampa' name='print' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.print &&
                      historyOfChanges.print &&
                      historyOfChanges.print !== form.getFieldValue('print') && (
                        <Popover
                          placement='top'
                          title='Štampa'
                          content={<p>{historyOfChanges.print}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    <Option value='rolna-rolna'>rolna-rolna</Option>
                    <Option value='rolna-tabak'>rolna-tabak</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {data?.folioWay && (
              <Row className='mobileVersion' gutter={8}>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Plastifikacija/Foliotisak'
                    name='folioWay'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      disabled={preparerDisabledButtons}
                      onChange={(e) => {
                        onChangeFolioWay(e);
                        printingFormsPriceHandler();
                      }}
                      suffixIcon={
                        historyOfChanges &&
                        historyOfChanges.folioWay &&
                        historyOfChanges.folioWay &&
                        historyOfChanges.folioWay !== form.getFieldValue('folioWay') && (
                          <Popover
                            placement='top'
                            title='Štampa'
                            content={<p>{historyOfChanges.folioWay}</p>}
                            trigger='hover'
                          >
                            <HistoryOutlined style={{ color: 'black' }} />
                          </Popover>
                        )
                      }
                    >
                      <Option value='hladniFT'>Plastifikacija/Hladni FT</Option>
                      <Option value='topliFT'>Topli FT</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {!hideFolioTypeWarmFT && (
                  <Col md={4} xs={12}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className='firstChildInRowFirstSectionANTD'
                      label='Izrada na mašini'
                      name='folioPrintingMachine'
                    >
                      <Select
                        disabled={preparerDisabledButtons}
                        onChange={(e) => {
                          onChangeFolioPrintingMachine(e);
                        }}
                      >
                        {machinesForPlasticization &&
                          machinesForPlasticization.length > 0 &&
                          machinesForPlasticization.map((item, index) => (
                            <Option key={index} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col md={4} xs={12}>
                  <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label='Vrsta folije' name='folioType'>
                    <Select
                      disabled={preparerDisabledButtons}
                      dropdownStyle={{ minWidth: '50%' }}
                      onChange={(e) => {
                        onChangeFolioType(e);
                      }}
                    >
                      {folioTypes &&
                        folioTypes.length > 0 &&
                        folioTypes.map((item, index) => (
                          <Option value={item._id}>
                            {item.code} - {item.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className='firstChildInRowFirstSectionANTD'
                    label='Širina rolne (mm)'
                    name='rollWidth'
                  >
                    <InputNumber disabled={preparerDisabledButtons} min='0' />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <div style={{ width: '100%' }} className='subtitle'>
              GOTOVA ROLNA
            </div>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={12}>
                <Form.Item label='Hilzna' name='hilzna' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.hilzna &&
                      historyOfChanges.hilzna &&
                      historyOfChanges.hilzna !== form.getFieldValue('hilzna') && (
                        <Popover
                          placement='top'
                          title='Hilzna'
                          content={<p>{historyOfChanges.hilzna}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={6} xs={12}>
                <Form.Item label='Promer hilzne' name='promerHilzne' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.promerHilzne &&
                      historyOfChanges.promerHilzne &&
                      historyOfChanges.promerHilzne !== form.getFieldValue('promerHilzne') && (
                        <Popover
                          placement='top'
                          title='Promer hilzne'
                          content={<p>{historyOfChanges.promerHilzne}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    {promerHilzneArray.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {additionalPromerHilzne &&
                  additionalPromerHilzne.map((item, index) => (
                    <Form.Item
                      label={`Promer hilzne ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Select
                        onChange={(e) => {
                          let priv = additionalPromerHilzne;
                          priv[index] = e;
                          setadditionalPromerHilzne(priv);
                          setadditionalPromerHilzneSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalPromerHilzne[index]}
                        suffixIcon={
                          historyOfChanges &&
                          historyOfChanges.promerHilzne &&
                          historyOfChanges.promerHilzne !== form.getFieldValue(`promerHilzne${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Promer hilzne ${index + 2}`}
                              content={<p>{historyOfChanges.promerHilzne}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      >
                        <Option value=''></Option>
                        {promerHilzneArray.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ))}
              </Col>

              <Col md={6} xs={12}>
                <Form.Item
                  label='Maksimalan prečnik rolne'
                  name='rollDiameter'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rollDiameter &&
                      historyOfChanges.rollDiameter &&
                      historyOfChanges.rollDiameter !== form.getFieldValue('rollDiameter') && (
                        <Popover
                          placement='top'
                          title='Maksimalan prečnik rolne'
                          content={<p>{historyOfChanges.rollDiameter}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalRollDiameter &&
                  additionalRollDiameter.map((item, index) => (
                    <Form.Item
                      label={`Maksimalan prečnik rolne ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalRollDiameter;
                          priv[index] = e.target.value;
                          setadditionalRollDiameter(priv);
                          setadditionalRollDiameterSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalRollDiameter[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.rollDiameter &&
                          historyOfChanges.rollDiameter !== form.getFieldValue(`rollDiameter${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Maksimalan prečnik rolne ${index + 2}`}
                              content={<p>{historyOfChanges.rollDiameter}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>

              <Col md={6} xs={12}>
                <Form.Item
                  label='Br. kom. na rol.'
                  name='rollPiecesNum'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    type='number'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rollPiecesNum &&
                      historyOfChanges.rollPiecesNum &&
                      historyOfChanges.rollPiecesNum !== form.getFieldValue('rollPiecesNum') && (
                        <Popover
                          placement='top'
                          title='Br. kom. na rolni'
                          content={<p>{historyOfChanges.rollPiecesNum}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    style={{ width: '150px' }}
                    min='0'
                  />
                </Form.Item>
                {additionalRollPiecesNum &&
                  additionalRollPiecesNum.map((item, index) => (
                    <Form.Item
                      label={`Br. kom. na rol. ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalRollPiecesNum;
                          priv[index] = e.target.value;
                          setadditionalRollPiecesNum(priv);
                          setadditionalRollPiecesNumSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalRollPiecesNum[index]}
                        type='number'
                        style={{ width: '150px' }}
                        min='0'
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.rollPiecesNum &&
                          historyOfChanges.rollPiecesNum !== form.getFieldValue(`rollPiecesNum${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Br. kom. na rol. ${index + 2}`}
                              content={<p>{historyOfChanges.rollPiecesNum}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={12}>
                <Form.Item
                  label='Dužina hilzne'
                  name='oneHilznaWidth'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.oneHilznaWidth &&
                      historyOfChanges.oneHilznaWidth &&
                      historyOfChanges.oneHilznaWidth !== form.getFieldValue('oneHilznaWidth') && (
                        <Popover
                          placement='top'
                          title='Dužina hilzne'
                          content={<p>{historyOfChanges.oneHilznaWidth}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={6} xs={12}>
                <Form.Item
                  label='Potreban broj hilzni'
                  name='hilzneQuantity'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.hilzneQuantity &&
                      historyOfChanges.hilzneQuantity &&
                      historyOfChanges.hilzneQuantity !== form.getFieldValue('hilzneQuantity') && (
                        <Popover
                          placement='top'
                          title='Potreban broj hilzni'
                          content={<p>{historyOfChanges.hilzneQuantity}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Form.Item
                label='Sliviranje sa inspekcijom'
                name='shrinkSleeveInput'
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  disabled={preparerDisabledButtons}
                  suffix={
                    historyOfChanges &&
                    historyOfChanges.shrinkSleeveInput &&
                    historyOfChanges.shrinkSleeveInput &&
                    historyOfChanges.shrinkSleeveInput !== form.getFieldValue('shrinkSleeveInput') && (
                      <Popover
                        placement='top'
                        title='Sliviranje sa inspekcijom'
                        content={<p>{historyOfChanges.shrinkSleeveInput}</p>}
                        trigger='hover'
                      >
                        <HistoryOutlined style={{ color: 'black' }} />
                      </Popover>
                    )
                  }
                />
              </Form.Item>
            </Row>
            <Row>
              <Col md={13} xs={12}>
                {historyOfChanges &&
                  historyOfChanges.rewindingMachines &&
                  historyOfChanges.rewindingMachines &&
                  historyOfChanges.rewindingMachines !== form.getFieldValue('rewindingMachines') && (
                    <Popover
                      placement='top'
                      title='Redosled premotavanja'
                      content={<p>{historyOfChanges.rewindingMachines}</p>}
                      trigger='hover'
                    >
                      <HistoryOutlined style={{ color: 'black' }} />
                    </Popover>
                  )}
                <Form.Item
                  label='Redosled premotavanja:'
                  name='rewindingMachines'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <TextArea disabled={preparerDisabledButtons} rows='1' />
                </Form.Item>
              </Col>

              <Col md={6} xs={12}>
                <Form.Item
                  label='Brojevi za premotavačicu V/M'
                  name='rollNum'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rollNum &&
                      historyOfChanges.rollNum &&
                      historyOfChanges.rollNum !== form.getFieldValue('rollNum') && (
                        <Popover
                          placement='top'
                          title='Brojevi za premotavačicu V/M'
                          content={<p>{historyOfChanges.rollNum}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
                {additionalRollNum &&
                  additionalRollNum.map((item, index) => (
                    <Form.Item
                      label={`Brojevi za premotavačicu V/M ${index + 2}`}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      key={index}
                    >
                      <Input
                        onChange={(e) => {
                          e.persist();
                          let priv = additionalRollNum;
                          priv[index] = e.target.value;
                          setadditionalRollNum(priv);
                          setadditionalRollNumSaved(priv);
                          setReRender(!rerender);
                        }}
                        value={additionalRollNum[index]}
                        suffix={
                          historyOfChanges &&
                          historyOfChanges.rollNum &&
                          historyOfChanges.rollNum !== form.getFieldValue(`rollNum${index + 2}`) && (
                            <Popover
                              placement='top'
                              title={`Brojevi za premotavačicu V/M ${index + 2}`}
                              content={<p>{historyOfChanges.rollNum}</p>}
                              trigger='hover'
                            >
                              <HistoryOutlined style={{ color: 'black' }} />
                            </Popover>
                          )
                        }
                      />
                    </Form.Item>
                  ))}
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={12} xs={24}>
                <Form.Item
                  label='Smer odmotavanja prednje'
                  name='unrollDirection'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.unrollDirection &&
                      historyOfChanges.unrollDirection &&
                      historyOfChanges.unrollDirection !== form.getFieldValue('unrollDirection') && (
                        <Popover
                          placement='top'
                          title='Smer odmotavanja prednje'
                          content={<p>{historyOfChanges.unrollDirection}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    {unrollDirectionArray.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  label='Smer odmotavanja zadnje'
                  name='unrollDirectionBack'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.unrollDirectionBack &&
                      historyOfChanges.unrollDirectionBack &&
                      historyOfChanges.unrollDirectionBack !== form.getFieldValue('unrollDirectionBack') && (
                        <Popover
                          placement='top'
                          title='Smer odmotavanja zadnje'
                          content={<p>{historyOfChanges.unrollDirectionBack}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  >
                    <Option value=''></Option>
                    {unrollDirectionArray.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {additionalRollNum && (
              <Row className='mobileVersion' gutter={8}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label='Smer odmotavanja banderole'
                    name='unrollDirectionBanderola'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      suffixIcon={
                        historyOfChanges &&
                        historyOfChanges.unrollDirectionBanderola &&
                        historyOfChanges.unrollDirectionBanderola &&
                        historyOfChanges.unrollDirectionBanderola !==
                          form.getFieldValue('unrollDirectionBanderola') && (
                          <Popover
                            placement='top'
                            title='Smer odmotavanja banderole'
                            content={<p>{historyOfChanges.unrollDirectionBanderola}</p>}
                            trigger='hover'
                          >
                            <HistoryOutlined style={{ color: 'black' }} />
                          </Popover>
                        )
                      }
                    >
                      <Option value=''></Option>
                      {unrollDirectionArray.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={8}>
              {historyOfChanges &&
                historyOfChanges.note &&
                historyOfChanges.note &&
                historyOfChanges.note !== form.getFieldValue('note') && (
                  <Popover placement='top' title='Napomena' content={<p>{historyOfChanges.note}</p>} trigger='hover'>
                    <HistoryOutlined style={{ color: 'black' }} />
                  </Popover>
                )}
              <Col flex={4}>
                <Form.Item label='Napomena' name='note' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <TextArea disabled={preparerDisabledButtons} rows={3} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8}>
              <Col flex={4}>
                <Form.Item
                  label='Označavanje rolne'
                  name='rollLabeling'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rollLabeling &&
                      historyOfChanges.rollLabeling &&
                      historyOfChanges.rollLabeling !== form.getFieldValue('rollLabeling') && (
                        <Popover
                          placement='top'
                          title='Označavanje rolne'
                          content={<p>{historyOfChanges.rollLabeling}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col flex={1}>
                <Form.Item
                  label='Označavanje kutije'
                  name='boxLabeling'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.boxLabeling &&
                      historyOfChanges.boxLabeling &&
                      historyOfChanges.boxLabeling !== form.getFieldValue('boxLabeling') && (
                        <Popover
                          placement='top'
                          title='Označavanje kutije'
                          content={<p>{historyOfChanges.boxLabeling}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={8} xs={15}>
                <Form.Item
                  label='Slaganje rolni na paletu'
                  name='rollStacking'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.rollStacking &&
                      historyOfChanges.rollStacking &&
                      historyOfChanges.rollStacking !== form.getFieldValue('rollStacking') && (
                        <Popover
                          placement='top'
                          title='Slaganje rolni na paletu'
                          content={<p>{historyOfChanges.rollStacking}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={8} xs={15}>
                <Form.Item
                  label='Pakovanje i označavanje palete'
                  name='paletteLabeling'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.paletteLabeling &&
                      historyOfChanges.paletteLabeling &&
                      historyOfChanges.paletteLabeling !== form.getFieldValue('paletteLabeling') && (
                        <Popover
                          placement='top'
                          title='Pakovanje i označavanje palete'
                          content={<p>{historyOfChanges.paletteLabeling}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={8} xs={15}>
                <Form.Item label='Otprema robe' name='productSetout' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.productSetout &&
                      historyOfChanges.productSetout &&
                      historyOfChanges.productSetout !== form.getFieldValue('productSetout') && (
                        <Popover
                          placement='top'
                          title='Otprema robe'
                          content={<p>{historyOfChanges.productSetout}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ width: '100%' }} className='subtitle'>
              MATERIJAL ZA TREBOVANJE ZA ŠTAMPU
            </div>

            <Row>
              <div style={{ marginTop: '10px', marginLeft: 'auto', marginBottom: '10px' }}>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => handleAddRoll()}
                  type='primary'
                  disabled={preparerDisabledButtons}
                  htmlType='button'
                >
                  Dodaj
                </Button>
                <Button
                  onClick={() => {
                    if (materialArr.length > 1) {
                      setMaterialArr(materialArr.slice(0, -1));
                    }
                  }}
                  type='primary'
                  disabled={preparerDisabledButtons}
                  htmlType='button'
                >
                  Ukloni
                </Button>
              </div>
            </Row>

            {materialArr.map((item, index) => (
              <Row className='mobileVersion' gutter={8}>
                <Col md={8} xs={12}>
                  <Form.Item
                    name={['material', index]}
                    label='Materijal'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      dropdownStyle={{ minWidth: '60%' }}
                      disabled={preparerDisabledButtons}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children?.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => selectMaterial(value, form, index)}
                    >
                      <Option value=''></Option>
                      {materials.items?.map((m, i) => (
                        <Option key={i} value={m._id}>
                          {m.code} - {m.name} {m.state}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    name={['materialRoll', index]}
                    label='Rolna'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select disabled={preparerDisabledButtons} onChange={(value) => rollHandler(value, index)}>
                      {rolls[index] &&
                        rolls[index].items &&
                        rolls[index].items.length > 0 &&
                        rolls[index].items.map((roll, i) => (
                          <Option key={i} value={roll?._id}>
                            1 x {`${roll?.length?.toFixed(0)} (${roll.initialLength - roll.length})`}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={3} xs={8}>
                  <Form.Item
                    name={['goodMeters', index]}
                    label='Dobri metri'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <InputNumber
                      disabled={preparerDisabledButtons}
                      style={{ width: '150px' }}
                      name={['goodMeters', index]}
                      onChange={(e) => {
                        materialRollQuantityHandler(e, index);
                      }}
                      min='0'
                    />
                  </Form.Item>
                </Col>
                <Col md={3} xs={6}>
                  <Form.Item
                    name={['calcWaste', index]}
                    label='Kalkulisana makulatura'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <InputNumber
                      disabled={preparerDisabledButtons}
                      name={['calcWaste', index]}
                      onChange={(e) => {
                        materialRollQuantityHandler(e, index);
                      }}
                      style={{ width: '150px' }}
                      min='0'
                    />
                  </Form.Item>
                </Col>
                <Col md={2} xs={8}>
                  <Form.Item
                    name={['materialRollQuantity', index]}
                    label='Ukupna kol. materijala (m)'
                    rules={[
                      {
                        validator: (rule, value) => {
                          if (value) {
                            return value > roll[index].length
                              ? Promise.reject('Prekoračili ste količinu materijala')
                              : Promise.resolve();
                          }
                          if (material[index] && material[index].length === 24) {
                            return Promise.reject('Unesite kolicinu');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Input name={['materialRollQuantity', index]} style={{ width: '150px' }} disabled min='0' />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            {rollsReservedWorkOrder?.items?.map(
              (item, index) =>
                item.material && (
                  <>
                    <Row className='mobileVersion' gutter={8}>
                      <Col md={4} xs={24}>
                        <Form.Item label='Izabrani materijal' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                          <Input value={item.material.name} disabled />
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={24}>
                        <Form.Item
                          name={['rollID', index]}
                          label='Rolna'
                          disabled
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col md={2} xs={24}>
                        <Form.Item
                          name={['goodMetersReserved', index]}
                          label='Dobri metri'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col md={3} xs={24}>
                        <Form.Item
                          name={['calcWasteReserved', index]}
                          label='Kalkulisana makulatura'
                          labelCol={{ span: 36 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={24}>
                        <Form.Item
                          name={['materialRollQuantityReserved', index]}
                          label='Ukupna količina materijala(m)'
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col md={4} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          onClick={() => removeRollReserved(item._id)}
                          style={{ marginLeft: '20px', marginTop: '10px' }}
                          type='primary'
                          htmlType='button'
                          disabled={
                            !data?.workOrderStatus.includes('kreiran') && !data?.workOrderStatus.includes('čeka')
                          }
                        >
                          Ukloni
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
            )}
            <Row>
              <Col md={8} xs={12}>
                <p>Materijal</p>

                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name='materialName'
                  className='materialField'
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Vrsta materijala 1'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialName &&
                      historyOfChanges.materialName &&
                      historyOfChanges.materialName !== form.getFieldValue('materialName') && (
                        <Popover
                          placement='top'
                          title='Vrsta materijala 1'
                          content={<p>{historyOfChanges.materialName}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={7} xs={12}>
                <p>Dimenzije</p>

                <Form.Item name='materialDimension' className='materialField materialField1'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Dimenzije materijala 1'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialDimension &&
                      historyOfChanges.materialDimension &&
                      historyOfChanges.materialDimension !== form.getFieldValue('materialDimension') && (
                        <Popover
                          placement='top'
                          title='Dimenzije materijala 1'
                          content={<p>{historyOfChanges.materialDimension}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={12}>
                <p>Dobri metri</p>

                <Form.Item name='goodMetersFirst' className='materialField materialField1'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Dobri metri 1'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.goodMetersFirst &&
                      historyOfChanges.goodMetersFirst &&
                      historyOfChanges.goodMetersFirst !== form.getFieldValue('goodMetersFirst') && (
                        <Popover
                          placement='top'
                          title='Dobri metri 1'
                          content={<p>{historyOfChanges.goodMetersFirst}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={12}>
                <p>Kalk. makulatura</p>

                <Form.Item name='calculatedWastePaper' className='materialField materialField1'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Kalkulisana makulatura 1'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.calculatedWastePaper &&
                      historyOfChanges.calculatedWastePaper &&
                      historyOfChanges.calculatedWastePaper !== form.getFieldValue('calculatedWastePaper') && (
                        <Popover
                          placement='top'
                          title='Kalkulisana makulatura 1'
                          content={<p>{historyOfChanges.calculatedWastePaper}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={12}>
                <p>Količina</p>

                <Form.Item name='materialQuantity' className='materialField materialField1'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Količina materijala 1'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialQuantity &&
                      historyOfChanges.materialQuantity &&
                      historyOfChanges.materialQuantity !== form.getFieldValue('materialQuantity') && (
                        <Popover
                          placement='top'
                          title='Količina materijala 1'
                          content={<p>{historyOfChanges.materialQuantity}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={[0, 0]}>
              <Col md={8} xs={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name='materialNameSecond'
                  className='materialField'
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Vrsta materijala 2'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialNameSecond &&
                      historyOfChanges.materialNameSecond &&
                      historyOfChanges.materialNameSecond !== form.getFieldValue('materialNameSecond') && (
                        <Popover
                          placement='top'
                          title='Vrsta materijala 2'
                          content={<p>{historyOfChanges.materialNameSecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={7} xs={12}>
                <Form.Item name='materialDimensionSecond' className='materialField'>
                  <Input
                    placeholder='Dimenzije materijala 2'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialDimensionSecond &&
                      historyOfChanges.materialDimensionSecond &&
                      historyOfChanges.materialDimensionSecond !== form.getFieldValue('materialDimensionSecond') && (
                        <Popover
                          placement='top'
                          title='Dimenzije materijala 2'
                          content={<p>{historyOfChanges.materialDimensionSecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='goodMetersSecond' className='materialField'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Dobri metri 2'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.goodMetersSecond &&
                      historyOfChanges.goodMetersSecond &&
                      historyOfChanges.goodMetersSecond !== form.getFieldValue('goodMetersSecond') && (
                        <Popover
                          placement='top'
                          title='Dobri metri 2'
                          content={<p>{historyOfChanges.goodMetersSecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='calculatedWastePaperSecond' className='materialField materialField'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Kalkulisana makulatura 2'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.calculatedWastePaperSecond &&
                      historyOfChanges.calculatedWastePaperSecond &&
                      historyOfChanges.calculatedWastePaperSecond !==
                        form.getFieldValue('calculatedWastePaperSecond') && (
                        <Popover
                          placement='top'
                          title='Kalkulisana makulatura 2'
                          content={<p>{historyOfChanges.calculatedWastePaperSecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='materialQuantitySecond' className='materialField'>
                  <Input
                    placeholder='Količina materijala 2'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialQuantitySecond &&
                      historyOfChanges.materialQuantitySecond &&
                      historyOfChanges.materialQuantitySecond !== form.getFieldValue('materialQuantitySecond') && (
                        <Popover
                          placement='top'
                          title='Količina materijala 2'
                          content={<p>{historyOfChanges.materialQuantitySecond}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={[0, 0]}>
              <Col md={8} xs={12}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name='materialNameThird'
                  className='materialField'
                >
                  <Input
                    placeholder='Vrsta materijala 3'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialNameThird &&
                      historyOfChanges.materialNameThird &&
                      historyOfChanges.materialNameThird !== form.getFieldValue('materialNameThird') && (
                        <Popover
                          placement='top'
                          title='Vrsta materijala 3'
                          content={<p>{historyOfChanges.materialNameThird}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={7} xs={12}>
                <Form.Item name='materialDimensionThird' className='materialField'>
                  <Input
                    placeholder='Dimenzije materijala 3'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialDimensionThird &&
                      historyOfChanges.materialDimensionThird &&
                      historyOfChanges.materialDimensionThird !== form.getFieldValue('materialDimensionThird') && (
                        <Popover
                          placement='top'
                          title='Dimenzije materijala 3'
                          content={<p>{historyOfChanges.materialDimensionThird}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='goodMetersThird' className='materialField'>
                  <Input
                    placeholder='Dobri metri 3'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.goodMetersThird &&
                      historyOfChanges.goodMetersThird &&
                      historyOfChanges.goodMetersThird !== form.getFieldValue('goodMetersThird') && (
                        <Popover
                          placement='top'
                          title='Dobri metri 3'
                          content={<p>{historyOfChanges.goodMetersThird}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='calculatedWastePaperThird' className='materialField'>
                  <Input
                    placeholder='Kalkulisana makulatura 3'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.calculatedWastePaperThird &&
                      historyOfChanges.calculatedWastePaperThird &&
                      historyOfChanges.calculatedWastePaperThird !==
                        form.getFieldValue('calculatedWastePaperThird') && (
                        <Popover
                          placement='top'
                          title='Kalkulisana makulatura 3'
                          content={<p>{historyOfChanges.calculatedWastePaperThird}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col md={3} xs={12}>
                <Form.Item name='materialQuantityThird' className='materialField'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Količina materijala 3'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.materialQuantityThird &&
                      historyOfChanges.materialQuantityThird &&
                      historyOfChanges.materialQuantityThird !== form.getFieldValue('materialQuantityThird') && (
                        <Popover
                          placement='top'
                          title='Količina materijala 3'
                          content={<p>{historyOfChanges.materialQuantityThird}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='firstColor' className='colorField'>
                  <Input
                    placeholder='Boja 1:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.firstColor &&
                      historyOfChanges.firstColor &&
                      historyOfChanges.firstColor !== form.getFieldValue('firstColor') && (
                        <Popover
                          placement='top'
                          title='Boja 1'
                          content={<p>{historyOfChanges.firstColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='secondColor' className='colorField'>
                  <Input
                    placeholder='Boja 2:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.secondColor &&
                      historyOfChanges.secondColor &&
                      historyOfChanges.secondColor !== form.getFieldValue('secondColor') && (
                        <Popover
                          placement='top'
                          title='Boja 2'
                          content={<p>{historyOfChanges.secondColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='thirdColor' className='colorField'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Boja 3:'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.thirdColor &&
                      historyOfChanges.thirdColor &&
                      historyOfChanges.thirdColor !== form.getFieldValue('thirdColor') && (
                        <Popover
                          placement='top'
                          title='Boja 3'
                          content={<p>{historyOfChanges.thirdColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='fourthColor' className='colorField'>
                  <Input
                    disabled={preparerDisabledButtons}
                    placeholder='Boja 4:'
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.fourthColor &&
                      historyOfChanges.fourthColor &&
                      historyOfChanges.fourthColor !== form.getFieldValue('fourthColor') && (
                        <Popover
                          placement='top'
                          title='Boja 4'
                          content={<p>{historyOfChanges.fourthColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='fifthColor' className='colorField'>
                  <Input
                    placeholder='Boja 5:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.fifthColor &&
                      historyOfChanges.fifthColor &&
                      historyOfChanges.fifthColor !== form.getFieldValue('fifthColor') && (
                        <Popover
                          placement='top'
                          title='Boja 5'
                          content={<p>{historyOfChanges.fifthColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='sixthColor' className='colorField'>
                  <Input
                    placeholder='Boja 6:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.sixthColor &&
                      historyOfChanges.sixthColor &&
                      historyOfChanges.sixthColor !== form.getFieldValue('sixthColor') && (
                        <Popover
                          placement='top'
                          title='Boja 6'
                          content={<p>{historyOfChanges.sixthColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='seventhColor' className='colorField'>
                  <Input
                    placeholder='Boja 7:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.seventhColor &&
                      historyOfChanges.seventhColor &&
                      historyOfChanges.seventhColor !== form.getFieldValue('seventhColor') && (
                        <Popover
                          placement='top'
                          title='Boja 7'
                          content={<p>{historyOfChanges.seventhColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='eighthColor' className='colorField'>
                  <Input
                    placeholder='Boja 8:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.eighthColor &&
                      historyOfChanges.eighthColor &&
                      historyOfChanges.eighthColor !== form.getFieldValue('eighthColor') && (
                        <Popover
                          placement='top'
                          title='Boja 8'
                          content={<p>{historyOfChanges.eighthColor}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[0, 0]}>
              <Col span={12}>
                <Form.Item name='lacquier'>
                  <Input
                    placeholder='Lak:'
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.lacquier &&
                      historyOfChanges.lacquier &&
                      historyOfChanges.lacquier !== form.getFieldValue('lacquier') && (
                        <Popover
                          placement='top'
                          title='Lak'
                          content={<p>{historyOfChanges.lacquier}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='mobileVersion' gutter={8}>
              <Col md={6} xs={12}>
                <Form.Item label='Nalog izdao' name='createdBy' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Input disabled={true} />
                </Form.Item>

                <Form.Item
                  label='Slanje na overu'
                  name='sendAuthentication'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    disabled={preparerDisabledButtons}
                    suffixIcon={
                      historyOfChanges &&
                      historyOfChanges.sendAuthentication &&
                      historyOfChanges.sendAuthentication &&
                      historyOfChanges.sendAuthentication !== form.getFieldValue('sendAuthentication') && (
                        <Popover
                          placement='top'
                          title='Slanje na overu'
                          content={<p>{historyOfChanges.sendAuthentication}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                    onChange={() => {
                      setHideSendAuthenticationMail(!form.getFieldValue('sendAuthentication'));
                    }}
                  >
                    <Option value={true}>DA</Option>
                    <Option value={false}>NE</Option>
                  </Select>
                </Form.Item>
                {additionalMaterialInfo?.length > 0 && (
                  <Form.Item
                    name='multipleCalculationsSelected'
                    label='multipleCalculationsSelected'
                    hidden
                    value={true}
                  >
                    <Input />
                  </Form.Item>
                )}
              </Col>
              <Col md={6} xs={12}>
                <Form.Item label='Status' name='workOrderStatus' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                  <Select disabled={isNew || preparerDisabledButtons}>
                    <Option value='kreiran'>Kreiran</Option>
                    <Option value='priprema'>Priprema</Option>
                    <Option value='realizacija'>Realizacija</Option>
                    <Option value='odštampan'>Odštampan</Option>
                    <Option value='zatvoren'>Zatvoren</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label='Slanje na overu'
                  name='sendAuthenticationMail'
                  hidden={hideSendAuthenticationMail}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    disabled={preparerDisabledButtons}
                    suffix={
                      historyOfChanges &&
                      historyOfChanges.sendAuthenticationMail &&
                      historyOfChanges.sendAuthenticationMail &&
                      historyOfChanges.sendAuthenticationMail !== form.getFieldValue('sendAuthenticationMail') && (
                        <Popover
                          placement='top'
                          title='Slanje na overu'
                          content={<p>{historyOfChanges.sendAuthenticationMail}</p>}
                          trigger='hover'
                        >
                          <HistoryOutlined style={{ color: 'black' }} />
                        </Popover>
                      )
                    }
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label='Status overe'
                  name='workOrderAuthenticationStatus'
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select>
                    <Option value='Poslata na overu'>Poslata na overu</Option>
                    <Option value='Overena'>Overena</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
            <div style={{ marginTop: '10px' }} className='text-right'>
              {form.getFieldValue('workOrderStatus')?.includes('priprema') &&
                (currentuser.data.role.includes('preparer') || currentuser.data.role.includes('superAdmin')) &&
                form.getFieldValue('workOrderAuthenticationStatus') === 'Poslata na overu' && (
                  <Button onClick={handleAuthenticationStatus} style={{ marginRight: '10px' }}>
                    Overeno
                  </Button>
                )}
              {form.getFieldValue('workOrderStatus')?.includes('priprema') &&
                (currentuser.data.role.includes('preparer') || currentuser.data.role.includes('superAdmin')) &&
                form.getFieldValue('workOrderAuthenticationStatus') === 'Potrebna overa' && (
                  <Button onClick={handleSendAuthenticationStatus} style={{ marginRight: '10px' }}>
                    Poslato na overu
                  </Button>
                )}
              {!isNew && data && (
                <Button disabled={preparerDisabledButtons} onClick={btnShowNPPModal} style={{ marginRight: '10px' }}>
                  Sačuvaj kao nalog proizvodnji
                </Button>
              )}
              <Modal
                centered
                visible={isNPPModalVisible}
                title='Čuvanje radnog naloga kao nalog proizvodnji da proizvede'
                onCancel={handleNPPCancel}
                footer={[]}
              >
                <Form className='form-horizontal' layout='horizontal' form={modalForm}>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Naziv i opis proizvoda</label>
                      <Form.Item name='newProductName'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Tiraž za isporuku</label>
                      <Form.Item name='printing'>
                        <InputNumber min='0' />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    {/* <div className='saveRNasNPPitem'>
                      <label>Tiraž</label>
                      <Form.Item name='newCirculation'>
                        <InputNumber min='0' />
                      </Form.Item>
                    </div> */}

                    <div className='saveRNasNPPitem'>
                      <label>Tiraž prvog proizvoda</label>
                      <Form.Item name='firstProductQuantity'>
                        <InputNumber min='0' />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Tiraž drugog proizvoda</label>
                      <Form.Item name='secondProductQuantity'>
                        <InputNumber min='0' />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Datum narudžbe</label>
                      <Form.Item name='orderDate'>
                        <DatePicker format={'DD-MM-YYYY'} />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Rok isporuke</label>
                      <Form.Item name='deliveryDate'>
                        <DatePicker format={'DD-MM-YYYY'} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Porudžbenica</label>
                      <Form.Item name='orderCode'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Grafička priprema</label>
                      <Form.Item name='graphics'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Ploče</label>
                      <Form.Item name='plates'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Broj boja</label>
                      <Form.Item name='numberOfColors'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Štamparske forme: Naplaćuje se?</label>
                      <Form.Item name='printForms'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Maksimalan prečnik rolne</label>
                      <Form.Item name='rollDiameter'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Smer odmotavanja prednje</label>
                      <Form.Item name='unrollDirection'>
                        <Select dropdownStyle={{ minWidth: '500px' }}>
                          <Option value=''></Option>
                          {unrollDirectionArray.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Smer odmotavanja zadnje</label>
                      <Form.Item name='unrollDirectionBack'>
                        <Select dropdownStyle={{ minWidth: '500px' }}>
                          <Option value=''></Option>
                          {unrollDirectionArray.map((item, index) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Broj komada na rolni</label>
                      <Form.Item name='rollPiecesNum'>
                        <InputNumber min={0} />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Označavanje rolne</label>
                      <Form.Item name='rollLabeling'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Označavanje kutije</label>
                      <Form.Item name='boxLabeling'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Slaganje rolni na paletu</label>
                      <Form.Item name='rollStacking'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <div className='saveRNasNPPitem'>
                      <label>Pakovanje i označavanje palete</label>
                      <Form.Item name='paletteLabeling'>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className='saveRNasNPPitem'>
                      <label>Otprema robe</label>
                      <Form.Item name='productSetout'>
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className='newNPPModalRow'>
                    <Form.Item
                      label='Slanje na overu'
                      name='sendAuthentication'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select
                        onChange={() => {
                          setHideSendAuthenticationMail(!modalForm.getFieldValue('sendAuthentication'));
                        }}
                      >
                        <Option value={true}>DA</Option>
                        <Option value={false}>NE</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label='Slanje na overu'
                      name='sendAuthenticationMail'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input disabled={hideSendAuthenticationMail}></Input>
                    </Form.Item>
                  </div>
                  <Button onClick={saveAsOrderToProduction} type='primary' htmlType='button'>
                    Sačuvaj kao nalog proizvodnji da proizvede
                  </Button>
                </Form>
              </Modal>
              <Button style={{ marginRight: '10px' }} onClick={() => history.go(-1)} type='primary' htmlType='button'>
                Zatvori
              </Button>
              {isNew && (
                <Button id='submitButton' type='primary' htmlType='submit'>
                  Dodaj radni nalog
                </Button>
              )}
              {!isNew && (!data.workOrderCreated || data.workOrderCreated === false) && (
                <Button id='submitButton' type='primary' htmlType='submit'>
                  Sačuvaj promene
                </Button>
              )}
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default WorkOrderForm;
