import React from 'react';
import { Tabs, Card, Input, Form } from 'antd';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const TypeOfFolioViewForm = ({ data }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card className='workOrderForm ' title={`PREGLED TIPA ETIKETE`}>
          <Form {...layout} initialValues={initialValues} layout='vertical' form={form}>
            <div className='mainCont'>
              <Form.Item
                required
                name='code'
                className='ant-form-item-control-input-content'
                label='Šifra tipa folije:'
              >
                <Input disabled />
              </Form.Item>
            </div>

            <div>
              <div className='panel-body'>
                <Form.Item required className='formitemANTD' name='name' label='Naziv tipa folije:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='folioWay' label='Način plastifikacije:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='pricePerSquareMeter' label='Cena folije (€/m2):'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='unit' label='Jedinica mere:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='quantity' label='Količina na stanju:'>
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default TypeOfFolioViewForm;
