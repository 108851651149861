import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, DatePicker, notification, Select, Form, Modal, Typography } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ProductionProcess from '../../components/base/ProductionProcessDay';
import qs from 'querystring';
import moment from 'moment';
import Draggable from 'react-draggable';

const { Option } = Select;
const { Text } = Typography;
moment().local();

const ProductionProcessDay = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [machines, fetchMachines] = useAxios('', [], currentuser.data.token, 'get');
  const [machines2, setMachines2] = useState(new URLSearchParams(window.location.search).get('machine'));
  const [productionProcessDay, fetchProductionProcessDay] = useState();
  const [productionProcesses, fetchProductionProcesses] = useAxios('', [], currentuser.data.token, 'get');
  const [allProductionProcesses, fetchAllProductionProcesses] = useAxios('', [], currentuser.data.token, 'get');
  const [workTimes, fetchWorkTimes] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [machine, setMachine] = useState(sessionStorage.getItem('selectedMachine'));
  const [date, setDate] = useState(undefined);
  const [workOrders, fetchWorkOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLegendModalVisible, setIsLegendModalVisible] = useState(false);
  const [productionTime, setProductionTime] = useState();
  const defaultMachine =
    machines.data && machines.data.items ? machines.data.items.find((item) => item.name === 'Toshiba') : undefined;
  const [machineForProduction, setMachineForProduction] = useState(
    machine ? machine : defaultMachine ? defaultMachine._id : undefined,
  );
  const { search } = props.location;
  const [selectedTime, setSelectedTime] = useState();
  const [reloadComponent, setReloadComponent] = useState(false);
  const [createdAtFilter, setCreatedAtFilter] = useState();
  const [day, setDay] = useState(new URLSearchParams(window.location.search).get('day'));
  //////potrebno za dragable funkcionalnost
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  ////////////////////////////////////

  let [startTime, setStartTime] = useState(
    moment(day) === moment().format('YYYY-MM-DD')
      ? moment().add(1, 'hours').set({ minutes: 0, seconds: 0, milliseconds: 0 })
      : moment(day).set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
  );
  // const initialValues = { ...formInit, startTime };
  const query = qs.parse(search.slice(1));
  const startDate = new Date(new Date(query.day).setHours(0, 0, 0, 0));
  const endDate = new Date(
    new Date(new Date(query.day).setHours(0, 0, 0, 0)).setDate(new Date(query.day).getDate() + 1),
  );
  if (query && !createdAtFilter) {
    setCreatedAtFilter({ startDate, endDate, machine: query.machine });
  }
  useEffect(() => {
    const date = new Date(query.day);
    if (createdAtFilter) {
      getProductionProcessDay();
      // getStartOfDay();
    }
    setDay(new URLSearchParams(window.location.search).get('day'));
    fetchMachines(`${SERVER_URL}/machines`, []);
    fetchWorkOrders(`${SERVER_URL}/work-orders`, []);
    fetchWorkTimes(
      `${SERVER_URL}/work-time?filter=${JSON.stringify({
        machine,
        startTime: { $gte: new Date(query.day) },
        endTime: { $lt: date.setDate(date.getDate() + 1) },
      })}`,
      [],
    );
    fetchProductionProcesses(
      `${SERVER_URL}/production-process-by-machine?machine=${
        machine ? machine : defaultMachine && defaultMachine._id
      }&createdAt=${day}`,
    );
    fetchAllProductionProcesses(`${SERVER_URL}/production-process`);
    fetchRollsReserved(`${SERVER_URL}/rolls-reserved`, []);
  }, [createdAtFilter, machine, reloadComponent]);
  const getProductionProcessDay = async () => {
    if (createdAtFilter) {
      const productionProcessDayResponse = await Axios.get(
        `${SERVER_URL}/production-process?filter=${JSON.stringify(createdAtFilter)}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      if (productionProcessDayResponse) {
        fetchProductionProcessDay(productionProcessDayResponse);
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showLegendModal = () => {
    setIsLegendModalVisible(true);
  };

  const handleLegendCancel = () => {
    setIsLegendModalVisible(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onSubmitModal = async (values) => {
    try {
      let existRoll = false;
      for (const rollReserved of rollsReserved.data.items) {
        if (values.workOrder === rollReserved.workOrder?._id) {
          existRoll = true;
          break;
        }
      }

      const data = await Axios.get(`${SERVER_URL}/work-orders/${values.workOrder}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (!data.data.productName || !data.data.productCode) {
        notification.error({
          message: 'Nije odabran proizvod za ovaj radni nalog.',
          placement: 'bottomRight',
        });
        return;
      }
      if (!existRoll) {
        notification.error({
          message: 'Nije odabran materijal za ovaj radni nalog.',
          placement: 'bottomRight',
        });
        return;
      }

      values.productionTime = productionTime;
      for (const workOrder of workOrders.data.items) {
        if (values.workOrder === workOrder._id) {
          for (const machine of machines.data.items) {
            if (machineForProduction._id === machine._id) {
              values.machine = machine._id;
            }
          }
        }
      }

      const res = await Axios.post(
        `${SERVER_URL}/production-process?day=${query.day}&machine=${query.machine}`,
        { ...values },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      if (res.data.msg === 'Ne postoji radno vreme!') {
        notification.error({
          message: `Ne može se dodati van radnog vremena.`,
          placement: 'bottomRight',
        });
      } else {
        notification.success({
          message: `Dodato u plan proizvodnje.`,
          placement: 'bottomRight',
        });

        setIsModalVisible(false);
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isDataFetched =
    currentuser.language &&
    !machines.isLoading &&
    machines.data &&
    !productionProcessDay?.isLoading &&
    productionProcessDay?.data;

  let filteredArray = productionProcessDay && productionProcessDay.data && productionProcessDay.data.items;
  let totalHours = 0;

  if (filteredArray) {
    for (const item of filteredArray) {
      if (
        item.workOrder[0].workOrderStatus === 'priprema' ||
        item.workOrder[0].workOrderStatus === 'u proizvodnji' ||
        item.workOrder[0].workOrderStatus === 'u proizvodnji-priprema'
      ) {
        if (moment(item.startTime).format('YYYY-MM-DD') === moment(item.endTime).format('YYYY-MM-DD')) {
          totalHours += parseFloat(item.workOrder[0]?.productionTime);
        } else if (moment(item.startTime).format('YYYY-MM-DD') !== moment(item.endTime).format('YYYY-MM-DD')) {
          if (
            moment(item.startTime).format('YYYY-MM-DD') !== moment(item.endTime).format('YYYY-MM-DD') &&
            moment(item.endTime).format('HH') > 6 &&
            day === moment(item.endTime).format('YYYY-MM-DD')
          ) {
            totalHours = 0;
            totalHours += moment(item.endTime).format('HH') - 6;
          } else {
            totalHours += 22 - moment(item.startTime).format('HH');
          }
        }
      }
    }
  }

  const onChangeProductionProcess = async (values, id) => {
    try {
      await Axios.put(`${SERVER_URL}/production-process/${id}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Plan proizvodnje je uspešno izmenjen`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/production-process/${id}?machine=${query.machine}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Obrisano iz plana proizvodnje.`,
        placement: 'bottomRight',
      });

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      console.log(error);
    }
  };

  let filteredArrayFormatted =
    filteredArray &&
    filteredArray.map((item, index) => {
      // if (index > 0 && filteredArray[index - 1].verified === true || index === 0) {
      //   item.disabled = false;
      // } else {
      //   item.disabled = true;
      // }
      return item;
    });

  const findTimeAndMachine = (value) => {
    for (const workOrder of workOrders.data.items) {
      if (workOrder._id === value) {
        setProductionTime(workOrder.productionTime);
        setMachineForProduction(workOrder.machine);
      }
    }
  };

  let workOrderInProduction = [];
  if (allProductionProcesses.data && allProductionProcesses.data.items && workOrders.data && workOrders.data.items) {
    for (const singleProductionProcess of allProductionProcesses.data.items) {
      for (const workOrder of workOrders.data.items) {
        if (singleProductionProcess.workOrder[0]?._id === workOrder?._id) {
          workOrderInProduction.push(workOrder?._id);
        }
      }
    }
  }


  const filteredWorkOrders =
    workOrders?.data &&
    workOrders?.data?.items &&
    workOrders?.data?.items.filter(
      (item) =>
        ((item?.workOrderStatus === 'kreiran' || item?.workOrderStatus === 'kreiran-priprema') &&
          item?.machine?._id === sessionStorage.getItem('selectedMachine')) ||
        (!sessionStorage.getItem('selectedMachine') &&
          item.machine?._id === defaultMachine?._id &&
          item?.workOrderStatus === 'kreiran'),
    );

  useEffect(() => {
    // setStartTime(moment(startTime))
    if (day === moment().format('YYYY-MM-DD')) {
      setSelectedTime(moment().add(1, 'hours').set({ minutes: 0, seconds: 0, milliseconds: 0 }));
      setStartTime(moment().add(1, 'hours').set({ minutes: 0, seconds: 0, milliseconds: 0 }));
      form.setFieldsValue({ startTime: moment().add(1, 'hours').set({ minutes: 0, seconds: 0, milliseconds: 0 }) });
    } else {
      getStartOfDay();
      // form.setFieldValue({ startTime });
    }
    //             if (workTimes.data.count < 1) {
    //               startTime = moment(day).set({ hour: 6, minutes: 0, seconds: 0, milliseconds: 0 });
    //               setSelectedTime(startTime);
    //               setStartTime(startTime);
    //               form.setFieldsValue({ startTime });
    //             }
    // if (workOrderInProduction){
    // }
    // workOrders.data.items.forEach((wo) => {
    //   if(wo.machine._id === query.machine){
    //   }
    // });
    // for (let i = 0; i < workOrders.data.items.length; i++) {
    // }
    // for (const checkPP of productionProcesses.data) {
    //   if (moment().format('YYYY-MM-DD') === moment(checkPP.startTime).format('YYYY-MM-DD')) {
    //     startTime = moment(checkPP.endTime);
    //     form.setFieldsValue({ startTime });
    //   }
    //   if ((startTime.format('YYYY-MM-DD HH:mm')) === (moment(checkPP.startTime).format('YYYY-MM-DD HH:mm'))) {
    //     startTime = moment(checkPP.endTime);
    //     form.setFieldsValue({ startTime });
    //   }
    // }
  }, [isModalVisible, day, date, machine]);

  const getStartOfDay = async () => {
    const date = new Date(query.day);
    const res = await Axios.get(
      `${SERVER_URL}/work-time?filter=${JSON.stringify({
        machine,
        startTime: { $gte: new Date(query.day) },
        endTime: { $lt: date.setDate(date.getDate() + 1) },
      })}`,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    );

    if (res?.data?.items?.length > 0) {
      setSelectedTime(moment(res.data.items[0].startTime));
      setStartTime(moment(res.data.items[0].startTime));
      form.setFieldsValue({ startTime: moment(res.data.items[0].startTime) });
    } else {
      setSelectedTime(moment(query.day).set({ hour: 6, minute: 0, second: 0, millisecond: 0 }));
      setStartTime(moment(query.day).set({ hour: 6, minute: 0, second: 0, millisecond: 0 }));
      form.setFieldsValue({ startTime });
    }
  };

  useEffect(() => {
    getWO();
  }, [machine, day, isModalVisible]);

  const getWO = async () => {
    try {
      const date = moment(query.day);
      const date2 = moment(date).add(1, 'd');

      const res = await Axios.get(
        `${SERVER_URL}/production-process?filter=${JSON.stringify({
          startDate: date,
          endDate: date2,
          machine,
        })}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      const existingWOStartTime = res.data.items[res.data.items.length - 1].endTime;

      if (res?.data?.items[0]) {
        setStartTime(res.data.items[res.data.items.length - 1].endTime);
        setSelectedTime(moment(res.data.items[res.data.items.length - 1].endTime));
        setStartTime(moment(res.data.items[res.data.items.length - 1].endTime));
        form.setFieldsValue({ startTime: moment(res.data.items[res.data.items.length - 1].endTime) });
      }
    } catch (e) {}
  };

  const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/production-process'>
          <Button type='primary'>MESEČNI PLAN</Button>
        </Link>
        {currentuser.data.role.some((r) => ['superAdmin'].includes(r)) && (
          <Link to={`/admin/production-process-day-storekeeper?day=${query.day}&machine=${query.machine}`}>
            <Button style={{ marginLeft: 10, width: 230 }} type='primary'>
              Pregled za magacionera
            </Button>
          </Link>
        )}

        <Button
          style={{ marginLeft: '10px' }}
          onClick={() => {
            showModal();
            setReloadComponent((v) => !v);
            // getStartOfDay();
          }}
          disabled={!currentuser.data.role.some((r) => ['admin', 'superAdmin'].includes(r))}
        >
          DODAJ U PLAN PROIZVODNJE
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          onClick={() => {
            showLegendModal();
          }}
        >
          Prikaži legendu
        </Button>
        <Modal
          modalRender={(modal) => (
            <Draggable bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          visible={isLegendModalVisible}
          title='LEGENDA POJMOVA'
          onCancel={handleLegendCancel}
          footer={[]}
        >
          <div>
            <p>
              <strong>P.V.P</strong> - Planirano vreme proizvodnje
            </p>
            <p>
              <strong>PKM</strong> - Potrošena količina materijal
            </p>
            <p>
              <strong>RUPM</strong> - Razlika u potrošnji materijala
            </p>
            <p>
              <strong>RVP</strong> - Realizovano vreme štampe
            </p>
            <p>
              <strong>RVD</strong> - Realizovano vreme dorade
            </p>
          </div>
        </Modal>
      </div>
      <div style={{ display: 'flex' }}>
        <DatePicker
          defaultValue={moment(day)}
          onChange={(value) => {
            setDate(value);
            // getStartOfDay();
            setCreatedAtFilter();
            history.push(
              `/admin/production-process-day?day=${value && value.format('YYYY-MM-DD')}&machine=${
                machine ? machine : defaultMachine._id
              }`,
            );
          }}
        />
        <p style={{ marginTop: '15px', marginLeft: '30px', marginRight: '7px' }}>Mašina:</p>
        <div className='machine-selector'>
          <Select
            value={machine ? machine : defaultMachine ? defaultMachine._id : undefined}
            onChange={(value) => {
              setMachine(value);
              setCreatedAtFilter();
              sessionStorage.setItem('selectedMachine', value);
              history.push(
                `/admin/production-process-day?day=${date ? date.format('YYYY-MM-DD') : day}&machine=${
                  sessionStorage.getItem('selectedMachine')
                    ? sessionStorage.getItem('selectedMachine')
                    : defaultMachine._id
                }`,
              );
            }}
          >
            {machines.data.items &&
              machines.data.items.length > 0 &&
              machines.data.items.map((item, index) => (
                <Option key={index} value={item._id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
      </div>
      <div className='productionHeader'>
        <p className='totalHours'>
          Ukupno zauzeto vreme za {date ? moment(date).format('DD.MM.YYYY') : moment(day).format('DD.MM.YYYY')}:{' '}
          {totalHours}h
        </p>
      </div>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {isDataFetched && filteredArrayFormatted && filteredArrayFormatted.length > 0 && (
          <ProductionProcess
            user={currentuser.data}
            language={currentuser.language}
            workOrders={workOrders.data.items}
            data={filteredArrayFormatted}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            updateHandler={onChangeProductionProcess}
            machines={machines.data.items}
            deleteHandler={onDelete}
            reload={reloadComponent}
            setReload={setReloadComponent}
          />
        )}
        <div style={{ textAlign: 'center' }}>
          {!productionProcessDay?.isLoading &&
            productionProcessDay?.data &&
            productionProcessDay?.data.items &&
            productionProcessDay.data.items.length === 0 && <h2>Nema proizvodnih procesa za izabrani datum.</h2>}
        </div>
        <Modal visible={isModalVisible} title='Dodaj u plan proizvodnje' onCancel={handleCancel} footer={[]}>
          <Form
            onFinish={(values) => onSubmitModal(values, true)}
            initialValues={{ startTime: moment(startTime) }}
            form={form}
          >
            <Form.Item
              label='Radni nalog'
              name='workOrder'
              rules={[{ required: true, message: 'Molimo Vas izaberite radni nalog!' }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => findTimeAndMachine(value)}
              >
                {filteredWorkOrders &&
                  filteredWorkOrders.length > 0 &&
                  filteredWorkOrders.map((item, index) => (
                    <Option
                      key={index}
                      value={item._id}
                    >{`${item.code} - ${item.machine?.name} - ${item.companyName} - ${item.productName}`}</Option>
                  ))}
              </Select>
            </Form.Item>
            {startTime && (
              <Form.Item label='Vreme početka' name='startTime'>
                <DatePicker showTime={{ format: 'HH:mm' }} format={'YYYY-MM-DD HH:mm'} />
              </Form.Item>
            )}
            <p>Trajanje procesa: {productionTime} h</p>
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Mašina: {machineForProduction && machineForProduction.name}</Text>
            </div>
            <Button disabled={moment(day).isBefore(today)} key='submit' type='primary' htmlType='submit'>
              Sačuvaj
            </Button>
            <Button key='cancel' type='primary' onClick={handleCancel} style={{ marginLeft: '10px' }}>
              Odustani
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default ProductionProcessDay;
