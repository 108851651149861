import React from 'react';
import { Form, Button, Card, Select, Input } from 'antd';

const tailLayout = {
  wrapperCol: { span: 24 },
};

const RollForm = ({ data, updateHandler, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let initialValues = data;

  const onFinish = (values) => {
    updateHandler(values);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title='IZMENA ROLNE' bordered={false}>
            <Form
              initialValues={initialValues}
              onFinish={(values) => onFinish(values)}
              layout='horizontal'
              form={form}
              className='rollForm'
            >
              <Form.Item style={{ marginLeft: '54px' }} label='ID rolne' name='rollID'>
                <Input disabled />
              </Form.Item>

              <Form.Item style={{ marginLeft: '54px' }} label='Težina' name='weight'>
                <Input />
              </Form.Item>

              <Form.Item style={{ marginLeft: '54px' }} label='Dužina' name='length'>
                <Input disabled />
              </Form.Item>

              <Form.Item style={{ marginLeft: '54px' }} label='Stanje' name='state'>
                <Input disabled />
              </Form.Item>

              <Form.Item style={{ marginLeft: '54px' }} label='Status' name='status'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Pozicija u magacinu' name='storagePosition'>
                <Input />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                  Izmeni rolnu
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default RollForm;
