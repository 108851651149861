import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { useContext } from 'react';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
	
  const { isCollapsed, collapsedNav } = props;
  ROUTES.map((item) => {
    if (item.label === 'Plan proizvodnje') {
      item.path = item.children[0]?.path;
      item.component = item.children[0]?.component;
      for (const child of item.children) {
        child.showInMenu = false;
      }
    }
    return item;
  });

  const currentuser = useContext(UserContext);

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/logo.png' style={{ maxHeight: '49px' }} alt='Logo Birografika' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {ROUTES.map((route) =>
          currentuser.data?.role.includes('storekeeper') && !route.allowed?.includes('storekeeper') ? (
            <></>
          ) : route.label !== 'Plan proizvodnje' ? (
            <SubMenu
              key={route.path}
              title={
                <span>
                  <route.icon />
                  {route.label}
                </span>
              }
            >
			  {route.children
                .filter((item) => item.showInMenu)
                .map((item) => (
                  <Menu.Item key={item.path}>
                    <Link to={item.path}>{item.label}</Link>
                  </Menu.Item>
                ))}
            </SubMenu>
          ) : (
            <Menu.Item key={route.path}>
              <Link to={route.path}>
                <route.icon />
                {route.label}
              </Link>
            </Menu.Item>
          ),
        )}
      </Menu>
    </Sider>
  );
};
export default NavMenu;
