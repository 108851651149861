import React from 'react';
import { Tabs, Card, Input, Button, Form, InputNumber } from 'antd';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const HilzneForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card title={`${isNew ? 'NOVA HILZNA' : 'IZMENA HILZNE'}`}>
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            form={form}
            className='form-horizontal'
            layout='horizontal'
          >
            <div className='mainCont'>
              <Form.Item required name='code' className='ant-form-item-control-input-content' label='Šifra hilzne:'>
                <Input />
              </Form.Item>
            </div>

            <div>
              <div className='panel-body'>
                <Form.Item required className='formitemANTD' name='name' label='Naziv hilzne:'>
                  <Input />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='unit' label='Jedinica mere:'>
                  <Input />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='price' label='Cena:'>
                  <InputNumber min='0' className='formitemANTDTwo' />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='width' label='Dužina (mm):'>
                  <InputNumber min='0' className='formitemANTDTwo' />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='quantity' label='Količina na stanju (kom):'>
                  <InputNumber min='0' className='formitemANTDTwo' />
                </Form.Item>
              </div>
            </div>

            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} hilznu
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default HilzneForm;
