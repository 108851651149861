import React, {useContext, useEffect} from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import { SERVER_URL } from '../config';
import Axios from 'axios';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const tailLayout = {
    wrapperCol: { span: 24 },
};

const EmailVerification = (props) => {
    const user = useContext(UserContext);
    const { emailToken } = props.match.params;

    const onFinish = (values) => {
        user.handleLogin(values);
    };

    useEffect(() => {
        if(emailToken){
            Axios.get(`${SERVER_URL}/email-verification/${emailToken}`).then((res) => console.log(res));
        }
    }, []);

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="log">
            <div className="card-wrapper">
                <img style={{ 'marginBottom': '20px' }} className="login-logo" src="/logo.png" alt="Logo Birografika" />
                <Card title="Sign In" className="login-header" bordered={false}>
                    <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                        <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label='Password' name='password' rules={[{ required: true, message: 'Please enter your   password!' }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                            <Button type='primary' htmlType='submit' style={{ backgroundColor: "#216c97", borderRadius: '0', width: '120px' }}>
                                LOGIN</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default EmailVerification;
