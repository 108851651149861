import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CompanyForm from '../../components/forms/CompanyForm';

const EditCompany = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [company, fetchCompany] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { companyId } = props.match.params;
  const [companies, fetchCompanies] = useAxios('', null, currentuser.data.token, 'get');
  const SERVER_URL_CALCULATOR =
    process.env.NODE_ENV === 'production'
      ? 'https://birografikaprodaja-api.concordsoft.solutions'
      : 'http://localhost:3333';

  useEffect(() => {
    if (companyId) {
      fetchCompany(`${SERVER_URL}/companies/${companyId}`, []);
    }
    fetchCompanies(`${SERVER_URL}/companies`, []);
  }, [companyId, fetchCompany, fetchCompanies]);

  const updateCompany = async (data) => {
    try {
      const companyExist = companies.data.items.filter((company) => company.sku === data.sku);
      if (companyExist.length === 1 && company.data.sku !== data.sku) {
        notification.error({
          message: 'Kompanija sa ovom šifrom već postoji.',
          placement: 'bottomRight',
        });
      } else {
        await Axios.put(
          `${SERVER_URL}/companies/${companyId}`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );

        await Axios.put(
          `${SERVER_URL_CALCULATOR}/client-from-production`,
          {
            oldcompany: company,
            data,
            editedBy: currentuser,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        notification.success({
          message: 'Kompanija je izmenjena.',
          placement: 'bottomRight',
        });
        history.push('/admin');
        history.push('/admin/companies');
      }
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const createCompany = async (data) => {
    try {
      let companyExist = false;

      if (data.code) {
        companyExist = companies.data.items.filter((company) => company.sku === data.sku);
      }

      if (companyExist.length === 1) {
        notification.error({
          message: 'Kompanija sa ovom šifrom već postoji.',
          placement: 'bottomRight',
        });
      } else {
        const res = await Axios.post(
          `${SERVER_URL}/companies`,
          { ...data },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
        );

        let values = data;
        values.sku = res.data.code;

        await Axios.post(
          `${SERVER_URL_CALCULATOR}/client-from-production`,
          {
            data: values,
            createdBy: currentuser,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );

        notification.success({
          message: 'Kompanija je kreirana u proizvodnji i kalkulatoru.',
          placement: 'bottomRight',
        });
        setResult(true);
        history.push('/admin/companies');
      }
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : 'Problem sa dodavanjem kompanije. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {companyId && company.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {companyId && !company.isLoading && !company.isError && company.data && (
          <CompanyForm
            data={company.data}
            updateHandler={updateCompany}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {companyId && !company.isLoading && company.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        {!companyId && company && !company.data && (
          <CompanyForm
            data={null}
            createHandler={createCompany}
            result={result}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
