import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ProductForm } from '../../components/forms';

const EditProduct = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [product, fetchProduct] = useAxios('', [], currentuser.data.token, 'get');
  const { productId } = props.match.params;
  const [productPdf, fetchProductPdf] = useAxios('', [], currentuser.data.token, 'get');
  const [tools, fetchTools] = useAxios('', [], currentuser.data.token, 'get');

  const [products, fetchProducts] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchProductPdf(`${SERVER_URL}/product-pdf`, {});
    fetchTools(`${SERVER_URL}/tools`, {});

    if (productId) {
      fetchProduct(`${SERVER_URL}/products/${productId}`, {});
    }
    fetchProducts(`${SERVER_URL}/products`, {});
  }, [productId, fetchProduct, fetchProducts, fetchProductPdf]);

  const handleChangePDF = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/products-by-pdf/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (response.data && response.data.length > 0) {
      notification.warning({
        message: 'Izabrani PDF se već koristi u drugom proizvodu!',
        duration: 4,
        placement: 'bottomRight',
      });
    }
  };

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/products` : `${SERVER_URL}/products/${productId}`;
    try {
      const productExist = products.data.items.filter((product) => product.productCode === formData.productCode);
      if (
        (isNew && productExist.length !== 0) ||
        (!isNew && productExist.length === 1 && product.data.productCode !== formData.productCode)
      ) {
        notification.error({
          message: 'Proizvod sa ovom šifrom već postoji.',
          placement: 'bottomRight',
        });
      } else {
        const response = await Axios[method](route, formData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        notification.success({
          message: `Proizvod je ${isNew ? 'kreiran.' : 'izmenjen.'}`,
          placement: 'bottomRight',
        });
        history.push('/admin/products');
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !product.isLoading && product.data && currentuser.language && productPdf.data;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!productId && isDataFetched && (
          <ProductForm
            isNew={true}
            onSubmit={onSubmit}
            handleChangePDF={handleChangePDF}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            productPdf={productPdf}
            tools={tools}
          />
        )}

        {productId && !product.isError && product.data && isDataFetched && (
          <ProductForm
            isNew={false}
            data={product.data}
            onSubmit={onSubmit}
            handleChangePDF={handleChangePDF}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            productPdf={productPdf}
            tools={tools}
          />
        )}
      </div>
    </div>
  );
};

export default EditProduct;
