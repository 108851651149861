import React from 'react';
import { Input, Button, Form, Row, Col, Card, Upload, notification } from 'antd';
import { SERVER_URL } from '../../config';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const initValues = {};

const ProductPDFForm = ({ isNew, data, onSubmit, token }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  let initialValues = data ? { ...data } : initValues;

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={`NOVI PDF`} bordered={false}>
          <Form
            className='form-horizontal'
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='horizontal'
            form={form}
          >
            <Form.Item label='PDF dokument' name='unitThreeTotal'>
              <Upload
                action={`${SERVER_URL}/upload-pdf-product`}
                accept='.pdf'
                name='pdf-file'
                headers={{
                  Authorization: `Bearer ${token}`,
                  uri: 'static/pdf/',
                  timestamp: Date.now().toString(),
                }}
                beforeUpload={(file) => {
                  const isAllowedFormat = ['application/pdf'].includes(file.type);
                  if (!isAllowedFormat) {
                    notification.error({
                      message: 'Ovaj fajl nije pdf!',
                      placement: 'bottomRight',
                    });
                  }
                  return isAllowedFormat;
                }}
                onChange={({ file }) => {
                  if (file.status === 'removed') {
                    form.setFieldsValue({ file: undefined });
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Upload PDF</Button>
              </Upload>
            </Form.Item>

            <div style={{ marginTop: '10px' }} className='text-right'>
              <Button type='primary' onClick={() => history.go(-1)} htmlType='submit'>
                Zatvori
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ProductPDFForm;
