import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';
import moment from 'moment';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'avatar',
  'address',
  'zip',
  'taxId',
  'phone',
  'legalType',
  'wishList',
  'companyName',
  'city',
  'country'
];

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users`, []);
  }, [fetchUsers]);

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (users.data.items && users.data.items.length > 0) {
    const keys = Object.keys(users.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'firstName') {
        alternativeNames.push('ime');
      } else if (columnKey === 'lastName') {
        alternativeNames.push('prezime');
      } else if (columnKey === 'role') {
        alternativeNames.push('uloga');
      } else if (columnKey === 'status') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'email') {
        alternativeNames.push(columnKey);
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('kreirano');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('ažurirano');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index]
    }));
  }

  let tableData = [];
  if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-user'>
          <Button type='primary'>Novi korisnik</Button>
        </a>
      </div>

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {users.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!users.isLoading && users.data.items && users.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteUserHandler}
            columnKeys={newColumnKeys}
            title='Korisnik'
            editPath='/admin/edit-user/'
            viewPath='/admin/view-user/'
          />
        )}
        {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Users;
