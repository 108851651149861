import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ToolTable';
import { SERVER_URL } from '../../config';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'numItemInTM',
  'product',
  'numRows',
  'material',
  'date',
  'note',
  'createdAt',
  'updatedAt',
  'file',
  'materialWidth',
  'omimInch',
  'amount',
];

const Tool = () => {
  const currentuser = useContext(UserContext);
  const [tools, fetchTools] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchTools(`${SERVER_URL}/tools`, []);
  }, [fetchTools]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/tools/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Alat je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/tools');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (tools.data && tools.data.items && tools.data.items.length > 0) {
    const keys = Object.keys(tools.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('box')) {
      columnKeys.push('box');
    }
    if (!columnKeys.includes('uploaded')) {
      columnKeys.push('uploaded');
    }
    if (!columnKeys.includes('dimension')) {
      columnKeys.push('dimension');
    }
    if (!columnKeys.includes('companyName')) {
      columnKeys.push('companyName');
    }
    if (!columnKeys.includes('numTeeths')) {
      columnKeys.push('numTeeths');
    }
    if (!columnKeys.includes('shapeDesc')) {
      columnKeys.push('shapeDesc');
    }
    if (!columnKeys.includes('toolID')) {
      columnKeys.push('toolID');
    }
    let dimension, shapeDescription, company, box, numberOfTeeths, draw, toolID;
    for (const columnKey of columnKeys) {
      if (columnKey === 'box') {
        box = {
          originalName: columnKey,
          alternativeName: 'kutija',
        };
      } else if (columnKey === 'dimension') {
        dimension = {
          originalName: columnKey,
          alternativeName: 'dimenzija',
        };
      } else if (columnKey === 'companyName') {
        company = {
          originalName: columnKey,
          alternativeName: 'kupac',
        };
      } else if (columnKey === 'numTeeths') {
        numberOfTeeths = {
          originalName: columnKey,
          alternativeName: 'broj zuba',
        };
      } else if (columnKey === 'shapeDesc') {
        shapeDescription = {
          originalName: columnKey,
          alternativeName: 'opis oblika',
        };
      } else if (columnKey === 'uploaded') {
        draw = {
          originalName: columnKey,
          alternativeName: 'crtež',
        };
      } else if (columnKey === 'toolID') {
        toolID = {
          originalName: columnKey,
          alternativeName: 'oznaka alata',
        };
      }
    }
    newColumnKeys.push(dimension, toolID, shapeDescription, company, box, numberOfTeeths, draw);
  }

  let tableData = [];
  if (tools.data && tools.data.items && tools.data.items.length > 0) {
    tableData = tools.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      item.uploaded = item.file ? 'DA' : 'NE';
      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];
    // console.log(file);
    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/import-tools`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-tool'>
          <Button type='primary' disabled={!currentuser.data.role.some((r) => ['superAdmin', 'admin'].includes(r))}>
            Novi alat
          </Button>
        </a>
        <Button
          disabled={!currentuser.data.role.some((r) => ['superAdmin', 'admin'].includes(r))}
          style={{ marginLeft: '10px' }}
          icon={<UploadOutlined />}
          onClick={() => document.getElementById('input-file').click()}
          type='button'
        >
          Import
        </Button>
        <span style={{ marginLeft: '15px' }}>
          UKUPAN BROJ ALATA: {tools && tools.data && tools.data.items && tools.data.items.length}
        </span>
      </div>
      {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
      <div style={{ textAlign: 'center' }}>
        {tools.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!tools.isLoading && tools.data && tools.data.items && tools.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={newColumnKeys}
            title='Alat'
            editPath='/admin/edit-tool/'
            viewPath='/admin/view-tool/'
          />
        )}
        {!tools.isLoading && tools.data && tools.data.items && tools.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Tool;
