import React, { useState, useContext, useEffect } from 'react';
import { Divider, Input, Select, Button, Form, Card, Table, Popconfirm, notification, InputNumber } from 'antd';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

import useAxios from '../../hooks/useAxios';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { duration } from 'moment';

const { Option } = Select;

const formInit = {
  _id: null,
  name: undefined,
  code: '',
  calculationMethod: '',
  width: 1,
  rolls: [
    {
      weight: '',
      length: '',
    },
  ],
  status: 'ACTIVE',
};

const ExcessRoll = ({ isNew, data, onSubmit, materials, rolls }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [workOrders, setWorkOrders] = useState();
  const [rollsArr, setRollsArr] = useState();

  useEffect(() => {
    fetchWorkOrders();
    handleRollsLength();
  }, [rolls, rollsArr]);

  const fetchWorkOrders = async () => {
    const res = await Axios.get(`${SERVER_URL}/get-workorder-status/priprema`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setWorkOrders(res.data);
  };

  let initialValues = { ...formInit, ...{ ...data, rolls } };

  // console.log(`1`, formInit);
  // console.log(`2`, data);
  // console.log(`3`, rolls);

  const handleAddingExcessRoll = async () => {
    const formData = form.getFieldsValue();

    if (!formData.workOrder) {
      notification.error({
        message: 'Izaberite radni nalog!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.rollID) {
      notification.error({
        message: 'Izaberite rolnu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.goodMeters) {
      notification.error({
        message: 'Unesite dobre metre!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.calcWaste) {
      notification.error({
        message: 'Unesite kalkulisanu makulaturu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    formData.materialQuantity = parseFloat(formData.goodMeters) + parseFloat(formData.calcWaste);

    for (const iterator of rolls) {
      if (iterator._id === formData.rollID) {
        if (iterator.length < formData.materialQuantity) {
          notification.error({
            message: 'Prekoračili ste količinu materijala!',
            duration: 2,
            placement: 'bottomRight',
          });
          return;
        }
      }
    }

    // console.log(form.getFieldsValue());
    // console.log(data);

    let dataRoll = {
      workOrder: formData.workOrder,
      roll: formData.rollID,
      material: data._id,
      calcWaste: formData.calcWaste,
      goodMeters: formData.goodMeters,
      materialQuantity: formData.materialQuantity,
      status: 'active',
      excessRoll: true,
    };

    await Axios.post(`${SERVER_URL}/rolls-reserved`, dataRoll, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    notification.success({
      message: `Dodata je nova rolna u radni nalog.`,
      placement: 'bottomRight',
      duration: 2,
    });
    history.push('/admin');
    history.push(`/admin/excess-roll/${data._id}`);
  };

  const handleRollsLength = async () => {
    for (let i = 0; i < rolls.length; i++) {
      const element = rolls[i];

      const res = await Axios.get(`${SERVER_URL}/rolls-reserved-byRoll/${element._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let totalQuantity = 0;
      for (const iterator of res.data) {
        totalQuantity += iterator.materialQuantity;
      }
      rolls[i].reservedLength = totalQuantity;
    }

    setRollsArr(rolls);
  };

  return (
    <div className={isNew ? 'dashboard' : 'panel panel-body'}>
      <div className={isNew ? 'card-wrapper' : 'panel-body'}>
        <Card title={`${data.name} - DODAVANJE VIŠAK ROLNE`} bordered={false}>
          <Form className='form-horizontal' initialValues={initialValues} layout='horizontal' form={form}>
            <Form.Item required label='Radni nalog' name='workOrder' style={{ marginLeft: '108px' }}>
              <Select>
                {workOrders &&
                  workOrders.length > 0 &&
                  workOrders.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.code}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label='Rolna' required name='rollID' style={{ marginLeft: '116px' }}>
              <Select>
                {rollsArr &&
                  rollsArr.length > 0 &&
                  rollsArr.map((item, index) => (
                    <Option key={index} value={item._id}>
                      {item.rollID} - ({item.length - item.reservedLength})
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label='Dobri metri (m)' name='goodMeters' required style={{ marginLeft: '84px' }}>
              <InputNumber min='0' className='formitemANTDTwo' />
            </Form.Item>
            <Form.Item required label='Kalkulisana makulatura (m)' name='calcWaste'>
              <InputNumber min='0' className='formitemANTDTwo' />
            </Form.Item>

            <div className='text-right'>
              <Button
                onClick={() => {
                  handleAddingExcessRoll();
                }}
                style={{ marginRight: '10px' }}
                type='primary'
              >
                Dodaj višak rolnu
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ExcessRoll;
