import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { FilePdfOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const ProductPDFsTable = ({ data, deleteHandler, columnKeys, title, editPath }) => {
  const user = useContext(UserContext);

  const createPdf = async (rec) => {
    const fileName = rec.originalname;
    const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf-tool?fileName=${fileName}`, {
      responseType: 'arraybuffer',
      withCredentials: false,
      headers: { Authorization: `Bearer ${user.data.token}` },
    });
    const pathArr = fileName.split('\\');
    const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
    let a = document.createElement('a');
    let url = URL.createObjectURL(blob);
    a.href = url;
    a.download = pathArr[pathArr.length - 1];
    a.click();
    URL.revokeObjectURL(url);
  };

  const columns = [
    {
      title: 'Naziv',
      dataIndex: 'originalname',
      key: 'originalname',
    },
  ];
  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati ${title.toLowerCase()}`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            <DeleteOutlined title={`Obriši ${title.toLowerCase()}`} />
          </Popconfirm>

          <FilePdfOutlined title='PDF' onClick={() => createPdf(record)} />
        </div>
      </div>
    ),
  });

  function onChange(page) {
    page && localStorage.setItem('pageNumber', page);
    localStorage.getItem('pageNumber') &&
      parseInt(localStorage.getItem('pageNumber')) === 1 &&
      localStorage.removeItem('pageNumber');
  }

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  } else {
    localStorage.setItem('url', window.location.href);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          onChange: onChange,
          defaultCurrent: localStorage.getItem('pageNumber') ? parseInt(localStorage.getItem('pageNumber')) : 1,
        }}
      />
    </div>
  );
};

export default ProductPDFsTable;
