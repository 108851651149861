import React, { useContext } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteOutlined, DownOutlined, EditOutlined, EyeFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';

const DataTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0 && selectedKeys[0] !== filter[property]) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : '')}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      let searchFilter = [];
      searchFilter = value.split(' ');
      let filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !searchFilter) {
        return false;
      }

      return searchFilter.every((value) => filterRecord.toLowerCase().includes(value.toLowerCase()));
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem('filter', JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  ///dinamicko kreiranje kolona-zamenjeno za manuelno jer filter na broju rolni ne radi
  // const columns = columnKeys.map((item) => ({
  //   key: item.originalName,
  //   title: item.alternativeName,
  //   dataIndex: item.originalName === 'createdBy' ? 'user' : ['title', 'category'].includes(item.originalName) ? item.originalName === 'title' ? [item.originalName, user.language ? user.language.selected.code : ''] : [item.originalName, 'name', user.language ? user.language.selected.code : ''] : item.originalName,
  //   ...getColumnSearchProps(item.originalName === 'createdBy' ? 'user' : ['title', 'category'].includes(item.originalName) ? item.originalName === 'title' ? [item.originalName, user.language ? user.language.selected.code : ''] : [item.originalName, 'name', user.language ? user.language.selected.code : ''] : item.originalName),
  // }));

  const columns = [
    {
      key: 'code',
      title: 'ŠIFRA',
      dataIndex: 'code',
      ...getColumnSearchProps('code'),
    },
    {
      key: 'name',
      title: 'NAZIV MATERIJALA',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      key: 'rollsNumber',
      title: 'Broj Rolni',
      dataIndex: 'rollsNumber',
    },
    {
      key: 'type',
      title: 'Tip',
      dataIndex: 'type',
      ...getColumnSearchProps('type'),
    },
    {
      key: 'state',
      title: 'STANJE (U KG/M2)',
      dataIndex: 'state',
      ...getColumnSearchProps('state'),
    },
    {
      key: 'rolls',
      title: 'UKUPNA KOLIČINA (U TM)',
      dataIndex: 'rolls',
      ...getColumnSearchProps('rools'),
    },
    {
      key: 'reserved',
      title: 'ZAUZETO (U TM)',
      dataIndex: 'reserved',
      ...getColumnSearchProps('reserved'),
    },
    {
      title: 'DOSTUPNO (U TM)',
      render: (record) => {
        return parseFloat(record.rolls) - parseFloat(record.reserved);
      },
    },
  ];

  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={'/admin/view-material-rolls/' + record._id}>
            <EyeOutlined title={`Pregledaj rolne ${title.toLowerCase()}a`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div> */}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeFilled
                title={`Pregledaj ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined title={`Izmeni ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        {user.data.role.some((r) => ['superAdmin', 'admin', 'storekeeper'].includes(r)) && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={`/admin/excess-roll/` + record._id}>
              <DownOutlined title={`Ovo će kreirati višak rolnu`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        {user.data.role.some((r) => ['superAdmin', 'admin'].includes(r)) && (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`Ovo će obrisati ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined
                style={{ pointerEvents: record.rolls > 0 && 'none' }}
                title={`Obriši ${title.toLowerCase()}`}
              />
            </Popconfirm>
          </div>
        )}
      </div>
    ),
  });

  function onChange(page) {
    page && localStorage.setItem('pageNumber', page);
    localStorage.getItem('pageNumber') &&
      parseInt(localStorage.getItem('pageNumber')) === 1 &&
      localStorage.removeItem('pageNumber');
  }

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  } else {
    localStorage.setItem('url', window.location.href);
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          onChange: onChange,
          defaultCurrent: localStorage.getItem('pageNumber') ? parseInt(localStorage.getItem('pageNumber')) : 1,
        }}
      />
    </div>
  );
};

export default DataTable;
