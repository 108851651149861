import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ProductionProcessForm } from '../../components/forms';
import moment, { ISO_8601 } from 'moment';

const ProductionProcess = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [machines, fetchMachines] = useAxios('', [], currentuser.data.token, 'get');
  const [allWorkOrders, fetchAllWorkOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [workTimes, fetchWorkTimes] = useAxios('', [], currentuser.data.token, 'get');
  const [productionProcesses, fetchProductionProcesses] = useAxios('', [], currentuser.data.token, 'get');
  const [productionProcess, fetchProductionProcess] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [machine, setMachine] = useState(undefined);
  const [workOrders, setWorkOrders] = useState([]);
  // const [productionProcess, setProductionProcess] = useState([]);

  const [createdAt, setCreatedAt] = useState(new Date());
  const { productionProcessId } = props.match.params;
  const selectedMachine =
    machines.data &&
    machines.data.items &&
    machines.data.items.find((item) => item._id === sessionStorage.getItem('selectedMachine'));
  const defaultMachine = sessionStorage.getItem('selectedMachine')
    ? selectedMachine
    : machines.data && machines.data.items
    ? machines.data.items.find((item) => item.name === 'Toshiba')
    : undefined;

  useEffect(() => {
    const getProductionProcess = async () => {
      if (defaultMachine) {
        // const productionProcessResponse = await Axios.get(`${SERVER_URL}/production-process-by-machine?machine=${defaultMachine._id}&createdAt=${new Date()}`, {
        //   withCredentials: false,
        //   headers: { Authorization: `Bearer ${currentuser.data.token}` }
        // });
        const workOrdersResponse = await Axios.get(
          `${SERVER_URL}/work-orders-by-machine?machine=${defaultMachine._id}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        setWorkOrders(workOrdersResponse.data);
        // setProductionProcess(productionProcessResponse.data);
      }
    };
    getProductionProcess();
    if (defaultMachine) {
      setMachine(defaultMachine._id);
      fetchProductionProcess(
        `${SERVER_URL}/production-process-by-machine?machine=${defaultMachine._id}&createdAt=${new Date()}`,
      );
    }
    fetchMachines(`${SERVER_URL}/machines`, []);
    fetchAllWorkOrders(`${SERVER_URL}/work-orders`, []);
    fetchWorkTimes(`${SERVER_URL}/work-time`, []);
    fetchProductionProcesses(`${SERVER_URL}/production-process`, []);
    fetchRollsReserved(`${SERVER_URL}/rolls-reserved`, []);
  }, [
    fetchMachines,
    defaultMachine,
    fetchAllWorkOrders,
    fetchWorkTimes,
    fetchProductionProcesses,
    fetchProductionProcess,
    fetchRollsReserved,
  ]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const productionProcesses = await Axios.get(`${SERVER_URL}/production-process`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    let filteredProcesses = [];
    for (const process of productionProcesses.data.items) {
      if (moment(process.startTime).format('YYYY-MM-DD') === moment(formData.startTime).format('YYYY-MM-DD')) {
        filteredProcesses.push(process);
      }
    }

    let isOverlap = false;
    const maxHoursForDay = moment().set('hour', 22).set('minute', 0);
    const minHoursForDay = moment().set('hour', 6).set('minute', 0);
    for (let i = 0; i < filteredProcesses.length; i++) {
      if (
        isOverlap === false &&
        (moment(formData.startTime).isSame(moment(filteredProcesses[i].startTime), 'minutes') ||
          moment(formData.startTime).isBetween(
            moment(filteredProcesses[i].startTime),
            moment(filteredProcesses[i].endTime),
            'minutes',
          ) ||
          moment(formData.endTime).isSame(moment(filteredProcesses[i].endTime), 'minutes') ||
          moment(formData.endTime).isBetween(
            moment(filteredProcesses[i].startTime),
            moment(filteredProcesses[i].endTime),
            'minutes',
          ) ||
          (moment(formData.startTime).isBefore(moment(filteredProcesses[i].startTime), 'minutes') &&
            moment(formData.endTime).isAfter(moment(filteredProcesses[i].endTime), 'minutes')) ||
          (moment(formData.startTime).isAfter(moment(filteredProcesses[i].startTime), 'minutes') &&
            moment(formData.endTime).isBefore(moment(filteredProcesses[i].endTime), 'minutes')))
      ) {
        isOverlap = true;
        filteredProcesses[i].startTime = formData.endTime;
        filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime).add(
          parseFloat(filteredProcesses[i].workOrder[0].productionTime),
          'hours',
        );
        const productionProcessId = filteredProcesses[i]._id;
        delete filteredProcesses[i]._id;
        if (
          moment(filteredProcesses[i].startTime).get('hour') >= moment(maxHoursForDay).get('hour') ||
          moment(filteredProcesses[i].startTime).get('hour') < moment(minHoursForDay).get('hour')
        ) {
          filteredProcesses[i].startTime = moment(filteredProcesses[i].startTime)
            .add(1, 'days')
            .set('hour', 6)
            .set('minute', 0);
          filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime).add(
            parseFloat(filteredProcesses[i].workOrder[0].productionTime),
            'hours',
          );
        } else if (
          moment(filteredProcesses[i].endTime).format('HH:mm') > moment(maxHoursForDay).format('HH:mm') ||
          moment(filteredProcesses[i].endTime).format('HH:mm') <= moment(minHoursForDay).format('HH:mm')
        ) {
          let restHours =
            parseFloat(filteredProcesses[i].workOrder[0].productionTime) -
            22 +
            parseFloat(moment(filteredProcesses[i].startTime).format('HH')) +
            parseFloat(moment(filteredProcesses[i].startTime).format('mm')) / 60;
          filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime)
            .add(1, 'days')
            .set('hour', 6)
            .set('minute', 0)
            .add(restHours, 'hours');
        }
        await Axios.put(
          `${SERVER_URL}/production-process/${productionProcessId}`,
          { ...filteredProcesses[i], workOrder: filteredProcesses[i].workOrder[0]._id },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      } else if (isOverlap) {
        filteredProcesses[i].startTime = filteredProcesses[i - 1].endTime;
        filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime).add(
          parseFloat(filteredProcesses[i].workOrder[0].productionTime),
          'hours',
        );
        const productionProcessId = filteredProcesses[i]._id;
        delete filteredProcesses[i]._id;
        if (
          moment(filteredProcesses[i].startTime).get('hour') >= moment(maxHoursForDay).get('hour') ||
          moment(filteredProcesses[i].startTime).get('hour') < moment(minHoursForDay).get('hour')
        ) {
          filteredProcesses[i].startTime = moment(filteredProcesses[i].startTime)
            .add(1, 'days')
            .set('hour', 6)
            .set('minute', 0);
          filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime).add(
            parseFloat(filteredProcesses[i].workOrder[0].productionTime),
            'hours',
          );
        } else if (
          moment(filteredProcesses[i].endTime).format('HH:mm') > moment(maxHoursForDay).format('HH:mm') ||
          moment(filteredProcesses[i].endTime).format('HH:mm') <= moment(minHoursForDay).format('HH:mm') ||
          moment(filteredProcesses[i].endTime).isAfter(moment(filteredProcesses[i].startTime), 'days')
        ) {
          let restHours =
            parseFloat(filteredProcesses[i].workOrder[0].productionTime) -
            22 +
            parseFloat(moment(filteredProcesses[i].startTime).format('HH')) +
            parseFloat(moment(filteredProcesses[i].startTime).format('mm')) / 60;
          filteredProcesses[i].endTime = moment(filteredProcesses[i].startTime)
            .add(1, 'days')
            .set('hour', 6)
            .set('minute', 0)
            .add(restHours, 'hours');
        }
        await Axios.put(
          `${SERVER_URL}/production-process/${productionProcessId}`,
          { ...filteredProcesses[i], workOrder: filteredProcesses[i].workOrder[0]._id },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
    }

    const route = isNew
      ? `${SERVER_URL}/production-process`
      : `${SERVER_URL}/production-process/${productionProcessId}`;
    try {
      // console.log('okaj', method, route, formData);
      const res = await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const wo = await Axios.get(`${SERVER_URL}/work-orders/${formData.workOrder}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      // console.log(wo.data);

      if (wo.data.workOrderStatus === 'kreiran-priprema') {
        await Axios.put(
          `${SERVER_URL}/work-orders/${formData.workOrder}`,
          {
            workOrderStatus: 'u proizvodnji-priprema',
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      } else {
        await Axios.put(
          `${SERVER_URL}/work-orders/${formData.workOrder}`,
          {
            workOrderStatus: 'u proizvodnji',
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }

      notification.success({
        message: `${isNew ? 'Dodato u' : 'Izmenjen je'} plan proizvodnje.`,
        placement: 'bottomRight',
      });
      setTimeout(() => window.location.reload(), 1000);
      history.push('/admin');
      history.push('/admin/production-process');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const selectMachine = async (value, form) => {
    form.setFieldsValue({ workOrder: undefined });
    try {
      const response = await Axios.get(`${SERVER_URL}/work-orders-by-machine?machine=${value}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const productionProcessResponse = await Axios.get(
        `${SERVER_URL}/production-process-by-machine?machine=${value}&createdAt=${createdAt}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setMachine(value);
      // setProductionProcess(productionProcessResponse.data);
      fetchProductionProcess(`${SERVER_URL}/production-process-by-machine?machine=${value}&createdAt=${createdAt}`);
      setWorkOrders(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const calendarHandler = (value) => {
    const createdAt = new Date(value);

    try {
      // const productionProcessResponse = await Axios.get(`${SERVER_URL}/production-process-by-machine?machine=${machine ? machine : defaultMachine._id}&createdAt=${createdAt}`, {
      //   withCredentials: false,
      //   headers: { Authorization: `Bearer ${currentuser.data.token}` }
      // });
      // setProductionProcess(productionProcessResponse.data);
      fetchProductionProcess(
        `${SERVER_URL}/production-process-by-machine?machine=${
          machine ? machine : defaultMachine._id
        }&createdAt=${createdAt}`,
      );
      setCreatedAt(createdAt);

      // setProductionProcess(productionProcessResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const isDataFetched =
    currentuser.language &&
    !machines.isLoading &&
    machines.data &&
    !productionProcess.isLoading &&
    productionProcess.data &&
    workOrders;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!productionProcessId && isDataFetched && (
          <ProductionProcessForm
            isNew={true}
            machines={machines.data}
            machine={machine}
            language={currentuser.language}
            workOrders={workOrders}
            allWorkOrders={allWorkOrders.data.items}
            productionProcesses={productionProcesses.data.items}
            workTimes={workTimes.data.items}
            productionProcess={productionProcess.data}
            machineHandler={selectMachine}
            changeCalendar={calendarHandler}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            createdAt={createdAt}
            rollsReserved={rollsReserved.data.items}
          />
        )}

        {/*{workTimeId && !workTime.isError && workTime.data && isDataFetched && (*/}
        {/*	<WorkTimeForm*/}
        {/*		isNew={false}*/}
        {/*		data={workTime.data}*/}
        {/*		machines={machines.data}*/}
        {/*		language={currentuser.language}*/}
        {/*		onSubmit={onSubmit}*/}
        {/*		SERVER_URL={SERVER_URL}*/}
        {/*		token={currentuser.data.token}*/}
        {/*	/>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default ProductionProcess;
