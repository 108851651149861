import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import TypeOfFolioViewForm from '../../components/forms/TypeOfFolioViewForm';

const ViewTypeOfFolio = (props) => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { typeoffolioId } = props.match.params;

  useEffect(() => {
    if (typeoffolioId) fetchData(`${SERVER_URL}/type-of-folio/${typeoffolioId}`, {});
  }, [fetchData, typeoffolioId]);

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/types-of-folio'>
          <Button type='primary'>Svi tipovi folije</Button>
        </Link>
      </div>

      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!typeoffolioId && isDataFetched && (
          <TypeOfFolioViewForm
            isNew={true}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {typeoffolioId && !data.isError && data.data && isDataFetched && (
          <TypeOfFolioViewForm
            isNew={false}
            data={data.data}
            language={currentuser.language}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ViewTypeOfFolio;
