import React, { useState, useContext, useEffect } from 'react';
import { Select, Button, Form, Row, Col, DatePicker, Calendar, Badge, Modal, Typography, notification } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
const { Option } = Select;
const { Text } = Typography;

const formInit = {
  _id: null,
  machine: undefined,
  workOrder: undefined,
  startTime: undefined,
  endTime: undefined,
  productionTime: undefined,
  operators: '',
  workerTime: '',
  workerMaterial: '',
  workerRollNum: '',
  workerProductionQuantity: '',
};

const MaterialForm = ({
  isNew,
  data,
  onSubmit,
  machines,
  machineHandler,
  workOrders,
  productionProcess,
  changeCalendar,
  machine,
  allWorkOrders,
  workTimes,
  productionProcesses,
  createdAt,
  rollsReserved,
}) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  let startTime = data
    ? moment(data.startTime)
    : moment().add(1, 'hours').set({ minutes: 0, seconds: 0, milliseconds: 0 });
  let endTime = data
    ? moment(data.endTime)
    : moment(new Date()).set({
        hours: new Date().getDay() > 0 && new Date().getDay() < 6 ? 22 : 14,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
  let defaultMachine = machines.items ? machines.items.find((item) => item.name === 'Toshiba') : undefined;
  let initialValues = {
    ...formInit,
    ...{ ...data, startTime, endTime, machine: defaultMachine ? defaultMachine._id : undefined },
  };
  const [date, setDate] = useState(createdAt);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productionTime, setProductionTime] = useState();
  const [machineForProduction, setMachineForProduction] = useState({});
  const [selectedTime, setSelectedTime] = useState(
    moment(new Date()).set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }),
  );

  const onFinish = async (values, isNew) => {
    values.productionTime = productionTime;
    if (productionTime > 16) {
      values.endTime = moment(values.startTime).set('hours', 22);
      let restHours =
        productionTime -
        22 +
        parseFloat(moment(values.startTime).format('HH')) +
        parseFloat(moment(values.startTime).format('mm')) / 60;
      while (restHours > 0) {
        if (restHours > 16) {
          values.endTime = moment(values.endTime).add(1, 'days').set('hours', 22);
          restHours -= 16;
        } else {
          values.endTime = moment(values.endTime)
            .add(1, 'days')
            .set('hours', 6)
            .add(Math.floor(restHours), 'hours')
            .add(restHours - Math.floor(restHours), 'minutes');
          restHours = 0;
        }
      }
    } else if (
      moment(values.startTime)
        .add(parseFloat(productionTime), 'hours')
        .isAfter(moment(values.startTime).set('hours', 22), 'minutes')
    ) {
      let restHours =
        parseFloat(productionTime) -
        22 +
        parseFloat(moment(values.startTime).format('HH')) +
        parseFloat(moment(values.startTime).format('mm')) / 60;
      values.endTime = moment(values.startTime).add(1, 'days').set('hour', 6).set('minute', 0).add(restHours, 'hours');
    } else {
      values.endTime = moment(values.startTime).add(parseFloat(productionTime), 'hours');
    }
    for (const workOrder of allWorkOrders) {
      if (values.workOrder === workOrder._id) {
        for (const machine of machines.items) {
          if (machineForProduction._id === machine._id) {
            values.machine = machine._id;
          }
        }
      }
    }

    let existRoll = false;
    for (const rollReserved of rollsReserved) {
      if (values.workOrder === rollReserved.workOrder?._id) {
        existRoll = true;
        break;
      }
    }

    const data = await Axios.get(`${SERVER_URL}/work-orders/${values.workOrder}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (!data.data.productName || !data.data.productCode) {
      notification.error({
        message: 'Nije odabran proizvod za ovaj radni nalog.',
        placement: 'bottomRight',
      });
      return;
    }

    let isError = true;
    let isNotInWorkTime = true;
    let notExistMachine = false;
    let isInPast = true;

    const maxWorkTime = moment(values.startTime)
      .set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
      .format('YYYY-MM-DD HH:mm');
    const minWorkTime = moment(values.startTime)
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
      .format('YYYY-MM-DD HH:mm');

    if (
      values.startTime.format('YYYY-MM-DD HH:mm') >= minWorkTime &&
      values.startTime.format('YYYY-MM-DD HH:mm') < maxWorkTime
    ) {
      isNotInWorkTime = false;
    }
    if (values.startTime.format('dddd') === 'nedelja' || values.startTime.format('dddd') === 'subota') {
      isNotInWorkTime = true;
    }
    if (values.startTime.format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm')) {
      isInPast = false;
    }
    for (const workTime of workTimes) {
      if (
        values.startTime.format('YYYY-MM-DD HH:mm') >= moment(workTime.startTime).format('YYYY-MM-DD HH:mm') &&
        values.startTime.format('YYYY-MM-DD HH:mm') < moment(workTime.endTime).format('YYYY-MM-DD HH:mm')
      ) {
        isNotInWorkTime = false;
      }
      if (machineForProduction._id === workTime.machine._id) {
        notExistMachine = false;
      }
      if (values.startTime.format('YYYY-MM-DD HH:mm') >= moment().format('YYYY-MM-DD HH:mm')) {
        isInPast = false;
      }
    }

    if (!isNotInWorkTime && !notExistMachine && !isInPast && existRoll) {
      isError = false;
      setIsModalVisible(false);
      onSubmit(values, isNew);
      localStorage.setItem('nextDate', JSON.stringify(values.endTime));
    }

    if (isError) {
      if (!existRoll) {
        notification.error({
          message: 'Nije odabran materijal za ovaj radni nalog.',
          placement: 'bottomRight',
        });
      }
      if (isNotInWorkTime) {
        notification.error({
          message: 'Ne može se dodati van radnog vremena.',
          placement: 'bottomRight',
        });
      }
      if (notExistMachine) {
        notification.error({
          message: 'Ne postoji radno vreme za odabranu mašinu.',
          placement: 'bottomRight',
        });
      }
      if (isInPast) {
        notification.error({
          message: 'Ne može se dodati u prošlosti.',
          placement: 'bottomRight',
        });
      }
    }
  };

  const getListData = (value) => {
    let listData = [];
    for (let i = 0; i < productionProcess.length; i++) {
      if (
        (new Date(productionProcess[i].startTime).getDate() === value.date() &&
          new Date(productionProcess[i].startTime).getMonth() === value.month()) ||
        (new Date(productionProcess[i].endTime).getDate() === value.date() &&
          new Date(productionProcess[i].endTime).getMonth() === value.month()) ||
        (new Date(productionProcess[i].startTime).getDate() < value.date() &&
          new Date(productionProcess[i].endTime).getDate() > value.date() &&
          new Date(productionProcess[i].startTime).getMonth() === value.month() &&
          new Date(productionProcess[i].endTime).getMonth() === value.month())
      ) {

        if (productionProcess[i].verified === true && productionProcess[i].closed === false) {
          listData?.push({
            type: 'workOrderDonePrinting',
            content: productionProcess[i].workOrder?.companyName + ': ' + productionProcess[i].workOrder?.code,
            _id: productionProcess[i]?._id,
            date: productionProcess[i]?.createdAt,
            machine: productionProcess[i].machine?._id,
          });
        } else if (productionProcess[i].closed === true) {
          listData?.push({
            type: 'workOrderClosed',
            content: productionProcess[i].workOrder?.companyName + ': ' + productionProcess[i].workOrder?.code,
            _id: productionProcess[i]?._id,
            date: productionProcess[i]?.createdAt,
            machine: productionProcess[i].machine?._id,
          });
        } else if (productionProcess[i].closed === false && productionProcess[i].verified === false && productionProcess[i].workOrder.workOrderisPrinting === true) {
          listData?.push({
            type: 'workOrderPrinting',
            content: productionProcess[i].workOrder?.companyName + ': ' + productionProcess[i].workOrder?.code,
            _id: productionProcess[i]?._id,
            date: productionProcess[i]?.createdAt,
            machine: productionProcess[i].machine?._id,
          });
        } else if (productionProcess[i].closed === false && productionProcess[i].verified === false &&  productionProcess[i].workOrder.workOrderisPrinting !== true) {
			listData?.push({
			  type: 'workOrder',
			  content: productionProcess[i].workOrder?.companyName + ': ' + productionProcess[i].workOrder?.code,
			  _id: productionProcess[i]?._id,
			  date: productionProcess[i]?.createdAt,
			  machine: productionProcess[i].machine?._id,
			});
		}
      }
    }
    return listData;
  };

  const dateCellRender = (value) => {
    let previousClass = '';

    if (moment(value).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      previousClass = 'previousDay';
    }
	
    const listData = getListData(value);
    return listData.length > 0 ? (
      <ul className={'events ' + previousClass + ''}>
        {listData.map((item, index) => (
          <li key={index}>
            <Badge className={item.type} status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    ) : (
      <div className={previousClass}></div>
    );
  };

  useEffect(() => {
    const elements = document.getElementsByClassName('previousDay');
	const workOrderPrinting = document.getElementsByClassName('workOrderPrinting');
	const workOrderDonePrinting = document.getElementsByClassName('workOrderDonePrinting');
	const workOrderClosed = document.getElementsByClassName('workOrderClosed');

    for (let i = 0; i < elements.length; i++) {
      elements[i].parentNode.parentNode.parentNode.classList.add('previousDay');
    }

	for (let i = 0; i < workOrderPrinting.length; i++) {
		workOrderPrinting[i].firstChild.nextSibling.style.backgroundColor = "green"
	  }

	for (let i = 0; i < workOrderDonePrinting.length; i++) {
		workOrderDonePrinting[i].firstChild.nextSibling.style.backgroundColor = "yellow"
		workOrderDonePrinting[i].firstChild.nextSibling.style.color = "black"
	  }

	  for (let i = 0; i < workOrderClosed.length; i++) {
		workOrderClosed[i].firstChild.nextSibling.style.backgroundColor = "red"
	  }
	  
  }, []);

  const selectCalendar = (value) => {
    if (
      new Date(value).getMonth() === date.getMonth() &&
      new Date(value).getFullYear() === date.getFullYear()
      // || new Date(value).getDate() !== date.getDate()
    ) {
      if (currentuser.data.role.includes('storekeeper')) {
        history.push(`/admin/production-process-day-storekeeper?day=${value.format('YYYY-MM-DD')}&machine=${machine}`);
      } else {
        history.push(`/admin/production-process-day?day=${value.format('YYYY-MM-DD')}&machine=${machine}`);
      }
    }
    setDate(new Date(value));
  };
  // document.querySelectorAll('[tabIndex="0"]')[1] && document.querySelectorAll('[tabIndex="0"]')[1].classList.add('productionProcessCalendar');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const findTimeAndMachine = (value) => {
    for (const workOrder of allWorkOrders) {
      if (workOrder._id === value) {
        setProductionTime(workOrder.productionTime);
        setMachineForProduction(workOrder.machine);
      }
    }
  };

  let workOrderInProduction = [];

  if (productionProcesses && allWorkOrders) {
    for (const singleProductionProcess of productionProcesses) {
      for (const workOrder of allWorkOrders) {
        if (singleProductionProcess.workOrder[0]?._id === workOrder._id) {
          workOrderInProduction.push(workOrder._id);
        }
      }
    }
  }
  // const filteredWorkOrders = allWorkOrders && allWorkOrders.filter((item) => !workOrderInProduction.includes(item?._id) && item?.machine?._id === sessionStorage.getItem('selectedMachine') || (!sessionStorage.getItem('selectedMachine') && item.machine?._id === defaultMachine?._id && !workOrderInProduction.includes(item?._id));
  const filteredWorkOrders =
    allWorkOrders &&
    allWorkOrders.filter(
      (item) =>
        ((item?.workOrderStatus === 'kreiran' || item?.workOrderStatus === 'kreiran-priprema') &&
          item?.machine?._id === sessionStorage.getItem('selectedMachine')) ||
        (!sessionStorage.getItem('selectedMachine') &&
          item.machine?._id === defaultMachine?._id &&
          item?.workOrderStatus === 'kreiran'),
    );

  useEffect(() => {
    for (const checkPP of productionProcess) {
      if (moment().format('YYYY-MM-DD') === moment(checkPP.startTime).format('YYYY-MM-DD')) {
        startTime = moment(checkPP.endTime);
        form.setFieldsValue({ startTime });
      }
      if (startTime.format('YYYY-MM-DD HH:mm') === moment(checkPP.startTime).format('YYYY-MM-DD HH:mm')) {
        startTime = moment(checkPP.endTime);
        form.setFieldsValue({ startTime });
      }
    }
  }, [isModalVisible]);

  const changeStartTime = (value) => {
    if (value) {
      if (
        !moment(value).isSame(moment(selectedTime), 'days') ||
        !moment(value).isSame(moment(selectedTime), 'months')
      ) {
        startTime = moment(value).set({ seconds: 0, milliseconds: 0 });
        form.setFieldsValue({ startTime });
        for (const singleProductionProcess of productionProcess) {
          if (new Date(singleProductionProcess.startTime).getDate() === moment(value).date()) {
            startTime = moment(singleProductionProcess.endTime);
          }
        }
        setSelectedTime(startTime);
        form.setFieldsValue({ startTime });
      } else if (!moment(value).isSame(moment(selectedTime), 'hours')) {
        form.setFieldsValue({ startTime: value });
        setSelectedTime(value);
      }
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body' style={{ padding: '3em' }}>
        <Form className='form-horizontal' layout='horizontal'>
          <Row type='flex' gutter={24}>
            <Col xs={12} md={12}>
              <div className='panel-body'>
                <Form.Item label='Izaberite mašinu' name='machine'>
                  <Select
                    defaultValue={
                      sessionStorage.getItem('selectedMachine')
                        ? sessionStorage.getItem('selectedMachine')
                        : defaultMachine
                        ? defaultMachine._id
                        : undefined
                    }
                    onChange={(value) => {
                      machineHandler(value, form);
                      sessionStorage.setItem('selectedMachine', value);
                    }}
                    style={{ width: '80%' }}
                  >
                    {machines.items &&
                      machines.items.length > 0 &&
                      machines.items.map((item, index) => (
                        <Option key={index} value={item._id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Button
                  type='primary'
                  onClick={showModal}
                  disabled={!currentuser.data.role.some((r) => ['admin', 'superAdmin'].includes(r))}
                >
                  Dodaj u plan proizvodnje
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24}>
              <div className='panel-body'>
                <Form.Item>
                  <Calendar
                    mode='month'
                    value={moment(createdAt)}
                    onSelect={selectCalendar}
                    onPanelChange={changeCalendar}
                    dateCellRender={dateCellRender}
                  />
                </Form.Item>
                <div className='text-right'>
                  <Button
                    type='primary'
                    onClick={showModal}
                    disabled={!currentuser.data.role.some((r) => ['admin', 'superAdmin'].includes(r))}
                  >
                    Dodaj u plan proizvodnje
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Modal visible={isModalVisible} title='Dodaj u plan proizvodnje' onCancel={handleCancel} footer={[]}>
          <Form onFinish={(values) => onFinish(values, !data)} initialValues={initialValues} form={form}>
            <Form.Item
              label='Radni nalog'
              name='workOrder'
              rules={[{ required: true, message: 'Molimo Vas izaberite radni nalog!' }]}
            >
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(value) => findTimeAndMachine(value)}
              >
                {filteredWorkOrders &&
                  filteredWorkOrders.length > 0 &&
                  filteredWorkOrders.map((item, index) => (
                    <Option
                      key={index}
                      value={item._id}
                    >{`${item.code} - ${item.machine?.name} - ${item.companyName} - ${item.productName}`}</Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label='Vreme početka' name='startTime'>
              <DatePicker showTime={{ format: 'HH:mm' }} format={'YYYY-MM-DD HH:mm'} onChange={changeStartTime} />
            </Form.Item>
            <p>Trajanje procesa: {productionTime} h</p>
            <div style={{ marginBottom: '20px' }}>
              <Text strong>Mašina: {machineForProduction && machineForProduction.name}</Text>
            </div>
            <Button key='submit' type='primary' htmlType='submit'>
              Sačuvaj
            </Button>
            <Button key='cancel' type='primary' onClick={handleCancel} style={{ marginLeft: '10px' }}>
              Odustani
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default MaterialForm;
