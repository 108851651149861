import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL, APP_URL } from '../../config';
import ExcessRollForm from '../../components/forms/ExcessRollForm';
const QRCode = require('qrcode');

const ExcessRoll = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [material, fetchMaterial] = useAxios('', {}, currentuser.data.token, 'get');
  const [materials, fetchMaterials] = useAxios('', [], currentuser.data.token, 'get');
  const [rolls, fetchRolls] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const { materialId } = props.match.params;

  let res;

  useEffect(() => {
    if (materialId) {
      fetchMaterial(`${SERVER_URL}/materials/${materialId}`, {});
      fetchRolls(`${SERVER_URL}/rolls-by-material?material=${materialId}`);
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?material=${materialId}`);
    }
    fetchMaterials(`${SERVER_URL}/materials`, []);
  }, [fetchMaterial, materialId, fetchMaterials, fetchRolls, fetchRollsReserved]);

  const generateQR = async (formData) => {
    const matName = `Naziv: ${material.data.name}\n`;
    const matCode = `Šifra: ${material.data.code}\n`;
    const state = `Stanje: ${formData.rolls[0].weight}kg\n`;
    const notification = `https://birografika-dashboard.concordsoft.solutions/admin/view-roll/${material.rollID}`;
    const qrName = matName + matCode + state + notification;
    try {
      await QRCode.toDataURL(qrName, async function (err, qr) {
        formData.QRCode = qr;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (formData, isNew) => {
    formData.token = Array.from(Array(6), () => Math.floor(Math.random() * 36).toString(36)).join('');
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/materials` : `${SERVER_URL}/materials/${materialId}`;
    const date = new Date();
    try {
      const materialExist = materials.data.items.filter((material) => material.code === formData.code);
      if (
        (isNew && materialExist.length !== 0) ||
        (!isNew && materialExist.length === 1 && material.data.code !== formData.code)
      ) {
        notification.error({
          message: 'Materijal sa ovom šifrom već postoji.',
          placement: 'bottomRight',
        });
      } else if (!formData.minQuantity) {
        notification.error({
          message: 'Morate uneti minimalnu količinu.',
          placement: 'bottomRight',
        });
      } else {
        const response = await Axios[method](route, formData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        if (formData.rolls && (response.data.material || materialId)) {
          formData.rollID = `${formData.code}${date.getUTCDate()}${
            date.getUTCMonth() + 1
          }${date.getUTCFullYear()}${date.getHours()}${Array.from(Array(4), () =>
            Math.floor(Math.random() * 36).toString(36),
          ).join('')}`;
          await generateQR(formData);
          res = await Axios.post(
            `${SERVER_URL}/rolls`,
            {
              ...formData,
              material: isNew ? response.data.material : materialId,
            },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          await Axios.put(
            `${SERVER_URL}/materials/${isNew ? response.data.material : materialId}`,
            {},
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        }

        notification.success({
          message: `Materijal je ${isNew ? 'kreiran.' : 'izmenjen.'}`,
          placement: 'bottomRight',
        });
        isNew
          ? (window.location.href = `/admin/materials`)
          : (window.location.href = `/admin/edit-material/${materialId}`);
      }
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };
  const isDataFetched = !material.isLoading && material.data && currentuser.language;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!materialId && isDataFetched && !materials.isLoading && (
          <ExcessRollForm
            isNew={true}
            materials={materials.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}

        {materialId && !material.isError && material.data && isDataFetched && (
          <ExcessRollForm
            isNew={false}
            data={material.data}
            rolls={rolls.data}
            language={currentuser.language}
            onSubmit={onSubmit}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
    </div>
  );
};

export default ExcessRoll;
