import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Card, Input, DatePicker } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import Axios from 'axios';

const ViewTool = (props) => {
  const [form] = Form.useForm();
  const { toolId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [tool, fetchTool] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (toolId) {
      fetchTool(`${SERVER_URL}/tools/${toolId}`, []);
    }
  }, [toolId, fetchTool]);

  if (tool.data) {
    form.setFieldsValue({
      box: tool.data.box,
      dimension: tool.data.dimension,
      numRows: tool.data.numRows,
      numItemInTM: tool.data.numItemInTM,
      numTeeths: tool.data.numTeeths,
      omimInch: tool.data.omimInch,
      shapeDesc: tool.data.shapeDesc,
      product: tool.data.product,
      companyName: tool.data.companyName,
      material: tool.data.material,
      toolID: tool.data.toolID,
      date: moment(tool.data.date),
      amount: tool.data.amount,
      note: tool.data.note,
      createdBy: tool.data.createdBy && tool.data?.createdBy?.firstName + ' ' + tool.data?.createdBy?.lastName,
      modifiedBy: tool.data.modifiedBy && tool.data?.modifiedBy?.firstName + ' ' + tool.data?.modifiedBy?.lastName,
      uploadedBy: tool.data.file && tool.data.file?.uploadedBy?.firstName + ' ' + tool.data.file?.uploadedBy?.lastName,
    });
  }
  const getPDF = async () => {
    const fileName = tool.data.file.originalname;
    const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf-tool?fileName=${fileName}`, {
      responseType: 'arraybuffer',
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    const pathArr = fileName.split('\\');
    const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
    let a = document.createElement('a');
    let url = URL.createObjectURL(blob);
    a.href = url;
    a.download = pathArr[pathArr.length - 1];
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/tools'>
          <Button type='primary'>Svi alati</Button>
        </Link>
      </div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title='PREGLED ALATA' bordered={false}>
            <Form className='form-horizontal toolForm' layout='horizontal' form={form}>
              <Form.Item label='Kutija' name='box' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Dimenzije' name='dimension' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Broj redova' name='numRows' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Broj kom u TM' name='numItemInTM' style={{ marginLeft: '11px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Broj zubaca' name='numTeeths' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='OBIM u inčima' name='omimInch' style={{ marginLeft: '7px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Širina materijala' name='materialWidth'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Opis oblika' name='shapeDesc' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Za koji proizvod' name='product' style={{ marginLeft: '3px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Kupac' name='companyName' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Za koji materijal' name='material' style={{ marginLeft: '2px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Broj alata' name='toolID' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Datum izrade' name='date' style={{ marginLeft: '16px' }}>
                <DatePicker format={'DD-MM-YYYY'} disabled />
              </Form.Item>

              <Form.Item label='Broj komada' name='amount' style={{ marginLeft: '22px' }}>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Napomena' name='note' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Kreirao' name='createdBy' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Izmenio' name='modifiedBy' className='toolField'>
                <Input disabled />
              </Form.Item>

              <Form.Item label='Upload izvršio' name='uploadedBy' style={{ marginLeft: '14px' }}>
                <Input disabled />
              </Form.Item>

              {tool.data && tool.data.file && (
                <Form.Item label='Tehnicki crtež' className='toolField'>
                  <Button icon={<DownloadOutlined />} onClick={getPDF}>
                    Preuzmi
                  </Button>
                </Form.Item>
              )}
              <div style={{ marginTop: '10px' }} className='text-center'>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => (window.location.href = '/admin/tools')}
                  type='primary'
                  htmlType='button'
                >
                  Zatvori
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewTool;
