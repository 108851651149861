import React, { useContext, useEffect } from "react";
import { Button, Card, Row, Col } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/materialRollsTable';

const ViewMaterialRolls = (props) => {
  const { materialId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [productionProcess, fetchProductionProcess] = useAxios('', [], currentuser.data.token, 'get');
  const [rolls, fetchRolls] = useAxios('', [], currentuser.data.token, 'get');
  const [materialConsumption, fetchMaterialConsumption] = useAxios('', [], currentuser.data.token, 'get');

  const filter = { material: materialId };
  useEffect(() => {
    if (materialId) {
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`, []);
      fetchRolls(`${SERVER_URL}/rolls?filter=${JSON.stringify(filter)}`, []);
      fetchMaterialConsumption(`${SERVER_URL}/material-consumption`, []);
    }
    fetchProductionProcess(`${SERVER_URL}/production-process`);
  }, [materialId, fetchRollsReserved, fetchProductionProcess, fetchRolls, fetchMaterialConsumption]);

  if (rollsReserved.data && rollsReserved.data.items && productionProcess.data && productionProcess.data.items) {
    for (const rollReserved of rollsReserved.data.items) {
      for (const singleProcess of productionProcess.data?.items) {
        if (singleProcess.workOrder[0]?._id === rollReserved.workOrder?._id && singleProcess.closed) {
          rollReserved.closedClass = { color: '#ED0000' };
        }
      }
    }
  }

  let columnKeys;
  let newColumnKeys;
  let tableData = [];
  if (rollsReserved && rollsReserved.data && rollsReserved.data.items) {
    columnKeys = ['workOrderCol', 'amount'];
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'workOrderCol') {
        alternativeNames.push('radni nalog');
      } else if (columnKey === 'amount') {
        alternativeNames.push('količina');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index]
    }));
    let supply;
    tableData = rollsReserved.data.items.map((item, index) => {
      // if (rolls?.data?.items && materialConsumption?.data?.items) {
      //   let status;
      //   for (const roll of rolls.data.items) {
      //     for (const rollReservedInRoll of roll.rollsReserved) {
      //       for (const rollReserved of rollsReserved.data.items) {
      //         if (rollReserved._id === rollReservedInRoll._id) {
      //           for (const matCons of materialConsumption?.data?.items) {
      //             if (matCons.RollReservedID === rollReserved._id) {
      //               item.spentQuantity = matCons.spentQuantity;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      item.workOrderCol = <span>{`${item.workOrder?.companyName} (`}<span style={item?.closedClass}>{`${item.workOrder?.code}`}</span>)</span>;
      item.amount = <span style={item?.closedClass}>{item.status === 'inactive' ? item?.materialQuantity : item.spentQuantity}</span>;
      return item;
    });
  }

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title='PREGLED ROLNI MATERIJALA' bordered={false}>
          <Table
            data={tableData}
            columnKeys={newColumnKeys}
          />
          {/*<Row gutter={16} style={{ backgroundColor: '#216c97', margin: '10px 0', fontSize: '1.1rem', color: 'white' }}>
            <Col span={12}>Radni nalog</Col>
            <Col span={12} className='text-right'>Količina</Col>
          </Row>
          {rollsReserved && rollsReserved.data && rollsReserved.data.items && rollsReserved.data.items.map((item) => (
            <Row gutter={16} className='rollReserved'>
              <Col span={12}><p style={{ marginBottom: '0' }}>{`${item.workOrder?.companyName} (`}<span style={item.closedClass}>{`${item.workOrder?.code}`}</span>)</p></Col>
              <Col span={12} className='text-right'><span style={item.closedClass}>{item.materialQuantity}</span></Col>
            </Row>
          ))}*/}
          <div className='text-center' style={{ marginTop: '10px' }}>
            <Button type='primary' onClick={() => window.location.href = '/admin/materials'}>
              Zatvori
            </Button>
          </div>
        </Card>
      </div>
    </div >
  );
};

export default ViewMaterialRolls;