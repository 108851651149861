import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ToolForm from '../../components/forms/ToolForm';


const EditTool = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [tool, fetchTool] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { toolId } = props.match.params;

  useEffect(() => {
    if (toolId) {
      fetchTool(`${SERVER_URL}/tools/${toolId}`, []);
    }
  }, [toolId, fetchTool]);

  const updateTool = async (data) => {
    try {
      await Axios.put(`${SERVER_URL}/tools/${toolId}`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Alat je izmenjen.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/tools');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const createTool = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/tools`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Alat je kreiran.',
        placement: 'bottomRight',
      });
      setResult(true);
      history.push('/admin/tools');
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem sa dodavanjem alata. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {toolId && tool.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {toolId && !tool.isLoading && !tool.isError && tool.data && (
          <ToolForm data={tool.data} updateHandler={updateTool} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
        {toolId && !tool.isLoading && tool.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!toolId && tool && !tool.data && (
          <ToolForm data={null} createHandler={createTool} result={result} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
      </div>
    </div>
  );
};


export default EditTool;