import React, { useContext } from 'react';
import { Table, Popconfirm, Input, Button } from 'antd';
import { DeleteOutlined, SearchOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const HilzneTable = ({ data, deleteHandler, editPath }) => {
  const user = useContext(UserContext);

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretrazi ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Pretrazi
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Resetuj
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      // console.log(record);
      const filterRecord =
        Array.isArray(dataIndex) && record[dataIndex[0]] ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];

      if (dataIndex === 'ordernumber') {
        if (!filterRecord || !value) return false;
        return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
      } else if (dataIndex === 'companyName') {
        if (record && record.orders && record.orders.length > 0 && record.orders[0].companyName) {
          return record?.orders[0]?.companyName.toString().toLowerCase().includes(value.toLowerCase());
        }
      } else if (dataIndex === 'productCode') {
        if (record && record.orders && record.orders.length > 0 && record.orders[0].productCode) {
          return record?.orders[0]?.productCode.toString().toLowerCase().includes(value.toLowerCase());
        }
      } else if (dataIndex === 'productName') {
        if (record && record.orders && record.orders.length > 0 && record.orders[0].productName) {
          return record?.orders[0]?.productName.toString().toLowerCase().includes(value.toLowerCase());
        }
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = [
    {
      title: 'Broj prijema porudžbenice',
      dataIndex: 'ordernumber',
      key: 'ordernumber',
      ...getColumnSearchProps('ordernumber'),
    },
    {
      title: 'Naziv kupca',
      render: (rec) => {
        return rec.orders[0]?.companyName;
      },
      ...getColumnSearchProps('companyName'),
    },
    {
      title: 'Sifra proizvoda',
      render: (rec) => {
        return rec.orders[0]?.productCode;
      },
      ...getColumnSearchProps('productCode'),
    },
    {
      title: 'Naziv proizvoda',
      render: (rec) => {
        if (rec && rec.orders && rec.orders.length > 0 && rec.orders[0].productName) {
          return rec.orders[0].productName;
        } else {
          return '';
        }
      },
      ...getColumnSearchProps('productName'),
    },
  ];

  columns.push({
    title: 'Akcije',
    width: '10vw' /*bilo je bez width,ali se tada ne poravnaju linije header i body*/,
    render: (text, record) => (
      <div
        style={{
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '15px',
        }}
      >
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={editPath + record._id}>
            <EditOutlined
              className='icon-unlock'
              title={`Izmeni potvrdu porudžbine`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Link to={`/admin/view-orderconfirmation/${record._id}`}>
            <EyeOutlined
              className='icon-unlock'
              title={`Pregledaj potvrdu porudžbine`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </Link>
        </div>

        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati potvrdu porudžbine`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='OK'
            cancelText='Cancel'
            cancelButtonProps={{ type: 'secondary' }}
            okButtonProps={{ type: 'primary' }}
          >
            <DeleteOutlined className='icon-unlock' title={`Obriši potvrdu porudžbine`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  return (
    <div className='table-data-okvir'>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: ['topCenter'],
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default HilzneTable;
