import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/MachinesTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'modifiedAt'
];

const Machines = () => {
  const currentuser = useContext(UserContext);
  const [machines, fetchMachines] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  useEffect(() => {
    fetchMachines(`${SERVER_URL}/machines`, []);
  }, [fetchMachines]);

  const deleteMachineHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/machines/${id}`,
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${currentuser.data.token}`
          },
        });
      notification.success({
        message: 'Mašina je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/machines');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (machines.data.items && machines.data.items.length > 0) {
    const keys = Object.keys(machines.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'name') {
        alternativeNames.push('Naziv mašine');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('kreirano');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('ažurirano');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index]
    }));
  }
  let tableData = [];
  if (machines.data && machines.data.items && machines.data.items.length > 0) {
    tableData = machines.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      item.modifiedAt = moment(item.modifiedAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-machine'>
          <Button type='primary'>Nova mašina</Button>
        </a>
      </div>
      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {machines.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!machines.isLoading && machines.data.items && machines.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteMachineHandler}
            columnKeys={newColumnKeys}
            editPath='/admin/edit-machine/'
          />
        )}
        {!machines.isLoading && machines.data.items && machines.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );

};

export default Machines;