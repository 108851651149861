import React from 'react';
import { Tabs, Card, Input, Form, Switch } from 'antd';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const HilzneViewForm = ({ data }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card className='workOrderForm ' title={`PREGLED HILZNE`}>
          <Form {...layout} initialValues={initialValues} layout='vertical' form={form}>
            <div className='mainCont'>
              <Form.Item required name='code' className='ant-form-item-control-input-content' label='Šifra hilzne:'>
                <Input disabled />
              </Form.Item>
            </div>

            <div>
              <div className='panel-body'>
                <Form.Item required className='formitemANTD' name='name' label='Naziv hilzne:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='unit' label='Jedinica mere:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='price' label='Cena:'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='width' label='Dužina (mm):'>
                  <Input disabled />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='quantity' label='Količina na stanju(kom):'>
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default HilzneViewForm;
