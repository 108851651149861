import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/DataTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'specificWeight',
  'calculationMethod',
  'width',
  'active',
  'status',
  'materialID',
  'QRCode',
];

const Material = () => {
  const currentuser = useContext(UserContext);
  const [materials, fetchMaterials] = useAxios('', [], currentuser.data.token, 'get');
  const [productionProcesses, fetchProductionProcesses] = useAxios('', [], currentuser.data.token, 'get');
  const [workOrders, fetchWorkOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  // const filter = { active: 'D' };
  useEffect(() => {
    fetchMaterials(`${SERVER_URL}/materials`, []);
    fetchProductionProcesses(`${SERVER_URL}/production-process`, []);
    fetchWorkOrders(`${SERVER_URL}/work-orders`, []);
  }, [fetchMaterials, fetchProductionProcesses, fetchWorkOrders]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/materials/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Materijal je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/materials');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  let columnKeys;
  let newColumnKeys = [];
  if (materials.data && materials.data.items && materials.data.items.length > 0) {
    const keys = Object.keys(materials.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('reserved')) {
      columnKeys.push('reserved');
    }
    if (!columnKeys.includes('rollsNumber')) {
      columnKeys.push('rollsNumber');
    }

    let sifra, nazivMaterijala, stanje, kolicina, zauzeto, tip, kreirano, azurirano, brojRolni;
    for (const columnKey of columnKeys) {
      if (columnKey === 'name') {
        nazivMaterijala = {
          originalName: columnKey,
          alternativeName: 'naziv materijala',
        };
      } else if (columnKey === 'code') {
        sifra = {
          originalName: columnKey,
          alternativeName: 'šifra',
        };
      } else if (columnKey === 'state') {
        stanje = {
          originalName: columnKey,
          alternativeName: 'Stanje (u kg/m2)',
        };
      } else if (columnKey === 'rolls') {
        kolicina = {
          originalName: columnKey,
          alternativeName: 'radna količina (u tm)',
        };
      } else if (columnKey === 'reserved') {
        zauzeto = {
          originalName: columnKey,
          alternativeName: 'zauzeto (u tm)',
        };
      } else if (columnKey === 'type') {
        tip = {
          originalName: columnKey,
          alternativeName: 'tip',
        };
      } else if (columnKey === 'createdAt') {
        kreirano = {
          originalName: columnKey,
          alternativeName: 'kreirano',
        };
      } else if (columnKey === 'updatedAt') {
        azurirano = {
          originalName: columnKey,
          alternativeName: 'ažurirano',
        };
      } else if (columnKey === 'rollsNumber') {
        brojRolni = {
          originalName: columnKey,
          alternativeName: 'broj rolni',
        };
      }
    }
    newColumnKeys.push(sifra, nazivMaterijala, brojRolni, tip, stanje, kolicina, zauzeto);
  }
  let tableData = [];
  let quantity = 0;
  let quantityReserved = 0;
  if (
    materials?.data &&
    materials?.data?.items &&
    materials?.data?.items?.length > 0 &&
    productionProcesses?.data &&
    productionProcesses?.data?.items &&
    workOrders?.data?.items
  ) {
    tableData = materials?.data?.items?.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      if (typeof item?.rolls[0] !== 'string' && item?.rolls && item?.rolls?.length > 0) {
        quantity = 0;
        for (const roll of item?.rolls) {
          quantity += roll.length;
        }
        item.rolls = quantity.toFixed(0);
      } else if (typeof item?.rolls[0] !== 'string' && item?.rolls && item?.rolls?.length === 0) {
        item.rolls = (0).toFixed(0);
      }

      if (item?.rollsreserved?.length > 0 && workOrders?.data?.items?.length > 0) {
        quantityReserved = 0;
        for (const rollreserved of item.rollsreserved) {
          for (const workOrder of workOrders?.data?.items) {
            if (rollreserved?.workOrder?._id === workOrder?._id && rollreserved.status === 'active') {
              quantityReserved += rollreserved.materialQuantity;
              // console.log(quantityReserved);
            }
          }
        }
        if (quantityReserved === '' || quantityReserved === undefined) {
          quantityReserved = 0;
        }
        item.reserved = quantityReserved.toFixed(0);
      } else if (typeof item.rollsreserved[0] !== 'string' && item.rollsreserved && item.rollsreserved.length === 0) {
        item.reserved = (0).toFixed(0);
      }
      if (parseFloat(item.rolls) === 0) {
        item.rollsNumber = 0;
      }
      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];

    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/import-materials`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-material'>
          <Button type='primary'>Novi materijal</Button>
        </a>
        <Button
          disabled={!currentuser.data.role.includes('superAdmin')}
          style={{ marginLeft: '10px' }}
          onClick={() => document.getElementById('input-file').click()}
          type='button'
        >
          Import
        </Button>
      </div>
      {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
      <div style={{ textAlign: 'center' }}>
        {materials.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!materials.isLoading && materials.data && materials.data.items && materials.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={newColumnKeys}
            title='Materijal'
            editPath='/admin/edit-material/'
            viewPath='/admin/view-material/'
          />
        )}
        {!materials.isLoading && materials.data && materials.data.items && materials.data.items.length === 0 && (
          <h2>NO DATA</h2>
        )}
      </div>
    </div>
  );
};

export default Material;
