import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, EyeOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const ToolTable = ({ data, deleteHandler, columnKeys, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [sortedInfo, setSortedInfo] = useState({});

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0 && selectedKeys[0] !== filter[property]) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : '')}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters, dataIndex, setSelectedKeys);
              setSortedInfo({});
            }}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem('filter', JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const collator = new Intl.Collator([], { numeric: true });

  const columns = [
    {
      key: 'dimension',
      title: 'Dimenzija',
      dataIndex: 'dimension',
      ...getColumnSearchProps('dimension'),
    },
    {
      key: 'toolID',
      title: 'Oznaka alata',
      dataIndex: 'toolID',
      ...getColumnSearchProps('toolID'),
    },
    {
      key: 'shapeDesc',
      title: 'Opis oblika',
      dataIndex: 'shapeDesc',
      ...getColumnSearchProps('shapeDesc'),
    },
    {
      key: 'companyName',
      title: 'Kupac',
      dataIndex: 'companyName',
      ...getColumnSearchProps('companyName'),
    },
    {
      key: 'box',
      title: 'Kutija',
      dataIndex: 'box',
      sorter: (a, b) => collator.compare(a.box, b.box),
      sortOrder: sortedInfo.columnKey === 'box' ? sortedInfo.order : null,
      ...getColumnSearchProps('box'),
    },
    {
      key: 'numTeeths',
      title: 'Broj zuba',
      dataIndex: 'numTeeths',
      ...getColumnSearchProps('numTeeths'),
    },
    {
      key: 'uploaded',
      title: 'Crtež',
      dataIndex: 'uploaded',
      ...getColumnSearchProps('uploaded'),
    },
  ];

  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {(user.data.role.includes('superAdmin') ||
          user.data.role.includes('preparer') ||
          user.data.role.includes('admin')) && (
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
              <Link to={editPath + record._id}>
                <EditOutlined title={'Izmeni alat'} style={{ textDecoration: 'none', color: 'black' }} />
              </Link>
            </div>
          )}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={'Pregledaj alat'} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        {(user.data.role.includes('superAdmin') || user.data.role.includes('admin')) && (
          <div style={{ margin: '2px', padding: '4px' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={'Ovo će obrisati alat'}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <DeleteOutlined title={'Obriši alat'} />
            </Popconfirm>
          </div>
        )}
      </div>
    ),
  });

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  } else {
    localStorage.setItem('url', window.location.href);
  }

  return (
    <div>
      <Table
        onChange={handleChange}
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={false}
      />
    </div>
  );
};

export default ToolTable;
