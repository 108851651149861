import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
// import { Link } from 'react-router-dom';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CompanyTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'number', 'modifiedAt', 'zip'];

const Companies = () => {
  const currentuser = useContext(UserContext);
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  useEffect(() => {
    fetchCompanies(`${SERVER_URL}/companies`, []);
  }, [fetchCompanies]);

  const deleteCompanyHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/companies/${id}`, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
        },
      });
      notification.success({
        message: 'Kompanija je obrisana.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/companies');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (companies.data && companies.data.items && companies.data.items.length > 0) {
    const keys = Object.keys(companies.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'name') {
        alternativeNames.push('Naziv kompanije');
      } else if (columnKey === 'sku') {
        alternativeNames.push('šifra');
      } else if (columnKey === 'city') {
        alternativeNames.push('grad');
      } else if (columnKey === 'deliveryAddress') {
        alternativeNames.push('adresa za isporuku');
      } else if (columnKey === 'invoiceAddress') {
        alternativeNames.push('adresa za fakturisanje');
      } else if (columnKey === 'numberOfItems') {
        alternativeNames.push('broj stavki');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('kreirano');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('ažurirano');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index],
    }));
  }
  let tableData = [];
  if (companies.data && companies.data.items && companies.data.items.length > 0) {
    tableData = companies.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  const handleImportFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/import-companies`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: response.data.message,
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  const handleCheckFile = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append('excelData', file);
    const response = await Axios.post(`${SERVER_URL}/check-companies`, formData, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    notification.success({
      message: 'Checked.',
      placement: 'bottomRight',
    });
    setTimeout(() => {
      window.location.reload();
    }, 1200);
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-company'>
          <Button type='primary'>Nova kompanija</Button>
        </a>
        <Button
          style={{ marginLeft: '10px' }}
          icon={<UploadOutlined />}
          onClick={() => document.getElementById('input-file').click()}
          type='button'
        >
          Import
        </Button>
        {/* <Button
          style={{ marginLeft: '10px' }}
          icon={<UploadOutlined />}
          onClick={() => document.getElementById('input-file-two').click()}
          type='button'
        >
          CheckCompanies
        </Button> */}
      </div>
      {<input style={{ display: 'none' }} id='input-file' type='file' accept='.XLSX' onChange={handleImportFile} />}
      {/* {<input style={{ display: 'none' }} id='input-file-two' type='file' accept='.XLSX' onChange={handleCheckFile} />} */}
      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {companies.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!companies.isLoading && companies.data.items && companies.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteCompanyHandler}
            columnKeys={newColumnKeys}
            editPath='/admin/edit-company/'
          />
        )}
        {!companies.isLoading && companies.data.items && companies.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Companies;
