import React from 'react';
import { Tabs, Card, Input, Button, Form, InputNumber, Select } from 'antd';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 9 },
};

const Option = { Select };

const TypeOfFolioForm = ({ isNew, data, onSubmit }) => {
  const [form] = Form.useForm();

  let initialValues = { ...data };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  return (
    <div className='panel panel-body '>
      <div className='panel-body'>
        <Card title={`${isNew ? 'NOVI TIP FOLIJE' : 'IZMENA TIPA FOLIJE'}`}>
          <Form
            {...layout}
            initialValues={initialValues}
            onFinish={(values) => onFinish(values, !data)}
            layout='vertical'
            form={form}
          >
            <div className='mainCont'>
              <Form.Item
                required
                name='code'
                className='ant-form-item-control-input-content'
                label='Šifra tipa folije:'
              >
                <Input />
              </Form.Item>
            </div>

            <div>
              <div className='panel-body'>
                <Form.Item required className='formitemANTD' name='name' label='Naziv tipa folije:'>
                  <Input />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='folioWay' label='Način plastifikacije:'>
                  <Select>
                    <Option value='hladniFT'>Hladni FT</Option>
                    <Option value='topliFT'>Topli FT</Option>
                  </Select>
                </Form.Item>
                <Form.Item required className='formitemANTD' name='pricePerSquareMeter' label='Cena folije (€/m2):'>
                  <InputNumber min='0' className='formitemANTDTwo' />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='unit' label='Jedinica mere:'>
                  <Input />
                </Form.Item>
                <Form.Item required className='formitemANTD' name='quantity' label='Količina na stanju:'>
                  <InputNumber min='0' className='formitemANTDTwo' />
                </Form.Item>
              </div>
            </div>

            <div className='text-right'>
              <Button type='primary' htmlType='submit'>
                {isNew ? 'Dodaj' : 'Izmeni'} tip folije
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default TypeOfFolioForm;
