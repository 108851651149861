import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
      if (filter) {
        for (const property in filter) {
          if (dataIndex === property) {
            if (Array.isArray(selectedKeys) && selectedKeys.length === 0 && selectedKeys[0] !== filter[property]) {
              setSelectedKeys([`${filter[property]}`]);
              confirm();
            }
          }
        }
      }

      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : '')}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys)}
            size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    let filter = localStorage.getItem('filter') ? JSON.parse(localStorage.getItem('filter')) : {};
    filter[`${dataIndex}`] = selectedKeys[0];
    localStorage.setItem('filter', JSON.stringify(filter));
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, setSelectedKeys) => {
    let filter = localStorage.getItem('filter') && JSON.parse(localStorage.getItem('filter'));
    if (filter) {
      for (const property in filter) {
        if (dataIndex === property) {
          setSelectedKeys([]);
          delete filter[property];
          clearFilters();
        }
      }
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  const columns = columnKeys.map((item) => ({
    key: item.originalName,
    title: item.alternativeName.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName) ? [item.originalName, user.language ? user.language.selected.code : ''] : item.originalName,
    ...getColumnSearchProps(['title', 'category'].includes(item.originalName) ? [item.originalName, user.language ? user.language.selected.code : ''] : item.originalName),
  }));
  columns.push({
    title: 'Opcije',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
          <Link to={editPath + record._id}>
            <EditOutlined title={`Izmeni ${title.toLowerCase()}a`} style={{ textDecoration: 'none', color: 'black' }} />
          </Link>
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={viewPath + record._id}>
              <EyeOutlined title={`Pregledaj ${title.toLowerCase()}a`} style={{ textDecoration: 'none', color: 'black' }} />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }}>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={`Ovo će obrisati ${title.toLowerCase()}a`}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText='Ok'
            cancelText='Cancel'
          >
            <DeleteOutlined title={`Obriši ${title.toLowerCase()}a`} />
          </Popconfirm>
        </div>
      </div>
    ),
  });

  function onChange(page) {
    page && localStorage.setItem('pageNumber', page);
    localStorage.getItem('pageNumber') && parseInt(localStorage.getItem('pageNumber')) === 1 && localStorage.removeItem('pageNumber');
  }

  if (localStorage.getItem('url') && localStorage.getItem('url') !== window.location.href) {
    localStorage.removeItem('pageNumber');
    localStorage.removeItem('filter');
    localStorage.setItem('url', window.location.href);
  }
  else {
    localStorage.setItem('url', window.location.href);
  }


  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
          onChange: onChange,
          defaultCurrent: localStorage.getItem('pageNumber') ? parseInt(localStorage.getItem('pageNumber')) : 1
        }}
      />
    </div>
  );
};

export default UsersTable;
