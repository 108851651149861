import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/WorkTimeTable';
import { SERVER_URL } from '../../config';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
];

const WorkTime = () => {
  const currentuser = useContext(UserContext);
  const [workTimes, fetchWorkTimes] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchWorkTimes(`${SERVER_URL}/work-time`, []);
  }, [fetchWorkTimes]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/work-time/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Radno vreme je obrisano.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/work-time');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (workTimes.data && workTimes.data.items && workTimes.data.items.length > 0) {
    const keys = Object.keys(workTimes.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'startTime') {
        alternativeNames.push('početak');
      } else if (columnKey === 'endTime') {
        alternativeNames.push('kraj');
      } else if (columnKey === 'machine') {
        alternativeNames.push('mašina');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('kreirano');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('ažurirano');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index]
    }));
  }

  let tableData = [];
  if (workTimes.data && workTimes.data.items && workTimes.data.items.length > 0) {
    tableData = workTimes.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      item.startTime = moment(item.startTime).format('MM/DD/YYYY, h:mm:ss A');
      item.endTime = moment(item.endTime).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-work-time'>
          <Button type='primary'>Novo radno vreme</Button>
        </a>
      </div>
      <div style={{ textAlign: 'center' }}>
        {workTimes.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!workTimes.isLoading && workTimes.data && workTimes.data.items && workTimes.data.items.length > 0 && (
          <Table data={tableData} deleteHandler={deleteDataHandler} columnKeys={newColumnKeys} title='Radno vreme' editPath='/admin/edit-work-time/' />
        )}
        {!workTimes.isLoading && workTimes.data && workTimes.data.items && workTimes.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default WorkTime;
