import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Axios from 'axios';

const RollVerification = (props) => {
  const user = useContext(UserContext);
  const rollToken = props.match.params.token;
  const [roll, setRoll] = useState();
  const [newMeasure, setNewMeasure] = useState();

  useEffect(() => {
    if (rollToken) {
      getRoll();
    }
  }, []);

  const getRoll = async () => {
    if (rollToken) {
      const res = await Axios.get(`${SERVER_URL}/verify-roll/${rollToken}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` },
      });
      if (res) {
        setRoll(res.data);
      }
    }
  };

  const verifyRoll = async () => {
    let status;
    let statusNum;
    try {
      if (!roll) {
        notification.error({
          message: 'Rolna nije pronadjena.',
          placement: 'bottomRight',
        });
        return;
      }
      if (roll.status === 'Potrošena') {
        notification.error({
          message: 'Nije moguće verifikovati rolnu koja je potrošena.',
          placement: 'bottomRight',
        });
        return;
      }
      if (roll.status === 'U magacinu' || roll.status === 'U proizvodnji') {
        notification.error({
          message: 'Nije moguće verifikovati rolnu koja ne čeka verifikaciju.',
          placement: 'bottomRight',
        });
        return;
      }
      if (roll.status === 'Čeka verifikaciju(iz proizvodnje)') {
        status = 'U magacinu';
        statusNum = 1;
      } else if (roll.status === 'Čeka verifikaciju(iz magacina)') {
        status = 'U proizvodnji';
        statusNum = 3;
      }
      await Axios.put(
        `${SERVER_URL}/verify-roll/${roll._id}`,
        {
          newMeasure: newMeasure,
          roll: roll,
          statusNum,
          status,
        },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        },
      );
      notification.success({
        message: 'Rolna je uspešno verifikovana.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('error', error);
      notification.info({
        message: 'Rolna je već verifikovana.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <img style={{ marginBottom: '20px' }} className='login-logo' src='/logo.png' alt='Logo Birografika' />
        <p>Verifikacija rolne</p>
        <Input
          placeholder='Izmerena kolicina'
          style={{ width: '200px', marginTop: '70px' }}
          onChange={(e) => setNewMeasure(e.target.value)}
        />
        <Button onClick={() => verifyRoll()}>Verifikuj</Button>
      </div>
    </div>
  );
};

export default RollVerification;
