import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/OrderToProductionTable';
import moment from 'moment';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdAt',
  'updatedAt',
  'materialRollQuantity',
  'materialRoll',
  'internalCode',
  'productCode',
  'printing',
  'format',
  'plates',
  'graphics',
  'graphicEdits',
  'films',
  'numberOfColors',
  'platesOrder',
  'lacquering',
  'print',
  'lacquerTypes',
  'promerHilzne',
  'rollDiameter',
  'unrollDirection',
  'unrollDirectionBack',
  'rollLabeling',
  'boxLabeling',
  'rollStacking',
  'paletteLabeling',
  'productSetout',
  'materialName',
  'materialDimension',
  'materialQuantity',
  'firstColor',
  'secondColor',
  'thirdColor',
  'fourthColor',
  'fifthColor',
  'sixthColor',
  'seventhColor',
  'lacquier',
  'cilinderVolume',
  'pieces',
  'rowsToCut',
  'rollPiecesNum',
  'rollNum',
  'materialNameSecond',
  'materialDimensionSecond',
  'materialQuantitySecond',
  'materialNameThird',
  'materialDimensionThird',
  'materialQuantityThird',
  'printForms',
  'productCodeSecond',
  'productNameSecond',
  'tools',
  'processFinished',
  'note',
  'closedBy',
  'id',
  'operatorI',
  'operatorII',
  'operatorIII',
  'companyID',
  'productID',
  'creationDate',
  'orderCode',
  'printingQuantity',
  'orderDate',
  'deliveryAddress',
  'invoiceAddress',
  'deliveryDate',
  'createdAt',
  'updatedAt',
];

const OrderToProduction = (props) => {
  const currentuser = useContext(UserContext);
  const [workOrders, fetchOrdersToProduction] = useAxios('', [], currentuser.data.token, 'get');
  const [rollreserveds, fetchRollReserveds] = useAxios('', [], currentuser.data.token, 'get');
  useEffect(() => {
    fetchOrdersToProduction(`${SERVER_URL}/orders-to-production`, []);
  }, [fetchOrdersToProduction, props]);

  useEffect(() => {
    fetchRollReserveds(`${SERVER_URL}/rolls-reserved/`, []);
  }, [fetchRollReserveds]);
  const deleteOrderToProductionHandler = async (id) => {
    try {
      await Axios.put(
        `${SERVER_URL}/order-to-production/${id}`,
        { orderToProductionStatus: 'storniran' },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Nalog proizvodnji da proizvede je storniran.',
        placement: 'bottomRight',
      });

      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  const copyWorkOrderHandler = async (id) => {
    try {
      let data = {};
      if (id) {
        const response = await Axios.get(`${SERVER_URL}/order-to-production/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        // const latestWo = await Axios.get(
        //   `${SERVER_URL}/orders-to-production?sorter=${JSON.stringify({ _id: -1 })}&limit=${JSON.stringify(1)}`,
        //   {
        //     withCredentials: false,
        //     headers: { Authorization: `Bearer ${currentuser.data.token}` },
        //   },
        // );

        data = response.data;
        delete data._id;
        delete data.editedBy;
        delete data.graphicEdits;
        delete data.closedBy;
        delete data.producedQuantity;
        data.creationDate = moment();
        data.orderDate = moment();
        data.deliveryDate = moment();
        data.orderToProductionStatus = 'kreiran';
        // if (!latestWo.data.items[0].code.includes(`/${moment().format('YYYY').toString().substring(2)}`)) {
        //   data.code = `00001/${moment().format('YYYY').toString().substring(2)}`;
        // } else {
        //   let codeArray = latestWo.data.items[0].code.split('/');
        //   let zeros = '';
        //   for (const digit of codeArray[0]) {
        //     if (parseInt(digit) !== 0) {
        //       break;
        //     }
        //     zeros += digit;
        //   }

        //   codeArray[0] = parseInt(codeArray[0]) + 1;
        //   let codePartOne = zeros + codeArray[0];

        //   if (codePartOne.length > 5) {
        //     codePartOne = codePartOne.substring(1);
        //   }

        //   data.code = `${codePartOne}/${codeArray[1]}`;
        // }
      }

      await Axios.post(
        `${SERVER_URL}/orders-to-production`,
        { ...data, copied: true, idCopied: id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'NPP je kopiran.',
        placement: 'bottomRight',
      });
      localStorage.removeItem('filter');
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Problem sa kopiranjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (workOrders.data && workOrders.data.length > 0) {
    const keys = Object.keys(workOrders.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('productionTime')) {
      columnKeys.push('productionTime');
    }
    if (!columnKeys.includes('editedBy')) {
      columnKeys.push('editedBy');
    }
    if (!columnKeys.includes('productName')) {
      columnKeys.push('productName');
    }
    if (!columnKeys.includes('companyName')) {
      columnKeys.push('companyName');
    }

    let brRadnogNaloga, kupac, proizvod, masina, vremeZaIzradu, kreirao, izmenio, status;
    for (const columnKey of columnKeys) {
      if (columnKey === 'code') {
        brRadnogNaloga = {
          originalName: columnKey,
          alternativeName: 'br.',
        };
      } else if (columnKey === 'companyName') {
        kupac = {
          originalName: columnKey,
          alternativeName: 'naziv kupca',
        };
      } else if (columnKey === 'machine') {
        masina = {
          originalName: columnKey,
          alternativeName: 'mašina',
        };
      } else if (columnKey === 'productName') {
        proizvod = {
          originalName: columnKey,
          alternativeName: 'naziv proizvoda',
        };
      } else if (columnKey === 'orderToProductionStatus') {
        status = {
          originalName: columnKey,
          alternativeName: 'status',
        };
      } else if (columnKey === 'createdBy') {
        kreirao = {
          originalName: columnKey,
          alternativeName: 'npp kreirao',
        };
      } else if (columnKey === 'editedBy') {
        izmenio = {
          originalName: columnKey,
          alternativeName: 'npp izmenio',
        };
      } else if (columnKey === 'productionTime') {
        vremeZaIzradu = {
          originalName: columnKey,
          alternativeName: 'vreme za izradu (sati)',
        };
      }
    }
    newColumnKeys.push(
      brRadnogNaloga,

      kupac,
      proizvod,
      masina,
      vremeZaIzradu,
      kreirao,
      izmenio,
      status,
    );
  }
  let tableData = [];

  if (workOrders.data && workOrders.data.length > 0) {
    tableData = workOrders.data.map((item) => {
      item.creationDate = new Date(item.creationDate).toLocaleString();
      item.orderDate = new Date(item.orderDate).toLocaleString();
      item.deliveryDate = new Date(item.deliveryDate).toLocaleString();
      item.createdAt = item.createdAt;
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.productionTime = parseFloat(item.productionTime).toFixed(2);
      if (item.machine && item.machine.name) {
        item.machine = item.machine.name;
      }

      if (item.sendAuthentication && item.sendAuthentication === true) {
        item.sendAuthentication = 'DA';
      } else if (!item.sendAuthentication || item.sendAuthentication === false) {
        item.sendAuthentication = 'NE';
      }

      if (item.workOrderCreated && item.workOrderCreated === true) {
        item.workOrderCreated = 'DA';
      } else if (!item.workOrderCreated || item.workOrderCreated === false) {
        item.workOrderCreated = 'NE';
      }

      return item;
    });
  }

  const createPdf = async (id) => {
    try {
      const response = await Axios.post(
        `${SERVER_URL}/create-pdf-otp/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'application/json' },
        },
      );
      if (response.data.filename) {
        const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf?filename=${response.data.filename}`, {
          responseType: 'arraybuffer',
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        const pathArr = response.data.filename.split('\\');
        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = pathArr[pathArr.length - 1];
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err.response);
      notification.error({
        message:
          err.response.status === 409 ? err.response.data.message : 'Problem sa kreiranjem PDF-a. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      {currentuser.data.role.some((r) => ['admin', 'superAdmin', 'sales'].includes(r)) && props.value !== 'npp' && (
        <div className='actions-block'>
          <a href='/admin/new-order-to-production'>
            <Button type='primary'>Novi nalog proizvodnji da proizvede</Button>
          </a>
        </div>
      )}

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {workOrders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!workOrders.isLoading && workOrders.data && workOrders.data.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderToProductionHandler}
            copyHandler={copyWorkOrderHandler}
            columnKeys={newColumnKeys}
            createPdf={createPdf}
            title='radni nalog'
            value={props.value}
            editPath='/admin/edit-order-to-production/'
            viewPath='/admin/view-order-to-production/'
          />
        )}
        {!workOrders.isLoading && workOrders.data && workOrders.data.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default OrderToProduction;
