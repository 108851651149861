import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Form, Input, DatePicker, notification, Modal, Select, InputNumber } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { SERVER_URL_CALCULATOR } from '../../config';
import Axios from 'axios';
import { set } from 'lodash';

const { TextArea } = Input;
const { Option } = Select;

const ViewWorkOrder = (props, products) => {
  const [form] = Form.useForm();

  const [modalForm] = Form.useForm();
  const [modalSalesForm] = Form.useForm();
  const { workOrderId } = props.match.params;
  const currentuser = useContext(UserContext);
  const [workOrder, fetchWorkOrder] = useAxios('', null, currentuser.data.token, 'get');
  const [rollsReservedWorkOrder, fetchRollsReservedWorkOrder] = useAxios('', [], currentuser.data.token, 'get');
  const [machinesForPlasticization, setMachinesForPlasticization] = useState();
  const [hideFolioTypeWarmFT, setHideFolioTypeWarmFT] = useState(true);
  const [folioTypes, setFolioTypes] = useState([]);
  const [productsInWorkOrder, fetchProductsInWorkOrder] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [reworkers, fetchReworkers] = useAxios('', [], currentuser.data.token, 'get');
  const [materialConsumptions, fetchMaterialConsumptions] = useAxios('', [], currentuser.data.token, 'get');
  const [workers, fetchWorkers] = useAxios('', [], currentuser.data.token, 'get');
  const [finishedProduct, fetchFinishedProduct] = useAxios('', [], currentuser.data.token, 'get');
  const [finishedQuantity, setFinishedQuantity] = useState();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNPPModalVisible, setIsNPPModalVisible] = useState(false);
  const [isNPPModalSalesVisible, setIsNPPModalSalesVisible] = useState(false);
  const [hideSendAuthenticationMail, setHideSendAuthenticationMail] = useState(false);

  const [productsForStickers, setProductsForStickers] = useState();

  let compareArr = [];
  let result = [];

  let {
    additionalCalculatedWastePaper = [],
    additionalCilinderVolume = [],
    additionalFormat = [],
    additionalLacquerTypes = [],
    additionalLacquering = [],
    additionalMaterialInfo = [],
    additionalNumberOfColors = [],
    additionalPieces = [],
    additionalPiecesByMaterialWidth = [],
    additionalPlates = [],
    additionalPlatesOrder = [],
    additionalProductionTime = [],
    additionalPromerHilzne = [],
    additionalRollDiameter = [],
    additionalRollNum = [],
    additionalRollPiecesNum = [],
    additionalRowsToCut = [],
    additionalTools = [],
    additionalToolsBox = [],
    additionalToolsID = [],
    additionalToolsIsCharging = [],
    additionalVarnishCoverage = [],
    additionalVarnishWay = [],
  } = (workOrder.data && workOrder.data.additionalWorkOrderData[0]) || {};

  useEffect(() => {
    if (workOrderId) {
      fetchWorkOrder(`${SERVER_URL}/work-orders/${workOrderId}`, []);
    }
    const filter = { workOrder: workOrderId };
    const filterTwo = workOrderId;
    fetchReworkers(`${SERVER_URL}/rework-time-by-WO/${workOrderId}`);
    fetchWorkers(`${SERVER_URL}/worker-time-by-WO/${workOrderId}`);
    fetchMaterialConsumptions(`${SERVER_URL}/material-consumptionWO/${workOrderId}`);
    fetchRollsReservedWorkOrder(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`);
    fetchProductsInWorkOrder(`${SERVER_URL}/products-in-workorder?workOrderId=${filterTwo}`);
    if (rollsReservedWorkOrder && rollsReservedWorkOrder.data && rollsReservedWorkOrder.data.items) {
      const filterSecond = { material: { $in: rollsReservedWorkOrder.data.items.map((item) => item.material?._id) } };
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filterSecond)}`);
    }
  }, [workOrderId, fetchWorkOrder, fetchRollsReservedWorkOrder, fetchRollsReserved, rollsReservedWorkOrder]);

  const fetchFolioTypes = async (folioWay) => {
    const res = await Axios.get(`${SERVER_URL}/types-of-folio-byWay/${folioWay}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setFolioTypes(res.data);
  };

  useEffect(() => {
    if (form.getFieldValue('folioWay')) {
      fetchFolioTypes(form.getFieldValue('folioWay'));
    }

    fetchFinishingWorkPlasticization();
  }, []);

  useEffect(() => {
    if (form.getFieldValue('folioWay') === 'hladniFT') {
      setHideFolioTypeWarmFT(true);
    } else if (form.getFieldValue('folioWay') === 'topliFT') {
      setHideFolioTypeWarmFT(false);
    }
  });

  const fetchFinishingWorkPlasticization = async () => {
    const res = await Axios.get(`${SERVER_URL_CALCULATOR}/finishing-work-name/Plastifikacija`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setMachinesForPlasticization(res.data[0].machines);
  };

  useEffect(() => {
    const filter = { workOrder: workOrderId };

    fetchFinishedProduct(`${SERVER_URL}/finished-product/?filter=${JSON.stringify(filter)}`, []);

    if (finishedProduct.data.length != 0) {
      const listofFinishedProjects = finishedProduct.data.items;
      let newValue = 0;
      listofFinishedProjects.forEach((element) => {
        newValue = newValue + parseInt(element.quantity);
      });
      setFinishedQuantity(newValue);
    }
  });

  const btnShowModal = async () => {
    ////set products list mogucih za print da bude lista dodatnih proizvoda povezanih sad radnim nalogom
    let products = productsInWorkOrder.data.items;

    products = products.filter(function (item) {
      ///products se filtrira da se ne prikazuju obrisani proizvodi
      return item.product.length > 0;
    });

    setProductsForStickers(products);

    ///Fetch prvog proizvoda iz radnog naloga
    const prodByCode = await Axios.get(`${SERVER_URL}/products-code/${workOrder.data.productCode}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    //dodavanje tog proizvoda u listu
    setProductsForStickers((current) => [
      ...current,
      {
        workorder: workOrderId,
        product: [
          {
            productName: workOrder?.data?.productName,
            productCode: workOrder?.data?.productCode,
            _id: prodByCode.data[0]._id,
          },
        ],
      },
    ]);

    ///provera da li postoji drugi proizvod njegovo fetchovanje ako postoji i dodavanje njega u listu
    if (workOrder?.data?.productNameSecond) {
      const prodByCodeSecond = await Axios.get(`${SERVER_URL}/products-code/${workOrder.data.productCodeSecond}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setProductsForStickers((current) => [
        ...current,
        {
          workorder: workOrderId,
          product: [
            {
              productName: workOrder?.data?.productNameSecond,
              productCode: workOrder?.data?.productCodeSecond,
              _id: prodByCodeSecond.data[0]._id,
            },
          ],
        },
      ]);
    }

    setIsModalVisible(true);
  };

  const btnShowNPPModal = async () => {
    setIsNPPModalVisible(true);
  };

  const btnShowNPPModalSales = async () => {
    setIsNPPModalSalesVisible(true);
  };

  const handleNPPSalesCancel = () => {
    setIsNPPModalSalesVisible(false);
  };

  const handleNPPCancel = () => {
    setIsNPPModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const unrollDirectionArray = [
    'U smeru čitanja teksta - etiketa motana spolja',
    'U smeru čitanja teksta - etiketa motana iznutra',
    'Suprotno od smera čitanja teksta - etiketa motana spolja',
    'Suprotno od smera čitanja teksta - etiketa motana iznutra',
    'Na noge - etiketa motana spolja',
    'Na noge - etiketa motana iznutra',
    'Na glavu - etiketa motana spolja',
    'Na glavu - etiketa motana iznutra',
  ];

  for (let i = rollsReservedWorkOrder.data?.items?.length - 1; i >= 0; i--) {
    let sum = 0;
    for (let j = rollsReserved.data?.items?.length - 1; j >= 0; j--) {
      if (rollsReservedWorkOrder.data.items[i].roll?._id === rollsReserved.data.items[j].roll?._id) {
        sum = sum + rollsReserved.data.items[j].materialQuantity;
        const state =
          rollsReserved.data.items[j].material?.calculationMethod === 't/p/s' ||
          rollsReserved.data.items[j].material?.calculationMethod === 't*1000/p/s'
            ? rollsReserved.data.items[j].material?.width *
              rollsReserved.data.items[j].material?.specificWeight *
              (rollsReserved.data.items[j].roll?.length - sum)
            : rollsReserved.data.items[j].material?.width * (rollsReserved.data.items[j].roll?.length - sum);

        result[i] =
          rollsReserved.data.items[j].material?.code +
          ' ' +
          rollsReserved.data.items[j].material?.name +
          ' - ' +
          rollsReserved.data.items[j].roll?.length?.toFixed(2) +
          '(' +
          sum.toFixed(0) +
          ')';
        compareArr[i] = rollsReserved.data.items[j].roll?.length - sum;
      }
    }
  }

  if (workOrder.data) {
    form.setFieldsValue({
      finishedProduct: finishedQuantity,
      code: workOrder.data.code,
      email: workOrder.data.email,
      prevWorkOrderCode: workOrder.data.prevWorkOrderCode,
      contactPerson: workOrder.data.contactPerson,
      phone: workOrder.data.phone,
      orderToProduction: workOrder.data.orderToProduction,
      companyID: workOrder.data.companyID,
      companyName: workOrder.data.companyName,
      machine: workOrder?.data?.machine?.name,
      creationDate: moment(workOrder.data.creationDate),
      productionTime: workOrder.data.productionTime,
      productID: workOrder.data.productID,
      productName: workOrder.data.productName,
      firstProductQuantity: workOrder.data.firstProductQuantity,
      internalCode: workOrder.data.internalCode,
      productCode: workOrder.data.productCode,
      sendAuthentication: workOrder.data.sendAuthentication,
      sendAuthenticationMail: workOrder.data.sendAuthenticationMail,
      orderCode: workOrder.data.orderCode,
      orderDate: moment(workOrder.data.orderDate),
      deliveryDate: moment(workOrder.data.deliveryDate),
      printing: workOrder.data.printing,
      printingQuantity: workOrder.data.printingQuantity,
      productUnits: workOrder.data.productUnits,
      deliveryAddress: workOrder.data.deliveryAddress,
      invoiceAddress: workOrder.data.invoiceAddress,
      format: workOrder.data.format,
      plates: workOrder.data.plates,
      graphics: workOrder.data.graphics,
      graphicEdits: workOrder.data.graphicEdits ? workOrder.data.graphicEdits : '',
      films: workOrder.data.films,
      numberOfColors: workOrder.data.numberOfColors,
      platesOrder: workOrder.data.platesOrder,
      lacquering: workOrder.data.lacquering,
      print: workOrder.data.print,
      lacquerTypes: workOrder.data.lacquerTypes,
      varnishWay: workOrder.data.varnishWay,
      promerHilzne: workOrder.data.promerHilzne,
      rollDiameter: workOrder.data.rollDiameter,
      unrollDirection: workOrder.data.unrollDirection,
      note: workOrder.data.note,

      folioWay: workOrder.data.folioWay,
      folioPrintingMachine: workOrder.data.folioPrintingMachine,
      folioType: workOrder.data.folioType,
      rollWidth: workOrder.data.rollWidth,
      folioPrice: workOrder.data.folioPrice,
      folioTotal: workOrder.data.folioTotal,

      rollLabeling: workOrder.data.rollLabeling,
      boxLabeling: workOrder.data.boxLabeling,
      rollStacking: workOrder.data.rollStacking,
      paletteLabeling: workOrder.data.paletteLabeling,
      productSetout: workOrder.data.productSetout,
      workOrderStatus: workOrder.data.workOrderStatus,
      workOrderAuthenticationStatus: workOrder.data.workOrderAuthenticationStatus,
      materialName: workOrder.data.materialName,
      materialDimension: workOrder.data.materialDimension,
      materialQuantity: workOrder.data.materialQuantity,
      firstColor: workOrder.data.firstColor,
      secondColor: workOrder.data.secondColor,
      thirdColor: workOrder.data.thirdColor,
      fourthColor: workOrder.data.fourthColor,
      fifthColor: workOrder.data.fifthColor,
      sixthColor: workOrder.data.sixthColor,
      hilzna: workOrder.data.hilzna,
      oneHilzneWidth: workOrder.data.oneHilzneWidth,
      hilzneQuantity: workOrder.data.hilzneQuantity,

      rollPiecesNum: workOrder.data.rollPiecesNum,
      seventhColor: workOrder.data.seventhColor,
      lacquier: workOrder.data.lacquier,
      cilinderVolume: workOrder.data.cilinderVolume,
      pieces: workOrder.data.pieces,
      rowsToCut: workOrder.data.rowsToCut,
      piecesByMaterialWidth: workOrder.data.piecesByMaterialWidth,

      rollNum: workOrder.data.rollNum,
      calculatedWastePaper: workOrder.data.calculatedWastePaper,
      calculatedWastePaperSecond: workOrder.data.calculatedWastePaperSecond,
      calculatedWastePaperThird: workOrder.data.calculatedWastePaperThird,
      varnishCoverage: workOrder.data.varnishCoverage,
      materialNameSecond: workOrder.data.materialNameSecond,
      materialDimensionSecond: workOrder.data.materialDimensionSecond,
      materialQuantitySecond: workOrder.data.materialQuantitySecond,
      materialNameThird: workOrder.data.materialNameThird,
      materialDimensionThird: workOrder.data.materialDimensionThird,
      materialQuantityThird: workOrder.data.materialQuantityThird,
      printForms: workOrder.data.printForms,
      productCodeSecond: workOrder.data.productCodeSecond,
      productNameSecond: workOrder.data.productNameSecond,
      secondProductQuantity: workOrder.data.secondProductQuantity,
      tools: workOrder.data.tools,
      toolsIsCharging: workOrder.data.toolsIsCharging,
      toolsID: workOrder.data.toolsID,
      goodMetersFirst: workOrder.data.goodMetersFirst,
      goodMetersSecond: workOrder.data.goodMetersSecond,
      goodMetersThird: workOrder.data.goodMetersThird,
      toolsBox: workOrder.data.toolsBox,
      unrollDirectionBack: workOrder.data.unrollDirectionBack,
      closedBy: workOrder.data.closedBy,
      processFinished: workOrder.data.processFinished
        ? moment(workOrder.data.processFinished).format('MM-DD-YYYY : HH:mm')
        : '-',
      createdBy: workOrder.data.createdBy,
      editedBy: workOrder.data.editedBy
        ? `${workOrder.data.editedBy} - ${moment(workOrder.data.updatedAt).format('MM-DD-YYYY : HH:mm')}`
        : '',
      materialReserved: result.length > 0 ? result : [],
      materialRollQuantityReserved: rollsReservedWorkOrder.data.items?.map((item) => item.materialQuantity),
      goodMetersReserved: rollsReservedWorkOrder.data.items?.map((item) => item.goodMeters),
      calcWasteReserved: rollsReservedWorkOrder.data.items?.map((item) => item.calcWaste),
      rollID: rollsReservedWorkOrder.data.items?.map((item) => item.roll?.rollID),
      workers: workers.data.map((item) => item.operator),
      workersOnWo: materialConsumptions?.data?.items?.map((item) => item.operator),
      workersOnWoTime: materialConsumptions?.data?.items?.map((item) =>
        moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      ),
      workersCreatedAt: workers.data.map((item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')),
      reworkers: reworkers.data.map((item) => item.operator),
      reworkersCreatedAt: reworkers.data.map((item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')),
      // productsAddedAdditionaly: workOrder.data.productsAddedAdditionaly,
    });
  }

  let productsAddedAdditionaly = workOrder.data ? workOrder.data.productsAddedAdditionaly : [];

  const graphicEdits = workOrder.data && workOrder.data.graphicEdits ? workOrder.data.graphicEdits : '';
  let history = useHistory();

  const btnGoBack = () => {
    history.goBack();
  };
  const btnPrintSticker = async (size) => {
    const res = await Axios.post(
      `${SERVER_URL}/workorder-stickers`,
      { selectedproduct: selectedProduct, workorder: workOrder.data, size: size.size },
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'application/json' },
      },
    );

    if (res.data.filename) {
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-workorder-stickersname?filename=${res.data.filename}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const pathArr = res.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const createPdf = async (code) => {
    const prodByCode = await Axios.get(`${SERVER_URL}/products-code/${code}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (prodByCode.data[0]?.file) {
      let file = prodByCode.data[0].file;
      const pdfResponse = await Axios.get(`${SERVER_URL}/product-pdf/${file}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const pathArr = file.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      URL.revokeObjectURL(url);
    } else {
      notification.error({
        message: 'Ne postoji PDF dokument vezan za ovaj proizvod.',
        placement: 'bottomRight',
      });
    }
  };

  const createPdfSecond = async (code) => {
    const prodByCode = await Axios.get(`${SERVER_URL}/products-code/${code}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (prodByCode.data[0]?.file) {
      let file = prodByCode.data[0].file;
      const pdfResponse = await Axios.get(`${SERVER_URL}/product-pdf/${file}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const pathArr = file.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      URL.revokeObjectURL(url);
    } else {
      notification.error({
        message: 'Ne postoji PDF dokument vezan za ovaj proizvod.',
        placement: 'bottomRight',
      });
    }
  };

  const createPdfAdded = async (item, index) => {
    if (item.product[0].file) {
      const id = item.product[0].file;
      const pdfResponse = await Axios.get(`${SERVER_URL}/product-pdf/${id}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const pathArr = id.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  //////RAZVRSTAVANJE ROLNI U DVA NIZA JEDAN KOJI POKAZUJE ROLNE KOJE SU ODMA DODATE I DRUGI KOJI POKAZUJE ROLNE KOJE SU DODATE KAO VISAK
  let rollsReservedWorkOrderRegular = [];
  let rollsReservedWorkOrderExcess = [];

  if (
    rollsReservedWorkOrder &&
    rollsReservedWorkOrder.data &&
    rollsReservedWorkOrder.data.items &&
    rollsReservedWorkOrder.data.items.length > 0
  ) {
    for (const iterator of rollsReservedWorkOrder.data.items) {
      if (iterator.excessRoll === true) {
        rollsReservedWorkOrderExcess.push(iterator);
      } else if (iterator.excessRoll === false || !iterator.excessRoll) {
        rollsReservedWorkOrderRegular.push(iterator);
      }
    }
  }

  form.setFieldsValue({
    materialReservedExcess: rollsReservedWorkOrderExcess.map(
      (item) => `${item?.material?.code} - ${item?.material?.name}`,
    ),
    materialRollQuantityReservedExcess: rollsReservedWorkOrderExcess.map((item) => item.materialQuantity),
    goodMetersReservedExcess: rollsReservedWorkOrderExcess.map((item) => item.goodMeters),
    calcWasteReservedExcess: rollsReservedWorkOrderExcess.map((item) => item.calcWaste),
    rollIDExcess: rollsReservedWorkOrderExcess.map((item) => item.roll?.rollID),
  });

  form.setFieldsValue({
    materialReserved: rollsReservedWorkOrderRegular.map((item) => `${item.material?.code} - ${item.material?.name}`),
    materialRollQuantityReserved: rollsReservedWorkOrderRegular.map((item) => item.materialQuantity),
    goodMetersReserved: rollsReservedWorkOrderRegular.map((item) => item.goodMeters),
    calcWasteReserved: rollsReservedWorkOrderRegular.map((item) => item.calcWaste),
    rollID: rollsReservedWorkOrderRegular.map((item) => item.roll?.rollID),
  });

  const saveAsOrderToProduction = async () => {
    let formData = workOrder.data;
    // formData.tools = 'Postojeći';
    // formData.toolsIsCharging = 'NE';
    formData.plates = 'Postojeće';
    formData.printForms = 0;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.editedBy;
    formData.createdBy = `${currentuser.data.firstName} ${currentuser.data.lastName}`;
    formData.creationDate = moment();
    formData.creationDate = moment();
    delete formData.updatedAt;

    let newCirculation;

    if (
      !isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('1');
      newCirculation =
        parseFloat(modalForm.getFieldValue('firstProductQuantity')) +
        parseFloat(modalForm.getFieldValue('secondProductQuantity'));
    } else if (
      !isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('2');
      newCirculation = parseFloat(modalForm.getFieldValue('firstProductQuantity'));
    } else if (
      isNaN(parseFloat(modalForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('3');

      newCirculation = parseFloat(modalForm.getFieldValue('secondProductQuantity'));
    } else {
      // console.log('4', formData);

      newCirculation = parseFloat(formData.printing);
    }

    let oldCirculation = parseFloat(formData.printing);
    let totalWorkerTimesOldCir = 0;
    let timeForOnePiece;
    let productionTime = 0;
    let materialQuantityOld = parseFloat(formData.materialQuantity);
    let goodMetersOneForOnePiece = 0;
    let materialQuantitySecondOld = parseFloat(formData.materialQuantitySecond);
    let materialQuantityThirdOld = parseFloat(formData.materialQuantityThird);
    const workerTimes = await Axios.get(`${SERVER_URL}/worker-time-by-WO/${workOrder.data._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (workerTimes.data.length < 1) {
      notification.info({
        message:
          'Nalog nije zatvoren kroz plan proizvodnje. Podaci za vreme i dobre metre će se koristiti iz izabranog naloga.',
        duration: 2,
        placement: 'bottomRight',
      });

      productionTime = formData.productionTime;
    } else {
      ////PRERACUNAVANJE VREMENA ZA IZRADU NA OSNOVU REALIZOVANOG VREMENA OD NALOGA
      for (const iterator of workerTimes.data) {
        totalWorkerTimesOldCir += parseFloat(iterator.time);
      }

      timeForOnePiece = totalWorkerTimesOldCir / oldCirculation;

      productionTime = timeForOnePiece * newCirculation;

      productionTime = productionTime.toString();

      //productionTime --- ako nije ceo broj treba zaokruziti na pola sata
      if (productionTime.includes('.')) {
        if (productionTime.split('.')[1] !== '0') {
          // productionTime = JSON.stringify(productionTime);

          let afterDot = productionTime.split('.')[1];

          afterDot = parseFloat(afterDot);

          if (parseInt(afterDot) > 5) {
            let hourPart = parseInt(productionTime.split('.')[0]);
            hourPart++;

            productionTime = JSON.stringify(hourPart) + '.0';
          } else {
            let hourPart = productionTime.split('.')[0];

            productionTime = hourPart + '.5';
          }
        }
        productionTime = parseFloat(productionTime);
      }
    }

    // ////provera da li postoje dobri metri uneti za RN
    // if (formData.materialQuantity) {
    //   goodMetersOneForOnePiece = materialQuantityOld / oldCirculation;
    //   formData.materialQuantity = newCirculation goodMetersFirst* goodMetersOneForOnePiece;
    // } else if (formData.materialQuantitySecond) {
    //   goodMetersOneForOnePiece = materialQuantitySecondOld / oldCirculation;
    //   formData.materialQuantitySecond = newCirculation * goodMetersOneForOnePiece;
    // } else if (formData.materialQuantityThird) {
    //   goodMetersOneForOnePiece = materialQuantityThirdOld / oldCirculation;
    //   formData.materialQuantityThird = newCirculation * goodMetersOneForOnePiece;
    // }

    if (formData.goodMetersFirst) {
      formData.goodMetersFirst = Math.ceil(parseFloat(newCirculation / (formData.pieces * formData.rowsToCut)));
      formData.materialQuantity = parseFloat(formData.goodMetersFirst + formData.calculatedWastePaper);
    }

    if (
      formData.goodMetersSecond &&
      formData.additionalWorkOrderData &&
      formData.additionalWorkOrderData.length > 0 &&
      formData.additionalWorkOrderData[0].additionalPieces &&
      formData.additionalWorkOrderData[0].additionalRowsToCut &&
      formData.additionalWorkOrderData[0].additionalPieces.length > 0 &&
      formData.additionalWorkOrderData[0].additionalRowsToCut.length > 0
    ) {
      formData.goodMetersSecond = Math.ceil(
        parseFloat(
          newCirculation /
            (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[0]) *
              parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[0])),
        ),
      );
      formData.materialQuantitySecond = parseFloat(formData.goodMetersSecond + formData.calculatedWastePaper);
    }

    if (
      formData.goodMetersThird &&
      formData.additionalWorkOrderData &&
      formData.additionalWorkOrderData.length > 0 &&
      formData.additionalWorkOrderData[0].additionalPieces &&
      formData.additionalWorkOrderData[0].additionalRowsToCut &&
      formData.additionalWorkOrderData[0].additionalPieces.length > 1 &&
      formData.additionalWorkOrderData[0].additionalRowsToCut.length > 1
    ) {
      formData.goodMetersThird = Math.ceil(
        parseFloat(
          newCirculation /
            (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[1]) *
              parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[1])),
        ),
      );
      formData.materialQuantityThird = parseFloat(formData.goodMetersThird + formData.calculatedWastePaper);
    }

    try {
      formData.productCodeAdded = [];
      formData.productNameAdded = [];
      formData.orderToProductionStatus = 'kreiran';
      formData.workOrder = formData.code;
      formData.productionTime = productionTime;
      formData.graphics = 'Postojeća';
      formData.printingQuantity = newCirculation;
      let modalValues = modalForm.getFieldsValue();
      if (modalValues.boxLabeling) {
        formData.boxLabeling = modalValues.boxLabeling;
      }
      if (modalValues.deliveryDate) {
        formData.deliveryDate = modalValues.deliveryDate;
      }
      if (modalValues.graphics) {
        formData.graphics = modalValues.graphics;
      }
      if (modalValues.newCirculation) {
        formData.newCirculation = modalValues.newCirculation;
      }
      if (modalValues.newProductName) {
        formData.productName = modalValues.newProductName;
        formData.productCode = '';
        formData.newProduct = true;
        formData.workOrder = '';
      }
      if (modalValues.numberOfColors) {
        formData.numberOfColors = modalValues.numberOfColors;
      }
      if (modalValues.orderCode) {
        formData.orderCode = modalValues.orderCode;
      }
      if (modalValues.orderDate) {
        formData.orderDate = modalValues.orderDate;
      }
      if (modalValues.paletteLabeling) {
        formData.paletteLabeling = modalValues.paletteLabeling;
      }
      if (modalValues.plates) {
        formData.plates = modalValues.plates;
      }
      if (modalValues.printForms) {
        formData.printForms = modalValues.printForms;
      }
      if (modalValues.printing) {
        formData.printing = modalValues.printing;
      }
      if (modalValues.productSetout) {
        formData.productSetout = modalValues.productSetout;
      }
      if (modalValues.rollDiameter) {
        formData.rollDiameter = modalValues.rollDiameter;
      }
      if (modalValues.rollLabeling) {
        formData.rollLabeling = modalValues.rollLabeling;
      }
      if (modalValues.rollPiecesNum) {
        formData.rollPiecesNum = modalValues.rollPiecesNum;
      }
      if (modalValues.rollStacking) {
        formData.rollStacking = modalValues.rollStacking;
      }
      if (modalValues.unrollDirection) {
        formData.unrollDirection = modalValues.unrollDirection;
      }
      if (modalValues.unrollDirectionBack) {
        formData.unrollDirectionBack = modalValues.unrollDirectionBack;
      }
      if (modalValues.sendAuthentication) {
        formData.sendAuthentication = modalValues.sendAuthentication;
      } else {
        formData.sendAuthentication = false;
      }
      if (modalValues.sendAuthenticationMail) {
        formData.sendAuthenticationMail = modalValues.sendAuthenticationMail;
      } else {
        formData.sendAuthenticationMail = '';
      }
      if (modalValues.firstProductQuantity) {
        formData.firstProductQuantity = modalValues.firstProductQuantity;
      }
      if (modalValues.secondProductQuantity) {
        formData.secondProductQuantity = modalValues.secondProductQuantity;
      }

      ///sacuvaj ga kao npp
      const res = await Axios.post(`${SERVER_URL}/orders-to-productionClosedWO`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      ///proveri da li je imao dodatne proizvode
      const resp = await Axios.get(`${SERVER_URL}/products-in-workorder?workOrderId=${workOrder.data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let dataArr = [];
      if (resp && resp.data && resp.data.items) {
        for (const iterator of resp.data.items) {
          if (iterator.product && iterator.product.length > 0) {
            let data = { orderToProduction: res.data.ordertoproductionId, product: iterator?.product[0]?.productCode };
            dataArr.push(data);
          }
        }
      }
      if (dataArr) {
        //ako jeste dodaj ih u npp
        await Axios.post(`${SERVER_URL}/products-in-ordertoproduction`, dataArr, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      ///proveri da li ima rollsRes ako ima sacuvaj u NPP u polje za info
      const rr = await Axios.get(`${SERVER_URL}/rolls-reserved-byWorkOrder/${workOrder.data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let rollsResArr = [];
      if (rr && rr.data) {
        for (const iterator of rr.data) {
          iterator.orderToProduction = res.data.ordertoproductionId;
          iterator.workOrder = undefined;
          iterator._id = undefined;
          rollsResArr.push(iterator);
        }
      }

      let materialInfo = '';

      if (rollsResArr.length > 0) {
        for (const iterator of rollsResArr) {
          materialInfo =
            materialInfo +
            ` Materijal: ${iterator?.material?.name}` +
            ` Rolna: ${iterator.roll.rollID}` +
            ` Dobrih metara: ${iterator.goodMeters}` +
            ` Kalkulisana makulatura: ${iterator.calcWaste}, `;
        }
      }

      formData.materialInfoFromWO = materialInfo;
      formData.dontAddEditedBy = true;
      formData.creationDate = new Date();
      formData.code = res.data.item.code;

      delete formData._id;

      await Axios.put(`${SERVER_URL}/order-to-production-data/${res.data.item._id}`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Nalog proizvodnji je kreiran.`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.href = '/admin/orders-to-production';
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const saveAsOrderToProductionSales = async () => {
    let formData = workOrder.data;
    // formData.tools = 'Postojeći';
    // formData.toolsIsCharging = 'NE';
    formData.plates = 'Postojeće';
    formData.printForms = 0;
    delete formData.createdAt;
    delete formData.updatedAt;
    delete formData.editedBy;
    formData.createdBy = `${currentuser.data.firstName} ${currentuser.data.lastName}`;
    formData.creationDate = moment();
    formData.creationDate = moment();
    delete formData.updatedAt;

    let newCirculation;

    if (
      !isNaN(parseFloat(modalSalesForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalSalesForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('1');
      newCirculation =
        parseFloat(modalSalesForm.getFieldValue('firstProductQuantity')) +
        parseFloat(modalSalesForm.getFieldValue('secondProductQuantity'));
    } else if (
      !isNaN(parseFloat(modalSalesForm.getFieldValue('firstProductQuantity'))) &&
      isNaN(parseFloat(modalSalesForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('2');
      newCirculation = parseFloat(modalSalesForm.getFieldValue('firstProductQuantity'));
    } else if (
      isNaN(parseFloat(modalSalesForm.getFieldValue('firstProductQuantity'))) &&
      !isNaN(parseFloat(modalSalesForm.getFieldValue('secondProductQuantity')))
    ) {
      // console.log('3');

      newCirculation = parseFloat(modalSalesForm.getFieldValue('secondProductQuantity'));
    } else {
      // console.log('4', formData);

      newCirculation = parseFloat(formData.printing);
    }

    let oldCirculation = parseFloat(formData.printing);
    let totalWorkerTimesOldCir = 0;
    let timeForOnePiece;
    let productionTime = 0;
    let materialQuantityOld = parseFloat(formData.materialQuantity);
    let goodMetersOneForOnePiece = 0;
    let materialQuantitySecondOld = parseFloat(formData.materialQuantitySecond);
    let materialQuantityThirdOld = parseFloat(formData.materialQuantityThird);
    const workerTimes = await Axios.get(`${SERVER_URL}/worker-time-by-WO/${workOrder.data._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    ///provera da li je RN zatvoren ili odštampan
    if (workOrder.data.workOrderStatus === 'Odštampan' || workOrder.data.workOrderStatus === 'zatvoren') {
      //ukoliko jeste proveri da li je zatvoren kroz PP ili nije

      if (workerTimes.data.length < 1) {
        notification.info({
          message:
            'Nalog nije zatvoren kroz plan proizvodnje. Podaci za vreme i dobre metre će se koristiti iz izabranog naloga.',
          duration: 2,
          placement: 'bottomRight',
        });

        productionTime = formData.productionTime;
      } else {
        ////PRERACUNAVANJE VREMENA ZA IZRADU NA OSNOVU REALIZOVANOG VREMENA OD NALOGA
        for (const iterator of workerTimes.data) {
          totalWorkerTimesOldCir += parseFloat(iterator.time);
        }

        timeForOnePiece = totalWorkerTimesOldCir / oldCirculation;

        productionTime = timeForOnePiece * newCirculation;

        productionTime = productionTime.toString();

        //productionTime --- ako nije ceo broj treba zaokruziti na pola sata
        if (productionTime.includes('.')) {
          if (productionTime.split('.')[1] !== '0') {
            // productionTime = JSON.stringify(productionTime);

            let afterDot = productionTime.split('.')[1];

            afterDot = parseFloat(afterDot);

            if (parseInt(afterDot) > 5) {
              let hourPart = parseInt(productionTime.split('.')[0]);
              hourPart++;

              productionTime = JSON.stringify(hourPart) + '.0';
            } else {
              let hourPart = productionTime.split('.')[0];

              productionTime = hourPart + '.5';
            }
          }
          productionTime = parseFloat(productionTime);
        }
      }
    } else {
      //ukoliko nije gledaj novi tiraz i postojeće vreme
      productionTime = formData.productionTime;
      timeForOnePiece = productionTime / oldCirculation;

      productionTime = timeForOnePiece * newCirculation;

      productionTime = productionTime.toString();

      //productionTime --- ako nije ceo broj treba zaokruziti na pola sata
      if (productionTime.includes('.')) {
        if (productionTime.split('.')[1] !== '0') {
          // productionTime = JSON.stringify(productionTime);

          let afterDot = productionTime.split('.')[1];

          afterDot = parseFloat(afterDot);

          if (parseInt(afterDot) > 5) {
            let hourPart = parseInt(productionTime.split('.')[0]);
            hourPart++;

            productionTime = JSON.stringify(hourPart) + '.0';
          } else {
            let hourPart = productionTime.split('.')[0];

            productionTime = hourPart + '.5';
          }
        }
        productionTime = parseFloat(productionTime);
      }
    }

    // ////provera da li postoje dobri metri uneti za RN
    // if (formData.materialQuantity) {
    //   goodMetersOneForOnePiece = materialQuantityOld / oldCirculation;
    //   formData.materialQuantity = newCirculation * goodMetersOneForOnePiece;
    // } else if (formData.materialQuantitySecond) {
    //   goodMetersOneForOnePiece = materialQuantitySecondOld / oldCirculation;
    //   formData.materialQuantitySecond = newCirculation * goodMetersOneForOnePiece;
    // } else if (formData.materialQuantityThird) {
    //   goodMetersOneForOnePiece = materialQuantityThirdOld / oldCirculation;
    //   formData.materialQuantityThird = newCirculation * goodMetersOneForOnePiece;
    // }
    if (formData.goodMetersFirst) {
      formData.goodMetersFirst = Math.ceil(parseFloat(newCirculation / (formData.pieces * formData.rowsToCut)));
      formData.materialQuantity = parseFloat(formData.goodMetersFirst + formData.calculatedWastePaper);
    }

    if (
      formData.goodMetersSecond &&
      formData.additionalWorkOrderData &&
      formData.additionalWorkOrderData.length > 0 &&
      formData.additionalWorkOrderData[0].additionalPieces &&
      formData.additionalWorkOrderData[0].additionalRowsToCut &&
      formData.additionalWorkOrderData[0].additionalPieces.length > 0 &&
      formData.additionalWorkOrderData[0].additionalRowsToCut.length > 0
    ) {
      formData.goodMetersSecond = Math.ceil(
        parseFloat(
          newCirculation /
            (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[0]) *
              parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[0])),
        ),
      );
      formData.materialQuantitySecond = parseFloat(formData.goodMetersSecond + formData.calculatedWastePaper);
    }

    if (
      formData.goodMetersThird &&
      formData.additionalWorkOrderData &&
      formData.additionalWorkOrderData.length > 0 &&
      formData.additionalWorkOrderData[0].additionalPieces &&
      formData.additionalWorkOrderData[0].additionalRowsToCut &&
      formData.additionalWorkOrderData[0].additionalPieces.length > 1 &&
      formData.additionalWorkOrderData[0].additionalRowsToCut.length > 1
    ) {
      formData.goodMetersThird = Math.ceil(
        parseFloat(
          newCirculation /
            (parseFloat(formData.additionalWorkOrderData[0].additionalPieces[1]) *
              parseFloat(formData.additionalWorkOrderData[0].additionalRowsToCut[1])),
        ),
      );
      formData.materialQuantityThird = parseFloat(formData.goodMetersThird + formData.calculatedWastePaper);
    }

    try {
      formData.productCodeAdded = [];
      formData.productNameAdded = [];
      formData.orderToProductionStatus = 'kreiran';
      formData.workOrder = formData.code;
      formData.productionTime = productionTime;
      formData.graphics = 'Postojeća';
      formData.printingQuantity = newCirculation;
      let modalValues = modalSalesForm.getFieldsValue();
      if (modalValues.boxLabeling) {
        formData.boxLabeling = modalValues.boxLabeling;
      }
      if (modalValues.deliveryDate) {
        formData.deliveryDate = modalValues.deliveryDate;
      }
      if (modalValues.graphics) {
        formData.graphics = modalValues.graphics;
      }
      if (modalValues.newCirculation) {
        formData.newCirculation = modalValues.newCirculation;
      }
      if (modalValues.newProductName) {
        formData.productName = modalValues.newProductName;
        formData.productCode = '';
        formData.newProduct = true;
        formData.workOrder = '';
      }
      if (modalValues.numberOfColors) {
        formData.numberOfColors = modalValues.numberOfColors;
      }
      if (modalValues.orderCode) {
        formData.orderCode = modalValues.orderCode;
      }
      if (modalValues.orderDate) {
        formData.orderDate = modalValues.orderDate;
      }
      if (modalValues.paletteLabeling) {
        formData.paletteLabeling = modalValues.paletteLabeling;
      }
      if (modalValues.plates) {
        formData.plates = modalValues.plates;
      }
      if (modalValues.printForms) {
        formData.printForms = modalValues.printForms;
      }
      if (modalValues.printing) {
        formData.printing = modalValues.printing;
      }
      if (modalValues.productSetout) {
        formData.productSetout = modalValues.productSetout;
      }
      if (modalValues.rollDiameter) {
        formData.rollDiameter = modalValues.rollDiameter;
      }
      if (modalValues.rollLabeling) {
        formData.rollLabeling = modalValues.rollLabeling;
      }
      if (modalValues.rollPiecesNum) {
        formData.rollPiecesNum = modalValues.rollPiecesNum;
      }
      if (modalValues.rollStacking) {
        formData.rollStacking = modalValues.rollStacking;
      }
      if (modalValues.unrollDirection) {
        formData.unrollDirection = modalValues.unrollDirection;
      }
      if (modalValues.unrollDirectionBack) {
        formData.unrollDirectionBack = modalValues.unrollDirectionBack;
      }
      if (modalValues.sendAuthentication) {
        formData.sendAuthentication = modalValues.sendAuthentication;
      } else {
        formData.sendAuthentication = false;
      }
      if (modalValues.sendAuthenticationMail) {
        formData.sendAuthenticationMail = modalValues.sendAuthenticationMail;
      } else {
        formData.sendAuthenticationMail = '';
      }
      if (modalValues.firstProductQuantity) {
        formData.firstProductQuantity = modalValues.firstProductQuantity;
      }
      if (modalValues.secondProductQuantity) {
        formData.secondProductQuantity = modalValues.secondProductQuantity;
      }

      ///sacuvaj ga kao npp
      const res = await Axios.post(`${SERVER_URL}/orders-to-productionClosedWO`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      ///proveri da li je imao dodatne proizvode
      const resp = await Axios.get(`${SERVER_URL}/products-in-workorder?workOrderId=${workOrder.data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let dataArr = [];
      if (resp && resp.data && resp.data.items) {
        for (const iterator of resp.data.items) {
          if (iterator.product && iterator.product.length > 0) {
            let data = { orderToProduction: res.data.ordertoproductionId, product: iterator?.product[0]?.productCode };
            dataArr.push(data);
          }
        }
      }
      if (dataArr) {
        //ako jeste dodaj ih u npp
        await Axios.post(`${SERVER_URL}/products-in-ordertoproduction`, dataArr, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }

      ///proveri da li ima rollsRes ako ima sacuvaj u NPP u polje za info
      const rr = await Axios.get(`${SERVER_URL}/rolls-reserved-byWorkOrder/${workOrder.data._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let rollsResArr = [];
      if (rr && rr.data) {
        for (const iterator of rr.data) {
          iterator.orderToProduction = res.data.ordertoproductionId;
          iterator.workOrder = undefined;
          iterator._id = undefined;
          rollsResArr.push(iterator);
        }
      }

      let materialInfo = '';

      if (rollsResArr.length > 0) {
        for (const iterator of rollsResArr) {
          materialInfo =
            materialInfo +
            ` Materijal: ${iterator?.material?.name}` +
            ` Rolna: ${iterator.roll.rollID}` +
            ` Dobrih metara: ${iterator.goodMeters}` +
            ` Kalkulisana makulatura: ${iterator.calcWaste}, `;
        }
      }

      formData.materialInfoFromWO = materialInfo;
      formData.dontAddEditedBy = true;
      formData.code = res.data.item.code;
      delete formData._id;

      await Axios.put(`${SERVER_URL}/order-to-production-data/${res.data.item._id}`, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Nalog proizvodnji je kreiran.`,
        placement: 'bottomRight',
      });

      setTimeout(() => {
        window.location.href = '/admin/orders-to-production';
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const handleSendAuthenticationStatus = async () => {
    let formdata = form.getFieldsValue();

    formdata.workOrderAuthenticationStatus = 'Poslata na overu';

    const response = await Axios.put(`${SERVER_URL}/work-order-authstatus/${workOrder.data._id}`, formdata, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    notification.success({
      message: 'Radni nalog uspešno poslat na overu!',
      duration: 2,
      placement: 'bottomRight',
    });

    setTimeout(() => {
      window.location.href = '/admin/work-orders';
    }, 700);
  };

  const handleAuthenticationStatus = async () => {
    let formdata = form.getFieldsValue();

    // if (!formdata.platesOrder) {
    //   notification.error({
    //     message: 'Ne možete overiti, niste odabrali redosled boja.',
    //     duration: 2,
    //     placement: 'bottomRight',
    //   });
    //   return;
    // }

    // if (formdata.productCode && !itemFile) {
    //   notification.error({
    //     message: 'Ne možete overiti, niste dodali PDF za prvi proizvod.',
    //     duration: 2,
    //     placement: 'bottomRight',
    //   });
    //   return;
    // }

    // if (formdata.productCodeSecond !== undefined && !itemFileTwo) {
    //   notification.error({
    //     message: 'Ne možete overiti, niste dodali PDF za drugi proizvod.',
    //     duration: 2,
    //     placement: 'bottomRight',
    //   });
    //   return;
    // }

    // if (formdata.productCodeAdded) {
    //   if (formdata.productCodeAdded.length !== itemFileAdded.length) {
    //     notification.error({
    //       message: 'Ne možete overiti, niste dodali PDF za neki od dodatnij proizvoda.',
    //       duration: 2,
    //       placement: 'bottomRight',
    //     });
    //     return;
    //   }
    // }

    formdata.workOrderAuthenticationStatus = 'Overena';

    const response = await Axios.put(`${SERVER_URL}/work-order-authstatus/${workOrder.data._id}`, formdata, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    notification.success({
      message: 'Uspešno overen radni nalog!',
      duration: 2,
      placement: 'bottomRight',
    });

    setTimeout(() => {
      window.location.href = '/admin/work-orders';
    }, 700);
  };

  const [firstProductInitQuantity, setFirstProductInitQuantity] = useState(
    workOrder.data ? workOrder.data.firstProductQuantity : 0,
  );
  const [secondProductInitQuantity, setSecondProductInitQuantity] = useState(
    workOrder.data ? workOrder.data.secondProductQuantity : 0,
  );

  useEffect(() => {
    setFirstProductInitQuantity(workOrder.data ? workOrder.data.firstProductQuantity : 0);
    setSecondProductInitQuantity(workOrder.data ? workOrder.data.secondProductQuantity : 0);
  }, [workOrder]);

  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    let totalQuantity = 0;
    if (productsAddedAdditionaly.length > 0) {
      productsAddedAdditionaly.forEach((item) => {
        // console.log('item', item);
        totalQuantity += Number(item.productQuantity);
      });
      if (firstProductInitQuantity && !isNaN(firstProductInitQuantity)) {
        totalQuantity += firstProductInitQuantity;
      }
      if (secondProductInitQuantity && !isNaN(secondProductInitQuantity)) {
        totalQuantity += secondProductInitQuantity;
      }
      setTotalQuantity(totalQuantity);
    } else {
      if (firstProductInitQuantity && !isNaN(firstProductInitQuantity)) {
        totalQuantity += firstProductInitQuantity;
      }
      if (secondProductInitQuantity && !isNaN(secondProductInitQuantity)) {
        totalQuantity += secondProductInitQuantity;
      }
      setTotalQuantity(totalQuantity);
    }
  }, [firstProductInitQuantity, secondProductInitQuantity, productsAddedAdditionaly]);

  return (
    <div className='content-wrapper'>
      {currentuser.data.role.includes('admin') && (
        <div className='actions-block workOrderButton'>
          <Link to='/admin/work-orders'>
            <Button type='primary'>Svi radni nalozi</Button>
          </Link>
          {workOrder.data && workOrder.data.workOrderStatus !== 'zatvoren' && (
            <Link to={'/admin/edit-work-order/' + workOrderId}>
              <Button className='secondButton' type='primary' style={{ marginLeft: '10px' }}>
                Izmeni radni nalog
              </Button>
            </Link>
          )}
        </div>
      )}

      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card className='workOrderForm' title={`Radni nalog br ${workOrder.data ? workOrder.data.code : ''}`}>
            <Form className='form-horizontal' layout='horizontal' form={form}>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={12}>
                  <Form.Item label='Broj radnog naloga' name='code'>
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item label='Broj NPP-a' name='orderToProduction'>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={10}>
                  <Form.Item label='Mašina' name='machine'>
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item name='prevWorkOrderCode' label='Broj prethodnog radnog naloga'>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item label='Datum' name='creationDate'>
                    <DatePicker format={'DD-MM-YYYY'} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={10}>
                  <Form.Item label='Vreme za izradu' name='productionTime'>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalProductionTime &&
                    additionalProductionTime.map((item, index) => {
                      return (
                        <Form.Item
                          label={`Vreme za izradu ${index + 2}`}
                          name={`productionTime${index + 2}`}
                          key={index}
                          initialValue={item} // Set the initial value to the item in the additionalProductionTime array
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      );
                    })}
                </Col>
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                OSNOVNI PODACI O KUPCU I PROIZVODU
              </div>
              <Row>
                <Col md={3} xs={12}>
                  <Form.Item label='Datum narudžbe' name='orderDate' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <DatePicker disabled={true} format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item label='Rok isporuke' name='deliveryDate' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <DatePicker disabled={true} format={'DD-MM-YYYY'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Naziv i opis proizvoda'
                    name='productName'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea rows={2} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Šifra proizvoda'
                    name='productCode'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Tiraž'
                    name='firstProductQuantity'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Button
                    style={{ marginTop: '41px', height: '30px' }}
                    onClick={() => createPdf(form.getFieldValue('productCode'))}
                  >
                    OČITAJ PDF VEZAN ZA PROIZVOD
                  </Button>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Naziv i opis proizvoda 2'
                    name='productNameSecond'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea rows={2} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Šifra proizvoda 2'
                    name='productCodeSecond'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Tiraž'
                    name='secondProductQuantity'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Button
                    style={{ marginTop: '41px', height: '30px' }}
                    onClick={() => createPdfSecond(form.getFieldValue('productCodeSecond'))}
                  >
                    OČITAJ PDF VEZAN ZA PROIZVOD
                  </Button>
                </Col>
              </Row>
              {productsAddedAdditionaly &&
                productsAddedAdditionaly.length > 0 &&
                productsAddedAdditionaly.map((item, index) => {
                  return (
                    <div>
                      <Row className='mobileVersion' gutter={8}>
                        <Col md={8} xs={24}>
                          <Form.Item label='Naziv dodatnog proizvoda' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input value={`${item.productNameAdded}`} disabled></Input>
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item label='Šifra' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input value={`${item.productCodeAdded}`} disabled></Input>
                          </Form.Item>
                        </Col>
                        <Col md={2} xs={24}>
                          <Form.Item label='Tiraž' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input value={item.productQuantity} disabled></Input>
                          </Form.Item>
                        </Col>
                        {/* <Col md={4} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            onClick={() => removeAdditionalProduct(item.productCodeAdded, index)}
                            style={{ marginLeft: '20px', marginTop: '10px' }}
                            type='primary'
                            htmlType='button'
                          >
                            Ukloni
                          </Button>
                        </Col> */}
                      </Row>
                    </div>
                  );
                })}
              {(!productsAddedAdditionaly || productsAddedAdditionaly.length === 0) &&
                productsInWorkOrder.data?.items?.map(
                  (item, index) =>
                    item.product[0] && (
                      <div>
                        <Row className='mobileVersion' gutter={8}>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label='Naziv dodatnog proizvoda'
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input value={item.product[0].productName} disabled />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label='Šifra dodatnog proizvoda'
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Input value={item.product[0].productCode} disabled></Input>
                            </Form.Item>
                          </Col>
                          {item.product[0].file && (
                            <Col md={8} xs={24}>
                              <Button
                                style={{ marginTop: '41px', height: '30px' }}
                                onClick={() => createPdfAdded(item, index)}
                              >
                                OČITAJ PDF VEZAN ZA PROIZVOD
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ),
                )}
              <Row className='mobileVersion' gutter={8}>
                <Col md={8} xs={24}>
                  <Form.Item label='Kupac' name='companyName' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <TextArea rows={2} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                  <Form.Item label='Šifra kupca' name='companyID' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={12}>
                  <Form.Item label='Porudžbenica' name='orderCode' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={24}>
                  {/* {console.log(totalQuantity)} */}
                  {totalQuantity && totalQuantity > 0 ? (
                    <Form.Item
                      label='Tiraž za štampuu'
                      required
                      // name='printingQuantity'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input type='number' disabled value={totalQuantity || 0} />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label='Tiraž za štampu'
                      name='printingQuantity'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TextArea rows={2} disabled={true} />
                    </Form.Item>
                  )}
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Jedinice mere'
                    name='productUnits'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled style={{ width: 250 }} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Tiraž za isporuku'
                    name='printing'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Adresa za isporuku'
                    name='deliveryAddress'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Adresa za fakturisanje'
                    name='invoiceAddress'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Kontakt osoba'
                    name='contactPerson'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label='Telefon' name='phone' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label='Email' name='email' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                TEHNIČKI PODACI O PROIZVODU
              </div>
              <Row className='formatField' gutter={8} justify='center'>
                <Col md={12} xs={24}>
                  <Form.Item label='Format' name='format' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <TextArea rows={2} disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              {workOrder.data &&
                additionalFormat &&
                additionalFormat.map((item, index) => (
                  <Row className='formatField' gutter={8} justify='center' key={index}>
                    <Col md={12} xs={24}>
                      <Form.Item
                        label={`Format ${index + 2}`}
                        name={`format${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item}
                      >
                        <TextArea rows={2} disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              <Row className='mobileVersion' gutter={8}>
                <Col md={8} xs={12}>
                  <Form.Item
                    label='Obim cilindra/Z'
                    name='cilinderVolume'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalCilinderVolume &&
                    additionalCilinderVolume.map((item, index) => (
                      <Form.Item
                        label={`Obim cilindra/Z ${index + 2}`}
                        required
                        name={`cilinderVolume${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        key={index}
                        initialValue={item} // Set the initial value to the item in the additionalCilinderVolume array
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={8} xs={12}>
                  <Form.Item label='Broj kom u TM' name='pieces' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalPieces &&
                    additionalPieces.map((item, index) => (
                      <Form.Item
                        label={`Broj kom u TM ${index + 2}`}
                        name={`pieces${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        key={index}
                        initialValue={item} // Set the initial value to the item in the additionalPieces array
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Broj redova za odsecanje'
                    name='rowsToCut'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalRowsToCut &&
                    additionalRowsToCut.map((item, index) => (
                      <Form.Item
                        label={`Broj redova za odsecanje ${index + 2}`}
                        name={`rowsToCut${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalRowsToCut array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Broj kom u TM po SM'
                    name='piecesByMaterialWidth'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled style={{ width: '150px' }} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalPiecesByMaterialWidth &&
                    additionalPiecesByMaterialWidth.map((item, index) => (
                      <Form.Item
                        label={`Broj kom u TM po SM ${index + 2}`}
                        name={`piecesByMaterialWidth${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalPiecesByMaterialWidth array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                PRIPREMA
              </div>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Grafička priprema'
                    name='graphics'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label='Ploče' name='plates' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalPlates &&
                    additionalPlates.map((item, index) => (
                      <Form.Item
                        label={`Ploče ${index + 2}`}
                        name={`plates${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalPlates array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item label='Broj boja' name='numberOfColors' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalNumberOfColors &&
                    additionalNumberOfColors.map((item, index) => (
                      <Form.Item
                        label={`Broj boja ${index + 2}`}
                        required
                        name={`numberOfColors${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalNumberOfColors array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label='Štamparske forme: Naplaćuje se?'
                    name='printForms'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              {graphicEdits !== '' && (
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item
                      label='Izmene za grafičku'
                      name='graphicEdits'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row className='mobileVersion' gutter={8}>
                <Col flex={2} xs={24}>
                  <Form.Item
                    label='Redosled ploča'
                    name='platesOrder'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea rows={2} disabled={true} />
                  </Form.Item>
                </Col>
                {workOrder.data &&
                  additionalPlatesOrder &&
                  additionalPlatesOrder.map((item, index) => (
                    <Col flex={2} xs={24} key={index}>
                      <Form.Item
                        label={`Redosled ploča ${index + 2}`}
                        name={`platesOrder${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalPlatesOrder array
                        key={index}
                      >
                        <TextArea rows={2} disabled={true} />
                      </Form.Item>
                    </Col>
                  ))}
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={4} xs={12}>
                  <Form.Item label='Alat' name='tools' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalTools &&
                    additionalTools.map((item, index) => (
                      <Form.Item
                        label={`Alat ${index + 2}`}
                        name={`tools${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalTools array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item label='Oznaka alata' name='toolsID' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalToolsID &&
                    additionalToolsID.map((item, index) => (
                      <Form.Item
                        label={`Oznaka alata ${index + 2}`}
                        name={`toolsID${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalToolsID array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Oznaka kutije alata'
                    name='toolsBox'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalToolsBox &&
                    additionalToolsBox.map((item, index) => (
                      <Form.Item
                        label={`Oznaka kutije alata ${index + 2}`}
                        name={`toolsBox${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalToolsBox array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Naplaćuje se?'
                    name='toolsIsCharging'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalToolsIsCharging &&
                    additionalToolsIsCharging.map((item, index) => (
                      <Form.Item
                        label={`Naplaćuje se? ${index + 2}`}
                        name={`toolsIsCharging${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalToolsIsCharging array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
              </Row>

              <Row className='mobileVersion' gutter={8}>
                <Col md={4} xs={12}>
                  <Form.Item label='Lakiranje' name='lacquering' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalLacquering &&
                    additionalLacquering.map((item, index) => (
                      <Form.Item
                        label={`Lakiranje ${index + 2}`}
                        name={`lacquering${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalLacquering array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>

                <Col md={4} xs={12}>
                  <Form.Item label='Tip laka' name='lacquerTypes' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalLacquerTypes &&
                    additionalLacquerTypes.map((item, index) => (
                      <Form.Item
                        label={`Tip laka ${index + 2}`}
                        name={`lacquerTypes${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalLacquerTypes array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item label='Vrsta laka' name='varnishWay' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalVarnishWay &&
                    additionalVarnishWay.map((item, index) => (
                      <Form.Item
                        label={`Vrsta laka ${index + 2}`}
                        name={`varnishWay${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalVarnishWay array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    label='Pokrivenost laka'
                    name='varnishCoverage'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                  {workOrder.data &&
                    additionalVarnishCoverage &&
                    additionalVarnishCoverage.map((item, index) => (
                      <Form.Item
                        label={`Pokrivenost laka ${index + 2}`}
                        name={`varnishCoverage${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalVarnishCoverage array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={4} xs={12}>
                  <Form.Item label='Štampa' name='print' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                {/* <Col md={4} xs={12}>
                  <Form.Item
                    label='Kalkulisana makulatura'
                    name='calculatedWastePaper'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col> */}
              </Row>

              <Row className='mobileVersion' gutter={8}>
                {workOrder && workOrder.folioWay && (
                  <Col md={4} xs={12}>
                    <Form.Item
                      label='Plastifikacija/Foliotisak'
                      name='folioWay'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input disabled></Input>
                    </Form.Item>
                  </Col>
                )}
                {!hideFolioTypeWarmFT && (
                  <Col md={4} xs={12}>
                    <Form.Item
                      className='firstChildInRowFirstSectionANTD'
                      label='Izrada na mašini'
                      name='folioPrintingMachine'
                    >
                      <Select labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} disabled>
                        {machinesForPlasticization &&
                          machinesForPlasticization.length > 0 &&
                          machinesForPlasticization.map((item, index) => (
                            <Option key={index} value={item._id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {workOrder && workOrder.folioType && (
                  <Col md={4} xs={12}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className='firstChildInRowFirstSectionANTD'
                      label='Vrsta folije'
                      name='folioType'
                    >
                      <Select disabled dropdownStyle={{ minWidth: '50%' }}>
                        {folioTypes &&
                          folioTypes.length > 0 &&
                          folioTypes.map((item, index) => (
                            <Option value={item._id}>
                              {item.code} - {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {workOrder && workOrder.rollWidth && (
                  <Col md={4} xs={12}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className='firstChildInRowFirstSectionANTD'
                      label='Širina rolne (mm)'
                      name='rollWidth'
                    >
                      <Input disabled min='0' />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <div style={{ width: '100%' }} className='subtitle'>
                GOTOVA ROLNA
              </div>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={12}>
                  <Form.Item label='Hilzna' name='hilzna' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Promer hilzne'
                    name='promerHilzne'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                  {workOrder.data &&
                    additionalPromerHilzne &&
                    additionalPromerHilzne.map((item, index) => (
                      <Form.Item
                        label={`Promer hilzne ${index + 2}`}
                        name={`promerHilzne${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalPromerHilzne array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Maksimalan prečnik rolne'
                    name='rollDiameter'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                  {workOrder.data &&
                    additionalRollDiameter &&
                    additionalRollDiameter.map((item, index) => (
                      <Form.Item
                        label={`Maksimalan prečnik rolne ${index + 2}`}
                        name={`rollDiameter${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalRollDiameter array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Br. kom. na rol.'
                    name='rollPiecesNum'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled style={{ width: '150px' }} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalRollPiecesNum &&
                    additionalRollPiecesNum.map((item, index) => (
                      <Form.Item
                        label={`Br. kom. na rol. ${index + 2}`}
                        name={`rollPiecesNum${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalRollPiecesNum array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Dužina hilzne'
                    name='oneHilznaWidth'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Potreban broj hilzni'
                    name='hilzneQuantity'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={13} xs={12}>
                  <Form.Item
                    label='Redosled premotavanja:'
                    name='rewindingMachines'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextArea rows='1' disabled />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    label='Brojevi za premotavačicu V/M'
                    name='rollNum'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  {workOrder.data &&
                    additionalRollNum &&
                    additionalRollNum.map((item, index) => (
                      <Form.Item
                        label={`Brojevi za premotavačicu V/M ${index + 2}`}
                        name={`rollNum${index + 2}`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValue={item} // Set the initial value to the item in the additionalRollNum array
                        key={index}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    ))}
                </Col>
              </Row>

              <Row className='mobileVersion' gutter={8}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label='Smer odmotavanja prednje'
                    name='unrollDirection'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label='Smer odmotavanja zadnje'
                    name='unrollDirectionBack'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              {workOrder.data && additionalRollNum && (
                <Row className='mobileVersion' gutter={8}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label='Smer odmotavanja banderole'
                      name='unrollDirectionBanderola'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValue={workOrder.data.unrollDirectionBanderola}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {workOrder.data && additionalRollNum && (
                <Row className='mobileVersion' gutter={8}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      label='Smer odmotavanja banderole'
                      name='unrollDirectionBanderola'
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValue={workOrder.data.unrollDirectionBanderola}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Form.Item label='Napomena' name='note'>
                <TextArea rows={3} disabled={true} />
              </Form.Item>
              <Row gutter={8}>
                <Col flex={4}>
                  <Form.Item
                    label='Označavanje rolne'
                    name='rollLabeling'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item
                    label='Označavanje kutije'
                    name='boxLabeling'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={8}>
                <Col md={8} xs={15}>
                  <Form.Item
                    label='Slaganje rolni na paletu'
                    name='rollStacking'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={15}>
                  <Form.Item
                    label='Pakovanje i označavanje palete'
                    name='paletteLabeling'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={15}>
                  <Form.Item
                    label='Otprema robe'
                    name='productSetout'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                MATERIJAL ZA TREBOVANJE ZA ŠTAMPU
              </div>
              {rollsReservedWorkOrderRegular.map(
                (item, index) =>
                  item.material && (
                    <div>
                      <Row className='mobileVersion' gutter={8}>
                        <Col md={4} xs={12}>
                          <Form.Item
                            name={['materialReserved', index]}
                            label='Izabrani materijal'
                            // rules={[{ required: true, message: 'Molimo vas izaberite materijal!' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['rollID', index]}
                            label='Rolna'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['goodMetersReserved', index]}
                            label='Dobri metri'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['calcWasteReserved', index]}
                            label='Kalkulisana makulatura'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['materialRollQuantityReserved', index]}
                            label='Količina materijala'
                            // rules={[
                            //   {
                            //     validator: (rule, value) => {
                            //       return value > compareArr[index] + item.materialQuantity
                            //         ? Promise.reject('Prekoračili ste količinu materijala')
                            //         : Promise.resolve();
                            //     },
                            //   },
                            //   { required: true, message: 'Molimo unesite količinu materijala!' },
                            // ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
              )}

              <div style={{ width: '100%' }} className='subtitle'>
                MATERIJAL DODAT KAO VIŠAK ROLNA
              </div>
              {rollsReservedWorkOrderExcess.map(
                (item, index) =>
                  item.material && (
                    <div>
                      <Row className='mobileVersion' gutter={8}>
                        <Col md={4} xs={12}>
                          <Form.Item
                            name={['materialReservedExcess', index]}
                            label='Izabrani materijal'
                            // rules={[{ required: true, message: 'Molimo vas izaberite materijal!' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['rollIDExcess', index]}
                            label='Rolna'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['goodMetersReservedExcess', index]}
                            label='Dobri metri'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['calcWasteReservedExcess', index]}
                            label='Kalkulisana makulatura'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24}>
                          <Form.Item
                            name={['materialRollQuantityReservedExcess', index]}
                            label='Količina materijala'
                            // rules={[
                            //   {
                            //     validator: (rule, value) => {
                            //       return value > compareArr[index] + item.materialQuantity
                            //         ? Promise.reject('Prekoračili ste količinu materijala')
                            //         : Promise.resolve();
                            //     },
                            //   },
                            //   { required: true, message: 'Molimo unesite količinu materijala!' },
                            // ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ),
              )}
              <Row>
                <Col md={8} xs={12}>
                  <p>Materijal</p>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='materialName'
                    className='materialField'
                  >
                    <Input disabled placeholder='Vrsta materijala 1' />
                  </Form.Item>
                </Col>
                <Col md={7} xs={12}>
                  <p>Dimenzije</p>
                  <Form.Item name='materialDimension' className='materialField materialField1'>
                    <Input disabled placeholder='Dimenzije materijala 1' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <p>Dobri metri</p>
                  <Form.Item name='goodMetersFirst' className='materialField'>
                    <Input disabled placeholder='Dobri metri 1' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <p>Kalkulisana makulatura</p>
                  <Form.Item name='calculatedWastePaper' className='materialField materialField1'>
                    <Input disabled placeholder='Kalkulisana makulatura 1' />
                  </Form.Item>
                </Col>

                <Col md={3} xs={12}>
                  <p>Količina</p>
                  <Form.Item name='materialQuantity' className='materialField materialField1'>
                    <Input disabled placeholder='Količina materijala 1' />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={[0, 0]}>
                <Col md={8} xs={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='materialNameSecond'
                    className='materialFieldSecond'
                  >
                    <Input disabled placeholder='Vrsta materijala 2' />
                  </Form.Item>
                </Col>
                <Col md={7} xs={12}>
                  <Form.Item name='materialDimensionSecond' className='materialField'>
                    <Input disabled placeholder='Dimenzije materijala 2' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='goodMetersSecond' className='materialField'>
                    <Input disabled placeholder='Dobri metri 2' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='calculatedWastePaperSecond' className='materialField'>
                    <Input disabled placeholder='Kalkulisana makulatura 2' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='materialQuantitySecond' className='materialField'>
                    <Input disabled placeholder='Količina materijala 2' />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='mobileVersion' gutter={[0, 0]}>
                <Col md={8} xs={12}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name='materialNameThird'
                    className='materialField'
                  >
                    <Input disabled placeholder='Vrsta materijala 3' />
                  </Form.Item>
                </Col>
                <Col md={7} xs={12}>
                  <Form.Item name='materialDimensionThird' className='materialField'>
                    <Input disabled placeholder='Dimenzije materijala 3' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='goodMetersThird' className='materialField'>
                    <Input disabled placeholder='Dobri metri 3' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='calculatedWastePaperThird' className='materialField'>
                    <Input disabled placeholder='Kalkulisana makulatura 3' />
                  </Form.Item>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Item name='materialQuantityThird' className='materialField'>
                    <Input disabled placeholder='Količina materijala 3' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='firstColor' className='colorField'>
                    <Input placeholder='Boja 1:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='secondColor' className='colorField'>
                    <Input placeholder='Boja 2:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='thirdColor' className='colorField'>
                    <Input placeholder='Boja 3:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='fourthColor' className='colorField'>
                    <Input placeholder='Boja 4:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='fifthColor' className='colorField'>
                    <Input placeholder='Boja 5:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='sixthColor' className='colorField'>
                    <Input placeholder='Boja 6:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='seventhColor' className='colorField'>
                    <Input placeholder='Boja 7:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='eighthColor' className='colorField'>
                    <Input placeholder='Boja 8:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[0, 0]}>
                <Col span={12}>
                  <Form.Item name='lacquier'>
                    <Input placeholder='Lak:' disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                Detalji
              </div>
              <Row className='mobileVersion' gutter={8}>
                {workOrder.data && workOrder.data.workOrderStatus && workOrder.data.workOrderStatus !== 'zatvoren' ? (
                  <>
                    <Col md={4} xs={12}>
                      <Form.Item label='Nalog izdao' name='createdBy' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Input disabled={true} />
                      </Form.Item>
                      <Form.Item
                        label='Slanje na overu'
                        name='sendAuthentication'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select disabled>
                          <Option value={true}>DA</Option>
                          <Option value={false}>NE</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label='Poslednja izmena'
                        name='editedBy'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                      <Form.Item
                        label='Slanje na overu'
                        name='sendAuthenticationMail'
                        hidden={!form.getFieldValue('sendAuthentication')}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled></Input>
                      </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                      <Form.Item
                        label='Status'
                        name='workOrderStatus'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col md={4} xs={12}>
                      <Form.Item
                        label='Ukupna proizvedena količina'
                        name='finishedProduct'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={4}>
                      <Form.Item label='Nalog izdao' name='createdBy' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label='Status'
                        name='workOrderStatus'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        label='Napravljena količina'
                        name='finishedProduct'
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
              <div style={{ width: '100%' }} className='subtitle'>
                Operateri koji su radili na nalogu
              </div>

              <div style={{ width: '100%' }} className='subtitle'>
                Zatvaranje
              </div>
              {materialConsumptions?.data?.items?.map((item, index) => (
                <Row gutter={8}>
                  <Col>
                    <Form.Item
                      label='Operator'
                      name={['workersOnWo', index]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label='Vreme'
                      name={['workersOnWoTime', index]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <div style={{ width: '100%' }} className='subtitle'>
                Dorada
              </div>
              {reworkers?.data?.map(
                (item, index) =>
                  item.operator && (
                    <Row gutter={8}>
                      <Col>
                        <Form.Item
                          label='Operator 1'
                          name={['reworkers', index]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Vreme'
                          name={['reworkersCreatedAt', index]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Input disabled={true} />
                        </Form.Item>
                      </Col>
                    </Row>
                  ),
              )}
              <div style={{ marginTop: '10px' }} className='text-center'>
                {/* {currentuser.data.role.includes('sales') && ( */}
                <Button onClick={btnShowNPPModalSales} type='primary' htmlType='button'>
                  Sačuvaj kao nalog proizvodnji da proizvede
                </Button>
                {/* )} */}
              </div>
              <div style={{ marginTop: '10px' }} className='text-center'>
                {workOrder?.data?.workOrderStatus.includes('priprema') &&
                  (currentuser.data.role.includes('preparer') || currentuser.data.role.includes('superAdmin')) &&
                  workOrder?.data?.workOrderAuthenticationStatus === 'Potrebna overa' && (
                    <Button onClick={handleSendAuthenticationStatus} style={{ marginRight: '10px' }}>
                      Poslato na overu
                    </Button>
                  )}

                {form.getFieldValue('workOrderStatus')?.includes('priprema') &&
                  (currentuser.data.role.includes('preparer') || currentuser.data.role.includes('superAdmin')) &&
                  form.getFieldValue('workOrderAuthenticationStatus') === 'Poslata na overu' && (
                    <Button onClick={handleAuthenticationStatus} style={{ marginRight: '10px' }}>
                      Overeno
                    </Button>
                  )}
                <Button style={{ marginRight: '10px' }} onClick={btnGoBack} type='primary' htmlType='button'>
                  Zatvori
                </Button>
                <Button style={{ marginRight: '10px' }} onClick={btnShowModal} type='primary' htmlType='button'>
                  Odštampaj nalepnice
                </Button>
                {(workOrder?.data?.workOrderStatus === 'zatvoren' ||
                  workOrder?.data?.workOrderStatus === 'Odštampan') &&
                  !currentuser.data.role.includes('sales') && (
                    <Button onClick={btnShowNPPModal} type='primary' htmlType='button'>
                      Sačuvaj kao nalog proizvodnji da proizvede
                    </Button>
                  )}
              </div>
            </Form>
            <Modal
              visible={isModalVisible}
              title='Štampanje nalepnica za dostupne proizvode'
              onCancel={handleCancel}
              footer={[]}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Select
                  onChange={(e) => {
                    setSelectedProduct(e);
                  }}
                  style={{ width: '300px' }}
                >
                  {productsForStickers &&
                    productsForStickers &&
                    productsForStickers?.length > 0 &&
                    productsForStickers.map((prod, index) => (
                      <Option key={index} value={prod?.product[0]?._id}>
                        {prod?.product?.length > 0 ? prod?.product[0]?.productName : '-'}
                      </Option>
                    ))}
                </Select>
                <Button
                  style={{ width: '300px', marginTop: '10px' }}
                  onClick={() => {
                    let size = 'small';
                    btnPrintSticker({ size });
                  }}
                >
                  Odštampaj malu nalepnicu
                </Button>
                <Button
                  style={{ width: '300px', marginTop: '10px' }}
                  onClick={() => {
                    let size = 'big';
                    btnPrintSticker({ size });
                  }}
                >
                  Odštampaj veliku nalepnicu
                </Button>
              </div>
            </Modal>

            <Modal
              centered
              visible={isNPPModalVisible}
              title='Čuvanje radnog naloga kao nalog proizvodnji da prozivede'
              onCancel={handleNPPCancel}
              footer={[]}
            >
              <Form className='form-horizontal' layout='horizontal' form={modalForm}>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Naziv i opis proizvoda</label>
                    <Form.Item name='newProductName'>
                      <Input />
                    </Form.Item>
                  </div>

                  <div className='saveRNasNPPitem'>
                    <label>Tiraž za isporuku</label>
                    <Form.Item name='printing'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                </div>

                <div className='newNPPModalRow'>
                  {/* <div className='saveRNasNPPitem'>
                      <label>Tiraž</label>
                      <Form.Item name='newCirculation'>
                        <InputNumber min='0' />
                      </Form.Item>
                    </div> */}

                  <div className='saveRNasNPPitem'>
                    <label>Tiraž prvog proizvoda</label>
                    <Form.Item name='firstProductQuantity'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Tiraž drugog proizvoda</label>
                    <Form.Item name='secondProductQuantity'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Datum narudžbe</label>
                    <Form.Item name='orderDate'>
                      <DatePicker format={'DD-MM-YYYY'} />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Rok isporuke</label>
                    <Form.Item name='deliveryDate'>
                      <DatePicker format={'DD-MM-YYYY'} />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Porudžbenica</label>
                    <Form.Item name='orderCode'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Grafička priprema</label>
                    <Form.Item name='graphics'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Ploče</label>
                    <Form.Item name='plates'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Broj boja</label>
                    <Form.Item name='numberOfColors'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Štamparske forme: Naplaćuje se?</label>
                    <Form.Item name='printForms'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Maksimalan prečnik rolne</label>
                    <Form.Item name='rollDiameter'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Smer odmotavanja prednje</label>
                    <Form.Item name='unrollDirection'>
                      <Select dropdownStyle={{ minWidth: '500px' }}>
                        <Option value=''></Option>
                        {unrollDirectionArray.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Smer odmotavanja zadnje</label>
                    <Form.Item name='unrollDirectionBack'>
                      <Select dropdownStyle={{ minWidth: '500px' }}>
                        <Option value=''></Option>
                        {unrollDirectionArray.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Broj komada na rolni</label>
                    <Form.Item name='rollPiecesNum'>
                      <InputNumber min={0} />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Označavanje rolne</label>
                    <Form.Item name='rollLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Označavanje kutije</label>
                    <Form.Item name='boxLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Slaganje rolni na paletu</label>
                    <Form.Item name='rollStacking'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Pakovanje i označavanje palete</label>
                    <Form.Item name='paletteLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Otprema robe</label>
                    <Form.Item name='productSetout'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>

                <div className='newNPPModalRow'>
                  <Form.Item
                    label='Slanje na overu'
                    name='sendAuthentication'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      onChange={() => {
                        setHideSendAuthenticationMail(!modalForm.getFieldValue('sendAuthentication'));
                      }}
                    >
                      <Option value={true}>DA</Option>
                      <Option value={false}>NE</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Slanje na overu'
                    name='sendAuthenticationMail'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={hideSendAuthenticationMail}></Input>
                  </Form.Item>
                </div>

                <Button onClick={saveAsOrderToProduction} type='primary' htmlType='button'>
                  Sačuvaj kao nalog proizvodnji da proizvede
                </Button>
              </Form>
            </Modal>

            <Modal
              centered
              visible={isNPPModalSalesVisible}
              title='Čuvanje radnog naloga kao nalog proizvodnji da prozivede'
              onCancel={handleNPPSalesCancel}
              footer={[]}
            >
              <Form className='form-horizontal' layout='horizontal' form={modalSalesForm}>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Naziv i opis proizvoda</label>
                    <Form.Item name='newProductName'>
                      <Input />
                    </Form.Item>
                  </div>

                  <div className='saveRNasNPPitem'>
                    <label>Tiraž za isporuku</label>
                    <Form.Item name='printing'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                </div>

                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Tiraž prvog proizvoda</label>
                    <Form.Item name='firstProductQuantity'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Tiraž drugog proizvoda</label>
                    <Form.Item name='secondProductQuantity'>
                      <InputNumber min='0' />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Datum narudžbe</label>
                    <Form.Item name='orderDate'>
                      <DatePicker format={'DD-MM-YYYY'} />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Rok isporuke</label>
                    <Form.Item name='deliveryDate'>
                      <DatePicker format={'DD-MM-YYYY'} />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Porudžbenica</label>
                    <Form.Item name='orderCode'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Grafička priprema</label>
                    <Form.Item name='graphics'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Ploče</label>
                    <Form.Item name='plates'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Broj boja</label>
                    <Form.Item name='numberOfColors'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Štamparske forme: Naplaćuje se?</label>
                    <Form.Item name='printForms'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Maksimalan prečnik rolne</label>
                    <Form.Item name='rollDiameter'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Smer odmotavanja prednje</label>
                    <Form.Item name='unrollDirection'>
                      <Select dropdownStyle={{ minWidth: '500px' }}>
                        <Option value=''></Option>
                        {unrollDirectionArray.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Smer odmotavanja zadnje</label>
                    <Form.Item name='unrollDirectionBack'>
                      <Select dropdownStyle={{ minWidth: '500px' }}>
                        <Option value=''></Option>
                        {unrollDirectionArray.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Broj komada na rolni</label>
                    <Form.Item name='rollPiecesNum'>
                      <InputNumber min={0} />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Označavanje rolne</label>
                    <Form.Item name='rollLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Označavanje kutije</label>
                    <Form.Item name='boxLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Slaganje rolni na paletu</label>
                    <Form.Item name='rollStacking'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <div className='newNPPModalRow'>
                  <div className='saveRNasNPPitem'>
                    <label>Pakovanje i označavanje palete</label>
                    <Form.Item name='paletteLabeling'>
                      <Input />
                    </Form.Item>
                  </div>
                  <div className='saveRNasNPPitem'>
                    <label>Otprema robe</label>
                    <Form.Item name='productSetout'>
                      <Input />
                    </Form.Item>
                  </div>
                </div>

                <div className='newNPPModalRow'>
                  <Form.Item
                    label='Slanje na overu'
                    name='sendAuthentication'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Select
                      onChange={() => {
                        setHideSendAuthenticationMail(!modalSalesForm.getFieldValue('sendAuthentication'));
                      }}
                    >
                      <Option value={true}>DA</Option>
                      <Option value={false}>NE</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label='Slanje na overu'
                    name='sendAuthenticationMail'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input disabled={hideSendAuthenticationMail}></Input>
                  </Form.Item>
                </div>

                <Button onClick={saveAsOrderToProductionSales} type='primary' htmlType='button'>
                  Sačuvaj kao nalog proizvodnji da proizvede
                </Button>
              </Form>
            </Modal>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewWorkOrder;
