import React, { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ProductPDFForm from '../../components/forms/ProductPDFForm';

const EditPDF = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [product, fetchProduct] = useAxios('', [], currentuser.data.token, 'get');

  const onSubmit = async (formData, isNew) => {
    history.push('/admin/product-pdf');
  };

  return (
    <div style={{ padding: '8px' }}>
      <div>
        <ProductPDFForm
          isNew={false}
          data={product.data}
          onSubmit={onSubmit}
          SERVER_URL={SERVER_URL}
          token={currentuser.data.token}
        />
      </div>
    </div>
  );
};

export default EditPDF;
