import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ProductTable';
import { SERVER_URL } from '../../config';
import moment from 'moment';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'averagePrice',
  'family',
  'group',
  'barCode',
  'foreignName',
  'catalogueNumber',
  'species',
  'customTax',
  'act',
  'unitTreeunitTwo',
  'unitFourIn00',
  'unitFourIn000',
  'unitOneIn00',
  'purchasePrice',
  'max',
  'min',
  'weight',
  'type',
  'stack',
  'totalValue',
  'note',
  'manufacturer',
  'unitThreeTotal',
  'description',
  'unitFourTotal',
  'englishName',
  'supplier',
  'tax',
  'unitTwo',
  'unitOneIn000',
  'unitThreeIn00'
];

const Product = () => {
  const currentuser = useContext(UserContext);
  const [products, fetchProducts] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  useEffect(() => {
    fetchProducts(`${SERVER_URL}/products`, []);
  }, [fetchProducts]);

  const deleteProductHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/products/${id}`,
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${currentuser.data.token}`
          },
        });
      notification.success({
        message: 'Proizvod je obrisan.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/products');
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys;
  if (products.data && products.data.items && products.data.items.length > 0) {
    const keys = Object.keys(products.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    let alternativeNames = [];
    for (const columnKey of columnKeys) {
      if (columnKey === 'productCode') {
        alternativeNames.push('Šifra proizvoda');
      } else if (columnKey === 'productName') {
        alternativeNames.push('Naziv proizvoda');
      } else if (columnKey === 'quantity') {
        alternativeNames.push('jm (jedinica mere)');
      } else if (columnKey === 'createdAt') {
        alternativeNames.push('kreirano');
      } else if (columnKey === 'updatedAt') {
        alternativeNames.push('ažurirano');
      }
    }
    let keyArray = columnKeys;
    newColumnKeys = columnKeys.map((item, index) => ({
      originalName: keyArray[index],
      alternativeName: alternativeNames[index]
    }));
  }
  let tableData = [];
  if (products.data && products.data.items && products.data.items.length > 0) {
    tableData = products.data.items.map((item) => {
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      item.updatedAt = moment(item.updatedAt).format('MM/DD/YYYY, h:mm:ss A');
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <a href='/admin/new-product'>
          <Button type='primary'>Novi proizvod</Button>
        </a>
      </div>
      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {products.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {!products.isLoading && products.data.items && products.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteProductHandler}
            columnKeys={newColumnKeys}
            title='Proizvod'
            editPath='/admin/edit-product/'
          />
        )}
        {!products.isLoading && products.data.items && products.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );

};

export default Product;