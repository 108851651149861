import React, {useState} from 'react';
import { Select, Button, Form, DatePicker, Card, notification } from 'antd';
import moment from 'moment';
const { Option } = Select;


const formInit = {
  _id: null,
  machine: undefined,
  startTime: undefined,
  endTime: undefined,
};



const WorkTimeForm = ({ isNew, data, onSubmit, machines, addWorkTimeWholeYear, machineHandler }) => {
  const [startTimeState, setStartTimeState] = useState(moment().set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }));
  const [endTimeState, setEndTimeState] = useState(moment().set({ hours: 22, minutes: 0, seconds: 0, milliseconds: 0 }));
  const [form] = Form.useForm();

  let startTime = data ? moment(data.startTime) : moment().set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 });
  let endTime = data ? moment(data.endTime) : moment().set({ hours: new Date().getDay() > 0 && new Date().getDay() < 6 ? 22 : 14, minutes: 0, seconds: 0, milliseconds: 0 });
  let initialValues = { ...formInit, ...{ ...data, startTime, endTime } };

  const onFinish = async (values, isNew) => {
    onSubmit(values, isNew);
  };

  const createWholeYearTime = () => {
    if (form.getFieldValue('machine')) {
      addWorkTimeWholeYear(startTimeState, endTimeState);
    }
    else {
      notification.error({
        message: `Nije kreirano radno vreme za celu godinu.`,
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='dashboard'>
      <div className='card-wrapper'>
        <Card title={`${isNew ? 'NOVO RADNO VREME' : 'IZMENA RADNOG VREMENA'}`} bordered={false}>
          <Form initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='horizontal' form={form}>
            <div className='panel-body'>
              <Form.Item label='Početak' name='startTime'>
                <DatePicker onChange={(e) => {machineHandler(null, form); setStartTimeState(e)}} showTime={{ format: 'HH:mm' }} />
              </Form.Item>
              <Form.Item label='Završetak' name='endTime'>
                <DatePicker onChange={(e) => {machineHandler(null, form); setEndTimeState(e)}} showTime={{ format: 'HH:mm' }} />
              </Form.Item>
              <Form.Item label='Mašina' name='machine' rules={[{ required: true, whitespace: true, message: 'Molimo Vas izaberite mašinu!' }]}>
                <Select onChange={(value) => machineHandler(value, form)}>
                  {machines.items && machines.items.length > 0 && machines.items.map((item, index) => (
                    <Option key={index} value={item._id}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <div className='text-right'>
                {isNew && <Button style={{ marginRight: '10px' }} type='primary' onClick={createWholeYearTime}>Dodaj za celu godinu</Button>}
                <Button type='primary' htmlType='submit'>
                  {isNew ? 'Dodaj' : 'Izmeni'} radno vreme
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default WorkTimeForm;
