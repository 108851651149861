import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center', overflowX: 'auto', padding: '8px' }}>
      <h1>POČETNA</h1>
    </div>
  );
};

export default Home;
