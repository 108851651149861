import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/WorkOrderTable';
import moment from 'moment';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdAt',
  'updatedAt',
  'materialRollQuantity',
  'materialRoll',
  'internalCode',
  'productCode',
  'printing',
  'format',
  'plates',
  'graphics',
  'graphicEdits',
  'films',
  'numberOfColors',
  'platesOrder',
  'lacquering',
  'print',
  'lacquerTypes',
  'promerHilzne',
  'rollDiameter',
  'unrollDirection',
  'unrollDirectionBack',
  'rollLabeling',
  'boxLabeling',
  'rollStacking',
  'paletteLabeling',
  'productSetout',
  'materialName',
  'materialDimension',
  'materialQuantity',
  'firstColor',
  'secondColor',
  'thirdColor',
  'fourthColor',
  'fifthColor',
  'sixthColor',
  'seventhColor',
  'lacquier',
  'cilinderVolume',
  'pieces',
  'rowsToCut',
  'rollPiecesNum',
  'rollNum',
  'materialNameSecond',
  'materialDimensionSecond',
  'materialQuantitySecond',
  'materialNameThird',
  'materialDimensionThird',
  'materialQuantityThird',
  'printForms',
  'productCodeSecond',
  'productNameSecond',
  'tools',
  'processFinished',
  'note',
  'closedBy',
  'id',
  'operatorI',
  'operatorII',
  'operatorIII',
  'companyID',
  'productID',
  'creationDate',
  'orderCode',
  'printingQuantity',
  'orderDate',
  'deliveryAddress',
  'invoiceAddress',
  'deliveryDate',
  'createdAt',
  'updatedAt',
];

const WorkOrders = (props) => {
  const currentuser = useContext(UserContext);
  const [workOrders, fetchWorkOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [rollreserveds, fetchRollReserveds] = useAxios('', [], currentuser.data.token, 'get');
  useEffect(() => {
    fetchWorkOrders(`${SERVER_URL}/work-orders-analog`, []);
  }, [fetchWorkOrders, props]);

  useEffect(() => {
    fetchRollReserveds(`${SERVER_URL}/rolls-reserved/`, []);
  }, [fetchRollReserveds]);

  const deleteWorkOrderHandler = async (id) => {
    try {
      ///obrisati podatke o RN iz NPP-a ako je od tog RN kreiran NPP
      await Axios.put(
        `${SERVER_URL}/order-to-production-deleteWO/${id}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      await Axios.delete(`${SERVER_URL}/work-orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Radni nalog je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };
  const copyWorkOrderHandler = async (id) => {
    try {
      let data = {};
      if (id) {
        const response = await Axios.get(`${SERVER_URL}/work-orders/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        const latestWo = await Axios.get(
          `${SERVER_URL}/work-orders?sorter=${JSON.stringify({ _id: -1 })}&limit=${JSON.stringify(1)}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );

        data = response.data;
        delete data._id;
        delete data.editedBy;
        delete data.graphicEdits;
        delete data.closedBy;
        delete data.producedQuantity;
        data.creationDate = moment();
        data.orderDate = moment();
        data.deliveryDate = moment();
        data.workOrderStatus = 'kreiran';
        if (!latestWo.data.items[0].code.includes(`/${moment().format('YYYY').toString().substring(2)}`)) {
          data.code = `00001/${moment().format('YYYY').toString().substring(2)}`;
        } else {
          let codeArray = latestWo.data.items[0].code.split('/');
          let zeros = '';
          for (const digit of codeArray[0]) {
            if (parseInt(digit) !== 0) {
              break;
            }
            zeros += digit;
          }

          codeArray[0] = parseInt(codeArray[0]) + 1;
          let codePartOne = zeros + codeArray[0];

          if (codePartOne.length > 5) {
            codePartOne = codePartOne.substring(1);
          }

          data.code = `${codePartOne}/${codeArray[1]}`;
        }
      }

      let totalWorkerTimesOldCir = 0;

      let productionTime = 0;

      const workerTimes = await Axios.get(`${SERVER_URL}/worker-time-by-WO/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      data.machine = data.machine._id;

      if (workerTimes.data.length < 1) {
        notification.info({
          message:
            'Nalog nije zatvoren kroz plan proizvodnje. Podaci za vreme i dobre metre će se koristiti iz izabranog naloga.',
          duration: 2,
          placement: 'bottomRight',
        });

        productionTime = data.productionTime;
      } else {
        ////PRERACUNAVANJE VREMENA ZA IZRADU NA OSNOVU REALIZOVANOG VREMENA OD NALOGA
        for (const iterator of workerTimes.data) {
          totalWorkerTimesOldCir += parseFloat(iterator.time);
        }

        productionTime = totalWorkerTimesOldCir.toString();

        //productionTime --- ako nije ceo broj treba zaokruziti na pola sata
        if (productionTime.includes('.')) {
          if (productionTime.split('.')[1] !== '0') {
            // productionTime = JSON.stringify(productionTime);

            let afterDot = productionTime.split('.')[1];

            afterDot = parseFloat(afterDot);

            if (parseInt(afterDot) > 5) {
              let hourPart = parseInt(productionTime.split('.')[0]);
              hourPart++;

              productionTime = JSON.stringify(hourPart) + '.0';
            } else {
              let hourPart = productionTime.split('.')[0];

              productionTime = hourPart + '.5';
            }
          }
          productionTime = parseFloat(productionTime);
        }
      }

      data.productionTime = productionTime;

      await Axios.post(
        `${SERVER_URL}/work-orders`,
        { ...data, copied: true, idCopied: id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'Radni nalog je kopiran.',
        placement: 'bottomRight',
      });
      localStorage.removeItem('filter');
      setTimeout(() => {
        window.location.reload();
      }, 700);
    } catch (err) {
      notification.error({
        message: 'Problem sa kopiranjem. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  let newColumnKeys = [];
  if (workOrders.data.items && workOrders.data.items.length > 0) {
    const keys = Object.keys(workOrders.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    if (!columnKeys.includes('productionTime')) {
      columnKeys.push('productionTime');
    }
    if (!columnKeys.includes('editedBy')) {
      columnKeys.push('editedBy');
    }
    if (!columnKeys.includes('productName')) {
      columnKeys.push('productName');
    }

    let brRadnogNaloga, kupac, proizvod, masina, vremeZaIzradu, kreirao, izmenio, status;
    for (const columnKey of columnKeys) {
      if (columnKey === 'code') {
        brRadnogNaloga = {
          originalName: columnKey,
          alternativeName: 'br.',
        };
      } else if (columnKey === 'companyName') {
        kupac = {
          originalName: columnKey,
          alternativeName: 'naziv kupca',
        };
      } else if (columnKey === 'machine') {
        masina = {
          originalName: columnKey,
          alternativeName: 'mašina',
        };
      } else if (columnKey === 'productName') {
        proizvod = {
          originalName: columnKey,
          alternativeName: 'naziv proizvoda',
        };
      } else if (columnKey === 'workOrderStatus') {
        status = {
          originalName: columnKey,
          alternativeName: 'status',
        };
      } else if (columnKey === 'createdBy') {
        kreirao = {
          originalName: columnKey,
          alternativeName: 'nalog kreirao',
        };
      } else if (columnKey === 'editedBy') {
        izmenio = {
          originalName: columnKey,
          alternativeName: 'nalog izmenio',
        };
      } else if (columnKey === 'productionTime') {
        vremeZaIzradu = {
          originalName: columnKey,
          alternativeName: 'vreme za izradu (sati)',
        };
      }
    }
    newColumnKeys.push(brRadnogNaloga, kupac, proizvod, masina, vremeZaIzradu, kreirao, izmenio, status);
  }
  let tableData = [];

  if (workOrders.data.items && workOrders.data.items.length > 0) {
    tableData = workOrders.data.items.map((item) => {
      item.creationDate = new Date(item.creationDate).toLocaleString();
      item.orderDate = new Date(item.orderDate).toLocaleString();
      item.deliveryDate = new Date(item.deliveryDate).toLocaleString();
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.machine && item.machine.name) {
        item.machine = item.machine.name;
      }

      if (item && item.productionTime) {
        if (!isNaN(parseFloat(item.productionTime).toFixed(1))) {
          item.productionTime = parseFloat(item.productionTime).toFixed(1);
        }
      }

      return item;
    });
  }

  const createPdf = async (id) => {
	try{
    const response = await Axios.post(
      `${SERVER_URL}/create-pdf/${id}`,
      {},
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}`, 'Content-Type': 'application/json' },
      },
    );
    if (response.data.filename) {
      const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf?filename=${response.data.filename}`, {
        responseType: 'arraybuffer',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const pathArr = response.data.filename.split('\\');
      const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
      let a = document.createElement('a');
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = pathArr[pathArr.length - 1];
      a.click();
      window.URL.revokeObjectURL(url);
    }
}catch(err){
	console.log(err.response);
  notification.error({
	message:err.response.status === 409 ? err.response.data.message : 'Problem sa kreiranjem PDF-a. Pokušajte kasnije.',
	placement: 'bottomRight',
  });
}
  };

  return (
    <div style={{ padding: '8px' }}>
      {currentuser.data.role.some((r) => ['admin', 'superAdmin'].includes(r)) && props.value !== 'npp' && (
        <div className='actions-block'>
          <a href='/admin/new-work-order'>
            <Button type='primary'>Novi radni nalog</Button>
          </a>
        </div>
      )}

      <div style={{ textAlign: 'center', overflowX: 'auto' }}>
        {workOrders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!workOrders.isLoading && workOrders.data.items && workOrders.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteWorkOrderHandler}
            copyHandler={copyWorkOrderHandler}
            columnKeys={newColumnKeys}
            createPdf={createPdf}
            title='radni nalog'
            value={props.value}
            editPath='/admin/edit-work-order/'
            viewPath='/admin/view-work-order/'
          />
        )}
        {!workOrders.isLoading && workOrders.data.items && workOrders.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default WorkOrders;
