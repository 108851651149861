import React, { useContext } from 'react';
import { Form, Button, Card, Select, Input, Upload, message, DatePicker, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import Axios from 'axios';
import { UserContext } from '../../App';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initValues = {
  box: undefined,
  dimension: undefined,
  numRows: undefined,
  numItemInTM: undefined,
  numTeeths: undefined,
  omimInch: undefined,
  shapeDesc: undefined,
  product: undefined,
  companyName: undefined,
  material: undefined,
  toolID: undefined,
  date: moment(),
  amount: undefined,
  note: undefined,
};

const ToolForm = ({ data, updateHandler, createHandler, result, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let initialValues = data ? {
    ...data,
    date: moment(data.date)
  } : initValues;
  const isNew = data ? false : true;
  const currentuser = useContext(UserContext);


  const onFinish = async (values) => {
    let pdf;
    if (values.file && values.file.file) {
      let filter = { originalname: values.file.file.name };
      pdf = await Axios.get(`${SERVER_URL}/pdfs/?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (pdf && pdf.data.count > 0) {
        values.file = pdf.data.items[pdf.data.items.length - 1]._id;
      } else {
        delete values.file;
      }
    }

    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NOVI ALAT' : 'IZMENA ALATA'}`} bordered={false}>
            {!result && (
              <Form
                name='basic'
                initialValues={initialValues}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item
                  label='Kutija'
                  name='box'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Dimenzije'
                  name='dimension'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Broj redova'
                  name='numRows'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Broj kom u TM'
                  name='numItemInTM'
                  style={{ marginLeft: '11px' }}
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Broj zubaca'
                  name='numTeeths'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='OBIM u inčima'
                  name='omimInch'
                  style={{ marginLeft: '6px' }}
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Širina materijala'
                  name='materialWidth'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Opis oblika'
                  name='shapeDesc'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Za koji proizvod'
                  name='product'
                  style={{ marginLeft: '3px' }}
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Kupac'
                  name='companyName'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Za koji materijal'
                  name='material'
                  style={{ marginLeft: '2px' }}
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Broj alata'
                  name='toolID'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Datum izrade'
                  name='date'
                  className='creationDate'
                  style={{ marginLeft: '16px' }}
                >
                  <DatePicker format={'DD-MM-YYYY'} disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Broj komada'
                  name='amount'
                  style={{ marginLeft: '22px' }}
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>

                <Form.Item
                  label='Napomena'
                  name='note'
                  className='toolField'
                >
                  <Input disabled={currentuser.data.role.includes('preparer')} />
                </Form.Item>


                <Form.Item
                  label='Tehnički crtež'
                  name='file'
                  style={{ marginLeft: '16px' }}
                >

                  <Upload
                    action={`${SERVER_URL}/upload-pdf`}
                    accept='.pdf'
                    name='pdf-file'
                    headers={{
                      // type: fileType,
                      Authorization: `Bearer ${token}`,
                      uri: 'static/pdf/',
                      timestamp: Date.now().toString(),
                    }}
                    // fileList={fileList}
                    beforeUpload={(file) => {

                      const isAllowedFormat = ['application/pdf'].includes(file.type);
                      if (!isAllowedFormat) {
                        notification.error({
                          message: 'Ovaj fajl nije pdf!',
                          placement: 'bottomRight',
                        });
                      }
                      return isAllowedFormat;
                    }}
                    //   const isAllowedFileNumber = fileList.length === 0;
                    //   if (!isAllowedFileNumber) {
                    //     notification.error({
                    //       message: 'Only one file can be uploaded',
                    //       placement: 'bottomRight',
                    //     });
                    //   }
                    //   return isAllowedFileNumber && isAllowedFormat;
                    // }}
                    onChange={({ file }) => {
                      if (file.status === 'removed') {
                        form.setFieldsValue({ file: undefined });
                      }
                      // onChangeHandler(file, index);
                      // if (file.status === 'done') {
                      //   if (file.response && file.response.file) {
                      //     message.success(`${file.response.file.originalname} file uploaded successfully.`);
                      //   }
                      // } else if (file.status === 'error') {
                      //   message.error(`${file.response.file.originalname} file upload failed.`);
                      // }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    {isNew ? 'Dodaj' : 'Izmeni'} alat
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ToolForm;
