import React, { useEffect, useContext, useState } from 'react';
import { Button, Card, Row, Col, Input, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { useHistory } from 'react-router-dom';

const ViewRoll = (props) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [roll, setRoll] = useState();
  const [storagePosition, setStoragePosition] = useState();
  const [newMeasure, setNewMeasure] = useState();
  const [comment, setComment] = useState('');
  const { rollID } = props.match.params;
  const currentuser = useContext(UserContext);
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [rolls, fetchRolls] = useAxios('', [], currentuser.data.token, 'get');
  const [materialConsumption, fetchMaterialConsumption] = useAxios('', [], currentuser.data.token, 'get');

  const filter = {
    _id: rollID,
  };
  const filter2 = {
    rollID,
  };

  const filter3 = {
    roll: rollID,
  };

  const { TextArea } = Input;

  const verifyRoll = async () => {
    let status;
    let statusNum;

    try {
      if (!roll) {
        notification.error({ message: 'Rolna nije pronađena.', placement: 'bottomRight' });
        return;
      }
      if (roll.status === 'Potrošena') {
        notification.error({ message: 'Nije moguće verifikovati rolnu koja je potrošena.', placement: 'bottomRight' });
        return;
      }
      if (roll.status === 'U magacinu' || roll.status === 'U proizvodnji') {
        notification.error({
          message: 'Nije moguće verifikovati rolnu koja ne čeka verifikaciju.',
          placement: 'bottomRight',
        });
        return;
      }
      if (!currentuser.data.role.includes('storekeeper') && !currentuser.data.role.includes('superAdmin')) {
        notification.error({ message: 'Samo magacioner može da verifikuje rolnu.', placement: 'bottomRight' });
        return;
      }
      if (roll.status === 'Čeka verifikaciju(iz proizvodnje)') {
        status = 'U magacinu';
        statusNum = 1;
      } else if (roll.status === 'Čeka verifikaciju(iz magacina)') {
        status = 'U proizvodnji';
        statusNum = 3;
      }

      if (roll.status === 'Čeka verifikaciju(iz magacina)') {
        if (newMeasure) {
          notification.info({
            message: 'Prilikom verifikacije iz magacina količina se ne meri.',
            placement: 'bottomRight',
          });
        }
        setNewMeasure(0);
      }

      ///update pozicije u magacinu prilikom verifikacije
      await Axios.put(
        `${SERVER_URL}/rolls/${roll._id}`,
        {
          storagePosition,
        },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        },
      );

      await Axios.put(
        `${SERVER_URL}/verify-roll/${roll._id}`,
        {
          newMeasure: newMeasure,
          roll: roll,
          status,
          statusNum,
        },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        },
      );
      ///provera ako je unesen broj manji od fizickog stanja ili ukoliko je veci

      if (newMeasure > rollData[0].weight) {
        ///treba povecati stanje
        let firstMC = materialConsumption.data.items[0];
        let spentQuantity = rollData[0].weight - newMeasure;
        let lastRR = rollsReserved.data.items[rollsReserved.data.items.length - 1];

        await Axios.post(
          `${SERVER_URL}/material-consumption`,
          {
            MaterialID: firstMC.MaterialID,
            measuredQuantity: newMeasure,
            spentQuantity,
            RollReservedID: firstMC.RollReservedID,
            operator: firstMC.operator,
            workOrder: firstMC.workOrder,
            rollID,
            comment,
            verification: true,
          },
          {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${user.data.token}`,
            },
          },
        );
      } else if (newMeasure < rollData[0].weight) {
        ///treba smanjiti stanje
        ///treba sacuvati novu upotrebu
        ///treba izvuci poslednji wo za ovu rolnu u materialCOnsumption
        let firstMC = materialConsumption.data.items[0];
        let spentQuantity = rollData[0].weight - newMeasure;
        let lastRR = rollsReserved.data.items[rollsReserved.data.items.length - 1];

        await Axios.post(
          `${SERVER_URL}/material-consumption`,
          {
            MaterialID: firstMC.MaterialID,
            measuredQuantity: newMeasure,
            RollReservedID: firstMC.RollReservedID,
            spentQuantity,
            operator: firstMC.operator,
            workOrder: firstMC.workOrder,
            rollID,
            comment,
            verification: true,
          },
          {
            withCredentials: false,
            headers: {
              Authorization: `Bearer ${user.data.token}`,
            },
          },
        );
      }

      notification.success({ message: 'Rolna je uspešno verifikovana.', placement: 'bottomRight' });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('error', error);
      notification.info({ message: 'Rolna je već verifikovana.', placement: 'bottomRight' });
    }
  };

  const changeStoragePosition = async () => {
    try {
      ///update pozicije u magacinu prilikom verifikacije
      await Axios.put(
        `${SERVER_URL}/roll-position/${roll._id}`,
        {
          storagePosition,
        },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${user.data.token}`,
          },
        },
      );

      notification.success({ message: 'Uspešno promenjena lokacija.', placement: 'bottomRight' });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log('error', error);
      notification.info({ message: 'Došlo je do greške pri promeni lokacije.', placement: 'bottomRight' });
    }
  };

  useEffect(() => {
    if (rolls?.data?.items?.length > 0) {
      setRoll(rolls.data.items[0]);
    }
  });

  useEffect(() => {
    if (rollID) {
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter3)}`);

      fetchRolls(`${SERVER_URL}/rolls?filter=${JSON.stringify(filter)}`);
      fetchMaterialConsumption(`${SERVER_URL}/material-consumption?filter=${JSON.stringify(filter2)}`);
      // fetchProductionProcess(`${SERVER_URL}/production-process`, []);
    }
  }, [rollID, fetchRollsReserved, fetchRolls, fetchMaterialConsumption]);

  let rollData = [];
  let reservedLength;
  let reservedWeight;
  let spentByWO;
  let totalSpent;
  let toReduce;
  let consumptionArray = [];

  if (
    rolls &&
    rolls.data &&
    rolls.data.items &&
    materialConsumption &&
    materialConsumption.data &&
    materialConsumption.data.items &&
    rollsReserved &&
    rollsReserved.data &&
    rollsReserved.data.items
  ) {
    let status;
    for (const roll of rolls.data.items) {
      reservedWeight = 0;
      // supply = 0;
      reservedLength = 0;
      spentByWO = 0;
      toReduce = 0;
      totalSpent = 0;

      let workOrderCodes = [];
      let reservedCodes = [];
      consumptionArray = [];
      let resLength = 0;
      let resWeight = 0;
      status = roll.status;
      if (roll && roll.rollsReserved) {
        for (const rollReservedInRoll of roll.rollsReserved) {
          if (rollReservedInRoll && rollReservedInRoll.status === 'active') {
            resLength += rollReservedInRoll.materialQuantity;

            consumptionArray.push({
              workOrder: rollReservedInRoll?.workOrder?.code,
              reservedKG:
                roll.material.calculationMethod === 't*1000/p/s*1000'
                  ? (resLength * roll.material.specificWeight * roll.material.width) / 1000000
                  : ((resLength * roll.material.width) / 1000) * (roll.initialWeight / roll.initialState),
            });

            resWeight +=
              roll.material.calculationMethod === 't*1000/p/s*1000'
                ? (resLength * roll.material.specificWeight * roll.material.width) / 1000000
                : ((resLength * roll.material.width) / 1000) * (roll.initialWeight / roll.initialState);
          }
          for (const rollReserved of rollsReserved.data.items) {
            spentByWO = 0;
            if (rollReserved?._id === rollReservedInRoll?._id) {
              for (const matCons of materialConsumption.data.items) {
                if (matCons?.RollReservedID === rollReserved?._id) {
                  if (matCons?.workOrder === rollReserved?.workOrder?._id) {
                    spentByWO += matCons?.spentQuantity;
                    totalSpent += matCons?.spentQuantity;
                  } else {
                    workOrderCodes.push(`${rollReserved?.workOrder?.code} (${spentByWO.toFixed(2)}kg) `);
                    consumptionArray.push({
                      workOrder: rollReserved?.workOrder?.code,
                      spentKG: spentByWO.toFixed(2),
                    });
                  }
                }
              }

              workOrderCodes.push(`${rollReserved?.workOrder?.code} (${spentByWO.toFixed(2)}kg) `);
              consumptionArray.push({ workOrder: rollReserved?.workOrder?.code, spentKG: spentByWO.toFixed(2) });
              if (rollReserved?.status === 'active') {
                reservedLength = rollReserved.materialQuantity;
                reservedWeight =
                  rollReserved.material.calculationMethod === 't*1000/p/s*1000'
                    ? (reservedLength * rollReserved.material.specificWeight * rollReserved.material.width) / 1000000
                    : ((reservedLength * rollReserved.material.width) / 1000) *
                      (roll.initialWeight / roll.initialState);
                reservedCodes.push(
                  ` ${rollReserved?.workOrder?.code} (${reservedLength}m / ${reservedWeight.toFixed(2)}kg)`,
                );
              }
            }
          }
        }
      }

      //helper funkcija za racunanje potrosnje i rezervisanog stanja
      if (consumptionArray && consumptionArray.length > 0) {
        const uniqueWorkOrders = consumptionArray
          .map((item) => item.workOrder)
          .filter((value, index, self) => self.indexOf(value) === index);
        consumptionArray = uniqueWorkOrders.map((workOrder) => {
          return consumptionArray
            .filter((item) => item.workOrder === workOrder)
            .reduce((acc, item) => {
              if (!acc.workOrder) {
                acc.workOrder = workOrder;
                acc.reservedKG = 0;
                acc.spentKG = 0;
              }

              if (item.reservedKG) {
                acc.reservedKG += parseFloat(item.reservedKG);
              }
              if (item.spentKG) {
                acc.spentKG += parseFloat(item.spentKG);
              }

              return acc;
            }, {});
        });

        for (let i = 0; i < consumptionArray.length; i++) {
          if (consumptionArray[i].spentKG > consumptionArray[i].reservedKG) {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].spentKG,
            };
          } else if (consumptionArray[i].spentKG < consumptionArray[i].reservedKG) {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].reservedKG,
            };
          } else {
            consumptionArray[i] = {
              ...consumptionArray[i],
              toReduce: consumptionArray[i].reservedKG,
            };
          }
        }

        for (let i = 0; i < consumptionArray.length; i++) {
          toReduce += consumptionArray[i].toReduce;
        }
      }

      rollData.push({
        materialName: roll.material.name,
        type: roll.material.type,
        storagePosition: roll.storagePosition,
        code: roll.material.code,
        weight: roll.weight,
        toReduce,
        width: roll.material.width,
        specificWeight: roll.material.specificWeight,
        rollId: roll.rollID,
        workOrderCodes: workOrderCodes,
        reservedCodes: reservedCodes,
        QRCode: roll.QRCode,
        length: roll?.length?.toFixed(0),
        reservedLength: resLength?.toFixed(0),
        reservedWeight: resWeight?.toFixed(2),
        initialLength: roll.initialLength.toFixed(0),
        initialWeight: roll.initialWeight.toFixed(2),
        initialState: roll.initialState.toFixed(2),
        status: status,
      });
    }
  }
  if (rollData) {
    rollData.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (a.createdAt > b.createdAt) {
        return -1;
      }
    });
  }

  return (
    <>
      {rollData[0]?.status !== 'U magacinu' && (
        <div className='dashboard'>
          <div className='card-wrapper'>
            <Card title='PREGLED ROLNE' bordered={false}>
              <Row
                gutter={16}
                style={{
                  backgroundColor: '#216c97',
                  margin: '10px 0',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              >
                {/* <Col span={12}></Col>
            <Col span={12} className='text-right'>QR</Col> */}{' '}
              </Row>
              {rollData &&
                rollData.length > 0 &&
                rollData.map((item, index) => (
                  <Row key={index} gutter={16} className='rollReserved2'>
                    <Col span={12}>
                      <p>Naziv: {item?.materialName}</p>
                      <p>Šifra: {item?.code}</p>
                      <p>Pozicija u magacinu: {item?.storagePosition}</p>
                      <p>ID rolne: {item?.rollId}</p>
                      <p>
                        Stanje na ulazu: {item?.initialLength}m ({item?.initialWeight}kg)
                      </p>
                      <p>
                        Fizičko stanje: {item?.length}m ({item?.weight?.toFixed(2)}kg)
                      </p>
                      <p>Rezervisano: {item?.reservedCodes?.length > 0 ? `${item?.reservedCodes}` : '0m (0kg)'}</p>
                      <p>
                        Dostupno stanje:
                        {item?.type === 'kg' &&
                          `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                        {item?.type === 'm2' &&
                          `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                      </p>
                      <p>
                        Upotreba po radnim nalozima:
                        {item?.workOrderCodes?.length > 0
                          ? item?.workOrderCodes?.join(', ')
                          : 'Rolna još nije korištena'}
                      </p>
                      <p>Status rolne: {item?.status === 'spent' ? 'Potrošena' : item?.status}</p>
                    </Col>
                    <Col span={12} className='text-right'>
                      <img src={item?.QRCode ? item?.QRCode : ''} />
                    </Col>
                  </Row>
                ))}

              <Row
                gutter={16}
                style={{
                  backgroundColor: '#216c97',
                  margin: '10px -15px 10px -15px',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              >
                <Col span={12}>Verifikacija Rolne</Col>
              </Row>
              <div className='container'>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    style={{
                      width: '100%',

                      height: '40px',
                      marginRight: '5px',
                    }}
                    placeholder='Pozicija u magacinu'
                    onChange={(e) => setStoragePosition(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    marginTop: '15px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <TextArea
                    style={{
                      width: '100%',

                      height: '40px',
                      marginRight: '5px',
                    }}
                    placeholder='Komentar'
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    marginTop: '15px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    style={{
                      width: '100%',
                      // maxWidth: '30%',
                      height: '40px',
                      marginRight: '5px',
                    }}
                    placeholder='Izmerena kolicina'
                    onChange={(e) => setNewMeasure(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    marginTop: '15px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    style={{
                      width: '100%',
                    }}
                    type='primary'
                    onClick={() => verifyRoll()}
                  >
                    Verifikuj
                  </Button>
                </div>
              </div>
              <div
                style={{
                  height: '100%',
                  marginTop: '15px',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                  }}
                  type='primary'
                  onClick={() => history.push(`/admin/edit-material/${rolls?.data?.items[0]?.material?._id}`)}
                >
                  Zatvori
                </Button>
              </div>
            </Card>
          </div>
        </div>
      )}
      {rollData[0]?.status === 'U magacinu' && (
        <div className='dashboard'>
          <div className='card-wrapper'>
            <Card title='PREGLED ROLNE' bordered={false}>
              <Row
                gutter={16}
                style={{
                  backgroundColor: '#216c97',
                  margin: '10px 0',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              ></Row>
              {rollData &&
                rollData.length > 0 &&
                rollData.map((item, index) => (
                  <Row key={index} gutter={16} className='rollReserved2'>
                    <Col span={12}>
                      <p>Naziv: {item?.materialName}</p>
                      <p>Šifra: {item?.code}</p>
                      <p>Pozicija u magacinu: {item?.storagePosition}</p>
                      <p>ID rolne: {item?.rollId}</p>
                      <p>
                        Stanje na ulazu: {item?.initialLength}m ({item?.initialWeight}kg)
                      </p>
                      <p>
                        Fizičko stanje: {item?.length}m ({item?.weight?.toFixed(2)}kg)
                      </p>
                      <p>Rezervisano: {item?.reservedCodes?.length > 0 ? `${item?.reservedCodes}` : '0m (0kg)'}</p>
                      <p>
                        Dostupno stanje:
                        {item?.type === 'kg' &&
                          `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                        {item?.type === 'm2' &&
                          `${item?.length - item?.reservedLength}m` + `(${item?.initialWeight - item?.toReduce}kg)`}
                      </p>
                      <p>
                        Upotreba po radnim nalozima:
                        {item?.workOrderCodes?.length > 0
                          ? item?.workOrderCodes?.join(', ')
                          : 'Rolna još nije korištena'}
                      </p>
                      <p>Status rolne: {item?.status === 'spent' ? 'Potrošena' : item?.status}</p>
                    </Col>
                    <Col span={12} className='text-right'>
                      <img src={item?.QRCode ? item?.QRCode : ''} />
                    </Col>
                  </Row>
                ))}

              <Row
                gutter={16}
                style={{
                  backgroundColor: '#216c97',
                  margin: '10px -15px 10px -15px',
                  fontSize: '1.1rem',
                  color: 'white',
                }}
              ></Row>
              <div className='container'>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    style={{
                      maxWidth: '100%',
                      height: '40px',
                      marginRight: '5px',
                    }}
                    placeholder='Pozicija u magacinu'
                    onChange={(e) => setStoragePosition(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    marginTop: '15px',
                    justifyContent: 'center',
                  }}
                >
                  <Button style={{ width: '100%' }} type='primary' onClick={() => changeStoragePosition()}>
                    Promeni poziciju
                  </Button>
                </div>
              </div>
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  onClick={() => history.push(`/admin/edit-material/${rolls?.data?.items[0]?.material?._id}`)}
                >
                  Zatvori
                </Button>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewRoll;
