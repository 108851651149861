import React from 'react';
import { Form, Button, Card, Input } from 'antd';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  name: undefined,
};

const MachineForm = ({ data, updateHandler, createHandler, result, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let machine = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NOVA MAŠINA' : 'IZMENA MAŠINE'}`} bordered={false}>
            {!result && (
              <Form
                {...layout}
                name='basic'
                initialValues={machine}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >

                <Form.Item
                  label='Mašina'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite mašinu!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    {`${isNew ? 'Dodaj' : 'Izmeni'} mašinu`}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MachineForm;
