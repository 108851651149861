import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import MachineForm from '../../components/forms/MachineForm';

const EditMachine = (props) => {

  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [machine, fetchMachine] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { machineId } = props.match.params;

  useEffect(() => {
    if (machineId) {
      fetchMachine(`${SERVER_URL}/machines/${machineId}`, []);
    }
  }, [machineId, fetchMachine]);

  const updateMachine = async (data) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/machines`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      const machinesData = response.data;
      for (const machine of machinesData.items) {
        if (machine.name === data.name) {
          notification.error({
            message: 'Ova mašina već postoji.',
            placement: 'bottomRight',
          });
          return;
        }
      }
      await Axios.put(`${SERVER_URL}/machines/${machineId}`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Mašina je izmenjena.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/machines');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const createMachine = async (data) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/machines`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      const machinesData = response.data;
      for (const machine of machinesData.items) {
        if (machine.name === data.name) {
          notification.error({
            message: 'Ova mašina već postoji.',
            placement: 'bottomRight',
          });
          return;
        }
      }
      await Axios.post(`${SERVER_URL}/machines`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Mašina je kreirana.',
        placement: 'bottomRight',
      });
      setResult(true);
      history.push('/admin/machines');
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem sa dodavanjem mašine. Pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div style={{ textAlign: 'center' }}>
        {machineId && machine.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {machineId && !machine.isLoading && !machine.isError && machine.data && (
          <MachineForm data={machine.data} updateHandler={updateMachine} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
        {machineId && !machine.isLoading && machine.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!machineId && machine && !machine.data && (
          <MachineForm data={null} createHandler={createMachine} result={result} SERVER_URL={SERVER_URL} token={currentuser.data.token} />
        )}
      </div>
    </div>
  );
};

export default EditMachine;