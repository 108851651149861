import React from 'react';
import { Form, Button, Card, Select, Input } from 'antd';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const initialValues = {
  sku: undefined,
  name: undefined,
  zip: '',
  city: '',
  deliveryAddress: undefined,
  invoiceAddress: undefined,
  number: undefined,
  brojStavki: 1,
};

const CompanyForm = ({ data, updateHandler, createHandler, result, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let company = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          {/* eslint-disable-next-line no-useless-concat */}
          <Card title={`${isNew ? 'NOVA KOMPANIJA' : 'IZMENA KOMPANIJE'}`} bordered={false}>
            {!result && (
              <Form
                name='basic'
                initialValues={company}
                onFinish={(values) => onFinish(values, isNew)}
                onFinishFailed={onFinishFailed}
                layout='horizontal'
                form={form}
              >
                <Form.Item label='Šifra' name='sku' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Naziv kompanije'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite naziv kompanije!',
                    },
                  ]}
                  style={{ marginLeft: '38px' }}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Valuta (dana od dana otpreme robe)' name='currency'>
                  <Input />
                </Form.Item>

                <Form.Item label='PTT' name='zip' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Grad' name='city' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Adresa za isporuku'
                  name='deliveryAddress'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite adresu za isporuku!',
                    },
                  ]}
                  style={{ marginLeft: '20px' }}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Kontakt osoba za isporuku' name='contactDeliveryPerson'>
                  <Input />
                </Form.Item>

                <Form.Item label='Telefon osobe za isporuku' name='deliveryPersonPhone'>
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Adresa za fakturisanje'
                  name='invoiceAddress'
                  rules={[
                    {
                      required: true,
                      message: 'Molimo Vas unesite adresu za fakturisanje!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Kontakt osoba za fakture' name='contactPerson' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Telefon' name='phone' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Mobilni' name='mobile' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Email' name='email' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='PIB' name='pib' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Matični broj' name='idNumber' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Reg. broj' name='regNumber' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Šifra del.' name='industryCode' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item label='Komercijalista' name='salesRepresentative' className='companyField'>
                  <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                    {isNew ? 'Dodaj' : 'Izmeni'} kompaniju
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
