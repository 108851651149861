import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { WorkOrderForm } from '../../components/forms';
const QRCode = require('qrcode');

const EditWorkOrder = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [machines, fetchMachines] = useAxios('', [], currentuser.data.token, 'get');
  const [companies, fetchCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [products, fetchProducts] = useAxios('', [], currentuser.data.token, 'get');
  const [materials, fetchMaterials] = useAxios('', [], currentuser.data.token, 'get');
  const [workOrder, fetchWorkOrder] = useAxios('', [], currentuser.data.token, 'get');
  const [workOrders, fetchWorkOrders] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReservedWorkOrder, fetchRollsReservedWorkOrder] = useAxios('', [], currentuser.data.token, 'get');
  const [productsInWorkOrder, fetchProductsInWorkOrder] = useAxios('', [], currentuser.data.token, 'get');
  const [rollsReserved, fetchRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [rolls, setRolls] = useState([]);
  const [material, setMaterial] = useState([]);
  const [roll, setRoll] = useState([]);
  const [allRollsReserved, fetchAllRollsReserved] = useAxios('', [], currentuser.data.token, 'get');
  const [allRolls, fetchAllRolls] = useAxios('', [], currentuser.data.token, 'get');
  const { workOrderId } = props.match.params;

  // const server =
  //   process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
  //     ? 'http://localhost:' + process.env.FRONTEND_PORT
  //     : process.env.FRONTEND_URL;

  useEffect(() => {
    if (workOrderId) {
      fetchWorkOrder(`${SERVER_URL}/work-orders/${workOrderId}`, {});
      //   fetchRolls(`${SERVER_URL}/rolls-by-material?material=${materialId}`);
    }
    const filter = { workOrder: workOrderId };
    const filterTwo = workOrderId;
    fetchRollsReservedWorkOrder(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`);
    fetchProductsInWorkOrder(`${SERVER_URL}/products-in-workorder?workOrderId=${filterTwo}`);

    if (rollsReservedWorkOrder && rollsReservedWorkOrder.data && rollsReservedWorkOrder.data.items) {
      const filterSecond = { material: { $in: rollsReservedWorkOrder.data.items.map((item) => item.material?._id) } };
      fetchRollsReserved(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filterSecond)}`);
    }

    fetchMachines(`${SERVER_URL}/machines`, []);
    fetchCompanies(`${SERVER_URL}/companies`, []);
    fetchMaterials(`${SERVER_URL}/materials-wo`, []);
    fetchWorkOrders(`${SERVER_URL}/work-orders`, []);
    fetchProducts(`${SERVER_URL}/products`, []);
    fetchAllRollsReserved(`${SERVER_URL}/rolls-reserved`, []);
    fetchAllRolls(`${SERVER_URL}/rolls`, []);
  }, [
    fetchMachines,
    fetchCompanies,
    fetchMaterials,
    workOrderId,
    fetchWorkOrder,
    fetchRollsReservedWorkOrder,
    fetchProducts,
    rollsReservedWorkOrder,
    fetchRollsReserved,
    fetchWorkOrders,
    fetchAllRollsReserved,
  ]);

  const onSubmit = async (formData, isNew, productsAddedAdditionaly) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/work-orders` : `${SERVER_URL}/work-orders/${workOrderId}`;

    if (!formData.machine) {
      notification.error({
        message: 'Unesite masinu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.productionTime) {
      notification.error({
        message: 'Unesite vreme za izradu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.productCode) {
      notification.error({
        message: 'Unesite sifru prozivoda!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.productName) {
      notification.error({
        message: 'Unesite naziv prozivoda!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.orderDate) {
      notification.error({
        message: 'Unesite datum narudzbe!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.deliveryDate) {
      notification.error({
        message: 'Unesite rok isporuke!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.companyName) {
      notification.error({
        message: 'Unesite kupca!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.firstProductQuantity) {
      notification.error({
        message: 'Unesite tiraz za stampu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.cilinderVolume) {
      notification.error({
        message: 'Unesite obim cilindra!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.numberOfColors) {
      notification.error({
        message: 'Unesite broj boja!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.material) {
      notification.error({
        message: 'Unesite materijal!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.materialRoll) {
      notification.error({
        message: 'Izaberite rolnu!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }
    if (!formData.materialRollQuantity) {
      notification.error({
        message: 'Izaberite kolicinu materijala!',
        duration: 2,
        placement: 'bottomRight',
      });
      return;
    }

    const submitButton = document.getElementById('submitButton');
    submitButton.disabled = true;
    formData.productsAddedAdditionaly = productsAddedAdditionaly;

    try {
      if (isNew) {
        for (let i = 0; i < formData.materialRoll?.length; i++) {
          for (let j = i + 1; j < formData.materialRoll?.length; j++) {
            if (formData.materialRoll[i] === formData.materialRoll[j]) {
              notification.error({
                message: `Imate iste rolne, rolna je već prethodno dodata`,
                placement: 'bottomRight',
              });
              submitButton.disabled = false;
              return false;
            }
          }
        }
      } else {
        const promise = formData.materialRoll?.map(async (id) => {
          if (id) {
            const filter = { roll: id, workOrder: workOrderId };
            const res = await Axios.get(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });
            return res.data.items.length > 0 ? true : false;
          } else {
            return false;
          }
        });
        const existsRoll = promise && (await Promise.all(promise));
        if (existsRoll && existsRoll.includes(true)) {
          notification.error({
            message: `Imate iste rolne, rolna je već prethodno dodata`,
            placement: 'bottomRight',
          });
          submitButton.disabled = false;
          return false;
        }
      }

      const response = await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      let promises = formData.materialRollQuantity?.map(async (item, index) => {
        let data = {
          workOrder: isNew ? response.data.workOrderId : workOrderId,
          roll: formData.materialRoll[index],
          material: formData.material[index],
          materialQuantity: formData.materialRollQuantity[index],
          status: 'active',
          calcWaste: formData.calcWaste[index],
          goodMeters: formData.goodMeters[index],
        };
        if (data.roll) {
          await Axios.post(`${SERVER_URL}/rolls-reserved`, data, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
        }
        if (formData.materialRoll[index]) {
          let roll = await Axios.get(`${SERVER_URL}/rolls/${formData.materialRoll[index]}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          let filter = { roll: roll.data._id };
          let rollsReserved = await Axios.get(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          let workOrdersCodes = [];
          if (rollsReserved && rollsReserved.data && rollsReserved.data.items) {
            for (const item of rollsReserved.data.items) {
              workOrdersCodes.push(item.workOrder?.code);
            }
          }
          const material = await Axios.get(`${SERVER_URL}/materials/${formData.material[index]}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          const token = Array.from(Array(6), () => Math.floor(Math.random() * 36).toString(36)).join('');
          const matName = `Naziv: ${material.data.name}\n`;
          const matCode = `Šifra: ${material.data.code}\n`;
          const state = `Stanje: ${roll.data.weight}kg(${roll.data.length}m)\n`;
          const notification = `https://birografika-dashboard.concordsoft.solutions/admin/view-roll/${roll.data._id}`;
          const qrText = matName + matCode + state + notification;
          await Axios.put(
            `${SERVER_URL}/rolls/${formData.materialRoll[index]}`,
            { qrText, status: 'Čeka verifikaciju(iz magacina)', token },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
        }
      });
      if (promises) {
        await Promise.all(promises);
      }
      notification.success({
        message: `Radni nalog je ${isNew ? 'kreiran.' : 'izmenjen'}`,
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.href = '/admin/work-orders';
      }, 700);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      console.log(error);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const rollHandler = async (value, index) => {
    const response = await Axios.get(`${SERVER_URL}/rolls/${value}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    let r = [...roll];
    r[index] = response.data;
    const materialQuantity = r[index].rollsReserved.reduce(function (acc, curr) {
      return curr.status === 'active' ? acc + curr.materialQuantity : acc + 0;
    }, 0);
    if (r[index].rollsReserved.length > 0) {
      r[index].length -= materialQuantity;
      setRoll(r);
    } else {
      setRoll(r);
    }
  };

  const materialHandler = async (value, form, index) => {
    let formValue = form.getFieldsValue();
    let m = [...material];
    formValue.materialRollQuantity[index] = undefined;
    formValue.materialRoll[index] = undefined;
    form.setFieldsValue(formValue);
    m[index] = value;
    const filter = { material: value };
    if (value) {
      const response = await Axios.get(`${SERVER_URL}/rollsWO?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      let r = [...rolls];
      r[index] = response.data;
      r[index].items.map((item) => {
        for (let i = 0; i < item.rollsReserved.length; i++) {
          if (item.rollsReserved[i].status === 'active') {
            item.length -= item.rollsReserved[i].materialQuantity;
          }
          if (item.material.calculationMethod === 't*1000/p/s*1000') {
            item.state = item.material.width * item.material.specificWeight * item.length;
          } else {
            item.state = item.material.width * item.length;
          }
          if (item?.rollsReserved[i]?.workOrder?.workOrderStatus === 'zatvoren') {
            item.closedWorkOrder = true;
          }
        }
        return item;
      });
      r[index].items = r[index].items.filter((item) => item.length > 0);
      setRolls(r);
    }
    setMaterial(m);
  };

  const editRollReserved = async (data, form, index) => {
    let rollReserved = form.getFieldValue('materialRollQuantityReserved');
    try {
      await Axios.put(
        `${SERVER_URL}/rolls-reserved/${data._id}`,
        { materialQuantity: rollReserved[index] },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const removeRollReserved = async (id) => {
    try {
      let rollReserved = await Axios.get(`${SERVER_URL}/rolls-reserved/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      let roll = rollReserved.data.roll;
      await Axios.delete(`${SERVER_URL}/rolls-reserved/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      let material = await Axios.get(`${SERVER_URL}/materials/${roll.material}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      let filter = { roll: roll._id };
      let rolls = await Axios.get(`${SERVER_URL}/rolls-reserved?filter=${JSON.stringify(filter)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      if (rolls.data && rolls.data.count === 0) {
        const token = Array.from(Array(6), () => Math.floor(Math.random() * 36).toString(36)).join('');
        const matName = `Naziv: ${material.data.name}\n`;
        const matCode = `Šifra: ${material.data.code}\n`;
        const state = `Stanje: ${roll.weight}kg(${roll.length}m)\n`;

        const notification = `https://birografika-dashboard.concordsoft.solutions/admin/view-roll/${material.data.rollID}`;
        const qrText = matName + matCode + state + notification;
        await Axios.put(
          `${SERVER_URL}/rolls/${roll._id}`,
          { qrText, status: 'U magacinu', token },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
      }
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const removeProductsInWo = async (id) => {
    try {
      // console.log(id);
      let productsWoRes = await Axios.delete(`${SERVER_URL}/delete-products-in-workorder/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };

  const isDataFetched =
    !workOrder.isLoading &&
    workOrder.data &&
    currentuser.language &&
    !materials.isLoading &&
    materials.data &&
    !products.isLoading &&
    products.data;

  return (
    <div style={{ padding: '8px' }}>
      <div>
        {!isDataFetched && (
          <div style={{ textAlign: 'center' }}>
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          </div>
        )}

        {!workOrderId && isDataFetched && (
          <WorkOrderForm
            isNew={true}
            machines={machines.data}
            companies={companies.data}
            materials={materials.data}
            material={material}
            rolls={rolls}
            products={products.data}
            language={currentuser.language}
            roll={roll}
            onSubmit={onSubmit}
            selectMaterial={materialHandler}
            editRollReserved={editRollReserved}
            rollHandler={rollHandler}
            removeRollReserved={removeRollReserved}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            workOrders={workOrders.data}
            allRollsReserved={allRollsReserved.data}
            allRolls={allRolls.data}
          />
        )}
        {workOrderId &&
          !workOrder.isError &&
          workOrder.data &&
          isDataFetched &&
          !rollsReservedWorkOrder.isLoading &&
          rollsReservedWorkOrder.data &&
          !rollsReserved.isLoading &&
          rollsReserved.data && (
            <WorkOrderForm
              isNew={false}
              data={workOrder.data}
              machines={machines.data}
              companies={companies.data}
              materials={materials.data}
              material={material}
              rollsReserved={rollsReserved.data}
              allRollsReserved={allRollsReserved.data}
              rollsReservedWorkOrder={rollsReservedWorkOrder.data}
              productsInWorkOrder={productsInWorkOrder.data}
              removeProductsInWo={removeProductsInWo}
              rolls={rolls}
              roll={roll}
              products={products.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              selectMaterial={materialHandler}
              rollHandler={rollHandler}
              editRollReserved={editRollReserved}
              removeRollReserved={removeRollReserved}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              allRolls={allRolls.data}
            />
          )}
      </div>
    </div>
  );
};

export default EditWorkOrder;
