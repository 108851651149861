import React, { useContext } from 'react';
import { Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';

const RollsTable = ({ data, columnKeys, }) => {
  const user = useContext(UserContext);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => (dataIndex !== 'amount' && {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button type='primary' onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} size='small' style={{ width: 90, marginRight: 8 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecordSpan = Array.isArray(dataIndex) ? !dataIndex.includes('category') ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex[0]][dataIndex[1]][dataIndex[2]] : record[dataIndex];
      let filterRecord = filterRecordSpan.props.children[0] + filterRecordSpan.props.children[1].props.children.toString() + filterRecordSpan.props.children[2];
      if (!filterRecord || !value) return false;
      return filterRecord?.toString()?.toLowerCase().includes(value?.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys?.map((item) => ({
    key: item.originalName,
    title: item.alternativeName.toUpperCase(),
    dataIndex: ['title', 'category'].includes(item.originalName) ? [item.originalName, user.language ? user.language.selected.code : ''] : item.originalName,
    ...getColumnSearchProps(['title', 'category'].includes(item.originalName) ? [item.originalName, user.language ? user.language.selected.code : ''] : item.originalName),
  }));


  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={false}
      // pagination={{
      //   defaultPageSize: 15,
      //   position: 'bottom',
      //   showSizeChanger: false,
      //   pageSizeOptions: ['10', '20', '50', '100'],
      //   hideOnSinglePage: true,
      // }}
      />
    </div>
  );
};

export default RollsTable;
